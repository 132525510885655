import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import TablaCalendarizacionesIdTicket from "./TablaCalendarizacionesIdTicket";


function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item "> 
                        <Link to={"/ListaTickets"}>
                            Tickets                         
                        </Link>
                    </li>
                    <li className="breadcrumb-item active"> Calendarizaciones
                    </li>
                </ol>
            </div>
            <h4 className="page-title">Calendarizaciones Recientes</h4>
        </div>
    )
    }

function PaginaListaCalendarizaciones(props) {
        useEffect(() => { actualizarInfoPagina(props) }, [props]);
        return (
            <div className='container'>
                <TituloPagina/>
                <TablaCalendarizacionesIdTicket />
  {/* // //               <ModalDetalleUsuario/> */}
                
            </div>
        )
}
export default PaginaListaCalendarizaciones