import locgt from 'Connection/transacciones/locgt';
import React from 'react'
import prloc from 'Connection/transacciones/prloc';

function useListaLocalesPrecios({
    dataOption = 0
}) {
    const [values, setValues] = React.useState([]);
    const [totalValues, setTotalValues] = React.useState("");
    const [isFetching, setIsFetching] = React.useState(false)
    const fetchLocales = async(filtros = {}) => {
        
        setIsFetching(true)
        const request = await prloc(filtros, dataOption)
        // console.log("request",request,filtros)
        if (request.ans.stx === "ok") {
            setValues(request.ans.loc? request.ans.loc: [])
            setTotalValues(request.ans.prt? {ttc:request.ans.ttc, ttf:request.ans.ttf, tap:request.ans.tap, prt:request.ans.prt,tst:request.ans.tst, pds:(request.ans.prt - request.ans.tap)}: {})
        }
        setIsFetching(false)
    }
    return {
        values,
        totalValues,
        options: values,
        fetchLocales,
        isFetching
    }

}

export default useListaLocalesPrecios