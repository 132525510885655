import React, {useEffect} from 'react'
import { Form } from 'react-bootstrap'

function Texto({formulario, index}) {
    // console.log(formulario.values.listaRequerimientos[index])
    useEffect(() => {
        if(('alternativas' in formulario.values.listaRequerimientos[index])) {
            const toUpdateReq = [...formulario.values.listaRequerimientos]
            toUpdateReq[index] = {
                id: toUpdateReq[index].id,
                tipo: toUpdateReq[index].tipo,
                titulo: toUpdateReq[index].titulo,
                next: ""
            } // setea solo los campos necesarios
            formulario.setFieldValue("listaRequerimientos",toUpdateReq)
        }
    }, []);

    const handleOnChange = (e) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index] = {
            id: toUpdateReq[index].id,
            tipo: toUpdateReq[index].tipo,
            titulo: e.target.value,
            next: toUpdateReq[index].next
        }
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)

    }
    const onChangeOpcion = (e) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index].next = e.target.value 
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }
    return (
        <>
            <div className="row">
                <div className="col-9">

            <Form.Control
                value={formulario.values.listaRequerimientos[index].titulo}
                onChange={handleOnChange}
                type='text'
                maxLength={128}
                required
            />
            </div>
            <div className="col-3">
            <Form.Control
                maxLength={128}
                onChange={(e)=> onChangeOpcion(e)}
                id={4}
                type='number'
                placeholder={`N° Requerimiento`}
                required
            />
            </div>
            </div>  
        </>
    )
}

export default Texto