import { requestData } from "Connection/transacciones"

const aupon = async({
    userList,
    action
}) => {
    const jsonBody = {
        hdr: {
            txi: 54,
            req: "aupon",
            ott: true,
        },
        dat: {
            res: parseInt(action,10),
            uli: userList
        }
    }

    return await requestData(jsonBody)

}

export default aupon;