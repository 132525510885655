import { requestData } from "Connection/transacciones"

const prloc = async (dat = {}, option = 0) => {
    const jsonBody = {
        hdr: {
            txi: 93,
            req: "prloc",
            ott: true,
        },
        dat: {
                ...dat,
        }
    }
    // console.log("locrp", jsonBody.dat)
    return await requestData(jsonBody)

}

export default prloc;