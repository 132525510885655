import { requestData } from "Connection/transacciones"
import { clean } from "Utils/rut"


const clact = async(idCliente,nuevosDatos) => {
    const jsonBody = {
        "hdr": {
            "txi": 83,
            "req": "clact",
            "ott": true,
        },
        "dat": {
            "cid": parseInt(idCliente) ,
            "name": nuevosDatos.name    || undefined,
            "phon": nuevosDatos.phon    || undefined,
            "mail": nuevosDatos.mail    || undefined,
            "rut" : clean(nuevosDatos.ndoc)    || undefined,
            "pai" : nuevosDatos.pai.value   || undefined

        }
    }
    return await requestData(jsonBody)
    // console.log(jsonBody.dat)

}

export default clact