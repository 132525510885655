import wedat from 'Connection/transacciones/wedat';
import React from 'react'

function useDatosUsuarioPerfil() {
    const [datos, setValue] = React.useState(null);
    const [isFetching, setIsFetching] = React.useState(false);

    const refreshData = async() => {
        setIsFetching(true);
        const request = await wedat();
        if(request.ans.stx === "ok") {
            setValue(request.ans)
        }
        setIsFetching(false)
    } 
    return {   
        datos,
        isFetching,
        refreshData,
    }
}

export default useDatosUsuarioPerfil