import Tabla from "Components/Tabla/Tabla";
import InputTexto from "Components/FormElements/InputTexto";
import { Spinner, Button, Modal, ModalBody } from "react-bootstrap";
// import { Button } from "react-bootstrap";
// import catad from "Connection/transacciones/catad";
import matrx from "Connection/transacciones/matrx";
import { Link } from 'react-router-dom';
import useListaCategorias from "Hooks/Listas/useListaCategorias";
import React, { useState, useEffect, useMemo, useCallback} from "react";

const propsStyles = {
    asRow: true,
    labelSize:3,
    size: "sm"
}

const thClassName= "ps-1"


function GestionarCategorias({datosUsuario}){

    const {
        isFetching,
        catalogo,
        handlerData,
        agregarCategoria,
        eliminarCategoria,
        modificarCategoria
    } = useListaCategorias(datosUsuario.cid)
    // console.log(datosUsuario.cid)
    // const [fetching, setIsFetching] = useState(false);
    // const [catalogo, setCatalogo] = useState([]);

    const [catAdd, setCatAdd] =  React.useState("");
    const [catSelected, setCatSelected] = React.useState([])
    // console.log(catAdd)
    // const [catAdd, setCatAdd] =  React.useState("");
    const [catUpdate, setCatUpdate] =  React.useState("");
    const [catClicked, setCatClicked] =  React.useState("");
    const [catClickedN, setCatClickedN] =  React.useState("");


    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const handleAddShow = useCallback(() => setIsModalAddOpen(true),[]);
    const handleAddClose = useCallback(() => setIsModalAddOpen(false),[]);
    const [isModalUpdOpen, setIsModalUpdOpen] = useState(false);
    const handleShowUpdate = useCallback(() => setIsModalUpdOpen(true),[]);
    const handleCloseUpdate = useCallback(() => setIsModalUpdOpen(false),[]);

    useEffect(async()=> {
        handlerData();
    },[])


    const columnas = useMemo(() => [
        {   
            Header: "Nombres",
            accessor: "nam",
            Cell: props => {
                const {row,value} = props
                return (
                    <Link to={"/#"} onClick={e => {
                        e.preventDefault()
                        setCatClicked(row.original.cod)
                        setCatClickedN(value)
                        handleShowUpdate()
                    }}>
                        {value}
                    </Link>)
            },
            thClassName
        }
    ],[])


    const onAddChange = (value) => setCatAdd(value);
    const onUpdateChange = (value) => setCatUpdate(value);

    function Agregar(){
        // console.log(catAdd)
        agregarCategoria(catAdd)
        handleAddClose()

    }
    function Modificar(){
        // console.log([({"cod":catClicked,"nam":catUpdate})])
        modificarCategoria([({"cod":catClicked,"nam":catUpdate})])
        handleCloseUpdate()

    }

    return(
        <div className="card">
            <div className="card-body">
                <Tabla
                    columns={columnas}
                    data={catalogo}
                    globalFilter={true}
                    titleSection={
                        <>
                            <a href="/#" onClick={(e) => {
                                e.preventDefault()
                                handleAddShow()
                                }}> Agregar Categoria
                                </a>
                            <Modal 
                                show={isModalAddOpen} 
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                onHide={handleAddClose}>
                                <Modal.Header>
                                   <h4>Agregar Categoria</h4> 
                                </Modal.Header>
                                <ModalBody>
                                    <div className="mb-1">
                                        <InputTexto 
                                                {...propsStyles}
                                                label={"Categoria"}
                                                // name={"mail"}
                                                // disabled={isDisabled}
                                                // value={(catAdd)}
                                                // validation={formulario.fieldValidation.mail}
                                                onChange={onAddChange}
                                        />
                                    </div>
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={()=>handleAddClose()}>Cancelar</Button>
                                    <Button onClick={()=>Agregar()} disabled={catAdd.length === 0}>Agregar</Button>
                                </Modal.Footer>
                            </Modal>
                        </>}
                    tableClass={"table table-centered  table-hover mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={isFetching}
                    select
                    onSelected={(rows) => setCatSelected(rows.map(row=>row.cod))}
                />
                <div className="modal-footer">
                    {/* <Button 
                    disabled={catSelected.length === 0}>
                        Modificar
                    </Button> */}
                    <Button 
                        variant="outline-danger" 
                        onClick={() => eliminarCategoria(catSelected)}
                        disabled={catSelected.length === 0}>
                        Deshabilitar
                    </Button>
                    
                    <Modal 
                                show={isModalUpdOpen} 
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                onHide={handleCloseUpdate
                        }>
                                <Modal.Header>
                                   <h4>Modificar Categoria: <span className="text-info">{catClickedN}</span></h4> 
                                </Modal.Header>
                                <ModalBody>
                                    <div className="mb-1">
                                        <InputTexto
                                            {...propsStyles}
                                            label={"Categoria Nueva"}
                                            onChange={onUpdateChange}
                                        />
                                    </div>
                                </ModalBody>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={()=>handleCloseUpdate
                                ()}>Cancelar</Button>
                                    <Button onClick={()=>Modificar()}>Realizar Cambios</Button>
                                </Modal.Footer>
                            </Modal>
                </div>
            </div>
        </div>
    )
    
}
export default GestionarCategorias
