import React,{ useEffect } from "react";
import clnew from "Connection/transacciones/clnew.js";
import {Button, Spinner, Form, Col} from "react-bootstrap";
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast'
// import useFormulario from 'Hooks/Custom/useFormulario'
import InputTexto from "Components/FormElements/InputTexto"
import { phoneRegex, validarEmail } from 'Utils/validations'
import Select from 'react-select';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import { useNavigate } from 'react-router-dom';
import { customValidateRut, rutAllowedInputs, rutRegex, rutRegexDots } from "Utils/rut";


const propsStyles = {
    asRow: true,
    labelSize:3,
    size: "sm",
    className: 'mb-2',
}

function removechar(string){
    string = string.replace(/-/g,"")
    string = string.split(".").join("")
    return string
}

function TituloPagina() {
  return (
      <div className="page-title-box">
          <div className="page-title-right">
              <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to={"/Dashboard"} >Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item"><Link to={"/GestionClientes/Clientes"} >Clientes</Link></li>
                  <li className="breadcrumb-item active">Creación de Cliente</li>
              </ol>
          </div>
          <h4 className="page-title">Creación de Cliente</h4>
      </div>
  )
}


function LayoutAgregarCliente(props) {
    const paises = [
        {
            label: "Chile",
            value: 56
        },{
            label: "Mexico",
            value: 52
        }
    ]
    useEffect(() => { actualizarInfoPagina(props) }, []);
    const navigation = useNavigate()
     
    // Inputs formulario
    const [rut, setRut] = React.useState("");
    const [nombre, setNombre] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [pais, setPais] = React.useState([]);
    // const [reponedorEx, setReponedorEx] = React.useState("");
    // handle Change
    const onChangeRut = (value) => {
        if (rutRegexDots.test(value)) setRut(value.toUpperCase());
    }
    const onChangeNombre = (value) => setNombre(value);
    const onChangeEmail = (value) => setEmail(value);
    // const onReponedorClick = (value) => setReponedorEx(value);
    
    const onChangeTelefono = (value) => {
        if(phoneRegex.test(value)) setTelefono(value)
    } 

    // validadores
    const rutValido = React.useMemo(()=> customValidateRut(rut),[rut]);
    const nombreValido = React.useMemo(() => {
        if(nombre === "") return undefined
        else return true
    } ,[nombre])
    const emailValido = React.useMemo(()=> {
        if(email === "") return undefined
        else return validarEmail(email);
    },[email])
    const telefonoValido = React.useMemo(()=> {
        if(telefono === "") return undefined
        else return true;
    },[telefono])

    // const reponedorExValido = React.useMemo(()=> {
    //     if(reponedorEx === "") return undefined
    //     else return true;
    // },[reponedorEx])

    const [isSubmiting, setIsSubmiting] = React.useState(false);
    const handleSubmit = async() => {
        setIsSubmiting(true);
        const request = await clnew({
            rutCliente: rut,
            nombreCliente: nombre,
            telefonoCliente: telefono,
            emailCliente: email,
            paisCliente: pais.value
            // reponedorExclusivo: reponedorEx
        })
        if(request.ans.stx === "ok") {
            toast.success(request.ans.msg)
            navigation('..')
        }
        else if(request.ans.stx === "nk") {
        toast.error((t) => (<>
            {request.ans.msg}
        </>) )
        }
        setIsSubmiting(false);
    }

    return (
        <div className='container'>
            <TituloPagina/>
            <div className="card">
                <div className="card-body">
                    <div className="card-header detalle-ticket-header">
                        <h5 className="card-title mb-0" >
                            Datos generales
                        </h5>
                    </div>
                    <div className="card-body pb-1 pt-2 detalle-ticket-body">
                        
    {/* <h4>
         Datos del Cliente
    </h4> */}
                        <InputTexto 
                            {...propsStyles}
                            label="Rut"
                            disabled={isSubmiting}
                            value={rut}
                            validation={rutValido}
                            onChange={onChangeRut}
                        />   
                        <InputTexto
                            {...propsStyles}
                            label="Nombre(s)"
                            value={nombre}
                            onChange={onChangeNombre}
                            validation={nombreValido}
                            disabled={isSubmiting}
                        /> 
                            
                        <div className="row">
                            <div className="col-sm-3">
                                <label> Pais</label>
                            </div>
                            <div className="col-sm-9">
                                <Select
                                    placeholder="Elegir un pais"
                                    value={pais}
                                    onChange={ opt => setPais(opt)}
                                    options={paises}
                                    // validation={clienteValido}
                                />
                            </div>
                        </div>    
                        {/* <Form>
                        
                            <div className="mb-2">
                            <Form.Label column sm={propsStyles.labelSize} > ¿Reponedores exclusivos?</Form.Label>
                             
                            <Form.Check
                                inline
                                label="Si"
                                name="group1"
                                // size = "sm"
                                // as={()=>setReponedorEx("si")}
                                onChange={()=>onReponedorClick("si")}
                                type={"radio"}
                                // id={`inline-radio`}
                                id={reponedorEx}
                            />
                            <Form.Check
                                inline
                                label="No"
                                name="group1"
                                onChange={()=>onReponedorClick("no")}
                                type={"radio"}
                                id={reponedorEx}
                            />
                            </div>
                        </Form> */}
                        
             <h4>
                 Contacto
             </h4>
                        <InputTexto 
                            {...propsStyles}
                            label={"Mail"}
                            disabled={isSubmiting}
                            value={email}
                            validation={emailValido}
                            onChange={onChangeEmail}
                        />

                        <InputTexto 
                            {...propsStyles}
                            label="Teléfono"
                            disabled={isSubmiting}
                            value={telefono}
                            onChange={onChangeTelefono}
                            validation={telefonoValido}
                        />
                        

                        <div className="d-flex justify-content-end">
                           
                            <Button 
                                variant="primary"   
                                onClick={handleSubmit} 
                                disabled={!(rutValido && nombreValido && emailValido && telefonoValido)}
                                // disabled={!(rutValido && nombreValido && emailValido && telefonoValido && reponedorExValido)}
                            >
                                <div style={{width:"101.559px"}}>
                                    {isSubmiting === true && <Spinner animation="border" size='sm' />}
                                    {isSubmiting === false && 'Guardar Cliente'}
                                </div>

                            </Button>
                        </div>
                    </div>
                
                </div>
            
            </div>
            
        </div>
    );

}


export default LayoutAgregarCliente;
