import locgt from 'Connection/transacciones/locgt';
import React from 'react';
import taskm from 'Connection/transacciones/taskm';

function useListaTareas({
    dataOption = 0
}) {
    const [values, setValues] = React.useState([]);
    const [isFetching, setIsFetching] = React.useState(false)
    const fetchLocales = async(filtros = {}) => {
        setIsFetching(true)
        // console.log("aca",filtros,"aca2",dataOption)
        const request = await taskm(filtros, dataOption)

        if (request.ans.stx === "ok") {
            // console.log("reqi",request.ans.tas)
            setValues(request.ans.tas? request.ans.tas: []) 
        }
        setIsFetching(false)
    }
    return {
        values,
        options: values,
        fetchLocales,
        isFetching
    }

}

export default useListaTareas