import { requestData } from "Connection/transacciones"

const usact = async(idUsuario,nuevosDatos) => {
    const jsonBody = {
        "hdr": {
            "txi": 73,
            "req": "usact",
            "ott": true,
        },
        "dat": {
            "uid": parseInt(idUsuario) ,
            "name": nuevosDatos.name    || undefined,
            "snam": nuevosDatos.snam    || undefined,
            "phon": nuevosDatos.phon    || undefined,
            "addr": nuevosDatos.addr    || undefined,
            "bank": parseInt(nuevosDatos.bank)  || undefined,
            "acty": nuevosDatos.acty    || undefined,
            "acnu": nuevosDatos.acnu    || undefined,
            "comu": parseInt(nuevosDatos.comu)|| undefined,
            "mail": nuevosDatos.mail    || undefined,
            "ndoc": nuevosDatos.ndoc    || undefined
        }
    }
    return await requestData(jsonBody)

}

export default usact