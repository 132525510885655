import gehol from 'Connection/transacciones/gehol'
import clall from 'Connection/transacciones/clall'
import React from 'react'

function useCliente() {
    const [options, setOptions] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)

    const fetch = async(dependencies = {}) => {
        setIsFetching(true)
        const request = await clall();
        if(request.ans.stx === "ok") {
            if(request.ans.wus) {
                const newValues = request.ans.wus.map(item => ({
                    "value": item.cid,
                    "label": item.name
                }))
                setOptions(newValues)
            } else {
                setOptions([])
            }
        }
        setIsFetching(false)

    }

    return {
        options,
        isFetching,
        fetch
    }
    
}

export default useCliente