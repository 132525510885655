import { backend_request } from "Connection/backend_reduce";
export const requestData = async(jsonBody) => {
    let fetchedData;
    await backend_request(jsonBody, data => fetchedData = data )
    return fetchedData
}

export const regi0 = async() => {
    const jsonBody = {
        hdr: {
            txi: 6,
            req: "regi0",
            ott: true
        },
        dat: {
        }
    }
    return await requestData(jsonBody)
}


export const taskr = async({idDetalle, tipoDetalle}) => {
    const jsonBody = {
        "hdr": {
            "txi": 48,
            "req": "taskr",
            "ott": true
        },
        "dat": {
            "tid": parseInt(idDetalle,10),
            "ful":tipoDetalle
        }
    }
    return await requestData(jsonBody)
}


// setOpciones(data.ans.loc.map(({nam: label, lid: value})=>({value, label})))

