import { requestData } from "Connection/transacciones"

const aupic = async(userId, index) => {
    const jsonBody = {
        hdr: {
            txi: 53,
            req: "aupic",
            ott: true,
        },
        dat: {
            uid: parseInt(userId,10),
            phi: Number(index) + 1,
        }
    }

    return await requestData(jsonBody)

}

export default aupic;