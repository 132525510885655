import { requestData } from "Connection/transacciones"

const geprf = async({ usr, uid }) => {
    const jsonBody = {
        hdr: {
            "txi": 70,
            "req": "geprf",
            "ott": true,
        },
        dat: {
            usr: usr,
            uid: uid && parseInt(uid)
        }
    }
    return await requestData (jsonBody);
}

export default geprf;