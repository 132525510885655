import Tabla from 'Components/Tabla/Tabla';
import useUsuariosApp from 'Hooks/Listas/useListaUsuariosApp';//sujeto a mod
// import useListaUsuarioWeb from "Hooks/Listas/useListaUsuariosWeb"
import useListaUsuariosComerciales from "Hooks/Listas/useListaUsuariosComerciales";
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import FiltrosUsuariosComercial from './FiltrosUsuariosComercial';
import { Button } from 'react-bootstrap';
import { format } from 'Utils/rut'

// const thClassName= "ps-1"

// function TablaUsuariosComercial () {
//     const usuariosComerciales = useListaUsuarioWeb();
//     const columnas = React.useMemo(() => [
//         {
//             Header: 'Nombre',
//             accessor: item => `${item.name}, ${item.snam}`,
//             Cell: (props) => {
//                 const {value, row} = props
//                 return (
//                     <Link to={"User"} state={{userID: row.original.uid}} >
//                         {value}
//                     </Link>
//                 )
//             },
//             thClassName,
//         },

//         {
//             Header: 'Rut',
//             accessor: 'rut',
//             thClassName
//         }
//         ,

//         // {
//         //     Header: 'Comuna',
//         //     accessor: 'comuna',
//         //     thClassName
//         // },
//         // {
//         //     Header: 'Región',
//         //     accessor: 'region',
//         //     thClassName
//         // },
     
//         // {
//         //     Header: 'Banco',
//         //     accessor: 'banco',
//         //     thClassName
//         // }, 
//     ],[])
    
//     return (
//         <div className='card'>
//             <div className="card-body">
//                 <Tabla
//                     columns={columnas}
//                     data={usuariosComerciales.values}
//                     globalFilter={true}
//                     // titleSection={
//                     //     <FiltrosUsuariosComercial 
//                     //         totalUsuarios={usuariosComerciales.values.length}
//                     //         refreshUsers={usuariosComerciales.refreshUsers}    
//                     //     />
//                     // }
//                     tableClass={"table table-centered table-hover mb-0"}
//                     theadClassName={"table-light"}
//                     cellClassName={"p-1"}
//                     loading={usuariosComerciales.isFetching}
//                 />
//             </div>

//         </div>
        
//     )
// }

const thClassName= "ps-1"
const columnas = [
    {
        Header: 'Nombre',
        accessor: item => `${item.name}, ${item.snam}`,
        Cell: (props) => {
            const {value, row} = props
             return (
                 <Link to={"UserCom"} state={{idUser: row.original.uid}} >
                     {value}
                 </Link>
             )
         },
        thClassName
    },{
        Header: 'Cargo',
        accessor: "job",
        thClassName
    },{
        Header: 'Mail',
        accessor: "mail",
        thClassName
    },{
        Header: 'Teléfono',
        accessor: "phon",
        thClassName
    },{
        Header: 'Rut',
        accessor: "rut",
        Cell: props =>{
            const {value} = props 
            return(format(value))
        },
        thClassName
    },{ 
        Header: 'Cliente',
        accessor: "clin",
        thClassName
    }
]
function ButtonAgregarUsuario () {
    const nav = useNavigate();
    return (
        <Button onClick={() => nav("AgregarUsuario")}>
            <i className="mdi mdi-plus me-1" />
            Agregar Usuario
        </Button>
    )
}

function TablaUsuariosComercial (){
    const usuariosCom = useListaUsuariosComerciales();
    // console.log("user",usuariosWeb)
    React.useEffect(() => {
        usuariosCom.fetch();
    } , [])
    return(
        <div className='card'>
            
            <div className="card-body">   
                <Tabla
                    columns={columnas}
                    data={usuariosCom.lista}
                    loading={usuariosCom.isFetching}
                    globalFilter={true}
                    titleSection={<ButtonAgregarUsuario />}
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}

                /> 
            </div>
    </div>
)

}


export default TablaUsuariosComercial