import { requestData } from "Connection/transacciones"

const locmc = async (dat = {}, option = 0) => {
    const jsonBody = {
        hdr: {
            txi: 91,
            req: "locmc",
            ott: true,
        },
        dat: {
        }
    }
    // console.log("locrp", jsonBody)
    return await requestData(jsonBody)

}

export default locmc;