import React, { useEffect, useState } from "react";
import { backend_request } from "../../Connection/backend_reduce";
import "Assets/css/detalleTicketStyle.css"
import ticde from "Connection/transacciones/ticde";
import { Spinner } from "react-bootstrap";
const headerStyle = {backgroundColor:"#f1f3fa"}
const bodyStyle = {border:'1px solid #f1f3fa'}

const iconListClass = [
    "mdi mdi-pencil",
    // "mdi mdi-checkbox-marked-outline",
    "mdi mdi-checkbox-marked-circle-outline",
    "mdi mdi-checkbox-multiple-marked-outline",
    "mdi mdi-star-plus-outline",
    "mdi mdi-camera",
    "mdi mdi-calendar-clock",
    "mdi mdi-storefront-outline",
    "mdi mdi-directions-fork"
]
const tooltipIcon = [
    "Caja de texto",
    "Selección unica",
    "Selección múltiple",
    "Valoración",
    "Tomar foto",
    "Ingresar fecha y hora",
    "Reponer producto",
    "Requerimiento Condicional"
]


function ItemRequerimiento ({requerimiento}) {

    return (
        <>
        <div className="row">
            <div className="col" style={{maxWidth:"60px"}}>
                <strong > {requerimiento.qid} </strong>
                
            </div>
            <div className="col" style={{maxWidth:"20px"}}>
                <i 
                    className={iconListClass[requerimiento.aty-1]}
                    style={{fontSize:"16px"}}
                    rel="tooltip"
                    data-toggle="tooltip" 
                    data-placement="top" 
                    title={tooltipIcon[requerimiento.aty-1]}
                ></i>
            </div>
            <div className="col-2 ml-1">
                {requerimiento.tiq}
            </div>
            
            {(requerimiento.nx2===0)? 
            
            (requerimiento.nxt!==0)?
            <div className="col-3 ml-1">
                <div className="row">
                    <i 
                        className={"mdi mdi-arrow-right"}
                        style={{fontSize:"12px"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" 
                    > Requerimiento N°{requerimiento.nxt}
                    </i>
                </div>
            </div>: 
            <div className="col-3 ml-1">
                <div className="row">
                    <i 
                        className={"mdi mdi-arrow-collapse-right"}
                        style={{fontSize:"12px"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" 
                    > Requerimiento Final
                    </i>
                </div>
            </div>:
            <div className="col-3 ml-1">
                <div className="row">
                    <i 
                        className={"mdi mdi-arrow-right"}
                        style={{fontSize:"12px"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" 
                    > Requerimiento N°{requerimiento.nxt}
                    </i>
                </div>
                <div className="row">
                    <i 
                        className={"mdi mdi-arrow-right"}
                        style={{fontSize:"12px"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" 
                    > Requerimiento N°{requerimiento.nx2}
                    </i>
                </div>
            </div>}
            <div className="col">
            {requerimiento.alt &&
            <ul className="mb-0">
                {requerimiento.alt.map((item, index)=> {return(
                    <li key={"de"+index} 
                    style={{fontSize:"12px"}}>
                        {item.txt}
                    </li>
                )})}
            </ul>
            }
            
            {(requerimiento.al1 !== "" && requerimiento.al1 !== "null") &&
            <ul className="mb-0">
                <li key={"de1"} 
                        style={{fontSize:"12px"}}>
                    {requerimiento.al1}
                </li>
                <li key={"de2"} 
                        style={{fontSize:"12px"}}>
                    {requerimiento.al2}
                </li>

            </ul>
            }
            </div>

        </div>
        <hr className="separador"/>
        </>
    )
}

function ListaRequerimientos ({lista}) {
    return (
        <div>
            {lista.map((req, index) => <ItemRequerimiento requerimiento={req} key={"req-"+index}/> )}
        </div>
    )
}


function Requerimientos ({ticks,datosTicket}){
    // const [ticde, setTicde] = useState();
    const [listaRequerimientos, setListaRequerimientos] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(true)
    React.useEffect(() => {
        const run = async() => {
            if(datosTicket) {
                const request = await ticde(datosTicket.tid);
                if(request.ans.stx === "ok") {
                    setListaRequerimientos(request.ans.tas);
                }
            }
            setIsFetching(false)
        }
        run();
    }, [])

    return (
        <div>

            {ticde && 
                <div className="card-body pt-0">
                    <div className="card-header" style={headerStyle}>
                        <h5 className="card-title mb-0" >
                            Requerimientos
                        </h5>
                    </div>
                    <div className="card-body" style={bodyStyle}>
                        {isFetching && (
                            <div className="text-center">
                                <Spinner animation="border" />
                            </div>
                        )}
                        <ListaRequerimientos lista={listaRequerimientos} />
                    </div>
                </div>
            }
            
        </div>
    )
}

export default Requerimientos