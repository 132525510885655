import React, { useState, useEffect} from "react";
import { backend_request } from "Connection/backend_reduce";
import { fechaDefault, limpiarFecha } from "Utils/funcionesFechaHora";
import FiltroTareas from "./FiltroTareas";
import ResumenTicket from "./ResumenTicket";
import TareasLista from "./TareasLista";
import taskw from "Connection/transacciones/taskw";
import { Spinner } from "react-bootstrap";

const ContenidoTareasTicket = ({datosTicket}) => {
    const [datosTaskw, setDatosTaskw] = useState();
    const [loading, setIsLoading] = React.useState(false)
    
    const backendGetTaskw = async(ticket_id, fecha_inicial, fecha_final, id_supervisor, id_vendedor) => {
        const desdeFecha = fecha_inicial !== ''? limpiarFecha(fecha_inicial): 'null'
        const hastaFecha = fecha_final !== ''? limpiarFecha(fecha_final): 'null'
        const request = await taskw({
            "tid": ticket_id,
            "ind": desdeFecha, // desde
            "end": hastaFecha,   // hasta
            "vid": id_vendedor, // id vendedor  
            "sid": id_supervisor, // id supervisor
        })
        setDatosTaskw(request)
    }
    const updateTickets = React.useCallback(async(inicio, fin) => {
        const request = await taskw({
            idTicket: datosTicket.tid,
            desdeFecha: inicio,
            hastaFecha: fin,
        })
        // console.log("taskw",request)
        setDatosTaskw(request)
    },[])
    useEffect(() => {
        const run = async() => {
            setIsLoading(true)
            const request = await taskw({
                idTicket: datosTicket.tid
            })
            console.log(request)
            setIsLoading(false)

        }
        run()
    }, [])
    return (
        <div>
            {loading && (
                <div className="text-center">
                    <Spinner animation="border"/>
                </div>
            )}
            <FiltroTareas updateTickets={updateTickets}/>
            {datosTaskw?.ans.msg !== "correcto" &&
                <div className="alert alert-warning alert-dismissible fade show text-center" role="alert">
                    No se ha encontrado tareas.
                </div> 
            }
            {datosTaskw?.ans.msg === "correcto" &&
                <div className="row">
                    <div className="col-5">
                    <h4 className="page-title">Lista de tareas</h4>
                        { datosTaskw && 
                            <TareasLista lista={datosTaskw.ans.tas}/>
                        }
                    </div>
                    <div className="col-7">
                    <h4 className="page-title">Resumen</h4>
                        {datosTaskw &&
                            <ResumenTicket taskw={datosTaskw}/>                        
                        }
                    </div>
                </div>
            }
        </div>    
    )
}

function TareasTicket ({datosTicket}) {
    if(datosTicket === undefined){
        return null
    }
    return <ContenidoTareasTicket datosTicket={datosTicket} />
}

export default React.memo(TareasTicket);