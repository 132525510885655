import toast from 'react-hot-toast';
// import logout from './logout';
import $ from "jquery"
import Semaphore from './semaforo';
import { auten } from 'Connection/transacciones';
import { getOtt, setOtt, logout } from 'Authentication/Session';
// Cosas

const BACKEND_API = process.env.REACT_APP_API;
const throttler = new Semaphore(1);

export const backend_request = async(json_body, cb_function) => {
    await throttler.callFunction(handleRequest, json_body, cb_function)
        .catch(err => console.log(err)) ;
}

const handleRequest = async(json_body, cb_function) => {
    if (json_body.hdr.ott === true) {
        json_body.hdr.ott = getOtt()
    }else if (json_body.hdr.ott === false){
        json_body.hdr.ott = 'null'
    } 
    else {
        json_body.hdr.ott = getOtt() // fix de ott
    }
    // console.log("request",json_body);
    // console.log(json_body);
    let response =  await fetch(BACKEND_API,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(json_body)
    })
    actualizarHoraRequest();
    await response.json()
        .then(data => {
            try {
                const dataString = data.ans.replace(/\n/g,' ');
                // console.log("data",dataString)
                const toAns = JSON.parse(dataString);
                setOtt(toAns.hdr.ott);
                console.log(toAns);
                switch (toAns.ans.stx) {
                    case "nk":
                        if (toAns.ans.msg === "Error st23"){
                            sessionStorage.setItem("error",'st23');
                            logout();
                            break;
                        }
                        toast.error(
                            <div>
                                Error interno <br/>
                                reportar el error - {toAns.ans.msg}
                            </div>, {
                            duration: 5000,
                            showHideTransition: 'slide',
                            allowToastClose: false,
                            loader: false,
                        })
                        break;
                
                    default:
                        cb_function(toAns);
                        break;
                }    
            } catch (error) {
                console.log(error);
            }
            


            

                     
        })
        .catch(error => {
            toast.error(error)
            const toAns = {ans: {stx:"nk"}}
            cb_function(toAns)
        })
}
const actualizarHoraRequest = () => {
    const now = new Date();
    const dia = now.getDate()
    const mes = now.getMonth() + 1;
    const año = now.getFullYear();

    const hora = now.getHours();
    const min = now.getMinutes();
    const horaString = `${dia> 10? dia:"0"+dia}/${mes> 10? mes:"0"+mes}/${año} - ${hora> 10? hora:"0"+hora}:${min> 10? min:"0"+min}`;
    $("#hora-request").html(horaString);
}