import React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isAuthenticated } from "./Session";

function NoAuthRoute () {
    const isAuth = isAuthenticated();
    return isAuth? <Navigate to={"/Dashboard"}/> : <Outlet /> 
}

export default NoAuthRoute;
