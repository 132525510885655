import { getClient, getName, logout } from "Authentication/Session";
import React from "react"
import { Link } from "react-router-dom";

function Usuario() {
    const userName = React.useRef(getName());
    const client = React.useRef(getClient());
    return(
        <div className="dropdown notification-list topbar-menu">

            <a className="nav-link dropdown-toggle nav-user arrow-none me-0 h-100"  data-bs-toggle="dropdown" id="topbar-userdrop" href="/#" role="button" aria-haspopup="true" aria-expanded="false">
                <span className="account-user-avatar">
                    <img src="/assets/images/users/Avatar.png" alt="user" className="rounded-circle" />
                </span>
                <div>
                    <span className="account-user-name" >
                        {userName.current}
                    </span>
                    <small>
                        {client.current}
                    </small>
                </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end topbar-dropdown-menu profile-dropdown" aria-labelledby="topbar-userdrop">
                <Link to="/Cuenta">
                    <button type="button"  className="dropdown-item notify-item">
                        <i className="mdi mdi-account me-2"></i>
                        <span>Mi cuenta</span>
                    </button>
                </Link>

                <button type="button"  className="dropdown-item notify-item" onClick={logout}>
                    <i className="mdi mdi-logout me-2"></i>
                    <span>Logout</span>
                </button>

                
                
            </div>
        </div>
    )
}

export default Usuario;
