import locgt from 'Connection/transacciones/locgt';
import React from 'react'

function useListaLocalesCalen({
    dataOption = 0
}) {
    const [values, setValues] = React.useState([]);
    const [isFetching, setIsFetching] = React.useState(false)
    const fetchLocales = async(filtros = {}) => {
        
        setIsFetching(true)
        // console.log("aca")
        const request = await locgt(filtros, dataOption)
        // console.log("aca",filtros,"aca2",dataOption)
        if (request.ans.stx === "ok") {
            // console.log("rl",request.ans.loc)
            setValues(request.ans.loc? request.ans.loc: []) 
        }
        setIsFetching(false)
    }
    return {
        values,
        options: values,
        fetchLocales,
        isFetching
    }

}

export default useListaLocalesCalen