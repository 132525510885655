import { requestData } from "Connection/transacciones"

const uscla = async({uid}) => {
    const jsonBody = {
        hdr: {
            txi: 85,
            req: "uscla",
            ott: true,
        },
        dat: {
            uid:uid
        }
    }
    // console.log(jsonBody)
    return await requestData(jsonBody)

}

export default uscla;