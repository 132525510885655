import OpcionItem from 'Components/Dashboard/DashboardItem'
import React from 'react'
import { Row } from 'react-bootstrap'

function AccionesUsuario({
    crearTicket, 
    tickets,
    calendarizaciones,
    reporte
    // test

}) {
    // if (!(crearTicket || tickets || calendarizaciones)){
        if (!(crearTicket || tickets || calendarizaciones || reporte)){
        return(null)
    }
    return (
        <>
        <h5 className="page-title">Usuario</h5> 
        <Row>
            {crearTicket&& (
                <OpcionItem
                    titulo="Crear Ticket"
                    descripcion={"Crea un nuevo ticket llenando un formulario."}
                    icono="mdi-book-plus-outline mt-2"
                    color="blue"
                    link="/CrearTicket"
                />
            )}
            {tickets&& (
                <OpcionItem
                    titulo="Tickets"
                    descripcion={"Gestiona Tickets."}
                    icono="mdi-book-edit mt-2"
                    color="yellow"
                    link="/ListaTickets"
                />
            )}
            
            {/* {test && (
                    <OpcionItem
                        titulo="Title test"
                        descripcion={"Descrition Test"}
                        icono="mdi-account mt-2"
                        color="primary"
                        link="/listaCtickets"
                    />
                )} */}
            {calendarizaciones&& (
                <OpcionItem
                    titulo="Calendarizaciones"
                    descripcion={"Gestiona Calendarizaciones."}
                    icono="mdi-calendar-clock mt-2"
                    color="green"
                    link="/Calendarizaciones"
                />
            )}
            
                {reporte && (
                    <OpcionItem
                        titulo="Reporte Repoflex"
                        descripcion={"Revisa reportes."}
                        icono="mdi-chart-box mt-2"
                        color="yellow"
                        link="/Reportes"
                    />
                )}
        </Row>
        </>
  )
}

export default AccionesUsuario