// import useKam from 'Hooks/Selects/useHolding'
import useCliente from 'Hooks/Selects/useCliente';
// import useKam from 'Hooks/Selects/useKam';
// import weall from 'Connection/transacciones/weall';
import Select from 'react-select';
import React from 'react'
// import { MultiSelect } from 'react-multi-select-component';
// import localization from "Assets/localization/multiSelect.json"
import { getClientID, getUser } from "Authentication/Session";

import clall from 'Connection/transacciones/clall'
import { Button } from 'react-bootstrap';

function MultiSelectCliente({
    isDisabled,
    dependencies = {}, 
    onChange = () => {},
    selected=[],
    name
}) {
    const cliente = useCliente();
    const fetched = React.useRef(false) 
    React.useEffect(() => {
        fetched.current = false; // si se actualiza una dependencia se debe menciar que esta desactualizado 
    }, [])
    const [options, setOptions] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)
    const [isSelected, setIsSelected] = React.useState([])
    const [itSsDisabled, setItIsDisabled] = React.useState(true)
    // const thisProfileOptions = React.useRef(getProfileOptions());
    // console.log( getClientID())


    const onToggle = async(isOpen) => {
        if(isOpen && !fetched.current) {
            fetched.current = true; // Lista de comunas actualizados? SI
            await cliente.fetch(dependencies);
        }
    }
    const handleOnChange = (values) => {
        // onToggle()
        // console.log(values.value, name)
        // const sorted = values.sort((a,b) => a.value - b.value)
        setIsSelected(values)
        // console.log("val",values)
        onChange([values.value], name)
    }

    React.useEffect(async() => {
        setIsFetching(true)
        const request = await clall();
        if(request.ans.stx === "ok") {
            if(request.ans.wus) {
                const newValues = request.ans.wus.map(item => ({
                    "value": item.cid,
                    "label": item.name
                }))
                setOptions(newValues)
                
                // if ((getClientID()) === "0") {
                //     setItIsDisabled(false)
                // }
                // else{
                //     options.map((item) => {
                //         if (String(item.value) === getClientID()) {
                //             // console.log(item)
                //             setIsSelected(item)
                //             handleOnChange(item)
                //             // onChange([item.value], name)
                //         }})
                //     }
                // console.log()
            } else {
                setOptions([])
            }
        }
        // if ((getClientID()) === "0") {
        //     setItIsDisabled(false)
        // }
        // else{
        //     options.map((item) => {
        //         if (String(item.value) === getClientID()) {
        //             // console.log(item)
        //             setIsSelected(item)
        //             handleOnChange(item)
        //             // onChange([item.value], name)
        //         }})
        //     }
        setIsFetching(false)
                
    }, [])

    React.useEffect(() => {
        
        if ((getClientID()) === "0") {
            setItIsDisabled(false)
        }
        else{
            options.map((item) => {
                if (String(item.value) === getClientID()) {
                    // console.log(item)
                    setIsSelected(item)
                    handleOnChange(item)
                    // onChange([item.value], name)
                }})
            }
        }, [options])


    return (
        <>
        {/* {(!isFetching) && */}
        <Select
        placeholder="Elegir un cliente"
        value={isSelected}
        onChange={handleOnChange}
        options={options}
        isDisabled={itSsDisabled}
        // validation={clienteValido}
        /> 
        {/* } */}
        
        
        {/* <Button onClick={()=>console.log(selected)}></Button> */}
    </>
    
        // <MultiSelect 
        //     value={selected}
        //     overrideStrings={localization}
        //     onChange={handleOnChange}
        //     options={cliente.options}
        //     onMenuToggle={onToggle}
        //     disabled={isDisabled}
        //     isLoading={cliente.isFetching}

        // />
    )
}

export default MultiSelectCliente