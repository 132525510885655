import Tabla from 'Components/Tabla/Tabla';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import useListaUsuarioWeb from "Hooks/Listas/useListaUsuariosWeb"
import { format } from 'Utils/rut'

const thClassName= "ps-1"
const columnas = [
    {
        Header: 'Nombre',
        accessor: item => `${item.name}, ${item.snam}`,
        Cell: (props) => {
            const {value, row} = props
             return (
                 <Link to={"UserWeb"} state={{userID: row.original.uid}} >
                     {value}
                 </Link>
             )
         },
        thClassName
    },{
        Header: 'Mail',
        accessor: "mail",
        thClassName
    },{
        Header: 'Teléfono',
        accessor: "phon",
        thClassName
    },{
        Header: 'Rut',
        accessor: "rut",
        Cell: props =>{
            const {value} = props 
            return(format(value))
        },
        thClassName
    },{ 
        Header: 'Cliente',
        accessor: "clin",
        thClassName
    }
]

function ButtonAgregarUsuario () {
    const nav = useNavigate();
    return (
        <Button onClick={() => nav("AgregarUsuario")}>
            <i className="mdi mdi-plus me-1" />
            Agregar Usuario
        </Button>
    )

}

function TablaUsuariosWeb (){
    const usuariosWeb = useListaUsuarioWeb();
    React.useEffect(() => {
        usuariosWeb.fetch();
    } , [])
    return(
        <div className='card'>
            
            <div className="card-body">   
                <Tabla
                    columns={columnas}
                    data={usuariosWeb.lista}
                    loading={usuariosWeb.isFetching}
                    globalFilter={true}
                    titleSection={<ButtonAgregarUsuario />}
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                /> 
            </div>

    </div>
)

}

export default TablaUsuariosWeb