import { requestData } from "Connection/transacciones"


const taskm = async(dat = {}, option = 0) => {
    const jsonBody = {
        hdr: {
            txi: 77,
            req: "taskm",
            ott: true,
        },
        dat: {
            ful: option,
            ...dat
        }
    }
    return await requestData (jsonBody)

}
export default taskm
