export const formatearFechaHora = (fecha_hora) => {
    return fecha_hora? `${fecha_hora.substring(6,8)}:${fecha_hora.substring(8,10)} - ${fecha_hora.substring(4,6)}/${fecha_hora.substring(2,4)}/${fecha_hora.substring(0,2)}`:""
}
export const formatearHoraFecha = (fecha_hora) => {
    return fecha_hora? `${fecha_hora.substring(4,6)}/${fecha_hora.substring(2,4)}/${fecha_hora.substring(0,2)} - ${fecha_hora.substring(6,8)}:${fecha_hora.substring(8,10)}`:""
}
export const formatearFecha = (fecha) =>  {
    return fecha? `${fecha.substring(4,6)}/${fecha.substring(2,4)}/${fecha.substring(0,2)}`:""
}

export const formatearHoraFechaAlt = (fecha_hora) => {
    return fecha_hora? `${fecha_hora.substring(2,4)}-${fecha_hora.substring(4,6)}-${fecha_hora.substring(0,2)}${" "}${fecha_hora.substring(6,8)}:${fecha_hora.substring(8,10)}`:""
}

export const formatearMDA = (fecha) => {
    return fecha? `${fecha.substring(2,4)}/${fecha.substring(4,6)}/${fecha.substring(0,2)}`:""
}

// fecha dd/mm/aaaa - hora hh:mm:ss
export const formatearFechaHora2 = (fecha_hora) => {
    return fecha_hora? `${fecha_hora.substring(0,2)}/${fecha_hora.substring(2,4)}/${fecha_hora.substring(4,8)} - ${fecha_hora.substring(8,10)}:${fecha_hora.substring(10,12)}:${fecha_hora.substring(12,14)}`:""
}


export const fechaHoy = () =>  {
    const date = new Date()
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month < 10 ? "0"+month:month}-${day  < 10 ? "0"+day:day}`
}

export const mesAnterior = () =>  {
    const date = new Date()
    const month = (date.getMonth() + 1) === 1 ? 12:date.getMonth() ;
    const year = (date.getMonth() + 1) === 1 ? date.getFullYear() - 1:date.getFullYear();
    return `${year}-${month < 10 ? "0"+month:month}`
}

export const mesActual = () =>  {
    const date = new Date()
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month < 10 ? "0"+month:month}`
}

export const mesProximo = () =>  {
    const date = new Date()
    const month = (date.getMonth() + 1) === 12 ? 0:date.getMonth() + 2;
    const year = (date.getMonth() + 1) === 12 ? date.getFullYear() + 1:date.getFullYear();
    return `${year}-${month < 10 ? "0"+month:month}`
}

export const fechaHoy2 = () =>  {
    const date = new Date()
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day  < 10 ? "0"+day:day}-${month < 10 ? "0"+month:month}-${year}`
}
export const limpiarFecha = (fecha) => {
    return fecha.replace(/-/gi, "").substring(2,8);
}

export const fechaDefault = () => {
    const date = new Date()
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}${month < 10 ? "0"+month:month}${day  < 10 ? "0"+day:day}`
} 