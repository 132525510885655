import React, { useState, useCallback, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";
import aupic from "Connection/transacciones/aupic";
import buttonRF from "Assets/images/rpbtn.jpg"
import { Spinner } from "react-bootstrap";
const style = {textAlign: 'center'}


function Tabimg({usr,index}){
    // console.log("us",usr,"ind",index)
    const [srcFoto, setSrcFoto] = useState(null);
    const [isFetching, setIsFetching] = React.useState(false)

    React.useEffect(() => {
        const handleOnClick = async() => {
            if (usr){
            setIsFetching(true);
                const request = await aupic(usr, index);
                if(request.ans.stx === "ok") {
                    setSrcFoto([`data:image/${request.ans.ext};base64,${request.ans.pic}`])
                }}
                
            setIsFetching(false)
        }
        handleOnClick()

    },[usr])
    // handleOnClick()
    return(
    <>
        {isFetching?( 
            <div style={style}>
                <Spinner animation="border" />
                </div>
            ): (
        <img
        src={srcFoto}
        
        width= "100%"
        height= "auto"/>
        )}
    </>
    )


    
}

function FotoDocumento ({usuarioId}) {
    return (
        <div>
            
            <nav>
                <div className="nav nav-tabs" role="tablist" >
                <a className="nav-link active" type="button" href="#home" data-bs-toggle="tab" aria-selected="true"> Selfie</a>
                <a className="nav-link" type="button" href="#home2"  data-bs-toggle="tab"  > Cedula Frontal</a>
                <a className="nav-link" type="button" href="#home3" data-bs-toggle="tab" > Cedula Trasera</a>
                <a className="nav-link" type="button" href="#home4" data-bs-toggle="tab" > Antecedentes</a>
                </div>
                <br></br>
                <div className="mx-auto">
                <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <Tabimg usr={usuarioId} index={0}/>
                </div>
                <div className="tab-pane fade" id="home2" role="tabpanel" aria-labelledby="contact-tab">
                <Tabimg usr={usuarioId} index={1}/>
                </div>
                <div className="tab-pane fade" id="home3" role="tabpanel" aria-labelledby="contact-tab">
                <Tabimg usr={usuarioId} index={2}/>
                </div>
                <div className="tab-pane fade" id="home4" role="tabpanel" aria-labelledby="contact-tab">
                <Tabimg usr={usuarioId} index={3}/>
                </div>
                </div>
                    {/* <img src={srcFoto}/> */}
                    
                    {/* <FotoDocumento3 hehe={srcFoto}/> */}
                </div>
                
            </nav>
       </div>
    )
}

export default FotoDocumento;