import Tabla from 'Components/Tabla/Tabla';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import useListaClientes from 'Hooks/Listas/useListaClientes';

const thClassName= "ps-1"
const columnas = [
    {
        Header: 'Nombre',
        accessor: "name",
        Cell: (props) => {
            const {value, row} = props
             return (
                 <Link to={"EditarCliente"} state={{clienteID: row.original.cid}} >
                     {value}
                 </Link>
             )
         },
        thClassName
    },{
        Header: 'Mail',
        accessor: "mail",
        thClassName
    },{
        Header: 'Teléfono',
        accessor: "phon",
        thClassName
    },{
        Header: 'Rut',
        accessor: "rut",
        thClassName
    },{
        Header: "Pais",
        accessor: "pai",
        Cell: (props) => {
            const {value, row} = props
            return (value.split("-")[1])
        }
    }
]

function TablaClientes (){

    const clientes = useListaClientes();

    React.useEffect(() => {
        clientes.fetch();
    }, [])


    return(
        <div className='card'>
            <div className="card-body">   
                <Tabla
                    columns={columnas}
                    data={clientes.lista}
                    globalFilter={true}
                    loading={clientes.isFetching}
                    titleSection={                
                        <Link to={"AgregarCliente"}>
                            <Button variant='dark'>
                                <i className="mdi mdi-plus me-2"> </i>
                                Agregar Cliente
                            </Button>
                        </Link>
                    }
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                />
            </div>

        </div>
    )

}

export default TablaClientes