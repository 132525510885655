import weact from 'Connection/transacciones/weact'
import wedes from 'Connection/transacciones/wedes'
import useFormulario from 'Hooks/Custom/useFormulario'
import InputTexto from "Components/FormElements/InputTexto"
import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
import { validarEmail } from 'Utils/validations'
import { Link } from 'react-router-dom'
import {useSetRecoilState } from 'recoil'
import { dangerModalContentState, isDangerModalOpenState } from 'Components/Modal/DangerModal'
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import useMountedComponent from 'Hooks/Custom/useMountedComponent'
import { getClientID, getProfileOptions, getUser } from "Authentication/Session";
import localization from "Assets/localization/multiSelect.json"
import { format } from 'Utils/rut'

const propsStyles = {
    asRow: true,
    labelSize:3,
    size: "sm"
}



function EditarDatosWeb ({datosUsuario, clientes, opciones}){

    const [perfilSelect, setPerfilSelect] =  React.useState([]); // valores seleccioandos en multiselect
    const [perfilOptions, setPerfilOptions] = React.useState([]);
    const thisProfileOptions = React.useRef(getProfileOptions());
    const thisUserRut = React.useRef(getUser());

    React.useEffect(() => {
        const perfilArray =  datosUsuario.prof.split("");  
        if (opciones.length > 0) {
            let selected = [];
            let optionsList = []
            
            opciones.forEach((value, index)=> {
                if (perfilArray[index] === '1') { //
                    selected.push(value);
                }
                optionsList.push({
                    ...value,
                    disabled: thisProfileOptions.current[index] === '1'? false: true,
                })
            })
            // console.log(selected, "selected")
            // console.log(optionsList, "optionsList")
            // console.log(thisProfileOptions.current[10], "perfilArray")
            setPerfilSelect(selected)
            setPerfilOptions(optionsList)
        }
    }, [datosUsuario, opciones]);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const {mounted} = useMountedComponent( )
    
    // console.log(perfilPrevio,"A")
    // console.log(opciones,"B")
    // console.log(perfilprecargado,"C")
    const formulario = useFormulario({
        initialValues: {
            "name": datosUsuario.name,  // input
            "snam": datosUsuario.snam,  // input
            "mail": datosUsuario.mail,  // input
            "phon": datosUsuario.phon,  // phon
            "ndoc": datosUsuario.rut,
            "prof": datosUsuario.prof,
            "clie": datosUsuario.clie

        },
        onSubmit: async({dirtyValues}) => {
            // console.log(dirtyValues)
            setIsDisabled(true)
            const request = await weact(datosUsuario.uid, dirtyValues);

            if(request.ans.stx === "ok"){
                toast.success(request.ans.msg)
                formulario.updateInitialValues();
                
                formulario.resetFieldValidation();
                // refreshUsers()
            }
            setIsDisabled(false)

        },

        validateOnSubmit: ({dirtyValues}) => {
            const errors = {}
            if(Object.keys(dirtyValues).length === 0){
                errors.submitValues = "No hay cambios que registrar."
                toast.error(errors.submitValues)
            }   
            return errors;
        },
    })

         
        // Inputs formulario
    const onNameChange = React.useCallback((value) => {
        const FIELD = "name"
        if (value.length <= 50) {
            if(value === "") { 
                formulario.setFieldIsInvalid(FIELD)
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        }
        // eslint-disable-next-line
    },[]) 

        

    const onSnamChange = React.useCallback((value) => {
        const FIELD = "snam"
        if (value.length <= 50) {
            if(value === "") { 
                formulario.setFieldIsInvalid(FIELD)
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        }
    },[])
    
    const onMailChange = React.useCallback((value) => {
        const FIELD = "mail"
        if (value.length <= 48) {
            if(!validarEmail(value)){
                formulario.setFieldIsInvalid(FIELD) // false: hay error
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        }
    },[])

    const onPhonChange = React.useCallback((value) => {
        const FIELD = "phon"
        if (value.length <= 12) {
            const re = /^[0-9\b]+$/;
            if ((value === '' || re.test(value))) {
                formulario.setFieldValue(FIELD, value);
                if(value === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== value) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
            }
        }
    },[])

    const onProfChange = React.useCallback((value) => {
        const FIELD = "prof"
        setPerfilSelect(value)
        const result = value.reduce((total, currentValue) => total = total + currentValue.value,0)
        // console.log(result)
            if ((result === '' || result)) {
                formulario.setFieldValue(FIELD, result);
                if(result === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== result) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
        }
    },[])

    const onClieChange = React.useCallback((value) => {
        const FIELD = "clie"
            if ((value.value === '' || value.value)) {
                formulario.setFieldValue(FIELD, value.value);
                if(value.value === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== value.value) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
        }
    },[])

    const setDangerContent = useSetRecoilState(dangerModalContentState);


        // const [modalIsOpen, setModalIsOpen] = useRecoilState(isDangerModalOpenState)
    const setModalIsOpen = useSetRecoilState(isDangerModalOpenState)
    const abrirModalAlertaDanger = () => setModalIsOpen(true)
    const [deshabilitandoBackend, setDeshabilitandoBackend] = React.useState(false)

    const navigation = useNavigate()
    const confirmarDeshabilitarUsuario =  React.useCallback(async () => {
        if(mounted === true) setDeshabilitandoBackend(true)
    
        const request = await wedes({uid: datosUsuario.uid})
        if(request.ans.stx === "ok"){
            toast.success(request.ans.msg);
            setModalIsOpen(false)
            navigation('..')
        }
    
        if(mounted === true) setDeshabilitandoBackend(false)
    },[])
    
        React.useEffect(() => {
            setDangerContent(<>
                <div className='text-white text-center'>
                    Una vez que se deshabilite esta cuenta no se podrá volver a activar.
                </div>
                <Button variant='light' className='text-danger my-3' onClick={() => confirmarDeshabilitarUsuario()} disabled={deshabilitandoBackend} >
                    <div style={{width: "100px"}}>
                        {deshabilitandoBackend === true ? (
                            <Spinner 
                                as={"span"}
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ):"Confirmar"}
                    </div>
                </Button>
            </>)
        },[deshabilitandoBackend])


return (
    
    <>
    <h4>
         Datos del usuario {format(formulario.values.ndoc)}
    </h4>
    <div>
    <div className="mb-1">
                 <InputTexto
                        {...propsStyles}
                        label="Nombre(s)"
                        name={"name"}
                        value={formulario.values.name}
                        onChange={onNameChange}
                        validation={formulario.fieldValidation.name}
                        disabled={isDisabled}
                 />
    </div>
             <div className="mb-1">
                 <InputTexto
                    {...propsStyles}
                    label={"Apellidos"}
                    onChange={onSnamChange}
                    name={"snam"}
                    disabled={isDisabled}
                    value={formulario.values.snam}
                    validation={formulario.fieldValidation.snam}
                 />
            </div>

            <div className="mb-1">
                <h5>
                    Cuenta
                    </h5>
                </div>
            <div className="mb-2">
                        <div className="row">
                            <div className="col-sm-3">
                                <label> Perfil de usuario</label>
                            </div>
                            <div className="col-sm-9">
                                <MultiSelect
                                    overrideStrings={localization}
                                    name="prof"
                                    placeholder="Test"
                                    value={perfilSelect}
                                    onChange={onProfChange}
                                    options={perfilOptions}
                                    disabled={datosUsuario.rut === thisUserRut.current }
                                />
                            </div>
                        </div> 
                    </div>
            {(getClientID()) === '0' ? ( 
            <div className="mb-2">
                <div className="row">
                    <div className="col-sm-3">
                        <label> Cliente</label>
                    </div>
                    <div className="col-sm-9">
                        <Select
                            name="clie"
                            disabled={isDisabled}
                            placeholder="Elegir un cliente"
                            value={{
                                label: clientes.find(opt => opt.value === formulario.values.clie)?.label, 
                                value: formulario.values.clie
                            }}
                            onChange={onClieChange}
                            options={clientes}
                            validation={formulario.fieldValidation.clie}
                        />
                    </div>
                </div> 

            </div>
            ) :
            (null)}
            </div>
            <div className="mb-1">
                <h4>
                    Contacto
                    </h4>
                </div>
             <div className="mb-1">
                 <InputTexto 
                        {...propsStyles}
                        label={"Mail"}
                        name={"mail"}
                        disabled={isDisabled}
                        value={formulario.values.mail}
                        validation={formulario.fieldValidation.mail}
                        onChange={onMailChange}
                 />
             </div>
             <div className="mb-1">
                 <InputTexto 
                        {...propsStyles}
                        label="Teléfono"
                        name="phon"
                        disabled={isDisabled}
                        value={formulario.values.phon}
                        onChange={onPhonChange}
                        validation={formulario.fieldValidation.phon}
                 />   
             </div>
            <div className='d-flex justify-content-between mt-3'>
                    <Link to={"/#"} onClick={(e) => {
                        abrirModalAlertaDanger()
                        e.preventDefault()
                    }} className="text-danger">
                        Deshabilitar usuario
                    </Link>
                    <Button variant="info" onClick={formulario.onSubmit} disabled={formulario.haveInvalidFields || !formulario.haveValidFields }>
                        Guardar cambios
                    </Button>
                </div>
            </>
            )
}

export default EditarDatosWeb;
