import React from 'react'
import CacheApp from 'Utils/CacheApp'
import { filtrosValores } from 'Utils/filtrosHandler'
import {isEqual} from 'lodash'

function useFiltrosTask({cacheKey = "", session = ""}) {
    // const _temp = React.useRef(CacheApp.getCacheTab({
    //     key: cacheKey,
    //     sessionKey: session,
    // })) // obtiene los valores del cache

    const filtrosAplicados = React.useRef() // valor solo para comparar
    // const [filtrosSelected, setFiltrosSelected] = React.useState(_temp.current || {})
    const [filtrosSelected, setFiltrosSelected] = React.useState({})
    // console.log("fs",filtrosSelected)

    const isFiltrosUpdated = React.useMemo(() =>isEqual(filtrosAplicados.current, filtrosSelected),[filtrosAplicados.current, filtrosSelected])

    const handleOnChange = (selected, key) => {
        // console.log("handler", selected,"+",key)
        setFiltrosSelected(oldValues => {
            let newValues = {
                ...oldValues,                
            }
            switch (key) {
                // case "ind":
                //     delete newValues.end
                // case "end":
                //     delete newValues.reg
                case "reg":
                    delete newValues.com
                case "com":
                    delete newValues.ret 
                case "ret":
                    delete newValues.loc
                case "loc":
                default:
                    break;
            }
            // if (selected.length > 0 && key=="ind"){
            //     newValues[key] = selected;
            //     console.log("view2",newValues)
            // }
            // if (selected.length > 0 && key === "ind"){
            //     newValues[key] = selected[0];
            //     console.log("ha")
            // }
            if (selected.length > 0) {
                if(key === "ind"|| key === "end"){
                    // console.log("ha",selected[0].value)
                newValues[key] = selected[0].value;
                }
                else{
                    newValues[key] = selected;
                    // console.log("hey")
                }
                // console.log("view2",selected[0].value)
            } else {
                delete newValues[key]
            }
            // console.log("view",newValues)
            return (newValues)
        }) // Actualiza los seleccionados
    }
    const aplicarFiltros = (callback) => {
        let requestFiltros = filtrosValores(filtrosSelected)
        callback(requestFiltros)
        filtrosAplicados.current = filtrosSelected
        // console.log("req",requestFiltros)
        // CacheApp.setCacheTab({
        //     key: cacheKey,
        //     sessionKey: session,
        //     value: filtrosSelected
        // })

    }
    const reiniciarFiltros = () => setFiltrosSelected({})

    return {
        // filtrosAplicados,
        filtrosSelected,
        isFiltrosUpdated,
        handleOnChange,
        aplicarFiltros,
        reiniciarFiltros,
    }
}

export default useFiltrosTask