import { requestData } from "Connection/transacciones"

const usloa = async({uid, cid}) => {
    const jsonBody = {
        hdr: {
            txi: 75,
            req: "usloa",
            ott: true,
        },
        dat: {
            uid:uid,
            cid:cid,
        }
    }
    // console.log(jsonBody)
    return await requestData(jsonBody)

}

export default usloa;