import DivControl from 'Components/DivControl'
import useDatosUsuario from 'Hooks/Datos/useDatosUsuario'
import React from 'react'
import { Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import CacheApp from 'Utils/CacheApp'
import Enum from 'Utils/Enum'
import { actualizarInfoPagina } from 'Utils/paginaInfo'
import ClientesAsignados from './_ClientesAsignados/ClientesAsignados'
import FormularioEditarDatos from './_EditarDatos/EditarDatos'
import GestionarExcepciones from './_GestionarExcepciones/gestionarExcepciones'
import LocalesAsignados from './_LocalesAsignados/LocalesAsignados'
// import DatosPersonales from './EditarDatos'

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">

                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={".."}>
                            Gestión Usuarios App
                        </Link>
                    </li>
                    <li className="breadcrumb-item active">
                        Usuario App
                    </li>
                </ol>
            </div>
            <h4 className="page-title">Usuario</h4>
        </div>
    )
}


const MENU_COL = 2



const itemsEnum = new Enum(
    'editarUsuario',
    'localesAsignados',
    'clientesAsignados',
    'gestionarExcepciones'
)
const CACHE_LAYOUT_USER_ITEM = 'cache-layout-item'

function LayoutUser(props) {
    React.useEffect(()=> actualizarInfoPagina(props),[]) // actualizacion del titulo de la pagina

    const location = useLocation()
    const { datos, isFetching, refreshData } = useDatosUsuario({userID: location.state?.userID})

    // elige el item activo
    const [activeItem, setActiveItem] = React.useState(
        itemsEnum.editarUsuario
    )

    const setItem = (e) => {
        const itemName = Number(e.target.name); // obtiene el item activo
        setActiveItem(itemName)    // setea el item activo
        CacheApp.setCacheTab({
            key: CACHE_LAYOUT_USER_ITEM
        })
    }
    // hooks de datos
    
    React.useEffect(() => {
        if(location.state?.userID){
            refreshData();
        }
    },[])
    if (!location.state?.userID){
        return ("no hay usuario seleccionado")
    }
    return (
        <div className="container">
            <TituloPagina/>
            
            <Card>
                <Row>
                    <Col md={MENU_COL} className="pe-0">
                        <ListGroup variant='flush'>
                            <ListGroup.Item
                                action
                                name={itemsEnum.editarUsuario}
                                active={activeItem === itemsEnum.editarUsuario}
                                onClick={setItem}
                            >
                                <i className='mdi mdi-account me-2' />
                                Datos de usuario
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                name={itemsEnum.clientesAsignados}
                                active={activeItem === itemsEnum.clientesAsignados}
                                onClick={setItem}
                            >
                                <i className='mdi mdi-account-multiple me-2' />
                                Clientes asignados
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                name={itemsEnum.localesAsignados}
                                active={activeItem === itemsEnum.localesAsignados}
                                onClick={setItem}
                            >
                                <i className='mdi mdi-store me-2' />
                                Locales asignados
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                name={itemsEnum.gestionarExcepciones}
                                active={activeItem === itemsEnum.gestionarExcepciones}
                                onClick={setItem}
                            >
                                <i className='mdi mdi-store-remove me-2' />
                                 Excepciones
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={12 - MENU_COL} style={{borderLeft: "1px solid #dbdbdb"}}>
                        <Card.Body>
                            {isFetching && (
                                <div className='text-center mt-2'>
                                    <Spinner animation="border" role="status" />
                                </div>
                            )}
                            { datos !== null && (<>
                                <DivControl show={activeItem === itemsEnum.editarUsuario}>
                                    <FormularioEditarDatos datosUsuario={datos} />
                                </DivControl>
                                <DivControl show={activeItem === itemsEnum.localesAsignados}> 
                                    <LocalesAsignados
                                        userID={location.state?.userID}
                                        userData={datos}
                                    />
                                </DivControl>
                                <DivControl show={activeItem === itemsEnum.clientesAsignados}> 
                                    <ClientesAsignados
                                        userID={location.state?.userID}
                                        userData={datos}
                                    />
                                </DivControl>
                                <DivControl show={activeItem === itemsEnum.gestionarExcepciones}> 
                                    <GestionarExcepciones
                                        userID={location.state?.userID}
                                        userData={datos}
                                    />
                                </DivControl>
                            </>)}
                           
                            
                            
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default LayoutUser