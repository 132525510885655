import { proxy } from "valtio";


export const state = proxy({
    solicitudes: [],
    isDisabled: false,
    usuarioSeleccionado: null,
    usuariosCache: {},

})

