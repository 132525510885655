import { requestData } from "Connection/transacciones"

const prmas = async({mcf}) => {
    const jsonBody = {
        hdr: {
            txi: 94,
            req: "prmas",
            ott: true,
        },
        dat: mcf
        
    }
    // console.log(mcf)
    console.log(jsonBody)
    return await requestData(jsonBody)

}

export default prmas;