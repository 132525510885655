import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import CalendarizarTicket from './CalendarizarTicket';

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={"/ListaTickets"} >Tickets</Link>
                    </li>
                    <li className="breadcrumb-item active">Calendarización de Ticket
</li>
                </ol>
            </div>
            <h4 className="page-title">Calendarización de Ticket</h4>
        </div>
    )
    }

function LayoutCalendarizarTicket(props) {
    const [calId, setCalId] = React.useState("")
        useEffect(() => { 
            actualizarInfoPagina(props)
            if (localStorage.getItem("Calen") !== ""){
                setCalId(localStorage.getItem("Calen") )
            }
        }, []);  // eslint-disable-line
        return (
            <div className='container'>
                <TituloPagina />
                <div className='card'>
                    <div className="card-body">
                <CalendarizarTicket idCal={calId}/>
                    </div> 
                </div>
                {/* <TablaTickets />
                <ModalOpcionesTickets /> */}
                
            </div>
        )
}
export default LayoutCalendarizarTicket