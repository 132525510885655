import React from "react"
import { isEqual } from "lodash";
import toast from "react-hot-toast";
import matrx from "Connection/transacciones/matrx";
import usclx from "Connection/transacciones/usclx";
import uscla from "Connection/transacciones/uscla";
function useClientesAsignadosUsuario ({uid}) {
    // valores
    const [clientesIniciales, setClientesIniciales] = React.useState([]) // clientes iniciales
    const [clientesAsignados, setClientesAsignados] = React.useState([]); // clientes para render
    const isDirty = React.useMemo(() => { // determina si hay cambios
        return !isEqual(
            clientesIniciales.map(item => item.cli), 
            clientesAsignados.map(item => item.cli))
    }, [clientesIniciales, clientesAsignados]) 

    // metodos
    const cancelarCambios = () => setClientesAsignados(clientesIniciales) // reinicia los valores
    const agregarcliente = (nuevoCliente) => {
        const clienteInfo = {
            cli: nuevoCliente.cli,
            mail: nuevoCliente.mail,
            cln: nuevoCliente.cln,
            phon: nuevoCliente.phon,
            rut: nuevoCliente.rut,
        }
        setClientesAsignados(clientes => {
            return [...clientes, clienteInfo]
        })
    }
    const eliminarCliente = (clienteId) => { // elimina a un cliente en base a su ID
        setClientesAsignados(clientes => clientes.filter(cliente => cliente.cli !== clienteId))
    }
    // Cambios en el backend
    const [isUpdating, setIsUpdating] = React.useState(false)
    const confirmarCambiosBackend = async() => { 
        const clientesAntes = clientesIniciales.map(cliente => cliente.cli)
        const clientesDespues = clientesAsignados.map(cliente => cliente.cli)
        setIsUpdating(true)
        // const request = await matrx({
        //     trx:"usclx",
        //     uid:uid,
        //     cli:clientesDespues,
        //     cla:clientesAntes
        // });
        const request = await usclx({
            uid:uid,
            cli:clientesDespues,
            cla:clientesAntes
        });
        setIsUpdating(false)
        if (request.ans.stx === "ok"){
            setClientesIniciales(clientesAsignados)
            toast.success(request.ans.msg)
        } else {
            toast.error(request.ans.msg)
        }
    }

    // control de request
    const [isFetching, setIsFetching] = React.useState(false);
    const fetched = React.useRef(false)
    const fetch = React.useCallback(async() => {
        setIsFetching(true)
        // const request = await matrx({   
        //     trx:"uscla",
        //     uid:uid})
        const request = await uscla({
            uid:uid
        });
        fetched.current = true
        if (request.ans.stx === "ok"){
            setClientesIniciales(request.ans.cla || [])
            setClientesAsignados(request.ans.cla || [])
        } 
        else if( request.asn.stx === "uk"){
            setClientesAsignados([])
        }
        
        setIsFetching(false)
    },[])

    return {
        clientesAsignados,
        fetched: fetched.current,
        fetch,
        isFetching,
        hasChanged: isDirty,
        agregarcliente,
        eliminarCliente,
        confirmarCambios: confirmarCambiosBackend,
        isUpdating,
        cancelarCambios,
    }

}

export default useClientesAsignadosUsuario