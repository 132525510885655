import React from 'react'
import CacheApp from 'Utils/CacheApp'
import { filtrosValores } from 'Utils/filtrosHandler'
import {isEqual} from 'lodash'

function useFiltrosCalen({cacheKey = "", session = ""}) {
    // const _temp = React.useRef(CacheApp.getCacheTab({
    //     key: cacheKey,
    //     sessionKey: session,
    // })) // obtiene los valores del cache

    const filtrosAplicados = React.useRef() // valor solo para comparar
    // const [filtrosSelected, setFiltrosSelected] = React.useState(_temp.current || {})
    const [filtrosSelected, setFiltrosSelected] = React.useState({})

    const isFiltrosUpdated = React.useMemo(() =>isEqual(filtrosAplicados.current, filtrosSelected),[filtrosAplicados.current, filtrosSelected])

    const handleOnChange = (selected, key) => {
        setFiltrosSelected(oldValues => {
            let newValues = {
                ...oldValues,                
            }
            switch (key) {
                case "hol":
                    delete newValues.ret 
                case "ret":
                    delete newValues.zon
                case "zon":
                    delete newValues.nie
                case "nie":
                    delete newValues.reg
                case "reg":
                    delete newValues.ciu 
                case "ciu":
                    delete newValues.com
                case "com":
                    delete newValues.loc 
                case "loc":
                default:
                    break;
            }
            if (selected.length > 0) {
                newValues[key] = selected;
            } else {
                delete newValues[key]
            }
            return (newValues)
        }) // Actualiza los seleccionados
    }
    const aplicarFiltros = (callback) => {
        let requestFiltros = filtrosValores(filtrosSelected)
        callback(requestFiltros)
        filtrosAplicados.current = filtrosSelected
        // CacheApp.setCacheTab({
        //     key: cacheKey,
        //     sessionKey: session,
        //     value: filtrosSelected
        // })

    }
    const reiniciarFiltros = () => setFiltrosSelected({})

    return {
        // filtrosAplicados,
        filtrosSelected,
        isFiltrosUpdated,
        handleOnChange,
        aplicarFiltros,
        reiniciarFiltros,
    }
}

export default useFiltrosCalen