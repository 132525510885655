import DivControl from 'Components/DivControl'
import geprf from 'Connection/transacciones/geprf'
import useDatosUsuario from 'Hooks/Datos/useDatosUsuarioCom'
import React from 'react'
import { Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import CacheApp from 'Utils/CacheApp'
import Enum from 'Utils/Enum'
import { actualizarInfoPagina } from 'Utils/paginaInfo'
import FormularioEditarDatos from './_EditarDatos/EditarDatos'
import LocalesAsignados from './_LocalesAsignados/LocalesAsignados'
// import DatosPersonales from './EditarDatos'
//

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">

                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={".."}>
                            Gestión Usuarios Comerciales
                        </Link>
                    </li>
                    <li className="breadcrumb-item active">
                        Perfil usuario Comercial
                    </li>
                </ol>
            </div>
            <h4 className="page-title">Usuario</h4>
        </div>
    )
}


const MENU_COL = 2



const itemsEnum = new Enum(
    'editarUsuario',
    'localesAsignados',
)
const CACHE_LAYOUT_USER_ITEM = 'cache-layout-item'

function LayoutUser(props) {
    React.useEffect(()=> actualizarInfoPagina(props),[]) // actualizacion del titulo de la pagina

    const location = useLocation()
    const { datos, isFetching, refreshData } = useDatosUsuario({idUser: location.state?.idUser})

    // elige el item activo
    const [activeItem, setActiveItem] = React.useState(
        itemsEnum.editarUsuario
    )

    const setItem = (e) => {
        const itemName = Number(e.target.name); // obtiene el item activo
        setActiveItem(itemName)    // setea el item activo
        CacheApp.setCacheTab({
            key: CACHE_LAYOUT_USER_ITEM
        })
    }
    // hooks de datos
    
    React.useEffect(() => {
        if(location.state?.idUser){
            refreshData();
        }
    },[])
    
    const [perfilOpc, setperfilOpc] = React.useState([])
    React.useEffect(()=>{
        const run = async()=>{
            const geprfRes = await geprf({})
            if (geprfRes.ans.stx === "ok"){
                const options = geprfRes.ans.prf.map(opt => ({label: opt.nam, value: opt.cod})); 
                setperfilOpc(options || []); 
            }
        }
        run();
    },[])

    if (!location.state?.idUser){
        return ("no hay usuario seleccionado")
    }
    return (
        <div className="container">
            <TituloPagina/>
            
            <Card>
                <Row>
                    <Col md={MENU_COL} className="pe-0">
                        <ListGroup variant='flush'>
                            <ListGroup.Item
                                action
                                name={itemsEnum.editarUsuario}
                                active={activeItem === itemsEnum.editarUsuario}
                                onClick={setItem}
                            >
                                <i className='mdi mdi-account me-2' /> 
                                Datos de usuario
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                name={itemsEnum.localesAsignados}
                                active={activeItem === itemsEnum.localesAsignados}
                                onClick={setItem}
                            >
                                <i className='mdi mdi-store me-2' />
                                Locales asignados
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={12 - MENU_COL} style={{borderLeft: "1px solid #dbdbdb"}}>
                        <Card.Body>
                            {isFetching && (
                                <div className='text-center mt-2'>
                                    <Spinner animation="border" role="status" />
                                </div>
                            )}
                            { datos !== null && (<> 
                            <DivControl show={activeItem === itemsEnum.localesAsignados}> 
                                    <LocalesAsignados
                                        idUser={location.state?.idUser}
                                        userData={datos}
                                    />
                                </DivControl>
                                <DivControl show={activeItem === itemsEnum.editarUsuario}>
                                    <FormularioEditarDatos datosUsuario={datos} opciones={perfilOpc} />
                                </DivControl>
                               
                            </>)}
                           
                            
                            
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default LayoutUser