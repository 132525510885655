import React from "react";
import ImageViewer from "react-simple-image-viewer";
import buttonRF from "Assets/images/rpbtn.jpg"
import { Spinner } from "react-bootstrap";
import gepic from "Connection/transacciones/gepic";

function FotoTareaTicket ({idTicket, idRequerimiento, arrGraph}) {
    // console.log("arraw",arrGraph)
    const [srcFoto, setSrcFoto] = React.useState([]);
    const [index, setIndex] = React.useState(0)
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(false)
    const imageFetched = React.useRef(false);

    const handleOnClick = async() => {
        if(imageFetched.current === false ) {
            setIsFetching(true);
            // const graph = []
            // for(let i=0; i < arrGraph.length; i++){
            //     const request = await gepic(idTicket, arrGraph[i]);
            //     // console.log("req",arrGraph[i])
            //     if(request.ans.stx === "ok") {
            //         if(idRequerimiento === arrGraph[i]){
            //             setIndex(i)
            //         }
            //         graph.push(`data:image/jpg;base64,${request.ans.pic}`)
            //         imageFetched.current = true;
            //     }
            // }
            // if(graph.length > 0){
            //     // console.log("graph",graph)
            //     setSrcFoto(graph)
            //     // console.log("graph",graph)
            //     imageFetched.current = true;
            // }
            const request = await gepic(idTicket, idRequerimiento);
            if(request.ans.stx === "ok") {
                setSrcFoto([`data:image/jpg;base64,${request.ans.pic}`])
                imageFetched.current = true;
            }
            setIsFetching(false)
        }
        setIsViewerOpen(true);
    }

    return (
        <>
        {
            isFetching? (
                <Spinner animation="border" size="sm" />
            ):(
                <img
                    src={buttonRF}
                    onClick={handleOnClick}
                    alt=""
                    type="button"
                />
            )
        }
            
            {isViewerOpen && (
                
                <ImageViewer
                    src={srcFoto}
                    currentIndex={index}
                    onClose={() => setIsViewerOpen(false)}
                    disableScroll={false}
                    backgroundStyle={{backgroundColor: "rgba(0,0,0,0.9)"}}
                    closeOnClickOutside={true}
                />
            )

            } 
        </>
    )
}

export default FotoTareaTicket