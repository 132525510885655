import React from 'react';
import BottonToggleFiltro from 'Components/Botones/BotonToggleFiltro';
import { Button, Collapse, Form } from 'react-bootstrap';
// import MultiSelectHolding from 'Components/Filtros/MultiSelectHolding';
import MultiSelectRetails from 'Components/Filtros/MultiSelectRetails';
import { filtrosValores } from 'Utils/filtrosHandler';
import MultiSelectLocales from 'Components/Filtros/MultiSelectLocales';
import MultiSelectCliente from 'Components/Filtros/MultiSelectCliente';
import Select from 'react-select';
// import MultiSelectNielsen from 'Components/Filtros/MultiSelectNielsen';
// import MultiSelectRegion from 'Components/Filtros/MultiSelectRegion';
// import MultiSelectCiudad from 'Components/Filtros/MultiSelectCiudad';
import MultiSelectComuna from 'Components/Filtros/MultiSelectComuna';
import MultiSelectKam from 'Components/Filtros/MultiSelectKam';
import MultiSelectKas from 'Components/Filtros/MultiSelectKas';
// import MultiSelectZonaGeografica from 'Components/Filtros/MultiSelectZonaGeografica';
// import useFiltros from 'Hooks/Custom/useFiltros';
// import useFiltrosTask from 'Hooks/Custom/useFiltrosTask';
import useFiltrosRep from 'Hooks/Custom/useFiltrosRep';
import { useLocation } from 'react-router-dom';
import { fechaHoy } from "Utils/funcionesFechaHora";
// import InputTexto from 'Components/FormElements/InputTexto';
import { fechaDefault, limpiarFecha } from "Utils/funcionesFechaHora";
const fechaDiaHoy = fechaHoy();
const opc = [{label:"0 Días o mas",value:0},{label:"30 Días o mas",value:30},{label:"60 Días o mas",value:60},{label:"90 Días o mas",value:90},
            {label:"120 Días o mas",value:120},{label:"150 Días o mas",value:150},{label:"180 Días o mas",value:180},{label:"210 Días o mas",value:210},
            {label:"240 Días o mas",value:240},{label:"270 Días o mas",value:270},{label:"300 Días o mas",value:300},{label:"330 Días o mas",value:330}
            ,{label:"360 Días o mas",value:360}]

function FiltroReportes({ totalLocales = 0, fetchLocales }) {
    // Vista de filtros

    const [filtrosVisibles, setFiltrosVisibles] = React.useState(true);            // State para mostrar el collapse
    const toggleFiltros = () => setFiltrosVisibles(!filtrosVisibles);
    const location = useLocation()
    const [durSelect, setDurSelect] = React.useState([opc[0]])

    const {
        // filtrosAplicados,
        isFiltrosUpdated,
        filtrosSelected, 
        handleOnChange, 
        aplicarFiltros,
        reiniciarFiltros
    } = useFiltrosRep({
        cacheKey: "f-locales",
        session: location.key
    })

    const [filtros, setfiltros] = React.useState({
        desde_fecha: fechaDiaHoy,
        hasta_fecha: fechaDiaHoy
    })
    const [isDisabled, setIsDisabled] = React.useState(false);
    
    const filtroDateOnChange = (value, key) => {
        // console.log("h",parseInt(limpiarFecha(value)))
        if(value){
            if(key === 1){
                handleOnChange([{value:parseInt(limpiarFecha(value)),label: "desde"}],"fud")
                filtrosValores({"fud":[{value:parseInt(limpiarFecha(value)),label: "desde"}]})
            }
            else{
                
                handleOnChange([{value:parseInt(limpiarFecha(value)),label: "hasta"}],"fuh")
                filtrosValores({"fuh":[{value:parseInt(limpiarFecha(value)),label: "desde"}]})

            }}
        else{
            handleOnChange([{value:220101,label: "desde"}],"fud")
            filtrosValores({"fud":[{value:220101,label: "desde"}]})
        }
    }

    const onDispChange = (value) => {
        // console.log(value)
        setDurSelect(value)
        handleOnChange([{value:value.value}],"dur")
        filtrosValores({"dur":[{value:value.value,label: "dur"}]})
    }
    React.useEffect(() => {

        filtroDateOnChange(fechaDiaHoy,1)
        filtroDateOnChange(fechaDiaHoy,2)
        onDispChange(opc[0])
        // const tareas = (localStorage.groupDetailList.split(",")).map(elem=> parseInt(elem, 10))
        // if(tareas){
        //     handleOnChange(tareas.map(elem=>({value:elem})),"tic")
        //         filtrosValores({"tic":[{value:tareas,label: "ticket_id"}]})
        // }
        
    },[])

 
    return (
        <div>
            <div className='d-flex align-items-baseline mb-2'>
                <span className='me-2'>
                    Mostrando <strong>{totalLocales}</strong> resultados
                </span>
                <span className='me-2'>
                    <BottonToggleFiltro onClick={toggleFiltros} >
                        Filtros{" "}
                    </BottonToggleFiltro>
                </span>
                <span className='text-warning ms-2'>
                    {!isFiltrosUpdated &&
                        <h4>
                            <span className="badge badge-danger-lighten">Filtros sin aplicar</span>
                        </h4>
                    }
                </span>
                
            </div>
            <Collapse in={filtrosVisibles} > 
                <div className=''>
                    <div className="row">
                        
                        
                        <div className='col-3 mb-2'>
                            Clientes
                            <MultiSelectCliente
                                selected={filtrosSelected.cli}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="cli"
                            />
                        </div>
                        
                        <div className='col-3 mb-2'>
                                    FUR desde
                                    <input 
                                        type="date" 
                                        className="form-control" 
                                        defaultValue={fechaDiaHoy}
                                        max={fechaDiaHoy}
                                        // onChange={e => filtroOnChange(e.target.value, "desde_fecha",1)}
                                        onChange={e => filtroDateOnChange(e.target.value,1)}
                                        // onChange={handleOnChange}
                                        disabled={isDisabled}
                                    />
                                </div>
                        <div className='col-3 mb-2'>
                            FUR hasta
                            <input 
                                type="date" 
                                className="form-control" 
                                defaultValue={fechaDiaHoy}
                                max={fechaDiaHoy}
                                // onChange={e => filtroOnChange(e.target.value, "hasta_fecha",2)}
                                onChange={e => filtroDateOnChange(e.target.value,2)}
                                // onChange={handleOnChange}
                                disabled={isDisabled}
                            />
                        
                        </div>
                        <div className='col-3 mb-2'>
                            DUR
                            <Select
                                value={durSelect}
                                options={opc}
                                onChange={(e)=> onDispChange(e)}
                                placeholder="Seleccionar DUR"
                            />  
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-3 mb-2'>
                            Retails
                            <MultiSelectRetails 
                                selected={filtrosSelected.ret}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="ret"
                            />
                        </div>
                        <div className='col-3 mb-2'>
                            Comunas
                            <MultiSelectComuna 
                                selected={filtrosSelected.com}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="com"
                            />
                        </div>
                        <div className='col-3 mb-2'>
                            Locales
                            <MultiSelectLocales 
                                selected={filtrosSelected.loc}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="loc"
                            />
                        </div>
                        <div className='col-3 mb-2'>
                            Kam
                            <MultiSelectKam
                                selected={filtrosSelected.kam}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="kam"
                            />
                        </div>
                        <div className='col-3 mb-2'>
                            Kas
                            <MultiSelectKas
                                selected={filtrosSelected.kas}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="kas"
                            />
                        </div>
                        
                        
                    </div>
                    <div className='text-end mb-2' >
                        <Button type='button' onClick={reiniciarFiltros} className='btn btn-secondary me-2'>
                            Limpiar filtros
                        </Button>
                        {/* <Button onClick={()=>{console.log(fetchLocales)}}>test</Button> */}
                        <Button type='button' variant='info' onClick={() => aplicarFiltros(fetchLocales)} disabled={isFiltrosUpdated }>
                            Aplicar filtros
                        </Button>
                    </div>

                    
                </div>
                
            </Collapse>
            

        </div>
    )
}

export default FiltroReportes;

