import React from "react";

const ZolbitFooter = () => {
    const appVersion = process.env.REACT_APP_VERSION
    // const builVersion = process.env.REACT_APP_BUILD

    // const appNombre = process.env.REACT_APP_NAME

    return(
        <div className="footer" >
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div>
                        {new Date().getFullYear()} © Zolbit
                    </div>
                    {/* <div>
                        Build {builVersion}
                    </div> */}
                    <div>
                        Versión {appVersion}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ZolbitFooter;