import gehol from 'Connection/transacciones/gehol'
import React from 'react'

function useHolding() {
    const [options, setOptions] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)

    const fetch = async(dependencies = {}) => {
        setIsFetching(true)
        const request = await gehol(dependencies);
        if(request.ans.stx === "ok") {
            if(request.ans.hol) {
                const newValues = request.ans.hol.map(item => ({
                    "value": item.cod,
                    "label": item.nam,   
                }))
                setOptions(newValues)
            } else {
                setOptions([])
            }
        }
        setIsFetching(false)

    }

    return {
        options,
        isFetching,
        fetch
    }
    
}

export default useHolding