import React, { useState, useEffect } from "react";
import { formatearFechaHora } from "Utils/funcionesFechaHora";
import { formatearHoraFecha} from "Utils/funcionesFechaHora";
import tasko from "Connection/transacciones/tasko.js";
import {Modal, Row, Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import { atom, useRecoilValue, useRecoilState } from "recoil"
import { Form, Button } from 'react-bootstrap'
import { backend_request } from "Connection/backend_reduce";
import FotoTareaTicket from "./FotoTareaTicket";
import Tabla from 'Components/Tabla/Tabla';
import toast from 'react-hot-toast';
const thClassName= "ps-1"

export const isModalTareaOpenState = atom({
    key: "isModalGroupTareaOpen",
    default: false
})

// export const tareaSeleccionadoState = atom({
//     key:"TareaSeleccionadoState",
//     default: undefined
// })

export const tareaNombreState = atom({
    key:"GroupTareaNombreState",
    default: undefined
})

const SeleccionMultiple = ({alternativasSeleccionadas}) => {
    return(
        <>
            {alternativasSeleccionadas.split("~").map((item,index)=>(
                <li key={"alt-"+index}>
                    {item}
                </li>
            ))}
        </>
    )
}



function Foto ({idRequerimiento, idTarea }) {
    // console.log('foto', idRequerimiento, idTarea)
    return(
        <FotoTareaTicket 
            idTicket={idTarea}
            idRequerimiento={idRequerimiento}
        />
    )
}

const FechaHora = ({fechaYHora}) => {
    return(formatearHoraFecha(fechaYHora)
        
    )
}


function SwitchResponse({type,response, item, ticketId}) {
    switch (type) {
        case 1:
            return response
        case 2:
            return response
        case 3:
            return <SeleccionMultiple alternativasSeleccionadas={response}/>
        case 4:
            return response
        case 7:
            return "Reposición realizada"
        case 5:
            return <Foto 
                        idTarea={ticketId}
                        idRequerimiento={item.qid} 
                        // fotoBase64={item.ant} 
                    />
        case 6:
            return <FechaHora  fechaYHora={response} />
        default:
            return response
        
    }
}

function DetalleTareaRequerimientos ({tadet}) {
    // console.log("tades+t",tadet)

    const [detalles, setDetalle] = React.useState("");
    // const [selection, setSelection] = React.useState([])
    const [isOpen, setIsOpen] = useRecoilState(isModalTareaOpenState)
    // const idTarea = useRecoilValue(tareaSeleccionadoState)
    const nombreTarea = useRecoilValue(tareaNombreState)

    // const onChangeDetalle = (value) => setDetalle(value.target.value);

    // const detalleValido = React.useMemo(()=> {
    //     if(detalles === "") return undefined
    //     else return true;
    // },[detalles])

    
    // const seleccionValida = React.useMemo(()=> {
    //     if(selection.length === 0) return undefined
    //     else return true;
    // },[selection])

    // const handleSubmit = async(response) => {
    //     // console.log("temp",selection)
    //     const request = await tasko({
    //         idTarea: tadet.ans.tid,
    //         resp: response,
    //         detail: detalles,
    //         ob: selection
    //     })
        
    //     if(request.ans.stx === "ok") {
    //         toast.success(request.ans.msg)
    //         window.location.reload(false)
    //     } else if(request.ans.stx === "nk") {
    //         toast.error((t) => (<>
    //             {request.ans.msg}
    //         </>) )
    //     }
        

    //     // handleClose()

    // }
    
    const handleClose = () => {
        setIsOpen(false);
    }

    const columnas = React.useMemo(() => [
        {
            Header: 'Requerimientos',
            accessor: 'qid',
            thClassName
        },{
            Header: 'Descripción',
            accessor: 'tiq',
            thClassName
        },{
            Header: 'Respuesta',
            accessor: 'aty',
            thClassName,
            Cell: props => {
                const {value,row}=props;
                return <SwitchResponse type={value} response={row.original.ant} item={row.original} ticketId={tadet.ans.tid}/>
            }
        }

    ] ,[tadet])
    // const lista_acciones = tadet.ans.tas;
    // console.log("tadet",tadet)
    return(
        <>
        <Modal         
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                {/* <Modal.Title>Tarea {nombreTarea} </Modal.Title> */}
            </Modal.Header>
                <Modal.Body >
                    <div className="row text-center">
                            <h3>
                            <span className="text-info">{tadet && tadet.ans.lid.split(":")[2]}</span>
                            </h3>
                    </div>
                    <Row>
                        <Col>
                        <div style={{fontSize:"16px"}}>
                            <b>Direccion:</b> {tadet && tadet.ans.adr}
                        </div>
                        <div style={{fontSize:"16px"}}>
                            <b>Region:</b> {tadet && tadet.ans.reg}
                        </div>
                        <div style={{fontSize:"16px"}}>
                            <b>Ticket:</b> {nombreTarea}
                        </div>
                        </Col> 
                        <Col>
                        {/* <div style={{fontSize:"16px"}}>
                            <b>Fecha de termino:</b> 30/10/2022                       
                        </div> */}
                        <div style={{fontSize:"16px"}}>
                            {/* <b>Inicio tarea:</b> {formatearFechaHora(tadet.ans.tsi)} {(tadet.ans.ubi === "e")? "en el local":"fuera del local"} ({tadet.ans.dse} metros)     */}
                            <b>Inicio tarea:</b> {formatearFechaHora(tadet.ans.tsi)} {(tadet.ans.ubi === "e")? <OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">En el local</Tooltip>}>
                        <i
                            className={"mdi mdi-map-marker-radius"}
                            style={{fontSize:"25px", color:"#638AFC"}}
                            rel="tooltip"
                            data-toggle="tooltip" 
                            data-placement="top" >
                        </i></OverlayTrigger> 
                    :<OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">Fuera del local</Tooltip>}><i 
                        className={"mdi mdi-map-marker-off"}
                        style={{fontSize:"25px", color:"#FC6363"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" >
                    </i></OverlayTrigger>} ({tadet.ans.dse} metros)                        
                        </div>
                        <div style={{fontSize:"16px"}}>
                            <b>Fin tarea:</b> {formatearFechaHora(tadet.ans.tse)} {(tadet.ans.ube === "e")? <OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">En el local</Tooltip>}>
                        <i 
                            className={"mdi mdi-map-marker-radius"}
                            style={{fontSize:"25px", color:"#638AFC"}}
                            rel="tooltip"
                            data-toggle="tooltip" 
                            data-placement="top" >
                        </i></OverlayTrigger> 
                    :<OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">Fuera del local</Tooltip>}><i 
                        className={"mdi mdi-map-marker-off"}
                        style={{fontSize:"25px", color:"#FC6363"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" >
                    </i></OverlayTrigger>} ({tadet.ans.dsi} metros)                            
                        </div>
                        
                        </Col> 
                    </Row>
                    
                    {/* <div className="row text-center">
                            <h3>{tadet && tadet.ans.lid.split(":")[2]}</h3>                                
                        </div>
                        <div className="row text-center">
                            <h3>{tadet && tadet.ans.adr}</h3>                                
                        </div>
                        <div className="row text-center">
                            <h3>{tadet && tadet.ans.reg}</h3>
                        </div> */}
                    <Tabla
                        columns={columnas}
                        // globalFilter={true}
                        data={tadet.ans.tas}
                        titleSection={""}
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        cellClassName={"p-1"}
                        // select
                        // onSelected={(rows) => setSelection(rows.map(row => row.qid))}
                    />
                    {/* <div className="row mb-2">
                        <div className='col-2'>
                            <label htmlFor="descripcion" className="col-form-label">
                                Descripción
                            </label>
                        </div>
                        <Form.Control
                            as='textarea'
                            id='descripcion' 
                            label="Detalle"
                            rows={3}
                            onChange={onChangeDetalle} 
                            type='text'
                            maxLength={512}
                            required>
                        </Form.Control>
                    </div> */}
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
        
    )

}

export default DetalleTareaRequerimientos;