import React from 'react'
import { MultiSelect } from 'react-multi-select-component';
import localization from "Assets/localization/multiSelect.json"
import { getCluster } from 'Authentication/Session';
function MultiSelectCluster({
    isDisabled,
    dependencies = {}, 
    onChange = () => {},
    selected=[],
    name

}) {

    // const cluster = [{label:"X",value:0},{label:"A",value:1},{label:"B",value:2},{label:"C",value:3},{label:"D",value:4},{label:"E",value:5}];
    const cluster = getCluster().map((item) => ({label: item.cco, value: item.cid}))
    // Variable utilizada para determinar si la lista de usaurios está actualizados
    const fetched = React.useRef(false) 

    React.useEffect(()=> {
        fetched.current = false; // si se actualiza una dependencia se debe menciar que esta desactualizado 
    },[ 
        dependencies.clu,
    ])

    // const onToggle = async(isOpen) => {
    //     if(isOpen && !fetched.current) {
    //         fetched.current = true; // Lista de comunas actualizados? SI
    //         await cluster.refreshKas(dependencies);
    //     }
    // }
    

    const handleOnChange = (values) => {
        const sorted = values.sort((a,b) => a.value - b.value)
        onChange(sorted, name)
    }
    return (
        <MultiSelect
            overrideStrings={localization}
            options={cluster}
            disabled={isDisabled}
            // isLoading={cluster.isFetching}
            // onMenuToggle={onToggle}
            value={selected}
            onChange={handleOnChange}
        />
    )
}

export default MultiSelectCluster;