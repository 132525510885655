import React from 'react'
import { Button } from 'react-bootstrap'
import InputTexto from "Components/FormElements/InputTexto"
import weact from 'Connection/transacciones/weact'
import toast from 'react-hot-toast'
import useFormulario from 'Hooks/Custom/useFormulario'
import { validarEmail } from 'Utils/validations'
import { format } from 'Utils/rut'
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import localization from "Assets/localization/multiSelect.json"
import { getClientID } from "Authentication/Session";


const propsStyles = {
    asRow: true,
    labelSize: 3,
    size: "sm"
}

function FormularioEditarDatos({datosUsuario, clientes, opciones}) {
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [perfilSelect, setPerfilSelect] =  React.useState([]);
    React.useEffect(() => {
        if (opciones.length > 0) {
            const perfilArray =  datosUsuario.prof.split("");
            let selected = [];
            perfilArray.forEach((value, index )=> {

                if (value === '1') {
                    selected.push(opciones[index]);
                }
            })
        }
        
    }, [datosUsuario, opciones]);

    const formulario = useFormulario({ 
        initialValues: {
            "name": datosUsuario.name,  // input
            "snam": datosUsuario.snam,  // input
            "mail": datosUsuario.mail,  // input
            "phon": datosUsuario.phon,
            "ndoc": datosUsuario.rut, // phon
            "prof": datosUsuario.prof,
            "clie": datosUsuario.clie
            
        },
        onSubmit: async({dirtyValues}) => {
            setIsDisabled(true)
            const request = await weact(datosUsuario.uid, dirtyValues);

            if(request.ans.stx === "ok"){
                toast.success(request.ans.msg)
                formulario.updateInitialValues();
                
                formulario.resetFieldValidation();
                // refreshUsers()
            }
            setIsDisabled(false)

        },

        validateOnSubmit: ({dirtyValues}) => {
            const errors = {}
            if(Object.keys(dirtyValues).length === 0){
                errors.submitValues = "No hay cambios que registrar."
                toast.error(errors.submitValues)
            }   
            return errors;
        },
    })

    const onNameChange = React.useCallback((value) => {
        const FIELD = "name"
        if (value.length <= 50) {
            if(value === "") { 
                formulario.setFieldIsInvalid(FIELD)
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        }
        // eslint-disable-next-line
    },[]) 

    const onSnamChange = React.useCallback((value) => {
        const FIELD = "snam"
        if (value.length <= 50) {
            if(value === "") { 
                formulario.setFieldIsInvalid(FIELD)
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        }
    },[])

    const onMailChange = React.useCallback((value) => {
        const FIELD = "mail"
        if (value.length <= 48) {
            if(!validarEmail(value)){
                formulario.setFieldIsInvalid(FIELD) // false: hay error
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        }
    },[])

    const onPhonChange = React.useCallback((value) => {
        const FIELD = "phon"
        if (value.length <= 12) {
            const re = /^[0-9\b]+$/;
            if ((value === '' || re.test(value))) {
                formulario.setFieldValue(FIELD, value);
                if(value === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== value) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
            }
        }
    },[])
    const onProfChange = React.useCallback((value) => {
        const FIELD = "prof"
        setPerfilSelect(value)
        const result = value.reduce((total, currentValue) => total = total + currentValue.value,0)
        // console.log(result)
            if ((result === '' || result)) {
                formulario.setFieldValue(FIELD, result);
                if(result === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== result) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
        }
    },[])

    const onClieChange = React.useCallback((value) => {
        const FIELD = "clie"
            if ((value.value === '' || value.value)) {
                formulario.setFieldValue(FIELD, value.value);
                if(value.value === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== value.value) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
        }
    },[])
    

    return (
        <>
                {/* <div className='card'>
                    <div className="card-body">
                        <div className="row"> */}
                            {/* <img className="rounded mx-auto d-block" src={require("./usuario.png")} alt="IconoUsuario" style={{ width: "200px", height: "200px" }} /> */}
                                    <div className="mb-1">
                                        <h4>
                                            Datos de Usuario {format(formulario.values.ndoc)}
                                        </h4>
                                    </div>
                                    <div className="mb-1">
                                        <InputTexto
                                            {...propsStyles}
                                            label="Nombre(s)"
                                            name={"name"}
                                            validation={formulario.fieldValidation.name}
                                            onChange={onNameChange}
                                            value={formulario.values.name}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div className="mb-1">
                                        <InputTexto
                                            {...propsStyles}
                                            label={"Apellidos"}
                                            onChange={onSnamChange}
                                            name={"snam"}
                                            disabled={isDisabled}
                                            value={formulario.values.snam}
                                            validation={formulario.fieldValidation.snam}
                                        />
                                    </div>
                                    <div className="mb-1">
                                        <h5>
                                            Cuenta
                                            </h5>
                                        </div>
                                    <div className="mb-2">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <label> Perfil de usuario</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <MultiSelect
                                                    overrideStrings={localization}
                                                    name="prof"
                                                    placeholder="Test"
                                                    value={opciones}
                                                    onChange={onProfChange}
                                                    options={opciones.map(value => ({
                                                        ...value,
                                                        disabled: true
                                                    }))}
                                                />
                                            </div>
                                        </div> 
                                    </div>
                                    {(getClientID()) === '0' ? ( 
                                    <div className="mb-2">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <label> Cliente</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <Select
                                                    name="clie"
                                                    disabled={isDisabled}
                                                    placeholder="Elegir un cliente"
                                                    value={{
                                                        label: clientes.find(opt => opt.value === formulario.values.clie)?.label, 
                                                        value: formulario.values.clie
                                                    }}
                                                    onChange={onClieChange}
                                                    options={clientes}
                                                    validation={formulario.fieldValidation.clie}
                                                />
                                            </div>
                                        </div> 

                                    </div>
                                    ) :
                                    (null)}
                                    <div className="mb-1">
                                        <h5>
                                            Contacto
                                            </h5>
                                        </div>
                                    <div className="mb-1">
                                        <InputTexto 
                                            {...propsStyles}
                                            label={"Mail"}
                                            name={"mail"}
                                            disabled={isDisabled}
                                            value={formulario.values.mail}
                                            validation={formulario.fieldValidation.mail}
                                            onChange={onMailChange}
                                        />
                                    </div>
                                    <div className="mb-1">
                                        <InputTexto 
                                            {...propsStyles}
                                            label="Teléfono"
                                            name="phon"
                                            disabled={isDisabled}
                                            value={formulario.values.phon}
                                            onChange={onPhonChange}
                                            validation={formulario.fieldValidation.phon}
                                        />   
                                    </div>
                                    <div className='d-flex justify-content-end mt-3'>
                                        <Button variant="btn btn-primary" onClick={formulario.onSubmit} disabled={formulario.haveInvalidFields || !formulario.haveValidFields }>
                                            Guardar cambios
                                        </Button>
                                    </div>
    
    
    </>)
}

export default FormularioEditarDatos;