import useDatosUsuarioWeb from 'Hooks/Datos/useDatosUsuarioWeb'
import React from 'react'
import { Card} from 'react-bootstrap'
import { Link, useLocation} from 'react-router-dom'
import { actualizarInfoPagina } from 'Utils/paginaInfo'
import EditarDatosWeb from "./EditarDatosWeb"
import clall from "Connection/transacciones/clall";
import geprf from "Connection/transacciones/geprf";


function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={".."}>
                            Gestión Usuarios Web
                        </Link>
                    </li>
                    <li className="breadcrumb-item active">
                        Perfil usuario Web
                    </li>
                </ol>
            </div>
            <h4 className="page-title">Usuario</h4>
        </div>
    )
}

function LayoutUser(props) {
    React.useEffect(()=> actualizarInfoPagina(props),[]) // eslint-disable-line
    const location = useLocation();

    const [clientes, setClientes] = React.useState([]);
    const detalleUsuarioWeb = useDatosUsuarioWeb(location.state.userID)
    const [perfilOpc, setperfilOpc] = React.useState([])


    React.useEffect(() => { 
        const run = async() => {
            await detalleUsuarioWeb.refreshData(); // obtiene los datos del usuario
            const clallRes = await clall(0);    // respuesta de los clientes
            if(clallRes.ans.stx === "ok") {
                setClientes(clallRes.ans.wus || [])               
            }
                // console.log("acaa2",detalleUsuarioWeb.datos.rut) 
            const geprfRes = await geprf({
                
            }); 
                // uid: detalleUsuarioWeb.datos.uid
                // lista de opciones perfil usuario 45
        
            if (geprfRes.ans.stx === "ok") { 
                const options = geprfRes.ans.prf.map(opt => ({label: opt.nam, value: opt.cod})); 
                // console.log("acaa",geprfRes.ans.prf)
                // console.log("acaa3",detalleUsuarioWeb.datos.uid)
                // console.log("ott",jsonBody2)
                setperfilOpc(options || []); 
            }
        }
        run();
    }, []); // eslint-disable-line


    const nClientes = React.useMemo(() => {
        return clientes.map(opt => ({label: opt.name, value: opt.cid}))
    },[clientes]);
    return (
        <div className="container">
            <TituloPagina/>
            <Card>
                <Card.Body>

                    {detalleUsuarioWeb.datos !== null && <EditarDatosWeb datosUsuario={detalleUsuarioWeb.datos} clientes={nClientes} opciones={perfilOpc}/>}
                </Card.Body>
            </Card>
        </div>
    )
}
    export default LayoutUser