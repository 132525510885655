import useTipoBanco from 'Hooks/Selects/useTipoBanco'
import React from 'react'
import { MultiSelect } from 'react-multi-select-component';
import localization from "Assets/localization/multiSelect.json"

function MultiSelectBanco({
    onChange = () => {},
    selected=[],
    name
}) {
    const bancos = useTipoBanco()

    const handleOnChange = (values) => {
        const sorted = values.sort((a,b) => a.value - b.value)
        onChange(sorted, name)
    }
  
    return (
        <MultiSelect 
            options={bancos.values}
            onChange={handleOnChange}
            value={selected}
            overrideStrings={localization}
        />
    )
}

export default MultiSelectBanco