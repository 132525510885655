import React, { useState, useEffect} from "react";
import { formatearFechaHora } from "Utils/funcionesFechaHora";
import Paginacion from "Components/Paginacion";
import ModalDetalleTarea from "./TareaDetalleModal";
import { getClientID } from "Authentication/Session";

function ItemTarea ({tarea, setTareaSeleccionada}) {
    return (
        <button type="button" 
            className={"list-group-item list-group-item-action lista-tareas pt-1 pb-1" }  
            aria-current="true" 
            onClick={()=>{
                setTareaSeleccionada(tarea.idt)
                window.$('#modalDetalle').modal('show')
            }}>
            <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">
                    
                    {tarea.lid? tarea.lid.split(":")[2]:""}
                </h5>
                <small className="mt-2">{formatearFechaHora(tarea.wen)}</small>
            </div>
            {/* {(getClientID()) === '0' &&
            <p className="mb-0">{tarea.usr}</p>} */}
            <p className="mb-0">{tarea.usr}</p>
            <small>{tarea.tst? tarea.tst.split(":")[1]:""}</small>
        </button>
    )
}



function TareasLista ({lista}) {
    // variables de los modal
    const [tareaSeleccionada, setTareaSeleccionada] = useState(null)
    
    // paginacion
    // const [itemsPorPagina, setItemsPorPagina] = useState(10);
    const itemsPorPagina = 10;
    const [paginaActual, setPaginaActual] = useState(1);
    const [currentItems, setCurrentItems] = useState([])

    const indiceUltimoItem = paginaActual * itemsPorPagina
    const indicePrimerItem = indiceUltimoItem - itemsPorPagina

    useEffect(() => {
        setCurrentItems(lista? lista.slice(indicePrimerItem, indiceUltimoItem):[])    
    }, [lista,paginaActual, indicePrimerItem, indiceUltimoItem]) // se activa cuando cambia la lista o la pagina

    useEffect(() => {
        setPaginaActual(1)
    }, [lista]) // se activa cuando se cambia la lista
    const paginar = (numeroPag) => setPaginaActual(numeroPag);
    return (
        <div>
            <div className="card">
                <ul className="list-group list-group-flush">
                    { currentItems &&
                        currentItems.map((tarea) => {
                            return <ItemTarea tarea={tarea} key={"item-tarea-" + tarea.idt} setTareaSeleccionada={setTareaSeleccionada} />
                        })
                    }
                </ul>
            </div>
            {lista && <Paginacion itemsPorPagina={itemsPorPagina} totalItems={lista.length} paginar={paginar} />}
            <ModalDetalleTarea 
                setTareaSeleccionada={setTareaSeleccionada}
                idTarea={tareaSeleccionada} 
            />
        </div>
    )
}

export default TareasLista