import { requestData } from "Connection/transacciones"

const genie = async(dep = {}) => {
    const dat = {
        hol: dep.hol,
        ret: dep.ret,
        zon: dep.zon
    }
    const jsonBody = {
        hdr: {
            ful: 1,
            txi: 65,
            req: "genie",
        },
        dat: dat
        
    }
    return await requestData (jsonBody)
}

export default genie