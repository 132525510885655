import React from 'react'
import { getTablaTipoCuenta } from 'Utils/tablasDecodificacion';

function useTipoCuenta () {
    const [values, setValues] = React.useState([]);
    React.useEffect(()=> {
        const run = async() => {
            if(values.length > 0) {
                return;
            } else {
                const tipoDeCuentas = await getTablaTipoCuenta(); 
                const newValues = tipoDeCuentas.map(item => ({
                    "value": item.cod,
                    "label": item.name,   
                }))
                setValues(newValues)
            }
        }
        run();
    },[])

    return {
        values
    }
} 

export default useTipoCuenta