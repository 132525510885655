import Tabla from 'Components/Tabla/Tabla';
import React from 'react';
import { Link } from 'react-router-dom';
import taskt from "Connection/transacciones/taskt";
import { useSetRecoilState } from "recoil"
import { atom, useRecoilValue, useRecoilState } from "recoil"
import { formatearFechaHora} from "Utils/funcionesFechaHora";
import { formatearHoraFecha} from "Utils/funcionesFechaHora";
import { format } from 'Utils/rut'
import { tareaSeleccionadoState } from './ModalOpcionesTareas';
// import { isModalTareaOpenState, tareaSeleccionadoState, tareaNombreState } from './ModalOpcionesTareas';
import { isModalTareaOpenState, tareaNombreState } from "./_ResumenTareas/TareaDetalleModalRequerimientos";
// import ModalDetalleTarea from "./_ResumenTareas/TareaDetalleModal";

const thClassName= "ps-1"

export const taskFetching = atom({
    key:"isTaskFetching",
    default: false
})



function TablaTareas ({status}){
    const setIsTareaModalOpen = useSetRecoilState(isModalTareaOpenState)
    const setTareaSeleccionado = useSetRecoilState (tareaSeleccionadoState)
    const nombreTareaSeleccionado = useSetRecoilState (tareaNombreState)
    // const [taskFetch2, setTaskFetch2] = useRecoilState(taskFetching) 
    const taskFetch = useRecoilValue(taskFetching)
    const abrirModal = (idTarea) => {
        setIsTareaModalOpen (true)
        setTareaSeleccionado(idTarea)
        // console.log("idt",idTarea)
    }
    // console.log(taskFetch)
    const [tareas, setTareas] = React.useState([])
    const [fetching, setIsFetching] = React.useState(false);
   React.useEffect(async() => {
        setIsFetching(true);
        const request = await taskt({type:status})
        // const request = await ticks({})
        // console.log("req",request)
        setTareas(request.ans.tax || [])
        setIsFetching(false);
    }, [taskFetch]);
    const columnas = React.useMemo(() => [
        {
            Header: 'Respondida',
            accessor: item => formatearHoraFecha(item.end),
            Cell: (props)=>{
                const {value, row} = props
                if (status === 9 ){
                    return(
                        <>No Aplica</>
                    )}
                else{
                    return(
                        <>{value}</>
                    )}
            },
            // accessor: "ttd",
            thClassName
        },{
            Header: 'Calendarizada',
            accessor: item => formatearHoraFecha(item.wen),
            // accessor: "ttd",
            thClassName
        },{
            Header: 'Nombre Tarea', 
            accessor: 'tit',
            // accessor: 'tna',
            Cell: (props) => {
                const {value, row} = props
                 if (status === 9){
                 return (
                    <>{value}</>
                 )}
                 else {
                 return (
                    <Link  to="#" onClick={(e)=>{
                        e.preventDefault()
                        nombreTareaSeleccionado(row.original.tit)
                        abrirModal(row.original.tid)
                    }}> 
                    {value}
                    </Link>
                 )}
             },
            thClassName
        },{
            Header: 'Rut Reponedor',
            accessor: 'rut',
            Cell: props =>{
                const {value} = props 
                return(format(value))
            },
            thClassName
        },{
            Header: 'Nombre Reponedor',
            accessor: 'nam',
            // accessor: 'eti',
            thClassName
        },{
            Header: 'Cliente',
            accessor: 'cli',
            // accessor: 'eti',
            thClassName
        },{
            Header: 'Local',
            accessor: 'pla',
            // accessor: 'eti',
            thClassName
        }


    ] ,[]) 
    
    return(
        <>
        <div className='card'>
        <div className="card-body">
            <Tabla
                columns={columnas}
                data={tareas}
                globalFilter={true}
                tableClass={"table table-centered table-hover  mb-0"}
                theadClassName={"table-light"}
                cellClassName={"p-1"}
                loading={fetching}
            /> 
        </div>

    </div>
    </>
)
}


export default TablaTareas
