import { requestData } from "Connection/transacciones"

    const ticde = async(ticket_id) => {
        const jsonBody = {
                "hdr": {
                    "txi": 43,
                    "req": "ticde",
                    "ott": true
                },
                "dat": {
                    "tid": parseInt(ticket_id,10)
                }
            }
        
        return await requestData(jsonBody)
        }//.then((ans) => { console.log(ans) }).catch((ans) => { console.log(ans) });

export default ticde;


