import React, { useEffect, createRef } from 'react'
import toast from 'react-hot-toast';
import { Button } from 'react-bootstrap'
import { actualizarInfoPagina } from 'Utils/paginaInfo';
// import FormularioCrearTicket from './formulario/FormularioCrearTicket';
import { Link, useNavigate } from 'react-router-dom'
import Excel from 'exceljs';
import calen from "Connection/transacciones/calen";
// import "./aa.css";
import Dropzone from 'react-dropzone';
import { set } from 'lodash';
import locmc from 'Connection/transacciones/locmc';
import {saveAs} from "file-saver";
import { numToCol } from 'Utils/excelutils';
import { getCountry } from "Authentication/Session";
import { getClient } from "Authentication/Session";

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={"/ListaTickets"}>
                        Tickets
                        </Link>
                    </li>
                    <li className="breadcrumb-item active">Calendarizacion Masiva</li>
                </ol>
            </div>
            <h4 className="page-title">Calendarizacion Masiva</h4>
        </div>
    )
}



function ImportLayout(props) {
    useEffect(() => { actualizarInfoPagina(props) }, [props]);
    const client = React.useRef(getClient());
    // obtener fecha
    const date = new Date();

    const navigate = useNavigate();
    


    const [selectedFile, setSelectedFile] = React.useState(null);
    const [check, setCheck] = React.useState(true);
    const [idTicket, setIdTicket] = React.useState(null);



    React.useEffect(() => {
        if (localStorage.getItem("tic_id") !== null) {
            setIdTicket(localStorage.getItem("tic_id"));
            localStorage.removeItem("tic_id");
        }

    }, []);

    const itsDisabled = React.useMemo(() => {
        if (selectedFile === null) {
            setCheck(true);
        }
        else {
            let name = selectedFile.name;
            if(name.includes('.xlsx') || name.includes('.xls')){
                setCheck(false);
            }
            else{
                setCheck(true);
            }
        }
    }, [selectedFile])

    const dropzoneRef = createRef();
    const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point 
    if (dropzoneRef.current) {
        dropzoneRef.current.open()
    }
    };

    const onDrop = (files) => {
        if (files.length > 0) {
            setSelectedFile(files[0]);
        }
      };
    

    const handler = async(e) => {
            
        const dataRequest = {};
        const data = [];
        const wb = new Excel.Workbook();
        const reader = new FileReader()
      
        reader.readAsArrayBuffer(selectedFile)
        reader.onload = () => {
          const buffer = reader.result;
          wb.xlsx.load(buffer).then(workbook => {
            let maxDays = 29
            // console.log(workbook, 'workbook instance')
            workbook.eachSheet((sheet, id) => {
                // if (id === 3) {
              sheet.eachRow((row, rowIndex) => {
                if (rowIndex === 2){
                    // console.log(row.values, rowIndex)
                    // dataRequest['cli'] = parseInt(getClientID())
                    dataRequest['ano'] = row.values[1]
                    dataRequest['mes'] = row.values[2]
                    dataRequest['hor'] = parseInt(row.values[3])
                    dataRequest['tic'] = parseInt(idTicket)
                    // dataRequest['cat'] = row.values[6]
                }
                if(rowIndex === 4){
                    maxDays = row.values.length
                }
                if(rowIndex >= 5 ){
                    let obj = {}
                    let day= []
                    // console.log(maxDays)
                    for(let i = 3; i < maxDays; i++){
                        if (i===3){
                            obj['lox'] = row.values[i]
                        }
                        if(row.values[i] === 'X' || row.values[i] === 'x'){
                            day.push("1")
                        }
                        else if (row.values[i] === undefined){
                            day.push("0")
                        }
                    }
                    obj['dia'] = day.join('')
                    data.push(obj)
                }
              })
                dataRequest['cma'] = data
                // console.log(dataRequest)
                const req = {}
                req['mcf'] = dataRequest
                // console.log(req)
                handler2(req)
            // }
            })
          })
        }}

        const handler2 = async(e) => {
            // console.log(e)
            const request = await calen(e)
            if (request.ans.stx === 'ok'){
                toast.success(request.ans.msg)
                navigate("/Calendarizaciones")
            }
            else if (request.ans.stx === "nk"){
                toast.error(((t) => (<>
                    {request.ans.msg}
                </>) ))
            }
            // console.log(e)
        }
        
        // const test = async(e) => {
        //     // const request = await locmc()
        //     console.log( getCountry)
        // }

        const exportXls = async() => {
            const request = await locmc()
            // const data = request.ans.loc
            const data = JSON.parse(JSON.stringify(request.ans.loc))
            // const headersRow1 = ["Año", "Mes", "Hora"]
            const headersRow4 = [" ", "Código", "loc_id", "Nombre Local", "Direccion",
                                "Comuna", "Region", "Retailer", "KAM", "KAS", "01", "02",
                                "03", "04", "05", "06", "07", "08", "09", "10", "11", "12",
                                "13", "14", "15", "16", "17", "18", "19", "20", "21", "22",
                                "23", "24", "25", "26", "27", "28", "29", "30", "31"]
            // console.log("data", data)
            
            const wb = new Excel.Workbook();
            const ws = wb.addWorksheet('Hoja 1');
            const header = ["this", "cod", "lid", "nam", "adr", "com", "reg", "ret", "kam", "kas",
                            "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12",
                            "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24",
                            "25", "26", "27", "28", "29", "30", "31"]
            const headerCol = []

            for (let i = 0; i < header.length; i++) {
                if (i === 0){
                    headerCol.push({key: header[i], width: 6})
                }
                else if (i === 1){
                    headerCol.push({key: header[i], width: 8})
                }
                else if (i === 3 || i === 4){
                    headerCol.push({key: header[i], width: 18})
                }
                else if (i === 5 || i === 6 || i === 7 || i === 8 || i === 9){
                    headerCol.push({key: header[i], width: 11})
                }
                else{
                    headerCol.push({key: header[i], width: 5})
                }
            }
            ws.getRow(4).values = header
            ws.columns = headerCol
            data.map(item => {
                ws.addRow(item)
            })

            let row = ws.getRow(4)
            for (let i = 1; i < row.cellCount; i++) {
                row.getCell(i).value = headersRow4[i-1]
            }

        
            ws.eachRow(function (row, rowNumber) {
                row.eachCell((cell, colNumber) => {
                    if (rowNumber === 4 && !(colNumber >= 4 && colNumber <= 10)) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'ff0070c0' }
                        }
                        cell.font = {
                            color: { argb: 'FFFFFF' },
                        }
                    }
                    else if(rowNumber === 4 && (colNumber >= 4 && colNumber <= 10)){
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'ffffcc66' }
                        }
                        cell.font = {
                            color: { argb: 'ff000000' },
                        }
                    }
                    
                    if (colNumber !== 1) {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                }
                    cell.alignment = { wrapText: false, horizontal: 'left'};
                })
                row.commit();
            });

            const cellA1 = ws.getCell('A1');
            cellA1.value = 'Año';
            cellA1.font = {
                color: { argb: 'FFFFFF' },
            }
            cellA1.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff0070c0' }
            }
            cellA1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cellA1.alignment = { wrapText: false, horizontal: 'left'};
            const cellB1 = ws.getCell('B1');
            cellB1.value = 'Mes';
            cellB1.font = {
                color: { argb: 'FFFFFF' },
            }
            cellB1.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff0070c0' }
            }
            cellB1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cellB1.alignment = { wrapText: false, horizontal: 'left'};
            const cellC1 = ws.getCell('C1');
            cellC1.value = 'Hora';
            cellC1.font = {
                color: { argb: 'FFFFFF' },
            }
            cellC1.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff0070c0' }
            }
            cellC1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cellC1.alignment = { wrapText: false, horizontal: 'left'};
            const valueCellA1 = ws.getCell('A2');
            valueCellA1.value = Number(date.getFullYear())
            valueCellA1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            valueCellA1.alignment = { wrapText: false, horizontal: 'left'};
            const valueCellB1 = ws.getCell('B2');
            valueCellB1.value = Number(date.getMonth()+1)
            valueCellB1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
            valueCellB1.alignment = { wrapText: false, horizontal: 'left'};
            const valueCellC1 = ws.getCell('C2');
            valueCellC1.value = 800
            valueCellC1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            valueCellC1.alignment = { wrapText: false, horizontal: 'left'};

            ws.mergeCells('D3:J3');
            const cellD4 = ws.getCell('D3');
            cellD4.value = "borrar antes de cargar";
            cellD4.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cellD4.alignment = { wrapText: false, horizontal: 'center'};

            const emptyCell = ws.getCell('A4');
            emptyCell.fill = {
                type: 'pattern',
                // pattern: 'solid',
                fgColor: { argb: 'ffffffff' }
            }

            // for(let i = 5; i <= 5 + data.length; i++){
            //     for(let j = 11; j <= 31; j++){
            //         ws.getCell(numToCol(j)+i.toString()).border = {
            //             top: { style: 'thin' },
            //             left: { style: 'thin' },
            //             bottom: { style: 'thin' },
            //             right: { style: 'thin' }
            //         }
            //     }
            // }
            for(let i = 11; i <= 41; i++){
                for(let j = 5; j <= 4 + data.length; j++){
                    ws.getCell(numToCol(i)+j.toString()).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    }
                }
            }
            // for (let i = 2; i <= 11+ maxReq; i++) {
            //     for (let j = 6; j <= 5 + exl.length; j++) {
            //       worksheet.getCell(numToCol(i)+j.toString()).border = {
            //         top: { style: 'thin' },
            //         left: { style: 'thin' },
            //         bottom: { style: 'thin' },
            //         right: { style: 'thin' }
            //       };
            //     }
            //    }

            const buffer = await wb.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            const blob = new Blob([buffer], {type: fileType});
            saveAs(blob,   client.current + " importacion" + fileExtension);


        }
        

	return (

		<div className='container'>
            <TituloPagina/>
            <div className="card">
                <div className="card-body" style={{textAlign:"center"}}>
                    <div className="col-md-6" style={{textAlign:"center", margin:"0 auto"}}>
                        <div>
                            <Button variant="outline-secondary" style={{textAlign:"lext"}} onClick={exportXls}>
                                Descargar Planilla
                            </Button>
                        </div>
                        {/* <div>
                            <Button variant="outline-secondary" style={{textAlign:"lext"}} onClick={test}>
                                testo
                            </Button>
                        </div> */}
                        <br/>
                        
                            <Dropzone ref={dropzoneRef} onDrop={onDrop} noClick noKeyboard>
                                {({getRootProps, getInputProps, acceptedFiles}) => {
                                    return (
                                    <div className="container">
                                        <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <p>Arrastre y suelte el archivo aquí</p>
                                        <p>o</p>
                                        <Button variant="outline-secondary" onClick={openDialog}>
                                            Explorar archivos
                                        </Button>
                                        </div>
                                        <aside>
                                        <h4>Archivo seleccionado:</h4>
                                        <ul>
                                            {acceptedFiles.map(file => (
                                            <li key={file.path}>
                                            {file.path}
                                                {/* {file.path} - {file.size} bytes */}
                                            </li>
                                            ))}
                                        </ul>
                                        </aside>
                                    </div>
                                    );
                                }}
                            </Dropzone>
                            <Button onClick={()=>handler()} disabled={check}>Enviar</Button>
                            {/* <Button onClick={()=>console.log(selectedFile.name.slice(selectedFile.name.length - 4))}>Enviar2</Button> */}
                            {/* <Button onClick={()=>console.log(date.getMonth()+1)}>try</Button> */}
                                        
                    </div>
                </div>
            </div>
		</div>
	)
}

export default ImportLayout