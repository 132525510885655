import React from "react"
import { format } from 'Utils/rut'

function DatoItem ({titulo, valor}){
    return(
        <div className="row mb-1">
            <div className="col-5">
                <span className="text-info">
                    
                        {titulo}
                    
                </span>
            </div>
            <div className="col-6">
                <span className="">
                    {valor? valor:"-"}
                </span>
            </div>
        </div>
    )
}

function PerfilUsuario ({datosUsuario}) {
    const seccionStyle = {marginRight:"0.5rem"}
    return(
        <div className="text-start">
            <h4>
                <i className="mdi mdi-account-circle-outline" style={seccionStyle}/>
                Datos del usuario
            </h4>
            <DatoItem titulo={"Nombre(s)"} valor={datosUsuario.name} iconClass={"mdi mdi-account"}/>
            <DatoItem titulo={"Apellidos"} valor={datosUsuario.snam}/>
            {/* <DatoItem titulo={"Rut"} valor={datosUsuario.rut}/> */}
            <DatoItem titulo={"Rut"} valor={format(datosUsuario.rut)}/>
            <DatoItem titulo={"N° de Documento"} valor={datosUsuario.ndoc}/>
            <h4>
                <i className="mdi mdi-email-outline" style={seccionStyle}/>
                Contacto
            </h4>
            <DatoItem titulo={"Correo"} valor={datosUsuario.mail}/>
            <DatoItem titulo={"Teléfono"} valor={datosUsuario.phon}/>
            <h4>
                <i className="mdi mdi-home-outline" style={seccionStyle}/>
                Domicilio
            </h4>
            <DatoItem titulo={"Región"} valor={datosUsuario.regn}/>
            <DatoItem titulo={"Comuna"} valor={datosUsuario.comn}/>
            <DatoItem titulo={"Dirección"} valor={datosUsuario.addr}/>
            <h4>
                <i className="mdi mdi-bank" style={seccionStyle}/>
                Datos bancarios
            </h4>
            <DatoItem titulo={"Banco"} valor={datosUsuario.bann}/>
            <DatoItem titulo={"Tipo de cuenta"} valor={datosUsuario.actn}/>
            <DatoItem titulo={"N de cuenta"} valor={datosUsuario.acnu}/>
        </div>
    )
}

export default PerfilUsuario;