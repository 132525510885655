import React, { useState, useCallback} from "react";
import Tabla from "Components/Tabla/Tabla";
import { Button, Modal, ModalBody, OverlayTrigger, Tooltip } from "react-bootstrap";
import FiltroReportes from "./FiltroReporte";
// import useListaReporte from "Hooks/Listas/useListaReporte";
import useListaLocales from "Hooks/Listas/useListaLocales";
import { Link } from 'react-router-dom';
import matrx from "Connection/transacciones/matrx";
import locus from "Connection/transacciones/locus";
import { format } from 'Utils/rut';
import useListaReporte from "Hooks/Listas/useListaReporte";
import { formatearFecha } from "Utils/funcionesFechaHora";

function TablaReporte ({cliente}) {    
    //Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleShow = useCallback(() => setIsModalOpen(true),[]);
    const handleClose = useCallback(() => setIsModalOpen(false),[]);
    //Asignados
    const [isFetching, setIsFetching] = React.useState(false);
    const [locName, setLocName] = React.useState("");
    const [usAsignated, setUsAsignated] = React.useState([]);
    const [fpr, setFpr] = React.useState("");
    const [fur, setFur] = React.useState("");
    const handlerData = React.useCallback(async(usType,local, fpri, furi) => {
        handleShow()
        if(usType === 0){
            setLocName("Ningun Local")
        }
        else{
            setLocName(local)
            setFpr(formatearFecha(String(fpri)))
            setFur(formatearFecha(String(furi)))
        }
        setIsFetching(true)
        // const request = await matrx({
        //         trx: "locus",
        //         lid:usType
        //     });
        console.log("usType",usType)
        const request = await locus({
                lid:usType
            });
        console.log("request",request)
        setUsAsignated(request.ans.usa || [])
        setIsFetching(false)
        
    },[])

    const locales = useListaReporte({
    });
    const compFiltros = (
        <><FiltroReportes
            totalLocales={locales.values.length}
            fetchLocales={locales.fetchLocales}
        /><div className="row">
            <div className="col">
                {cliente===0 && (
                            <div  className="text mb-2">
                                <Button onClick={()=>handlerData(0)}>
                                    Usuarios sin Local
                                </Button>
                            </div>)
                }
            </div>
            <div className="col" style={{textAlign:"right"}}>
                <OverlayTrigger placement={"left"} overlay={
                    <Tooltip id="tooltip-disabled" >
                        <div style={{fontSize:"12.5px"}}>FPR: Fecha Primera Respuesta</div>
                        <div style={{fontSize:"12.5px"}}>FUR: Fecha Ultima Respuesta</div>
                        <div style={{fontSize:"12.5px"}}>DUR: Dias Ultima Respuesta</div>
                    </Tooltip>}>
                    <i className={"mdi mdi-help-circle-outline"}
                        style={{fontSize:"30px", color:"#44BADC"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" >
                    </i>
                </OverlayTrigger>
            </div>
            </div>
        </>
    )
    //Columnas Locales
    const columnas = [
        // {   Header: "Cliente",
        //     accessor: "cli",
        //     thClassName:"ps-1"
        // },
        {
            Header: 'Código',
            accessor: 'cod',
            thClassName:"ps-1"
            // thClassName:"text-center",
            // filter: true
        }
        // ,{
        //     Header: 'Comuna',
        //     accessor: 'com',
        //     thClassName:"ps-1"

        //     //thClassName:"text-center",
        // }
        // ,{
        //     Header: 'Retailer',
        //     accessor: 'ret',
        //     thClassName:"ps-1"

        //     // thClassName:"text-center",
        // }
        ,{
            Header: 'Local',
            accessor: 'nam',
            thClassName:"ps-1",
            Cell: (props) => {
                const {value, row} = props
                 if (cliente !== 0){
                 return (
                    <>{value}</>
                 )}
                 else {
                 return (
                    <Link  to="#" onClick={(e)=>{
                        e.preventDefault()
                        handlerData(row.original.lid,row.original.nam, row.original.fpr, row.original.fur)
                        // setLocName("Local: "+row.original.nam)
                        // nombreTareaSeleccionado(row.original.tit)
                        // abrirModal(row.original.tid)
                    }}> 
                    {value}
                    </Link>
                 )}
             },
            //thClassName:"text-center"
        },{
            Header: 'KAM',
            accessor: 'kam',
            thClassName:"ps-1"
        },{
            Header: "KAS",
            accessor: "kas",
            thClassName: "ps-1"
        },{
            Header: "FPR",
            // Header: (props)=>{
            //     const {value, row} = props
            //     return(
            //         <div>asd</div>
            //     )
            // },0
            // accessor: item => formatearFecha(String(item.fpr)),
            accessor:"fpr",
            Cell: props =>{
                const {value} = props
                if(value === 0){
                    return("-")
                }
                else
                    return(formatearFecha(String(value)))
            },
            
            thClassName: "ps-1"
        },{
            Header: "FUR",
            accessor:"fur",
            // accessor: item => formatearFecha(String(item.fur)),
            Cell: props =>{
                const {value} = props
                if(value === 0){
                    return("-")
                }
                else
                    return(formatearFecha(String(value)))
            },
            thClassName: "ps-1"
        },{
            Header: "DUR",
            accessor: "dur",
            thClassName: "ps-1"
        },{
            Header: "RUT",
            accessor: "rru",
            Cell: props =>{
                const {value} = props 
                return(format(value))
            },
            thClassName: "ps-1"
        },{
            Header: "Reponedor",
            accessor: "rna",
            thClassName: "ps-1"
        }
    ]

    
    //Columnas Asignados
    const columnas2 = [
        {
            Header: 'Nombre',
            accessor: 'nam',
            thClassName:"ps-1"
        },{
            Header: 'Apellido',
            accessor: 'sna',
            thClassName:"ps-1"
        },{
            Header: 'Rut',
            accessor: 'rut',
            Cell: props =>{
                const {value} = props 
                return(format(value))
            },
            thClassName:"p-1",
        },{
            Header: 'Telefono',
            accessor: 'tel',
            thClassName:"ps-1"
        }
    ]

    const columnas3 = [
        {
            Header: 'Nombre',
            accessor: 'nam',
            thClassName:"ps-1"
        },{
            Header: 'Apellido',
            accessor: 'sna',
            thClassName:"ps-1"
        },{
            Header: 'Rut',
            accessor: 'rut',
            Cell: props =>{
                const {value} = props 
                return(format(value))
            },
            thClassName:"p-1",
        },{
            Header: 'Telefono',
            accessor: 'tel',
            thClassName:"ps-1"
        },{
            Header: 'FPR',
            accessor: 'fpr',
            Cell: props =>{
                const {value} = props
                if(value === 0){
                    return("-")
                }
                else
                    return(formatearFecha(String(value)))
            },
            thClassName:"ps-1"
        },{
            Header: 'FUR',
            accessor: 'fur',
            Cell: props =>{
                const {value} = props
                if(value === 0){
                    return("-")
                }
                else
                    return(formatearFecha(String(value)))
            },
            thClassName:"ps-1"
        }]


    return (
        <>
        <Modal 
            show={isModalOpen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>
                    <h4>Usuarios Asignados a: </h4>   
                </Modal.Title>
            </Modal.Header>
            <ModalBody>
                <div className="row text-center">
                    <h3>{locName}</h3>
                    {(locName !== "Ningun Local")? 
                    <>
                        <div className="row">
                            <br/>
                        </div>
                        <div className="row">
                            <div className="col" style={{fontSize:"16px"}}>
                                <b>Fecha primera reposicion: </b>{fpr}
                            </div>
                            <div className="col" style={{fontSize:"16px"}}>
                                <b>Fecha ultima reposicion: </b>{fur}
                            </div>
                        </div>
                        <div className="row">
                            <br/>
                        </div>
                    </>: <></>}
               </div>
                    <Tabla
                    columns={columnas3}
                    data={usAsignated}
                    globalFilter={true}
                    tableClass={"table table-centered  table-hover mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={isFetching}
                    />
            </ModalBody>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>handleClose()}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
        <div className="card">
            <div className="card-body">
                {/* <Button onClick={()=>console.log(locales)}>aaaa</Button> */}
                <Tabla
                    data={locales.values}
                    columns={columnas}
                    globalFilter={true}
                    titleSection={compFiltros}
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    loading={locales.isFetching}
                    cellClassName={"p-1"}
                />
            </div>
        </div>
            
        </>
    )
}

export default TablaReporte;
