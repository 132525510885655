import React, { useCallback } from "react";
import 'Assets/css/dashboardIcons.css'
import "./style.css"
import { useNavigate } from "react-router-dom";
import { Card, Col } from "react-bootstrap";
// style={{height:"140px"}}
// mdi-book-alert
function OpcionItem ({
    icono, 
    titulo, 
    descripcion, 
    color, 
    link = "/"
}) {
    const navigate = useNavigate();
    const handleClick = useCallback(() => navigate(link), [navigate])

    return(
        // <div className="col-lg-3 col-sm-4 mb-2" >
        //     <div className="card p-0" onClick={handleClick} type="button" >
        //         <div className="row">
        //             <div className="col-md-4 d-flex justify-content-center align-items-center">
        //                 <i className={`mdi ${icono} icon-lg icon-${color} icon-bg-${color} icon-bg-circle`}></i>
        //             </div>
        //             <div className="col-md-8">
        //                 <div className="card-body" style={{paddingRight:'5px', paddingLeft:'5px'}}>
        //                     <h5 className="card-title">{titulo}</h5>
        //                     <small>
        //                         {descripcion}
        //                     </small>
        //                 </div>
        //             </div>
                    
        //         </div>
        //     </div>

        // </div>
        <Col sm={4} lg={3}>
            <Card className="box-shadow w-100">
                <Card.Body type="button" className="px-1 py-0" onClick={handleClick}>
                    <div className="d-flex" style={{height:"100px", marginTop:"24px"}}>
                        <div className="text-center me-4">
                            <i className={`mdi ${icono} icon-lg icon-${color} icon-bg-${color} icon-bg-circle`}></i>
                        </div>
                        <div>
                            <h5 className="card-title">{titulo}</h5>
                            {descripcion}
                    

                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
} 


export default OpcionItem;
