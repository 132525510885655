import React from "react"
import { Link } from "react-router-dom";
function Logo () {
    return(
        <Link to={"/Dashboard"} className="me-auto">
            <img src="/assets/images/zolbit/rf-logo.png" alt="" height="50" className="my-2"/>
            {/* <div className="topnav-logo-lg"> */}
            {/* </div> */}
            {/* <div className="topnav-logo-sm">
                <img src="/assets/images/zolbit/rf-logo.png" alt="" height=""/>
            </div> */}
        </Link>
    )
}
export default Logo
