import React from "react";
import ReactPaginate from "react-paginate";


function Paginacion ({itemsPorPagina, totalItems, paginar}) {
    const numeroDePaginas = Math.ceil(totalItems/itemsPorPagina);
    // const numeroPaginas = []

    // for (let i = 0; i < Math.ceil(totalItems/itemsPorPagina); i++) {
    //     numeroPaginas.push(i+1);
    // }

    return(
        <ReactPaginate
            containerClassName={"pagination"} // contenedor
            previousLabel={"Anterior"} // Anterior
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}

            nextLabel={"Siguiente"} // Siguiente
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}

            breakLabel={"..."} // Break "...""
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}

            pageClassName={"page-item"} // items
            pageLinkClassName={"page-link"}
            activeClassName="active"
            pageCount={numeroDePaginas}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={(data)=> paginar(data.selected + 1)} 
        />
    )
}


    // return(
    //     <nav>
    //         <ul className="pagination">
    //             {
    //                 numeroPaginas.map(numeroPag => (
    //                     <li key={numeroPag} className="page-item">
    //                         <button className="page-link" onClick={()=> paginar(numeroPag)}>
    //                             {numeroPag}
    //                         </button>
    //                     </li>
    //                 ))
    //             }
    //         </ul>
    //     </nav>
    // )

    
export default Paginacion