import { requestData } from "Connection/transacciones"
import { parseInt } from "lodash";

const gepic = async(idTarea, idRequerimiento) => {
    const jsonBody = {
        hdr: {
            txi: 70,
            req: "gepic",
            ott: true,
        },
        dat: {
            tid: parseInt(idTarea,10),
            qid: parseInt(idRequerimiento,10),
        }
    }

    return await requestData(jsonBody)

}

export default gepic;