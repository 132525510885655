// import React from 'react';
import {Modal} from "react-bootstrap";
import toast from 'react-hot-toast';
import { Button } from 'react-bootstrap';
import { Link, useLocation  } from 'react-router-dom';
import { atom, useRecoilValue, useRecoilState } from "recoil"
import { taskr } from "Connection/transacciones";
import tasko from "Connection/transacciones/tasko.js";
import React, { useState, useEffect} from "react";
import DetalleTareaRequerimientos from "./_ResumenTareas/TareaDetalleModalRequerimientos";
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
// export const isModalTareaOpenState = atom({
//     key: "isModalTareaOpen",
//     default: false
// })

export const tareaSeleccionadoState = atom({
    key:"TareaSeleccionadoState",
    default: undefined
})

// export const tareaNombreState = atom({
//     key:"TareaNombreState",
//     default: undefined
// })


function ModalOpcionesTickets(){
    
    // const [detalles, setDetalle] = React.useState("");
    // const [isOpen, setIsOpen] = useRecoilState(isModalTareaOpenState)
    const idTarea = useRecoilValue(tareaSeleccionadoState)
    // const nombreTarea = useRecoilValue(tareaNombreState)
    
    // const onChangeDetalle = (value) => setDetalle(value.target.value);
    // const detalleValido = React.useMemo(()=> {
    //     if(detalles === "") return true
    //     else return false;
    // },[detalles])


    // const handleSubmit = async(response) => {
        // const request = await tasko({
        //     idTarea:idTarea,
        //     resp:response,
        //     detail:detalles
        // })
        
        // if(request.ans.stx === "ok") {
        //     toast.success(request.ans.msg)
        //     window.location.reload(false)
        // } else if(request.ans.stx === "nk") {
        //     toast.error((t) => (<>
        //         {request.ans.msg}
        //     </>) )
        // }
        

        // handleClose()

    // }

    // const handleClose = () => {
    //     setIsOpen(false);
    // }
    const [tadet, setTadet] = useState(null);

    const getTadet = async(idTicket) => {
    setTadet(null) // limpia los datos de tadet
    
    if (idTicket){
    const data = await taskr({idDetalle:idTicket})
    setTadet(data)
    }
    }
    useEffect(() => {
        if(idTarea !== 0 && idTarea !== null){
            getTadet(idTarea)
        }
        
    }, [idTarea])  //////////////////------^
      
    return(
        <>
            {/* <Modal         
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isOpen} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Tarea {nombreTarea} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row text-center">
                        <h3>{tadet && tadet.ans.lid.split(":")[2]}</h3>                                
                    </div>
                    <div className="row text-center">
                        <h3>{tadet && tadet.ans.adr}</h3>                                
                    </div>
                    <div className="row text-center">
                        <h3>{tadet && tadet.ans.reg}</h3>
                    </div> */}
                    {tadet &&
                        <DetalleTareaRequerimientos tadet={tadet} />
                        }
                    {/* <div className="row mb-2">
                        <div className='col-2'>
                            <label htmlFor="descripcion" className="col-form-label">
                                Descripción
                            </label>
                        </div>
                        <Form.Control
                            as='textarea'
                            id='descripcion' 
                            label="Detalle"
                            rows={3}
                            onChange={onChangeDetalle} 
                            type='text'
                            maxLength={512}
                            required>
                        </Form.Control>
                    </div> */}
                {/* </Modal.Body> */}
                {/* <Modal.Footer> */}
                    {/* <Button  variant="secondary" onClick={()=>handleClose()}>Cancelar</Button>
                    <Button  variant="danger" disabled={detalleValido} onClick={()=>handleSubmit(2) }>Rechazar</Button>
                    <Button variant="success" onClick={()=>handleSubmit(1)}>Aceptar</Button> */}
                {/* </Modal.Footer> */}
            {/* </Modal> */}
        </>
    )
}
export default ModalOpcionesTickets