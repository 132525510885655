import React from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

function Alternativas ({sumItem, chartId}) {
    const data = {
        labels: sumItem.alt,
        datasets: [{
          label: 'Resumen',
          data: sumItem.vot,
          backgroundColor: [
            '#FFE74C',
            '#ff5964',
            '#38618c',
            '#35a7ff',
            '#584d3d',
            '#23022e',
            '#9ae19d'
          ],
        //   backgroundColor: ['#ff0000', '#ff4000', '#ff8000', '#ffbf00', '#ffbf00', '#ffff00', '#bfff00', '#80ff00', '#40ff00', '#00ff00'],
          hoverOffset: 4
        }]
    };
    
    const options = {
        plugins: {
            legend: {
                position: "left"
            } 
        }
    }

    return(
        <div>
            <h5>
                Alternativas
            </h5>
            <h3>
                {sumItem.txt}
            </h3>
            <div>

                <Chart type="doughnut" data={data} style={{maxHeight:"250px"}} options={options}/>
            </div>
        </div>
    )
}

export default Alternativas