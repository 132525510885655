import { requestData } from "Connection/transacciones"

const uslox = async({uid, cid, antes, despues, excepciones}) => {
    const jsonBody = {
        hdr: {
            txi: 76,
            req: "uslox",
            ott: true,
        },
        dat: {

            uid: uid,
            cid: cid,
            lie: excepciones,
            lia: antes,
            lid: despues,
        }
    }
    // console.log(jsonBody)
    return await requestData(jsonBody)

}

export default uslox;