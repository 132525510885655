import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from "Utils/paginaInfo";
// import ConfiguracionUsuario from './_EditarDatos/PaginaConfiguracionUsuario';
// import wedat from 'Connection/transacciones/wedat'
import useDatosUsuario from 'Hooks/Datos/useDatosUsuarioPerfil'
import EditarDatos from './_EditarDatos/EditarDatos';
import { Card, Col, ListGroup, Nav, Row } from 'react-bootstrap';
import CambiarPassword from './_CambiarPassword/CambiarPassword';
import DivControl from 'Components/DivControl';

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Datos del usuario</li>
                </ol>
            </div>
            <h4 className="page-title">Datos del usuario</h4>
        </div>
    )
    }

const MENU_COL = 2
const BORDER_STYLE = "1px solid #dbdbdb"
function PaginaConfiguracionUsuario(props) {
    useEffect(() => { actualizarInfoPagina(props)}, [props]);
    const detalleUsuario = useDatosUsuario()
    // React.useEffect(() => {
    //         detalleUsuario.refreshData();
    // }, []);
    const [activeItem, setActiveItem] = React.useState(1)
    return (           
        <div className='container'>    
            <TituloPagina />
            <Card>
                <Row>
                    <Col md={MENU_COL} className="pe-0">
                        <ListGroup variant='flush'>
                            <ListGroup.Item 
                                action
                                active={activeItem === 1}
                                onClick={() => setActiveItem(1)}
                            >
                                <i className='mdi mdi-account me-2' />
                                Datos de usuario
                            </ListGroup.Item>
                            <ListGroup.Item 
                                action
                                active={activeItem === 2}
                                onClick={() => setActiveItem(2)}
                            >
                                <i className='mdi mdi-lock me-2' />
                                Cambiar contraseña
                            </ListGroup.Item>
                            
                        </ListGroup>
                    </Col>
                    <Col md={12-MENU_COL} style={{borderLeft: BORDER_STYLE}}>
                        <Card.Body>
                            <DivControl show={activeItem === 1}>
                                <EditarDatos />
                            </DivControl>
                            <DivControl show={activeItem === 2} >
                                <CambiarPassword /> 
                            </DivControl>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
            {/* {detalleUsuario.datos !== null && <ConfiguracionUsuario datosUsuario={detalleUsuario.datos}  />}              */}
        </div>
    )
}
export default PaginaConfiguracionUsuario