import React from "react"
import { Col, Row, Spinner } from "react-bootstrap";
import toast from 'react-hot-toast';
import { Button, Form } from "react-bootstrap";
import Tabla from "Components/Tabla/Tabla";
import { format } from 'Utils/rut'
import matrx from "Connection/transacciones/matrx";
import usloa from "Connection/transacciones/usloa";
import uslox from "Connection/transacciones/uslox";
import uscla from "Connection/transacciones/uscla";
// import useClientesAsignadosUsuario from "Hooks/Listas/useClientesAsignadosUsuario";
// import MenuAgregarClientes from "./TablaAgregarCliente";
import MenuAgregarLocales from "./TablaAgregarLocal";

function GestionarExcepciones ({userID, userData}) {

    const [isFetching, setIsFetching] = React.useState(false)
    const [clientArray, setClientArray] = React.useState([])
    const [selectedClient, setSelectedClient] = React.useState()
    const [localArray, setLocalArray] = React.useState([])
    const [initialArray, setInitialArray] = React.useState([])
    const [tableData, setTableData] = React.useState([])
    const [addExcepcion, setAddExcepcion] = React.useState([])
    const [deleteExcepcion, setDeleteExcepcion] = React.useState([])

    const [localQuantity, setLocalQuantity] = React.useState(0)

    const handleSelectClient = (e) => {
        setSelectedClient(e)
        // console.log(e)
    }
    const HandlerLista = async() => {
        setIsFetching(true)
        const request = await usloa({   
            uid: userID,
            cid: [Number(selectedClient)]
        })
        // console.log(request)
        if(request.ans.stx === "ok"){
            if(request.ans.loa){
                setLocalArray(request.ans.loa)
                setLocalQuantity(request.ans.knt)
                if(request.ans.loe){
                    let reqLoe = []
                    
                    request.ans.loe.map(local => {
                        request.ans.loa.map(local2 => {
                            if(local.lid === local2.lid){
                                reqLoe.push(local2)
                            }
                        
                    })
                    })
                        
                    if (reqLoe.length === request.ans.loe.length){
                        setAddExcepcion([])
                        setDeleteExcepcion([])
                        setInitialArray(reqLoe)
                        setTableData(reqLoe)
                        setIsFetching(false)
                    }
                }
                else{
                    setAddExcepcion([])
                    setDeleteExcepcion([])
                    setInitialArray([])
                    setTableData([])
                    setIsFetching(false)
                }
            }
            else{
                toast ("No hay locales en comun")
                setIsFetching(false)
            }
        }
    }
    
    React.useEffect (() => {
        const run = async() => {
            // const request = await matrx({   
            //     trx:"uscla",
            //     uid:userID
            // })
            const request = await uscla({
                uid: userID
            })
            // const request = await gecat(cliente);
            if (request.ans.cla){
                const options = request.ans.cla.map(opt => ({label: opt.cln, value: opt.cli})); 
                setClientArray(options)
            }
        }
        run()
    }, [])

    // const isDisabled = React.useMemo(() => {
    //     if (addExcepcion.length === 0 && deleteExcepcion.length === 0){
    //         return true
    //     }
    //     else{
    //         return false
    //     }
    // }, [addExcepcion, deleteExcepcion])



    const handlerRequest = async() => {
        // const asd = initialArray.map(local => local.lid)
        // console.log("addEx",addExcepcion)
        // console.log("deleteEx",deleteExcepcion)
        // console.log("tableData",tableData.map(local => local.lid))
        // console.log("initialArray",initialArray.map(local => local.lid))

        const exceptionsArray = []
        addExcepcion.map(local => exceptionsArray.push("+"+String(local)))
        deleteExcepcion.map(local => exceptionsArray.push("-"+String(local)))

        const request = await uslox({
            uid: userID,
            cid: Number(selectedClient),
            excepciones: exceptionsArray
            // excepciones: ["-1819", "-2570"]
        })

        if(request.ans.stx === "ok"){
            toast.success(request.ans.msg)
            
            HandlerLista()
        }
        else{
            toast.error("Error al guardar excepciones")
        }

}


    const agregarLocal = (value) => {
        const newArray = [...tableData, value]
        // setAddExcepcion([...addExcepcion, value.lid])
        setTableData(newArray)
        if(!initialArray.some(local => local.lid === value.lid)){
            setAddExcepcion([...addExcepcion, value.lid])
        }
        if(deleteExcepcion.some(local => local === value.lid)){
            const newArray2 = deleteExcepcion.filter(local => local !== value.lid)
            setDeleteExcepcion(newArray2)
        }
    }

    const eliminarLocal = (localId) => { // elimina a un local en base a su ID
        if(initialArray.some(local => local.lid === localId)){
            setDeleteExcepcion([...deleteExcepcion, localId])
        }
        if(addExcepcion.some(local => local === localId)){
            const newArray = addExcepcion.filter(local => local !== localId)
            setAddExcepcion(newArray)
        }
        setTableData(locales => locales.filter(local => local.lid !== localId))
    }

    const cancelarCambios = () => {
        setTableData(JSON.parse(JSON.stringify(initialArray)))
        setAddExcepcion([])
        setDeleteExcepcion([])
    }

    // const [check, setCheck] = React.useState(false)
    const isDisabled = React.useMemo(() => {
        const ini = initialArray.map(local => local.lid).sort((a,b)=>a-b)
        const tab = tableData.map(local => local.lid).sort((a,b)=>a-b)
        if (JSON.stringify(ini) === JSON.stringify(tab)){
            return(false)
        }
        else{
            return(true)
        }
    }, [tableData])
            




    const columnas = React.useMemo(() => [
        {
            Header: 'Codigo',
            accessor: 'cod',
            thClassName:"p-1",
        },{
            Header: 'Nombre',
            accessor: 'nam',
            thClassName:"p-1",
        },{
            Header: 'Region',
            accessor: 'reg',
            thClassName:"p-1",
        },{
            Header: 'Comuna',
            accessor: 'com',
            thClassName:"p-1",
        },{
            Header: 'Direccion',
            accessor: 'adr',
            thClassName:"p-1",
        },
        {
            Header: 'Acción',
            accessor: 'lid',
            thClassName:"ps-1",
            tdStyle: {width: "3rem"},
            Cell: props => {
                const { value } = props;
                return(
                    <div className="text-center">
                    <Button variant="danger" size="sm" onClick={() => eliminarLocal(value)}>
                        {/* <Button variant="danger" size="sm" > */}
                            <i className="mdi mdi-trash-can-outline"></i>
                        </Button>
                    </div>
                )
            }
        }
    ],[tableData])


    return (
        <div>
            <div className="mb-2">
                
                <h4>
                    Gestion de excepciones del usuario <span className="text-info">{format(userData.rut)}</span>
                </h4>
            </div>
            <div className="mb-2">
                <Row>
                    <Col>
                <Form.Select
                    value= {selectedClient}
                    onChange={(opt)=>setSelectedClient(opt.target.value)}
                    

                >
                    <option>Seleccione un cliente</option>
                    {clientArray.map((opt, i) => (
                        <option key={i} value={opt.value}>{opt.label}</option>
                    ))}
                </Form.Select>
                </Col>
                <Col>
                <Button variant="primary" size="sm" className="ms-2" onClick={()=> HandlerLista()}>
                    Seleccionar cliente

                </Button>
                </Col>
                </Row>
                <br></br>
                <Tabla
                    data={tableData}
                    columns={columnas}
                    globalFilter={true}
                    defaultItemPerPage={10}
                    titleSection={<>
                        <Row>
                            <Col>
                            <></>
                            {/* <>{console.log(tableData,localArray)}</> */}
                                {(localArray.length > 0)? <MenuAgregarLocales 
                                    localesAsignados={localArray}
                                    disabledOptions={tableData.map(loc => loc.lid)}
                                    agregarLocal={agregarLocal}
                                    eliminarLocal={eliminarLocal}
                                />: 
                                <span className="text-muted me-2" > Agregar Local</span>}
                            </Col>
                            <Col>
                                <div>
                                    <span className="text-muted me-2">Cantidad de locales: {localQuantity}</span>
                                </div>
                            </Col>
                        </Row>
                    </>}
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={isFetching}
                />
            </div>
            <Row>
                <Col>
                    <div className="modal-footer">
                        <Button variant="secondary" onClick={()=>cancelarCambios()}>
                            Cancelar
                        </Button>
                        {/* <Button variant="success"  onClick={()=>handlerRequest()}> */}
                        <Button variant="success"  onClick={()=>handlerRequest()}  disabled={!isDisabled}>
                            Guardar Cambios
                        </Button>

                        {/* <Button onClick={()=>{
                            console.log("addEx",addExcepcion)
                            console.log("deleteEx",deleteExcepcion)
                            // console.log("tableData",tableData)
                            // console.log("initialArray",initialArray)
                            console.log("tableData",tableData.map(local => local.lid))
                            console.log("initialArray",initialArray.map(local => local.lid))
                        }}>
                            Mostrar
                        </Button> */}
                    </div>
                </Col>
            </Row>



        </div>
    )
}
export default GestionarExcepciones