import React from 'react'
import TablaAgrupacion from './GroupTable'
import ModalOpcionesTickets from './ModalTask'

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="/Dashboard">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/ListaTickets">Tickets</a></li>
                    <li className="breadcrumb-item active">Agrupar Tickets</li>
                </ol>
            </div>
            <h4 className="page-title">Agrupar Tickets</h4>
        </div>
    )
}

function GroupDetail() {
    React.useEffect(() => {
        
    },[])
    return (
        <div className='container'>
            <TituloPagina />
            <TablaAgrupacion/>
            <ModalOpcionesTickets/>
        </div>)
}

export default GroupDetail