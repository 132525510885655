import { requestData } from "Connection/transacciones"

const gehol = async() => {
    const jsonBody = {
        hdr: {
            txi: 63,
            req: "gehol",
            ott: true
        },
        dat: {
            ful: 1,
        }
    }
    return await requestData (jsonBody);
}

export default gehol;