import { requestData } from "Connection/transacciones"

const gecom = async(dep = {}) => {
    const jsonBody = {
        hdr: {
            txi: 62,
            req: "gecom",
            ott: true
        },
        dat: {
            ful: 1,
            hol: dep.hol,
            ret: dep.ret,
            zon: dep.zon,
            nie: dep.nie,
            reg: dep.reg,
            ciu: dep.ciu,
        }
    }
    return await requestData (jsonBody)
    // const cacheKey = CACHE_KEY + JSON.stringify(dat)
    
    // const cacheResponse = CacheApp.getCacheApp({
    //     key: cacheKey
    // })
    // if (cacheResponse) { 
    //     // si hay alguna respuesta en el cache
    //     return cacheResponse;
    // } else { 
    //     // Si no esta en cache
    //     const response = 
    //     if (response.ans.stx === 'ok') {
    //         CacheApp.setCacheApp({
    //             key: cacheKey,
    //             value: response
    //         })
    //     }
    
    //     return response
    // }

}

export default gecom