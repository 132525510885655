import { requestData } from "Connection/transacciones"

const locgt = async (dat = {}, option = 0) => {
    const jsonBody = {
        hdr: {
            txi: 44,
            req: "locgt",
            ott: true,
        },
        dat: {
            ful: option,
            ...dat
        }
    }
    // console.log("jsonBody",jsonBody)
    return await requestData(jsonBody)
}

export default locgt;