import React, { useRef } from 'react'
import {isEqual} from "lodash"

function useFormulario(obj) {
    const initialValues = useRef(obj.initialValues)
    const [values, setValues] = React.useState(obj.initialValues)
    // const [errors, setErrors] = React.useState({})
    const [fieldValidation, setFieldValidation] = React.useState({})
    const isDirty = () => JSON.stringify(initialValues) !== JSON.stringify(values)
    const resetValues = () => {
        setValues(initialValues.current);
        setFieldValidation({})
        // setErrors({})

    }
    // states derivados usando memo
    const haveInvalidFields = React.useMemo(() => {   
        const keysValidation = Object.keys(fieldValidation)
        for (let key of keysValidation) {
            if (fieldValidation[key] === false) {
                return true
            }
        }
        return false
    }, [fieldValidation])
    
    const haveValidFields = React.useMemo(() => {   
        const keysValidation = Object.keys(fieldValidation)
        for (let key of keysValidation) {
            if (fieldValidation[key] === true) {
                return true
            }
        }
        return false
    }, [fieldValidation]);


    const isFormDirty = React.useMemo(() => !isEqual(values, initialValues.current),[values])

    const resetFieldValidation = () => setFieldValidation({});
    const setFieldValue = (field, newValue) => {
        setValues(oldValues => ({...oldValues,[field]: newValue}))
    }
    const getDirtyValues = () => {
        let dirtyValues = {}
        if (isDirty() === false) {
            return dirtyValues;
        } else {
            const keyValues = Object.keys(values)
            keyValues.forEach((key) => {
                if(values[key] !== initialValues.current[key]){
                    dirtyValues[key] = values[key] 
                }
            });
            return dirtyValues;
        }
    }
    
    const getInvalidFields = () => {
        const keysErrors = Object.keys(fieldValidation)
        let invalidFields = {}
        keysErrors.forEach(key => {
            if(fieldValidation[key] !== true && fieldValidation[key] !== undefined) {
                invalidFields[key] = fieldValidation[key] 
            }
        })
        return invalidFields;
    }

    const onSubmit = () => {
        const cbValues = { 
            "values": values,
            "dirtyValues": getDirtyValues(),
        }
        if(obj.validateOnSubmit) {
            const submitErrors = obj.validateOnSubmit(cbValues)
            
            const invalidFields = getInvalidFields()
            if(Object.keys(submitErrors).length > 0 || Object.keys(invalidFields).length > 0 ) {
                return;
            }
        }
        
        obj.onSubmit(cbValues)

    };

    const updateInitialValues = () => {
        initialValues.current = values;
    }

    
    const setFieldIsValid = (field) => {
        setFieldValidation(oldValues => ({
            ...oldValues,
            [field]: true
        }))
    }

    const setFieldIsInvalid = (field) => {
        setFieldValidation(oldValues => ({
            ...oldValues,
            [field]: false
        }))
    }

    const setFieldIsUndefined = (field) => {
        setFieldValidation(oldValues => {
            let copyValues = {...oldValues}
            delete copyValues[field]
            return copyValues
        })
    }

    const validateFields = (fields) => {
        const keysErrors = Object.keys(fields)
        for (let key of keysErrors) {
            if (fields[key] === false) {
                return false
            }
        }
        return true
    }
    return {
        values,
        updateInitialValues,
        initialValues: initialValues.current,
        fieldValidation,
        validateFields,
        setFieldIsValid,
        setFieldIsUndefined,
        setFieldIsInvalid,
        isDirty,
        resetValues,
        setFieldValue,
        resetFieldValidation,
        onSubmit,
        isFormDirty,
        haveInvalidFields,
        haveValidFields,
    }
}

export default useFormulario  