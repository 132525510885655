import { requestData } from "Connection/transacciones"

const ticks = async({
    idTicket = 0,
    cantidad = 0,
}) => {
    const jsonBody = {	
        hdr:	{	
            txi: 42,
            req: "ticks",
            ott: true,
        },	
        dat:{	
            knt: parseInt(cantidad), // cantidad de tickets pedidos
            tid: parseInt(idTicket)  // desde ticket inicial (0=max)
        }	
    }
    return await requestData(jsonBody)
}

export default ticks