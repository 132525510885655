// import gecom from 'Connection/transacciones/gecom';
import weall from 'Connection/transacciones/weall';
import React from 'react'

function useKas() {
    const [value, setValue] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)
    
    const mounted = React.useRef(true)
    React.useEffect(() => {
        return () => {
            mounted.current = false
        };
    }, []);

    const refreshKas = async(dependencies = {}) => {
        if(mounted.current === true) setIsFetching(true);
        const requestKas = await weall({nType:"kas"});
        if(requestKas.ans.stx === "ok" && mounted.current === true){

            const newValues = requestKas.ans.wus.map(item => ({
                "value": item.uid,
                "label": item.name + " " + item.snam,   
            }))
            setValue(newValues)
        }
        if(mounted.current === true) setIsFetching(false);
    }
    
    return {
        value,
        refreshKas,
        isFetching
    }
}

export default useKas