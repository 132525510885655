import useLocalesAsignadosCliente from "Hooks/Listas/useLocalesAsignadosCliente"
import React from "react"
// import { useLocation } from "react-router-dom"
import { Spinner, Row, Col, Modal} from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { getCluster } from 'Authentication/Session';

import MenuAgregarLocales from "./TablaAgregarLocales";
import MenuLocalesDeshabilitados from "./TablaLocalesDeshabilitados";
// import ListaLocalesAsingados from "./ListaLocalesAsignados";
// import useDatosUsuario from "Hooks/useDatosUsuario";
import Tabla from "Components/Tabla/Tabla";
import {useLocation} from "react-router-dom";
import { format } from 'Utils/rut'


// const clusterType=[{value:0,label:"X"},{value:1, label:"A"},{value:2, label:"B"},
// {value:3, label:"C"},{value:4, label:"D"},{value:5, label:"E"}]


function LocalesAsignados ({idCliente, datoCliente}) {

    
const clusterType = getCluster().map((item) => ({label: item.cco, value: item.cid}))
    const {
        localesIniciales,
        localesAsignados, 
        isFetching,
        fetch,
        agregarLocal,
        eliminarLocal,
        agregarLocalDeshabilitado,
        localQuantity,
        hasChanged,
        confirmarCambios,
        cancelarCambios,
        isUpdating,
    } = useLocalesAsignadosCliente({
        cid: idCliente
    })
    React.useEffect(() => {
        if(idCliente) {
            fetch();
        }
        // eslint-disable-next-line
    },[])
    
    const [ check, setCheck ] = React.useState(true)
    const checkValidate = () => {
        let CheckLocalcesAntes = []
        localesIniciales.map(
            local => {
                if (local.sta === 1){
                    CheckLocalcesAntes.push(local.clu)
                }
            })

        let checkLocalesDespues = localesAsignados.map(
        local => local.clu
        )
        if (JSON.stringify(CheckLocalcesAntes)=== JSON.stringify(checkLocalesDespues)){
            setCheck(true)
        }
        else{ setCheck(false)}
        // console.log("locales iniciales: ",CheckLocalcesAntes)
        // console.log("locales asignados: ",checkLocalesDespues)

    }

    React.useEffect(() => {
        checkValidate()
    },[localesAsignados])

    const [itIsChange, setItIsChange] = React.useState(false)

    React.useEffect(() => {
        checkValidate()
    },[itIsChange])

    const columnas = React.useMemo(() => [
        {
            Header: 'Código',
            accessor: 'cod',
            thClassName:"p-1",
        },
        {
            Header: 'Nombre',
            accessor: 'nam',
            thClassName:"ps-1",
        },
        {
            Header: 'Región',
            accessor: 'reg',
            thClassName:"ps-1",
        },
        {
            Header: 'Comuna',
            accessor: 'com',
            thClassName:"ps-1",
        },
        {
            Header: 'Dirección',
            accessor: 'adr',
            thClassName:"ps-1",
        },
        {
            Header: 'Retail',
            accessor: 'ret',
            thClassName:"ps-1",
        },
        {
            Header: 'Cluster',
            accessor: 'clu',
            thClassName:"ps-1",
            tdStyle: {width: "10rem"},
            Cell: props => {
                const { value, row } = props;
                const [selectedCluster, setSelectedCluster] = React.useState((value >= 10000000)? clusterType[Number(String(value)[1])].value :(value >= 1000000)? clusterType[Number(String(value)[0])].value : clusterType[0].value)
                const clusterHandler = (e) => {
                    // console.log(row)
                    setSelectedCluster(clusterType[e].value)
                    if (value >= 10000000){
                        row.original.clu = clusterType[e].value*1000000 + value%1000000 + 10000000
                        row.values.clu = clusterType[e].value*1000000 + value%1000000 + 10000000
                        // value = clusterType[e].value*1000000 + value%1000000 + 10000000
                    setItIsChange(!itIsChange)
                        
                    }
                    else{
                        row.original.clu = clusterType[e].value*1000000 + value%1000000
                        row.values.clu = clusterType[e].value*1000000 + value%1000000 + 10000000
                        // value = clusterType[e].value*1000000 + value%1000000
                    setItIsChange(!itIsChange)
                    }
                }
                return(
                    <>
                        {/* {((value >= 1000000)? clusterType[value[0]].label : clusterType[0].label)} */}
                        
                        <Form.Select
                            value= {selectedCluster}
                            onChange={(opt)=>{clusterHandler(opt.target.value)}}
                        >
                            {clusterType.map((cluster, index) => (
                                <option key={index} value={cluster.value}>
                                    {cluster.label}
                                </option>
                            ))}
                        </Form.Select>
                    </>
                )
            }
        },
        {
            Header: 'Acción',
            accessor: 'lid',
            thClassName:"ps-1",
            tdStyle: {width: "3rem"},
            Cell: props => {
                // const { value, } = props;
                // console.log(value)
                const { value, row } = props;
                // console.log(row)
                return(
                    <>
                    <div className="text-center">
                        <Button variant="danger" size="sm" onClick={() => {eliminarLocal(value)
                                                                            setItIsChange(!itIsChange)}}>
                            <i className="mdi mdi-trash-can-outline"></i>
                        </Button>
                    </div>
                    </>
                )
            }
        }
    ],[])




    return (
        <div>
            <div className="mb-2">
                
                <h4>
                    {/* Locales asignados al usuario <span className="text-info">{format(detalleUsuario.name)}</span> */}
                    Locales asignados a <span className="text-info">{(datoCliente.name)}</span>
                </h4>
                <div className="">
                    
                </div>
                
            </div>
            
            <div>
                <Tabla
                    columns={columnas}
                    data={localesAsignados}
                    globalFilter={true}                    
                    itemsPerPageOptions={[5,10,15,20]}
                    defaultItemPerPage={10}
                    titleSection={
                        <>
                            <Row>
                                <Col>
                                    <MenuAgregarLocales 
                                        disabledOptions={localesIniciales.map(loc => loc.lid)}
                                        agregarLocal={agregarLocal}
                                        eliminarLocal={eliminarLocal}
                                    />
                                </Col>
                                <Col>
                                    <div>
                                        <span className="text-muted me-2">Cantidad de locales: {localQuantity}</span>
                                    </div>
                                </Col>
                                <Col>
                                    <MenuLocalesDeshabilitados
                                        locales={localesIniciales}
                                        disabledOptions={localesAsignados.map(loc => loc.lid)}
                                        agregarLocal={agregarLocalDeshabilitado}
                                        eliminarLocal={eliminarLocal}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={isFetching}
                />
                <Row>
                    <Col>
                        <div className="text-end mt-2">
                            <Button 
                                className="me-2"
                                variant="secondary" 
                                // onClick={() => cancelarCambios()} 
                                onClick={() => {cancelarCambios()
                                                setItIsChange(!itIsChange)}} 
                                disabled={(isUpdating )}
                                // disabled={(!hasChanged || isUpdating)}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                style={{width: "9rem"}}
                                variant="success" 
                                onClick={() => confirmarCambios()} 
                                disabled={isUpdating}
                                // disabled={check || isUpdating}
                            >
                                {isUpdating? (
                                    <Spinner animation="border" size="sm"/>
                                ):("Guardar cambios")}
                                
                            </Button>

                            {/* <Button onClick={()=>console.log(localesAsignados.map(loc => loc.clu))}>asignados</Button>
                            <Button onClick={()=>console.log(localesIniciales)}>iniciales</Button> */}

                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    )
}
export default LocalesAsignados