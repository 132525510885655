import React from "react"
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Tabla from "Components/Tabla/Tabla";
import { format } from 'Utils/rut'
import useClientesAsignadosUsuario from "Hooks/Listas/useClientesAsignadosUsuario";
import MenuAgregarClientes from "./TablaAgregarCliente";

function ClientesAsignados ({userID, userData}) {
    const {
        clientesAsignados, 
        isFetching, 
        fetch, 
        agregarcliente,
        eliminarCliente,
        hasChanged,
        confirmarCambios,
        cancelarCambios,
        isUpdating,
    } = useClientesAsignadosUsuario({
        uid: userID
    })
    React.useEffect(() => {
        if(userID) {
            
            fetch();
        }
    },[])
    const columnas = React.useMemo(() => [
        {
            Header: 'Clientes',
            accessor: 'cln',
            thClassName:"p-1",
        },
        {
            Header: 'Acción',
            accessor: 'cli',
            thClassName:"ps-1",
            tdStyle: {width: "3rem"},
            Cell: props => {
                const { value } = props;
                return(
                    <div className="text-center">
                        <Button variant="danger" size="sm" onClick={() => eliminarCliente(value)}>
                            <i className="mdi mdi-trash-can-outline"></i>
                        </Button>
                    </div>
                )
            }
        }
    ],[])
    return (
        <div>
            <div className="mb-2">
                
                <h4>
                    Clientes asignados al usuario <span className="text-info">{format(userData.rut)}</span>
                </h4>
                <div className="">
                    
                </div>
                
            </div>
            
            <div>
                <Tabla
                    columns={columnas}
                    data={clientesAsignados}
                    globalFilter={true}                    
                    itemsPerPageOptions={[5,10,15,20]}
                    defaultItemPerPage={10}
                    titleSection={
                        <MenuAgregarClientes 
                            disabledOptions={clientesAsignados.map(wus => wus.cli)}
                            agregarCliente={agregarcliente}
                            eliminarLocal={eliminarCliente}
                        />
                    }
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={isFetching}
                />
                                
                <div className="text-end mt-2">
                    <Button 
                        className="me-2"
                        variant="secondary" 
                        onClick={() => cancelarCambios()}
                        disabled={!hasChanged || isUpdating}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        style={{width: "9rem"}}
                        variant="success" 
                        onClick={() => confirmarCambios()}
                        disabled={!hasChanged || isUpdating}
                    >
                            

                        {isUpdating? (
                            <Spinner animation="border" size="sm"/>
                        ):("Guardar cambios")}
                        
                    </Button>
                </div>

            </div>
        </div>
    )
}
export default ClientesAsignados