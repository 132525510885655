import { requestData } from "Connection/transacciones"

const calgt = async (id_ticket) => {
    if (id_ticket){
        const jsonBody = {
                "hdr":	{	
                    "txi": 46,
                    "req": "calgt",
                    "ott": true
                },	
                "dat":	{	
                    "knt":100,
                    "tid":Number(id_ticket)
            }
            
        }
        return await requestData(jsonBody)
        }
    else{
            const jsonBody = {
                "hdr":	{	"txi": 46,
                    "req": "calgt",
                    "ott": true
                },	
                "dat":	{	
                    "knt": 0
            }
            
        }
        return await requestData(jsonBody)
        }	
}

export default calgt;