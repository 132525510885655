import React from "react";

function PaginaError () {

    return (
        <>
            <div className="row justify-content-center mt-4">
                <div className="col-lg-2">
                    <div className="text-center">
                        <img src="/assets/images/file-searching.svg" height="180" alt="File not found"/>

                        <h1 className="text-error mt-4">404</h1>
                        <h4 className="text-uppercase text-danger mt-3">No se encontró la página</h4>
                        {/* <p className="text-muted mt-3">It's looking like you may have taken a wrong turn. Don't worry... it
                            happens to the best of us. Here's a
                            little tip that might help you get back on track.</p> */}

                        <a className="btn btn-info mt-3" href="/"><i className="mdi mdi-reply"></i> Volver al inicio</a>
                    </div> 
                </div> 
            </div>
        </>    
    )
}

export default PaginaError;