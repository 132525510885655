import React, { useEffect, createRef } from 'react'
import toast from 'react-hot-toast';
import { Accordion, Button, Modal, ModalBody  } from 'react-bootstrap'
import { actualizarInfoPagina } from 'Utils/paginaInfo';
// import FormularioCrearTicket from './formulario/FormularioCrearTicket';
import { Link, useNavigate } from 'react-router-dom'
import Excel from 'exceljs';
import calen from "Connection/transacciones/calen";
import prmas from 'Connection/transacciones/prmas';
// import "./aa.css";
import Dropzone from 'react-dropzone';
import { set } from 'lodash';
import locmc from 'Connection/transacciones/locmc';
import { saveAs } from "file-saver";
import { numToCol } from 'Utils/excelutils';
import { getCountry } from "Authentication/Session";
import { getClient } from "Authentication/Session";
import { getClientID } from 'Authentication/Session';
import prloc from 'Connection/transacciones/prloc';
import Tabla from 'Components/Tabla/Tabla';
import { fechaHoy2, formatearFechaHora, mesActual, mesProximo, mesAnterior } from "Utils/funcionesFechaHora";
import Select from 'react-select';
import prcal from 'Connection/transacciones/prcal';
import prloa from 'Connection/transacciones/prloa';
import prdel from 'Connection/transacciones/prdel';



const thClassName = "ps-1"
function obtEstado(valor) {
    const estado = valor.split(":")
    return estado[1]
}

function Calendas() {

    const navigate = useNavigate()

    const [activeKey, setActiveKey] = React.useState("0");

    const fechaMesAnterior = mesAnterior()
    const fechaMesActual = mesActual()
    const fechaMesProximo = mesProximo()

    // console.log("anterior: ",fechaMesAnterior,"actual: ",fechaMesActual)

    const months = ["Enero ", "Febrero ", "Marzo ", "Abril ",
        "Mayo ", "Junio ", "Julio ", "Agosto ",
        "Septiembre ", "Octubre ", "Noviembre ", "Diciembre "]

    const options = [{label: months[Number(fechaMesAnterior.slice(-2)) - 1] + fechaMesAnterior.slice(0, 4), value: Number(fechaMesAnterior.replace("-", ""))},
    { label: months[Number(fechaMesActual.slice(-2)) - 1] + fechaMesActual.slice(0, 4), value: Number(fechaMesActual.replace("-", "")) },
    { label: months[Number(fechaMesProximo.slice(-2)) - 1] + fechaMesProximo.slice(0, 4), value: Number(fechaMesProximo.replace("-", "")) }]

    const [dataCal, setDataCal] = React.useState([])
    const [isLastMonth, setIsLastMonth] = React.useState(false)
    const [selectedOption, setSelectedOption] = React.useState(null)
    const [isFetching, setIsFetching] = React.useState(false)

    const handlerCalendas = async (e) => {
        setIsFetching(true)
        const request = await prcal(selectedOption.value)
        if (request.ans.stx === 'ok') {
            setDataCal(request.ans.loc || [])
            if(selectedOption.value === options[0].value){
                setIsLastMonth(true)
            }
            else{
                setIsLastMonth(false)
            }
        }
        else if (request.ans.stx === "nk") {
            toast.error(((t) => (<>
                {request.ans.msg}
            </>)))
        }
        setIsFetching(false)
    }




    const columnas = [
        {
            Header: 'Nombre Ticket',
            accessor: 'tin',
            thClassName
        }, {
            Header: 'Calendarizado',
            accessor: item => formatearFechaHora(String(item.fec)),
            thClassName
        }, {
            Header: 'Inicio',
            accessor: item => formatearFechaHora(String(item.ini)),
            thClassName
        }, {
            Header: 'Termino',
            accessor: item => formatearFechaHora(String(item.fin)),
            thClassName
        },
        // {
        //     Header: 'Status',
        //     accessor: item => obtEstado(item.cst),
        //     thClassName
        // }, 
        {
            Header: 'Calendarizador',
            accessor: 'uca',
            thClassName
        },
        {
            Header: 'Locales Pres.',
            accessor: 'klo',
            Cell: (props) => {
                const { value, row } = props
                return (
                    <>{row.original.klp + "/" + value}</>
                )
            },
            thClassName
        },
        {
            Header: 'N° Pres.',
            accessor: 'bid',
            Cell: (props) => {
                const { value, row } = props
                if (value === 0) {//devolver espacio en blanco
                    return (
                        <></>
                    )
                }
                else {
                    return (
                        <>{value}</>
                    )
                }
            },
            thClassName
        }
    ]

    const [calendaSelected, setCalendaSelected] = React.useState([])
    const [unicCalenda, setUnicCalenda] = React.useState([])
    const [isEmpty, setIsEmpty] = React.useState(true)
    const [notUnic, setNotUnic] = React.useState(false)
    const [onlyZeros, setOnlyZeros] = React.useState(false)

    const onSelected = (selected) => {
        if (selected.length !== calendaSelected.length) {
            setIsEmpty(false)
            setCalendaSelected(selected.map((item) => item.cid))
            setUnicCalenda(selected.map((item) => item.bid))
            
            // unicCalenda.map((item) => {
            //     console.log(unicCalenda.filter((item2) => item2 === item))
            // })

            // selected.map((item) => {
            //     if (!unicCalenda.includes(item.bid)) {
            //         setUnicCalenda([...unicCalenda, item.bid])
            //     }4
            // })
        }
        if (unicCalenda.length > 0) {
            let pivot = unicCalenda[0]
            // console.log(pivot)
            for (let i = 0; i < unicCalenda.length; i++) {
                if (unicCalenda.length === 1) {
                    // console.log("only 1 element")
                    setNotUnic(false)
                    break
                }
                if (unicCalenda[i] !== pivot) {
                    setNotUnic(true)
                    // console.log("is not unic")
                    break
                }
                if (i+1 === unicCalenda.length) {
                    // console.log("length ", unicCalenda.length)
                    setNotUnic(false)
                }
            }
        }
        // if (unicCalenda.length > 0) {
        //     let pivot = unicCalenda[0]
        //     // console.log(pivot)
        //     for (let i = 0; i < unicCalenda.length; i++) {
        //         if (unicCalenda.length === 1) {
        //             // console.log("only 1 element")
        //             setNotUnic(false)
        //             break
        //         }
        //         if (pivot === 0 && unicCalenda[i] !== 0) {
        //             pivot = unicCalenda[i]
        //             continue
        //         }
        //         if (unicCalenda[i] !== pivot && pivot !==0 && i !== 0 && unicCalenda[i] !== 0) {
        //             setNotUnic(true)
        //             // console.log("is not unic")
        //             break
        //         }
        //         if (i+1 === unicCalenda.length) {
        //             // console.log("length ", unicCalenda.length)
        //             setNotUnic(false)
        //         }
        //     }
        // }

        if (unicCalenda.length > 0) {
            for (let i = 0; i < unicCalenda.length; i++) {
                if (unicCalenda[i] !== 0) {
                    setOnlyZeros(false)
                    break
                }
                if (i+1 === unicCalenda.length) {
                    setOnlyZeros(true)
                }
            }
        }

        if (selected.length === 0) {
            setIsEmpty(true)
            setNotUnic(true)
        }
        else {

        }
    }

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleShow = React.useCallback(() => setIsModalOpen(true),[]);
    const handleClose = React.useCallback(() => setIsModalOpen(false),[]);

    const handlerDel = async (e) => {
        const toDel = []
        for (let i = 0; i < unicCalenda.length; i++) {
            // console.log(unicCalenda[i])
            if (unicCalenda[i] !== 0 && !(toDel.includes(unicCalenda[i]))) {
                // console.log(unicCalenda[i])
                toDel.push(unicCalenda[i])
            }
        }
        // console.log("handlerDel: ", toDel)
        setIsFetching(true)
        // console.log(toDel)
        const request = await prdel(toDel)
        if (request.ans.stx === 'ok') {
            // setDataCal(request.ans.loc || [])
            toast.success(((t) => (<>
                Presupuestaciones eliminadas!
            </>)))
        }
        else if (request.ans.stx === "nk") {
            toast.error(((t) => (<>
                {request.ans.msg}
            </>)))
        }
        handleClose()
        handlerCalendas()
        setIsFetching(false)
    }

    const nextItem = (e) => {
        // setActiveKey("1")
        // console.log(calendaSelected)
        if (e === 1) {
            navigate("./CargarPresupuesto", { state: { unicCalenda: unicCalenda, calendaSelected: calendaSelected, fechaSelected: selectedOption } })
        }
        else if (e === 2) {
            navigate("./VerPresupuesto", { state: { unicCalenda: unicCalenda, fechaSelected: selectedOption } })
        }
    }


    const client = React.useRef(getClient());

    

    return (
        <>
            <div>
                <div className="row">
                    <Tabla
                        data={dataCal}
                        columns={columnas}
                        globalFilter={true}
                        titleSection={
                            <div className="row">
                                <div className="row d-flex align-items-end">
                                    <div className='col-3 mb-3' style={{ verticalAlign: "middle" }}>
                                        Seleccionar mes:
                                    </div>
                                    <div className='col-3 mb-2'>
                                        <Select
                                            placeholder="Seleccionar mes"
                                            options={options}
                                            value={selectedOption}
                                            onChange={setSelectedOption}
                                        />
                                    </div>
                                    <div className='col-3 mb-2'>
                                        <Button onClick={() => { handlerCalendas() }} variant="primary" >
                                            Aplicar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        loading={isFetching}
                        cellClassName={"p-1"}
                        select
                        onSelected={(selected) => onSelected(selected)}
                    />
                </div>
                <div className="row">
                    <div className="modal-footer">
                        <Button onClick={() => nextItem(1)} disabled={(isEmpty||notUnic||isLastMonth)} variant="primary" >
                            Asignar Presupuesto
                        </Button>
                        <Button onClick={() => nextItem(2)} disabled={(isEmpty||notUnic||onlyZeros)} variant="primary" >
                            Ver Presupuesto
                        </Button>
                        <Button onClick={() => handleShow()} disabled={(isEmpty||onlyZeros||notUnic||isLastMonth)} variant="danger" >
                        {/* <Button onClick={() => handleShow()}  variant="danger" > */}
                        {/* handlerDel() */}
                            Borrar Presupuesto
                        </Button>
                        <Modal show={isModalOpen} centered onHide={handleClose}>
                            <Modal.Header style={{display:"flex", justifyContent: "center"}}>
                                {/* <h4>Advertencia </h4> */}
                                        <i
                                            className={"mdi mdi-alert-octagon"}
                                            style={{fontSize:"100px", color:"#fa6767", display:"flex", justifyContent: "center"}}
                                            rel="tooltip"
                                            data-toggle="tooltip" 
                                            data-placement="top" >
                                        </i>
                            </Modal.Header>
                            <ModalBody style={{fontSize:"18px",display:"flex", justifyContent: "center"}}>
                                Se borraran&nbsp;
                                <span style={{color:"#fa6767"}}>{(dataCal.filter((item)=> item.bid===unicCalenda[0])).length}</span> 
                                &nbsp;Calendarizaciones  con el identificador&nbsp;
                                <span style={{color:"#7CB2FD"}}>{unicCalenda[0]}</span>
                            </ModalBody>
                            <Modal.Footer style={{display:"flex", justifyContent: "center"}}>
                                <Button variant="secondary" onClick={()=>handleClose()}>Cancelar</Button>
                                <Button variant="danger" onClick={()=>handlerDel()}>Borrar</Button>
                            </Modal.Footer>
                        </Modal>
                    {/* <Button onClick={() => console.log(unicCalenda)} variant="secondary">
                        Ver
                    </Button> */}
                    </div>
                </div>
            </div>
        </>

    )
}
export default Calendas;