// import React, { useEffect } from 'react'
import DivControl from 'Components/DivControl';
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import TablaTareas from "./TablaTareas";
import ModalOpcionesTareas from "./ModalOpcionesTareas"
import React, { useState, useEffect} from "react";
import Nav  from 'react-bootstrap/Nav';


function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Gestion Tareas</li>
                </ol>
            </div>
            <h4 className="page-title">Gestion de Tareas Enviadas</h4>
        </div>
    )
    }




function PaginaGestionTickets(props) {
        useEffect(() => { actualizarInfoPagina(props) }, []);  // eslint-disable-line
        const [activeItem, setActiveItem] = React.useState("1")
        function Toogle(eventKey){
            setActiveItem(eventKey)
        }
        return (
            <div className='container'>
                <TituloPagina />
                <ModalOpcionesTareas/>
                <div className='container'>
                    <Nav justify variant="tabs" defaultActiveKey="1" onSelect={Toogle}>
                        <Nav.Item>
                            <Nav.Link eventKey="1">
                                Enviadas
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="0">
                                Objetadas
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2">
                                Rechazadas
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3">
                                Eliminadas
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <DivControl show={activeItem==="1"}>
                        <TablaTareas/>
                    </DivControl>
                    <DivControl show={activeItem==="0"}>
                        <TablaTareas status={(5)}/>
                    </DivControl>
                    <DivControl show={activeItem==="2"}>
                        <TablaTareas status={(8)}/>
                    </DivControl>
                    <DivControl show={activeItem==="3"}>
                        <TablaTareas status={(9)}/>
                    </DivControl>
                </div>
            </div>
        )
}
export default PaginaGestionTickets