import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import CalendarizacionRevision from './CalendarizacionRevision';
// import TablaCalendarizaciones from "./TablaCalendarizaciones";


function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={"/Calendarizaciones"} >Calendarizaciones</Link>
                    </li>
                    <li className="breadcrumb-item active">Detalle Calendarización </li>
                </ol>
            </div>
            <h4 className="page-title">Detalle de Calendarización</h4>
        </div>
    )
    }

function PaginaDetalleCalendarizacion(props) {
        const [calId, setCalId] = React.useState("")

        useEffect(() => {
            actualizarInfoPagina(props)
            
            if (localStorage.getItem("detCalen") !== ""){
                setCalId(localStorage.getItem("detCalen") )
            }
        }, []);  // eslint-disable-line
        return (
            <div className='container'>
                <TituloPagina/>
                <div className='card'>
                    <div className="card-body">
                        {(calId === "")? <></>:
                        <><CalendarizacionRevision idCal={calId}/></>}
                        {/* <CalendarizacionRevision></CalendarizacionRevision> */}

                    </div>
                </div>
                {/* <TablaCalendarizaciones /> */}
                
            </div>
        )
}
export default PaginaDetalleCalendarizacion