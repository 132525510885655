import useTipoCuenta from 'Hooks/Selects/useTipoDeCuenta';
import React, { Suspense } from 'react'
import { Form } from 'react-bootstrap';
import FormGroupLabel from './FormGroupLabel';

function SelectTipoCuenta(props) {
    const {onChange, value, name, size, validation, disabled} = props;
    const tipoDeCuentas = useTipoCuenta();
    return(
        <FormGroupLabel {...props}>
            <Form.Select
                disabled={disabled}
                onChange={(e) => onChange(e.target.value, name)}
                size={size}
                value={value}
                isInvalid={validation === false}
                isValid={validation === true}
            >
                <option value={0}>Seleccionar tipo de cuenta</option>
                {tipoDeCuentas.values.map((opcion, idx) => <option key={idx} value={opcion.value}>{opcion.label}</option>)}
            </Form.Select>
        </FormGroupLabel>
    )
}

export default React.memo(SelectTipoCuenta)