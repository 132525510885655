import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
// import { downloadExcel } from "react-export-table-to-excel";
import { formatearHoraFecha, formatearHoraFechaAlt} from "Utils/funcionesFechaHora";
import { taskr } from "Connection/transacciones";
import { fechaHoy2, fechaHoy } from "Utils/funcionesFechaHora";
import Excel from 'exceljs';
import {saveAs} from "file-saver";
import logo from './logo2.png'
import { getClient } from "Authentication/Session";
import { numToCol, toApple } from 'Utils/excelutils';


function Exportxls ({tareas,isDis}){
    const [isSubmiting, setIsSubmiting] = React.useState(false);
    const client = React.useRef(getClient());
    let maxReq = 1;
    const data = JSON.parse(JSON.stringify(tareas.values));
    const fechaAMD = fechaHoy();
    const fechaDMA = fechaHoy2();
    const date = new Date();
    let hora = date.getHours();
    if (hora < 10) hora = "0"+hora;
    let minutos = date.getMinutes();
    if (minutos < 10) minutos = "0"+minutos;
    const handler = async() => {
        setIsSubmiting(true);
        for (let index = 0; index < data.length ;index++){
          const request = await taskr({idDetalle:data[index].idt, tipoDetalle:1})
          if (maxReq < request.ans.tas.length){
            maxReq = request.ans.tas.length
          }
          data[index].lid = request.ans.lid.split(":")[1]
          for (let ri=0; ri<request.ans.tas.length; ri++){
            let req="Requerimiento " +(ri+1)
            if (request.ans.tas[ri].ant === "---"){
              data[index][req]=""
              continue
            }
            switch (request.ans.tas[ri].aty){
              case 1:
              case 2:
              case 4:
                data[index][req]=request.ans.tas[ri].ant
                break
              case 3:
                // data[index][req]="=\""+request.ans.tas[ri].ant.replace("~", "\" & CARACTER(10) & \"")+"\"";
                data[index][req]=request.ans.tas[ri].ant.replace("~", "; ");
                break
              case 7:
                data[index][req]="Reposición realizada"
                break
              // case 2:
              //   data[index][req]="=HIPERVINCULO(\"www.google.com";\"LINK ACA\")"
              //   break
              case 6:
                data[index][req]=formatearHoraFecha(request.ans.tas[ri].ant)
                break
              case 5 :
                data[index][req]="http://prod.repoflex.cl/ListaTickets/Imagen/"+toApple(data[index].idt+"&"+request.ans.tas[ri].qid)
                // data[index][req]="http://desa.repoflex.cl/ListaTickets/Imagen/"+toApple(data[index].idt+"&"+request.ans.tas[ri].qid)
                // data[index][req]="http://localhost:3000/ListaTickets/Imagen/"+toApple(data[index].idt+"&"+request.ans.tas[ri].qid)
                break
              default:
                data[index][req]=request.ans.tas[ri].ant
              }
            }
        }
        
        const exl = JSON.parse(JSON.stringify(data));
        exl.forEach((index) =>{
            if (index.ubi === "e"){
              index.ubi = "En el local"
            }
            else{
              index.ubi = "Fuera del local"
            }
            delete index.idt
            // console.log(index)
            index.wen = new Date (formatearHoraFechaAlt(index.wen))
        })
        // console.log(exl)

      const header = [" ","Fecha/Hora", "Nombre Tarea", "Retail","Codigo Local","Nombre Local","Region", "Comuna", "KAM","KAS", "Geolocalización"]
      for (let i=1; i <= maxReq; i++){
        header.push("Requerimiento " +(i))
      }

        
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("My Sheet",{views: [{showGridLines: false}]});
      const response = await fetch(logo); const buffer2 = await response.arrayBuffer();
      const imageId2 = workbook.addImage({
        buffer: buffer2,
        extension: 'png',
      });
      worksheet.addImage(imageId2, 'B2:C3');
      const header1= ["this", "wen", "tna", "ret", "loc", "reg", "com", "kam", "kas", "ubi"]
      for (let i = 1; i <= maxReq; i++) {
        header1.push("Requerimiento "+i)}
      worksheet.getRow(5).values = header1;

      const header2 = [{key:"this",width: 3},{key:"wen", width: 11, style: { numFmt: 'dd-mm-yyyy'}}, {key:"tna", width: 18}, {key:"ret", width: 18}, {key:"lid" , width: 9}, {key:"loc" , width: 18}, 
      {key:"reg", width: 18}, {key:"com", width: 18},  {key:"kam", width: 5}, {key:"kas", width: 5}, {key:"ubi", width: 18}]

      for (let i = 1; i <= maxReq; i++) {
        header2.push({key:"Requerimiento "+i, width: 24})}
      worksheet.columns = header2;


      exl.map(item => {
        worksheet.addRow(item);
      })
      
      
      let row = worksheet.getRow(5);
      for (let i = 1; i <= header.length; i++) {
        row.getCell(i).value = header[i-1];
    }
      // worksheet.autoFilter = 'A5:R5';
      worksheet.eachRow(function (row, rowNumber) {

        row.eachCell((cell, colNumber) => {
            if (rowNumber === 5 && colNumber !== 1) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'A53ECE' }
                }
                cell.font = {
                    color: { argb: 'FFFFFF' },
                }
            }
            // console.log(typeof cellValue)
            if(typeof cell.value === "string"){
              if (cell.value.includes("/ListaTickets/Imagen/")){
                cell.value={
                  text: "Ver Foto",
                  hyperlink: cell.value,
                  tooltip: "Ver Foto"
                }
                cell.font ={
                  color: { argb: '0000FF' },
                  underline: true
                }
            }}
            
            if (colNumber !== 1) {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
          }
            cell.alignment = { wrapText: false, horizontal: 'left'};
        })
        row.commit();
    });

   for (let i = 2; i <= 11+ maxReq; i++) {
    for (let j = 6; j <= 5 + exl.length; j++) {
      worksheet.getCell(numToCol(i)+j.toString()).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    }
   }

    // console.log(exl.length)

    

    const cell = worksheet.getCell('D2');
    cell.value = "Agrupación de Tickets";
    cell.font = { size: 16, bold: true };
    const cell2 = worksheet.getCell('G2');
    cell2.value = fechaDMA + " " + hora +":"+ minutos;

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileExtension = '.xlsx';
      const blob = new Blob([buffer], {type: fileType});
      saveAs(blob, client.current +" "+fechaAMD + "_" + hora +"_"+ minutos + fileExtension);
      setIsSubmiting(false)
    }


return(
    
  <div className="modal-footer">
    <Button onClick={handler} disabled={isDis || isSubmiting}>
      {isSubmiting && <Spinner animation="border" size='sm'/>}
      {!isSubmiting && "Exportar"}
    </Button>
    {/* <Button onClick={()=> setIsSubmiting(false)}>Cerrar</Button> */}
  </div>

)
}
export default Exportxls