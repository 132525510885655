import { getTablaBanco } from 'Utils/tablasDecodificacion';
import React from 'react'

function useTipoBanco () {
    const [values, setValues] = React.useState([]);
    React.useEffect(() => {
        const run = async() => {
            if(values.length > 0) {
                return;
            } else {
                const tipoDeBanco = await getTablaBanco(); 
                const newValues = tipoDeBanco.map(item => ({
                    "value": item.cod,
                    "label": item.name,   
                }))
                setValues(newValues)
            }
        }
        run()
    },[])


    return {
        values
    }
}

export default useTipoBanco