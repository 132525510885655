import { requestData } from "Connection/transacciones"

const weact = async(idUsuario,nuevosDatos) => {
    const jsonBody = {
        "hdr": {
            "txi": 55,
            "req": "weact",
            "ott": true,
        },
        "dat": {
            "uid": parseInt(idUsuario) ,
            "name": nuevosDatos.name    || undefined,
            "snam": nuevosDatos.snam    || undefined,
            "phon": nuevosDatos.phon    || undefined,
            "mail": nuevosDatos.mail    || undefined,
            "prof": parseInt(nuevosDatos.prof) || undefined,
            "clie": parseInt(nuevosDatos.clie) || undefined,
            "ust": nuevosDatos.job      || undefined
        }
    }
    return await requestData(jsonBody)

}

export default weact