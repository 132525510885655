import { requestData } from "Connection/transacciones"

const usdat = async ({userID}) => {
    const jsonBody = {
        hdr: {
            txi: 72,
            req: "usdat",
            ott: true,
        },
        dat: {
            uid: userID
            // uid: 97
        }
    }
    return await requestData(jsonBody)
}

export default usdat;