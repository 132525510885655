import React from "react"
import { Link } from "react-router-dom";


function MenuAdministracion({
    gestionSolicitudes,
    gestionUsuariosWeb,
    gestionUsuariosApp,
    locales,
    clientes,
    gestionUsuariosCom,
    rTareas
}) {
    if (!(
        gestionSolicitudes ||
        gestionUsuariosWeb ||
        gestionUsuariosApp ||
        locales ||
        clientes ||
        gestionUsuariosCom ||
        rTareas
    )) {
        return null
    }
    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle arrow-none" href="/#" id="topnav-pages" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="uil-copy-alt me-1"></i>Administración
                <div className="arrow-down"></div>
            </a>
            <div className="dropdown-menu" aria-labelledby="topnav-pages">
                {locales && (<>
                    <div className="dropdown">
                        <a className="dropdown-item dropdown-toggle arrow-none" href="/#" id="topnav-auth" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Maestras <div className="arrow-down"></div>
                            </a>
                        <div className="dropdown-menu" aria-labelledby="topnav-auth">
                            {locales && <Link to="/Locales" className="dropdown-item">Locales</Link>}
                            {/* <a href="/#" className="dropdown-item">Productos</a> */}

                        </div>
                    </div>
                </>)}
                {(gestionUsuariosApp || gestionUsuariosWeb) && (
                    <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="/#" id="topnav-auth" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Gestión de usuarios <div className="arrow-down"></div>
                            </a>
                        <div className="dropdown-menu" aria-labelledby="topnav-auth">
                            {gestionUsuariosApp && (
                                <Link to="/GestionUsuarios/App" className="dropdown-item">
                                    <i className="mdi mdi-cellphone me-2"></i> App
                                </Link>
                            )}
                            {gestionUsuariosWeb && (
                                <Link to="/GestionUsuarios/Web" className="dropdown-item">
                                    <i className="mdi mdi-desktop-classic me-2"></i> Web
                                </Link>
                            )}
                             {gestionUsuariosCom && (
                                <Link to="/GestionUsuarios/Comercial" className="dropdown-item">
                                    <i className="mdi mdi-desktop-classic me-2"></i> Comercial
                                </Link>
                            )}

                            {/* <a href="/#" className="dropdown-item">Productos</a> */}

                        </div>
                    </div>
                )}
                {/* <a href="/#" className="dropdown-item">Pagos</a> */}
                {/* <a href="/#" className="dropdown-item">Perfil de Usuario Web</a> */}
                {clientes && (<Link to="/GestionClientes/Clientes" className="dropdown-item"> Clientes </Link>)}
                {gestionSolicitudes && <Link to="/Solicitudes" className="dropdown-item">Inscripciones</Link>}
                {rTareas && <Link to="/RevisionTareas" className="dropdown-item">Revision Tareas</Link>}
            </div>
        </li>
    )
}

export default MenuAdministracion;