import weall from "Connection/transacciones/weall";
import React from "react";


function useListaUsuariosWeb () {
    const [lista, setLista] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false);
    const fetch = React.useCallback(async() =>{
        setIsFetching(true);
        const request = await weall({nType: "web"});
        if(request.ans.stx === "ok") {
            setLista(request.ans.wus || []);
        }
        setIsFetching(false);
    } ,[])

    return {
        lista,
        isFetching,
        fetch,
    }
}

export default useListaUsuariosWeb;