import { requestData } from "Connection/transacciones"
import { getUser } from "Authentication/Session";
import { clean } from "Utils/rut";

const wedat = async (idUser) => {
    if (idUser){
    const jsonBody = {
        hdr:	{	
            "txi": 59,
            "req": "wedat",
            "ott": true,
        },	
        dat:	{	
            uid: idUser
        }	
    }
    return await requestData(jsonBody)
}

    else{
        const jsonBody = {
            hdr:	{	
                "txi": 59,
            "req": "wedat",
            "ott": true,
        },	
        dat:	{	
            usr: clean(getUser()).toUpperCase()
        }	
        }
        return await requestData(jsonBody)    
    }
}

export default wedat;