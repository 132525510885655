import Tabla from 'Components/Tabla/Tabla';
import React from 'react';
import calgt from "Connection/transacciones/calgt";
import { formatearFechaHora} from "Utils/funcionesFechaHora";

import { Link, useNavigate } from 'react-router-dom'

const thClassName= "ps-1"

function obtEstado(valor){
    const estado = valor.split(":")
    return estado[1]
}

function TablaCalendarizaciones (){
    const [Calendarizaciones, setCalendarizaciones] = React.useState([])
    React.useEffect(() => {
        calgt(localStorage.tic_id).then(value => {
            setCalendarizaciones(value.ans.cal || [])               
        })
    }, []);

    const handleOnClick = (idTicket) =>{
        localStorage.setItem("detCalen",idTicket)
        // navigation("./DetalleCalendarizacion")
    }

    const columnas = [
        {
            Header: 'Nombre Ticket',
            accessor: 'tna',
            Cell: (props) => {
                const {value, row} = props
                 return (
                    //  <a href={"calendarizacion_ticket_view.html?id_cal=" + row.original.cid} state={{TicketID: row.original.cid}} >
                    //      {value}
                    //  </a>
                    <Link onClick={()=>{handleOnClick(row.original.cid)}} to={"DetalleCalendarizacion" } >
                         {value}
                     </Link>
                 )
             },
            thClassName
        },{
            Header: 'Calendarizado',
            accessor: item => formatearFechaHora(item.cdt),
            thClassName
        },{
            Header: 'Inicio',
            accessor: item => formatearFechaHora(item.dsd),
            thClassName
        },{
            Header: 'Termino',
            accessor: item => formatearFechaHora(item.hst),
            thClassName
        },{
            Header: 'Status',
            accessor: item => obtEstado(item.cst),
            thClassName
        },{
            Header: 'Calendarizador',
            accessor: 'cus',
            thClassName
        }
    ]
        return(
            <div className='card'>
                <div className="card-body">
                    <Tabla
                        columns={columnas}
                        data={Calendarizaciones}
                        globalFilter={true}
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        cellClassName={"p-1"}
                    />
                </div>
            </div>
    )
}

export default TablaCalendarizaciones