import geciu from "Connection/transacciones/geciu"
import React from "react"

function useCiudad(){
    const [options, setOptions] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)

    const fetch = async(dependencies = {}) => {
        setIsFetching(true)
        const request = await geciu(dependencies);
        if(request.ans.stx === "ok") {
            if(request.ans.ciu) {
                const newValues = request.ans.ciu.map(item => ({
                    "value": item.cod,
                    "label": item.nam,   
                }))
                setOptions(newValues)
            } else {
                setOptions([])
            }
        }
        setIsFetching(false)

    }



    return {
        options,
        isFetching,
        fetch
    }
}


export default useCiudad
