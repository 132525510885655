import React from 'react';
import { Button } from 'react-bootstrap';

function BottonToggleFiltro({
    onClick,
    children
}) {
    const handleOnClick = (e) => {
        e.preventDefault();
        onClick();
    }
    return (
        <Button 
            onClick={handleOnClick} 
            variant={"outline-primary"} 
            className="btn-rounded">
            {children} <i className="mdi mdi-filter-variant"></i>
        </Button>
    )
}
export default BottonToggleFiltro;


