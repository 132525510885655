import React from 'react'
import { Form } from 'react-bootstrap'
import FormGroupLabel from './FormGroupLabel'
import useComuna from 'Hooks/Selects/useComuna'
import PropTypes from 'prop-types';

// const 


function SelectComuna (props) {
    const {
        onChange, value, name, disabled,
        dependsOn, 
        size, 
        validation, validationFeedback // validaciones
    } = props;
    const opciones = useComuna()
    React.useEffect(()=> {
        opciones.refreshComunas(dependsOn)
    },[dependsOn])
    return (
        <FormGroupLabel {...props}>
            <Form.Select 
                disabled={disabled}
                size={size}
                value={opciones.value.filter(com => com.value === value).length > 0? value: 0}
                isInvalid={validation === false}
                isValid={validation === true}
                onChange={(e) => {

                    onChange(e.target.value, name)
                }}
            >
                <option value={0}>Seleccionar Comuna</option>
                {opciones.value.map((comuna, idx) => <option key={idx} value={comuna.value}>{comuna.label}</option>)}
            </Form.Select>
            {validationFeedback.isValid &&
                <Form.Control.Feedback tooltip>
                    {validationFeedback.isValid}
                </Form.Control.Feedback>
            }
            {validationFeedback.isInvalid && 
                <Form.Control.Feedback type="invalid" tooltip>
                    {validationFeedback.isInvalid}
                </Form.Control.Feedback>
            }
        </FormGroupLabel>
    )
}

SelectComuna.propTypes = {
    "value": PropTypes.number,
    "validation": PropTypes.bool,
    "validationFeedback": PropTypes.shape({
        "isValid": PropTypes.string,
        "isInvalid": PropTypes.string,
    }),
}

SelectComuna.defaultProps = {
    "validation": undefined,
    "validationFeedback": {
        "isValid": undefined,
        "isInvalid": undefined,
    },
    dependsOn: {}
}


export default React.memo(SelectComuna)