export const filtrosValores = (filtros) => {
    let requestFiltros = {}
    // console.log("filtros",filtros)
    Object.keys(filtros).forEach(key => {
        if (key==="ind"){
            requestFiltros[key]=filtros.ind}
        else if (key==="end"){
            requestFiltros[key]=filtros.end
        }
        else if (key==="fud"){
            requestFiltros[key]=filtros.fud
        }
        else if (key==="fuh"){
            requestFiltros[key]=filtros.fuh
            // console.log("aca2")
        }
        else if (key==="cli"){
            requestFiltros[key]=filtros.cli[0]
            // console.log("aca", filtros.cli[0])
        }
        else if (key==="dur"){
            requestFiltros[key]=filtros.dur
        }
        else if (key==="ame"){
            requestFiltros[key]=filtros.ame
        }
        else if (key==="bid"){
            requestFiltros[key]=filtros.bid
        }
        else{
            // console.log("aca",filtros[key].map(item => item.value))
            requestFiltros[key] = filtros[key].map(item => item.value)
        }
    })
    return requestFiltros;
}
// export const filtrosValores = (filtros) => {
//     let requestFiltros = {}
//     // console.log("filtros",filtros)
//     Object.keys(filtros).forEach(key => {
//         if (key==="ind"){
//             requestFiltros[key]=filtros.ind}
//         else if (key==="end"){
//             requestFiltros[key]=filtros.end
//         }
//         else{
//             // console.log("aca",filtros[key].map(item => item.value))
//             requestFiltros[key] = filtros[key].map(item => item.value)
//         }
// })
//     return requestFiltros;

// }
