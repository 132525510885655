
import newpw from "Connection/transacciones/newpw";
import React, { useState } from "react";
import toast from "react-hot-toast";


const FormularioNuevaPassword = ({setForm}) => {
    // Formulario
    const [formulario, setFormulario] = useState({
        codigoVerificacion:'',
        nuevaPassword: '',
        nuevaPasswordDos: '',
        usuario: localStorage.usuarioRecuperar,
    });
    const [errorCodigo, setErrorCodigo] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorLargoPassword, seterrorLargoPassword] = useState(false);

    const handleInputChange = (event) => {
        if (event.target.name === 'nuevaPassword' || event.target.name === 'nuevaPasswordDos')
            setErrorPassword(false)
        if (event.target.name === 'nuevaPassword')
            seterrorLargoPassword(false)
        if (event.target.name === 'codigoVerificacion')
            setErrorCodigo(false)
        setFormulario({
            ...formulario,
            [event.target.name] : event.target.value,
        })
        
    }
    // Enviar datos al backend
    const backendCambiarPassword = async({codigoVerificacion, nuevaPassword, usuario}) => {
        const data = await newpw(usuario, nuevaPassword, codigoVerificacion)
        switch (data.ans.stx) {
            case "wc":
                setErrorCodigo(true)
                break;
            case "ok":
                toast.success(data.ans.msg,{
                    hideAfter: 5000,
                    showHideTransition: 'slide',
                    allowToastClose: true,
                })
                setForm("login")
                break;
            default:
                break;
        }
    }

    const submitCambiarPassword = async(event) => {
        event.preventDefault();
        let inputsErrors = false;
        if(formulario.nuevaPassword !== formulario.nuevaPasswordDos){
            inputsErrors = true
            setErrorPassword(true);
        } 
        
        if (formulario.nuevaPassword.length> 16 || formulario.nuevaPassword.length < 6){
            inputsErrors = true
            seterrorLargoPassword(true)
        }

        if (inputsErrors === false) {
            await backendCambiarPassword(formulario);
        }
    }
    const irLogin = (event) => {
        event.preventDefault();
        setForm("login")
    }
    return (
        <>
            <h4 className="mt-0">
                Modificar contraseña
            </h4>
            <p className="text-muted mb-2">
                Ingresa el código que recibiste en tu correo, junto con tu nueva contraseña. No olvides revisar spam.
            </p>
            {/* Inicio Form */}
            <form onSubmit={submitCambiarPassword}>
                <div className="mb-2">
                    <label htmlFor="codigoNuevaPassword">
                        Código de verificación
                    </label>
                    <input 
                        type="text"
                        id="codigoNuevaPassword"
                        className="form-control"
                        placeholder="------"
                        name="codigoVerificacion"
                        value={formulario.codigoVerificacion}
                        maxLength="7"
                        minLength="6"
                        onChange={(e) => {  
                            const re = /^[0-9\b]+$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                                handleInputChange(e)
                            }
                        }}
                    />
                    <small className="text-danger">
                        {errorCodigo? "El código ingresado no es válido.": <br/>}
                    </small>
                </div>

                <div className="mb-0">
                    <label htmlFor="nuevaPassworUno">
                        Contraseña
                    </label>
                    <input 
                        type="password"
                        id="nuevaPassworUno"
                        className="form-control"
                        name="nuevaPassword"
                        placeholder="Nueva contraseña"
                        value={formulario.nuevaPassword}
                        onChange={handleInputChange}
                    />
                    <small className="text-danger">
                        {errorLargoPassword? "La contraseña debe tener entre 6 y 16 caracteres.": <br/>}
                    </small>

                </div>
                <div className="mb-0">
                    <label htmlFor="nuevaPasswordDos">
                        Repetir contraseña
                    </label>
                    <input 
                        type="password"
                        id="nuevaPasswordDos"
                        className="form-control"
                        name="nuevaPasswordDos"
                        placeholder="Nueva contraseña"
                        value={formulario.nuevaPasswordDos}
                        onChange={handleInputChange}
                    />
                    <small className="text-danger">
                        {errorPassword? "Las contraseñas no coinciden.": <br/>}
                    </small>
                </div>
                

                
                <div className="d-grid mb-0 text-center">
                    <button 
                        className="btn btn-primary"
                        type="submit"
                    >
                        <i className="mdi mdi-lock-reset"></i> Modificar contraseña 
                    </button>
                </div>

            </form>
            {/* Fin form */}
            <div className="d-flex justify-content-center mt-3">
                <div>
                    <p className="text-muted">Volver al 
                        <a  href="/#" onClick={irLogin} className="text-muted ms-1"> 
                            <b>
                                Inicio de Sesión
                            </b>
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
}

export default FormularioNuevaPassword;