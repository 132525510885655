export const numToCol = (input) => {
    switch (input) {
        case 1: return 'A'
        case 2: return 'B'
        case 3: return 'C'
        case 4: return 'D'
        case 5: return 'E'
        case 6: return 'F'
        case 7: return 'G'
        case 8: return 'H'
        case 9: return 'I'
        case 10: return 'J'
        case 11: return 'K'
        case 12: return 'L'
        case 13: return 'M'
        case 14: return 'N'
        case 15: return 'O'
        case 16: return 'P'
        case 17: return 'Q'
        case 18: return 'R'
        case 19: return 'S'
        case 20: return 'T'
        case 21: return 'U'
        case 22: return 'V'
        case 23: return 'W'
        case 24: return 'X'
        case 25: return 'Y'
        case 26: return 'Z'
        case 27: return 'AA'
        case 28: return 'AB'
        case 29: return 'AC'
        case 30: return 'AD'
        case 31: return 'AE'
        case 32: return 'AF'
        case 33: return 'AG'
        case 34: return 'AH'
        case 35: return 'AI'
        case 36: return 'AJ'
        case 37: return 'AK'
        case 38: return 'AL'
        case 39: return 'AM'
        case 40: return 'AN'
        case 41: return 'AO'
        case 42: return 'AP'
        case 43: return 'AQ'
        case 44: return 'AR'
        default: return ''
}}

export const toApple = (input) => {
    let [beans, single] = input.split('&')
    let thisBean = beans.split('')
    let thisSingle = single.split('')
    let soup = []
    let stew = []
    for(let i = 0; i < thisBean.length; i++){
        switch (thisBean[i]) {
            case "1": soup.push("FYH")
                break;
            case "2": soup.push("KFL")
                break;
            case "3": soup.push("AWC")
                break;
            case "4": soup.push("NFM")
                break;
            case "5": soup.push("PMA")
                break;
            case "6": soup.push("JWZ")
                break;
            case "7": soup.push("XGA")
                break;
            case "8": soup.push("BAB")
                break;
            case "9": soup.push("HHO")
                break;
            case "0": soup.push("WAZ")
                break;
            default: soup.push("WAZ")
        }
        soup.push(Math.floor(Math.random() * 10001))
    }
    for(let i = 0; i < thisSingle.length; i++){
        switch (thisSingle[i]) {
            case "1": stew.push("FYH")
                break;
            case "2": stew.push("KFL")
                break;
            case "3": stew.push("AWC")
                break;
            case "4": stew.push("NFM")
                break;
            case "5": stew.push("PMA")
                break;
            case "6": stew.push("JWZ")
                break;
            case "7": stew.push("XGA")
                break;
            case "8": stew.push("BAB")
                break;
            case "9": stew.push("HHO")
                break;
            case "0": stew.push("WAZ")
                break;
            default: stew.push("WAZ")
        }
        stew.push(Math.floor(Math.random() * 10001))
    }
    return soup.join('') +"$"+ Math.floor(Math.random() * 10001) + stew.join('')

}

export const toBannana = (input) => {
    
    let [peas, carrots] = input.replace(/[0-9]/g, '').split('$')
    let soup = []
    let stew = []
    let error = false
        for(let i = 0; i < peas.length; i=i+3){
            switch (peas[i]+peas[i+1]+peas[i+2]) {
                case "FYH": soup.push("1")
                    break;
                case "KFL": soup.push("2")
                    break;
                case "AWC": soup.push("3")
                    break;
                case "NFM": soup.push("4")
                    break;
                case "PMA": soup.push("5")
                    break;
                case "JWZ": soup.push("6")
                    break;
                case "XGA": soup.push("7")
                    break;
                case "BAB": soup.push("8")
                    break;
                case "HHO": soup.push("9")
                    break;
                case "WAZ": soup.push("0")
                    break;
                    default: error = true
            }   
        }
        for(let i = 0; i < carrots.length; i=i+3){
            switch (carrots[i]+carrots[i+1]+carrots[i+2]) {
                case "FYH": stew.push("1")
                    break;
                case "KFL": stew.push("2")
                    break;
                case "AWC": stew.push("3")
                    break;
                case "NFM": stew.push("4")
                    break;
                case "PMA": stew.push("5")
                    break;
                case "JWZ": stew.push("6")
                    break;
                case "XGA": stew.push("7")
                    break;
                case "BAB": stew.push("8")
                    break;
                case "HHO": stew.push("9")
                    break;
                case "WAZ": stew.push("0")
                    break;
                default: error = true
            }
        }
        if(error){
            return "error"
        }
        else{
            return soup.join('') +"&"+ stew.join('')
        }
        // return soup.join('') +"&"+ stew.join('') 


}