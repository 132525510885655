import { requestData } from "Connection/transacciones"

const calen = async(dat) => {
    const jsonBody = {
        hdr: {
            txi: 45,
            req: "calen",
            ott: true,
        },
        dat: dat
        
    }
    // console.log(mcf)
    // console.log(jsonBody)
    return await requestData(jsonBody)

}

export default calen;