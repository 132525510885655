import React, {useEffect, useState} from 'react'
import { useRef } from 'react';
import { Button, CloseButton, Collapse, Form, InputGroup } from 'react-bootstrap'

function Alternativa (props) {
    return(
        <>
            <Form.Control
                maxLength={128}
                value={props.value}
                onChange={(e)=> props.onChange(e, props.index)}
                id={props.id}
                type='text'
                placeholder={`Ingrese opción ${props.index + 1}`}
                required
            />
        </>
    )
}

function Seleccion({formulario, index}) {
    useEffect(() => {
        // Si no tiene alternativa las añade
        if(!('alternativas' in formulario.values.listaRequerimientos[index])) { 
            const toUpdateReq = [...formulario.values.listaRequerimientos]
            toUpdateReq[index] = {
                id: toUpdateReq[index].id,
                tipo: toUpdateReq[index].tipo,
                titulo: toUpdateReq[index].titulo, // mantiene el titulo
                alternativas: [{"value": '', "id": 0}],
                next: "",
            }
            formulario.setFieldValue("listaRequerimientos",toUpdateReq)
        }
        console.log()
    }, []);
 
    const onTitleChange = (e) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index] = {
            id: toUpdateReq[index].id,
            tipo: toUpdateReq[index].tipo,
            titulo: e.target.value, // cambio de titulo
            alternativas: toUpdateReq[index].alternativas,
            next: toUpdateReq[index].next,
        }
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }

    const currentId = useRef(999); // parte en 1 pero ya tiene la opcion

    const agregarOpcion = () => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        console.log(toUpdateReq)
        toUpdateReq[index].alternativas = [...formulario.values.listaRequerimientos[index].alternativas, {"value":'', "id": currentId.current}]
        console.log(toUpdateReq)
        console.log(currentId.current)
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
        currentId.current++
    }

    const eliminarOpcion = (id) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index].alternativas = formulario.values.listaRequerimientos[index].alternativas.filter(alt => alt.id !== id);
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }
    const onChangeOpcion = (e, idx) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index].alternativas[idx].value = e.target.value 
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }
    const onChangeOpcion2 = (e) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index].next = e.target.value 
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className=''>
            <div className="row">
                <div className="col-9">
                    <InputGroup className=''>
                        <Form.Control
                            value={formulario.values.listaRequerimientos[index].titulo}
                            onChange={onTitleChange}
                            type='text'
                            placeholder={`Ingrese requerimiento ${index + 1}`}
                            required
                            maxLength={128}
                        />
                        <Button 
                            variant='outline-secondary'
                            onClick={() => setIsOpen(!isOpen)}
                            aria-controls="example-collapse-text"
                            aria-expanded={isOpen}
                        >
                            {isOpen?'▲': '▼'}
                        </Button>
                    </InputGroup>
                </div>
                <div className="col-3">
                    <Form.Control
                        maxLength={128}
                        onChange={(e)=> onChangeOpcion2(e)}
                        id={4}
                        type='number'
                        placeholder={`N° Requerimiento`}
                        required
                    />
                </div>
            </div>

            
            <Collapse in={isOpen}>
                <div className='mt-2'>
                    {formulario.values.listaRequerimientos[index].alternativas && 
                    formulario.values.listaRequerimientos[index].alternativas.map((item, idx) => (
                    <div className="row mb-1" key={idx} >
                        <div className="col  d-flex align-items-center">
                            <strong>
                                Opción {idx + 1}
                            </strong>
                        </div>
                        <div className="col d-flex align-items-center justify-content-end" style={{maxWidth: "50px"}}>
                            {idx !== 0 && (
                                <CloseButton  onClick={()=> eliminarOpcion(item.id)} />
                            )}
                        </div>
                        <div className="col-8">
                            <Alternativa 
                                value={item.value} 
                                index={idx} 
                                onChange={onChangeOpcion}
                            />
                        </div>                    
                    </div>
                    ))}
                    <div className="d-flex justify-content-end">
                        <Button onClick={agregarOpcion} variant='secondary' className='btn-rounded' >
                            Agregar opción
                        </Button>

                    </div>

                </div>
            </Collapse>
            

        </div>
    )
}
 
export default Seleccion