
// cod comuna
// cod region
// cod banco
// cod tipo cuenta

import { regi0 } from "Connection/transacciones"
import gecom from "Connection/transacciones/gecom";

const requestTablaDecodificacion = async() => {
    const request = await regi0();
    if (request.ans.stx === "ok"){
        localStorage.setItem("tablaRegiones",JSON.stringify(request.ans.regiones))
        localStorage.setItem("tablaBancos",JSON.stringify(request.ans.bancos))
        localStorage.setItem("tablaPaises",JSON.stringify(request.ans.paises))
        localStorage.setItem("tablaActypes",JSON.stringify(request.ans.actypes))
    }
    return
}

export const getTablaComunas = async() => {
    if(localStorage.tablaComunas === undefined){
        
        const comRequest = await gecom();
        if(comRequest.ans.stx === "ok") {
            localStorage.setItem("tablaComunas",JSON.stringify(comRequest.ans.com))
            return await comRequest.ans.com
        } 
    } else {
        return await JSON.parse(localStorage.tablaComunas)
    }
}

export const getTablaRegiones = async() => {
    if(localStorage.tablaRegiones === undefined){
        
        await requestTablaDecodificacion();
        return await JSON.parse(localStorage.tablaRegiones)
    }
    else {
        return await JSON.parse(localStorage.tablaRegiones)
    }
}


export const getTablaBanco = async() => {
    if(localStorage.tablaBancos === undefined){
        await requestTablaDecodificacion();
        return await JSON.parse(localStorage.tablaBancos)
    } else {
        return JSON.parse(localStorage.tablaBancos)
    }
}

export const getTablaTipoCuenta = async() => {
    if(localStorage.tablaActypes === undefined) {
        await requestTablaDecodificacion();
        return await JSON.parse(localStorage.tablaActypes)
    } else {
        return await JSON.parse(localStorage.tablaActypes)
    }
}

