import { requestData } from "Connection/transacciones"

export const gereg = async(dep = {}) => {
    const jsonBody = {
        "hdr": {
            txi: 66,
            req: "gereg",
            ott: true
        },
        "dat": {
            ful: 1,
            hol: dep.hol,
            ret: dep.ret,
            zon: dep.zon,
            nie: dep.nie,
            com: dep.com,
        }
    }
    return await requestData (jsonBody)
}