import { requestData } from "Connection/transacciones"
import { clean } from "Utils/rut";


const sndma = async(rutUsuario) => {
    const jsonBody = {
        hdr: {
            txi: 4,
            req: "sndma",
            ott: false,
        },
        dat: {
            usr: clean(rutUsuario).toUpperCase()
        }
    }
    return await requestData(jsonBody)
} 

export default sndma
