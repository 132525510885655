import { requestData } from "Connection/transacciones"
import { clean } from "Utils/rut";

const clnew = async ({rutCliente, nombreCliente, telefonoCliente, emailCliente, paisCliente}) => {
    const jsonBody = {
        "hdr": {
            "txi": 81,
            "req": "clnew",
            "ott": true
        },
        "dat": {
            "rut": clean(rutCliente).toUpperCase(),
            "name": nombreCliente,
            "phon": telefonoCliente,
            "mail": emailCliente,
            "pai": paisCliente
            }
        } 
    return await requestData(jsonBody)
    // console.log(jsonBody.dat)
}

export default clnew;