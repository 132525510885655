import React, { useState, useCallback} from "react";
import Tabla from "Components/Tabla/Tabla";
import { Button, Modal, ModalBody } from "react-bootstrap";
import FiltroLocales from "./FiltroLocales";
import useListaLocales from "Hooks/Listas/useListaLocales";
import { Link, useNavigate } from 'react-router-dom';
import matrx from "Connection/transacciones/matrx";
import locus from "Connection/transacciones/locus";
import { format } from 'Utils/rut';
import { formatearFecha } from "Utils/funcionesFechaHora";
import { getCountry } from "Authentication/Session";

function TablaLocales ({cliente}) {
    //Modal
    const Navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleShow = useCallback(() => setIsModalOpen(true),[]);
    const handleClose = useCallback(() => setIsModalOpen(false),[]);

    const [isModalOpenOptions, setIsModalOpenOptions] = useState(false);
    const handleShowOptions = useCallback(() => setIsModalOpenOptions(true),[]);
    const handleCloseOptions = useCallback(() => setIsModalOpenOptions(false),[]);
    //Asignados
    const [isFetching, setIsFetching] = React.useState(false);
    const [locName, setLocName] = React.useState("");
    const [usAsignated, setUsAsignated] = React.useState([]);
    const [useType, setUseType] = React.useState(0);

    const handlerData = React.useCallback(async(usType) => {
        handleShow()
        if(usType === 0){
            setLocName("Ningun Local")
        }
        setIsFetching(true)
        const request = await locus({
                lid:usType
            });
        setUsAsignated(request.ans.usa || [])
        setIsFetching(false)
        
    },[])

    
    const handlerDataOptions = React.useCallback(async(lid,local) => {
        setUseType(lid)
        setLocName(local)
        handleShowOptions()
    },[])

    const GestionarLocal = (lid) => {
        localStorage.setItem("lid",lid);
        handleCloseOptions()
    }

    const locales = useListaLocales({
        dataOption:1
    });
    const compFiltros = (
        <><FiltroLocales
            totalLocales={locales.values.length}
            fetchLocales={locales.fetchLocales}
        />
        {cliente===0 && (
                    <div  className="mb-2">
                        <Button type="button" className='btn me-2' onClick={()=>Navigate("./AgregarLocal")}>
                            Agregar local
                        </Button>
                        <Button type="button" className='btn me-2'  onClick={()=>(handlerData(0), setUseType(0))}>
                            Usuarios sin Local
                        </Button>
            </div>)}
        </>
    )
    //Columnas Locales
    const columnas = [
        {
            Header: 'Código',
            accessor: 'cod',
            thClassName:"ps-1"
            // thClassName:"text-center",
            // filter: true
        },{
            Header: 'Local',
            accessor: 'nam',
            thClassName:"ps-1",
            Cell: (props) => {
                const {value, row} = props
                 if (cliente !== 0){
                 return (
                    <>{value}</>
                 )}
                 else {
                 return (
                    <Link  to="#" onClick={(e)=>{
                        e.preventDefault()
                        // handlerData(row.original.lid,row.original.nam)
                        // setUseType(row.original.lid)
                        handlerDataOptions(row.original.lid,row.original.nam)

                        // setLocName("Local: "+row.original.nam)
                        // nombreTareaSeleccionado(row.original.tit)
                        // abrirModal(row.original.tid)
                 }}> 
                    {value}
                    </Link>
                 )}
             },
            //thClassName:"text-center"
        },{
            Header: 'Dirección',
            accessor: 'adr',
            thClassName:"ps-1"

            // thClassName:"text-center",
            // tdClassName:"text-center"
        },{
            Header: 'Comuna',
            accessor: 'com',
            thClassName:"ps-1"

            //thClassName:"text-center",
        },{
            Header: ((true)? 'Ciudad':"otro"),
            accessor: 'ciu',
            thClassName:"ps-1"
            //thClassName:"text-center",
        },{
            Header: 'Zona Geográfica',
            accessor: 'zon',
            thClassName:"ps-1"

            //thClassName:"text-center",
        },{
            Header: 'Zona Nielsen',
            accessor: 'nie',
            thClassName:"ps-1"

            // thClassName:"text-center",
        },{
            Header: 'Retailer',
            accessor: 'ret',
            thClassName:"ps-1"

            // thClassName:"text-center",
        },{
            Header: 'Holding',
            accessor: 'hol',
            thClassName:"ps-1"

            // thClassName:"text-center",
        }
    ]

    
    //Columnas Asignados
    const columnas2 = [
        {
            Header: 'Nombre',
            accessor: 'nam',
            thClassName:"ps-1"
        },{
            Header: 'Apellido',
            accessor: 'sna',
            thClassName:"ps-1"
        },{
            Header: 'Rut',
            accessor: 'rut',
            Cell: props =>{
                const {value} = props 
                return(format(value))
            },
            thClassName:"p-1",
        },{
            Header: 'Telefono',
            accessor: 'tel',
            thClassName:"ps-1"
        },{
            Header: 'FPR',
            accessor: 'fpr',
            Cell: props =>{
                const {value} = props
                if(value === 0){
                    return("-")
                }
                else
                    return(formatearFecha(String(value)))
            },
            thClassName:"ps-1"
        },{
            Header: 'FUR',
            accessor: 'fur',
            Cell: props =>{
                const {value} = props
                if(value === 0){
                    return("-")
                }
                else
                    return(formatearFecha(String(value)))
            },
            thClassName:"ps-1"
        }
    ]


    return (
        <>
        <Modal 
            show={isModalOpen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>
                    <h4>Usuarios Asignados a: </h4>   
                </Modal.Title>
            </Modal.Header>
            <ModalBody>
                <div className="row text-center">
                    <h4>{locName}</h4>
               </div>
                    <Tabla
                    columns={columnas2}
                    data={usAsignated}
                    globalFilter={true}
                    tableClass={"table table-centered  table-hover mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={isFetching}
                    />
            </ModalBody>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>handleClose()}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
        
        <Modal         
            size="xxl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isModalOpenOptions} onHide={handleCloseOptions}>
            <Modal.Header closeButton>
                <Modal.Title>{locName} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Link to={"GestionarLocal"} onClick={()=>GestionarLocal(useType)} className="dropdown-item">Editar Local</Link>
                <li onClick={()=>(handleCloseOptions(),handlerData(useType))}  className="dropdown-item">Ver Usuarios Asignados</li>
            </Modal.Body>
        </Modal>
        <div className="card">
            <div className="card-body">
                <Tabla
                    data={locales.values}
                    columns={columnas}
                    globalFilter={true}
                    titleSection={compFiltros}
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    loading={locales.isFetching}
                    cellClassName={"p-1"}
                />
            </div>
        </div>
            
        </>
    )
}

export default TablaLocales;