import { requestData } from "Connection/transacciones"

const usdes = async(userDat) => {
    const jsonBody = {
        "hdr": {
            "txi": 74,
            "req": "usdes",
            "ott": true,
        },
        "dat": userDat
    }

    // console.log(JSON.stringify(jsonBody))
    return await requestData(jsonBody)

}

export default usdes;