import React, { useState, useEffect } from "react";
import { backend_request } from "Connection/backend_reduce";
import toast from "react-hot-toast";
import { actualizarInfoPagina } from "Utils/paginaInfo";
import { getUser, logout } from "Authentication/Session";
import InputTexto from "Components/FormElements/InputTexto";
import { clean } from "Utils/rut";

const propsStyles = {
    asRow: true,
    labelSize: 3,
    size: "sm"
}

const CambiarPassword = () => {
    const [datos, setDatos] = useState({ 
        passVieja: '',
        passNueva: '',
        passNuevaDos:''
    }) // Datos ingresados en el formulario
    
    const [errorMismaPassword, setErrorMismaPassword] = useState(false); // Booleano para avisar cuando las contraseñas no coinciden
    const [errorLargoPassword, seterrorLargoPassword] = useState(false); // Booleano para avisar cuando el largo de la contraseña no es el indicado

    const [isDisabled, setIsDisabled] = useState(false) // Activa o desactiva botones

    const backendPassword = async({passVieja, passNueva}) => { // Método para validar los datos
        const jsonBody = {
            "hdr": {
                "txi": 5,
                "req": "chgpw",
                "ott": localStorage.ott
            },
            "dat": {
                "usr": clean(getUser()),
                "pso": passVieja,
                "psn": passNueva
            }
        }

        await backend_request(jsonBody, (data) => {
            if (data.ans.stx === "ok"){
                toast.success(data.ans.msg,{
                    hideAfter: 5000,
                    showHideTransition: 'slide',
                    allowToastClose: true,               
                } )
                setTimeout(() => {
                    logout()
                }, 2000);
            } else {
                toast.error(data.ans.msg,{
                    hideAfter: 5000,
                    showHideTransition: 'slide',
                    allowToastClose: true,               
                })
            }

        })
    }

    const handleInputChange = (event) => { // Ingresa los datos y se reciben inmediatamente en "datos"
        if (event.target.name === 'passNueva' || event.target.name === 'passNuevaDos')
            setErrorMismaPassword(false)

        if (event.target.name === 'passNueva')
            seterrorLargoPassword(false)

        setDatos({
            ...datos,
            [event.target.name] : event.target.value
        })
    }
    const oldPasswordChange = (value) => {
        setDatos(datos => ({
            ...datos,
            "passVieja" : value
        }))
    }
    const newPassword1Change = (value) => {
        setDatos(datos => ({
            ...datos,
            "passNueva" : value
        }))
    }
    const newPassword2Change = (value) => {
        setDatos(datos => ({
            ...datos,
            "passNuevaDos" : value
        }))
    }
    const submitEnviarDatos = async(event) => { //Se envían los datos al presionar el botón de confirmar
        event.preventDefault();
        let enviarFormulario = true; // Booleano para hacer la transacción solo si no hay errores ingresados

        if (datos.passNueva !== datos.passNuevaDos){
            setErrorMismaPassword(true)
            enviarFormulario = false
        }

        if (datos.passNueva.length > 16 || datos.passNueva.length < 6){
            seterrorLargoPassword(true)
            enviarFormulario = false
        }
        
        if (enviarFormulario === true){ 
            setIsDisabled(true)
            await backendPassword(datos)
            setIsDisabled(false)
        }
    }

    return (
        
        <div className="row">
            <h4>Cambiar Contraseña</h4>
            <form onSubmit={submitEnviarDatos}>
                    <div>
                        <InputTexto
                            required
                            {...propsStyles}
                            label="Ingresar contraseña actual"
                            type="password" 
                            placeholder="Contraseña actual"
                            value={datos.passVieja}
                            onChange={oldPasswordChange}
                            disabled={isDisabled}
                        />
                    </div>
                    <div className="mt-3">

                        <InputTexto 
                            required
                            {...propsStyles}
                            label="Ingresar contraseña nueva"
                            type="password"
                            placeholder="Contraseña nueva"
                            value={datos.passNueva}
                            onChange={newPassword1Change}
                            disabled={isDisabled} 
                        />
                        {/* <small className="text-danger">
                            {errorLargoPassword? "La contraseña debe tener entre 6 y 16 caracteres.": <br/>}
                        </small> */}
                    </div>
                    <div className="mt-2">
                        <InputTexto 
                            required
                            {...propsStyles}
                            label="Repetir contraseña nueva"
                            type="password" 
                            placeholder="Contraseña nueva"
                            name="passNuevaDos"
                            value={datos.passNuevaDos}
                            onChange={newPassword2Change}
                            disabled={isDisabled} 
                        />
                        {/* <small className="text-danger">
                            {errorMismaPassword? "Las contraseñas no coinciden.": <br/>}
                        </small> */}
                    </div>
                    <div className="mt-3 text-end ">
                        <button className="btn btn-primary"
                            type="submit" 
                            disabled={isDisabled}>
                            
                            {!isDisabled &&
                                "Cambiar contraseña"
                            }

                            {isDisabled &&
                                <span className="spinner-border spinner-border-sm" role="status">
                    
                                </span>
                            }
                        </button>
                        
                    </div>
            </form>
        </div>

            
    )
}

export default CambiarPassword;