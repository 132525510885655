import { requestData } from "Connection/transacciones"

const locid = async (dat = {}) => {
    const jsonBody = {
        hdr: {
            txi: 89,
            req: "locid",
            ott: true,
        },
        dat: {
            lid:Number(dat.lid)
        }
    }
    // console.log(jsonBody)
    return await requestData(jsonBody)
}

export default locid;