import React from 'react'
import { MultiSelect } from 'react-multi-select-component';
import localization from "Assets/localization/multiSelect.json"
import useZonaGeografica from 'Hooks/Selects/useZonaGeografica';

function MultiSelectZonaGeografica({
    isDisabled,
    dependencies = {}, 
    onChange = () => {},
    selected=[],
    name
}) {
    const zonaGeografica = useZonaGeografica();
    const fetched = React.useRef(false) 
    React.useEffect(() => {
        fetched.current = false; // si se actualiza una dependencia se debe menciar que esta desactualizado 
    }, [
        JSON.stringify(dependencies.hol),
        JSON.stringify(dependencies.ret),
    ])

    const onToggle = async(isOpen) => {
        if(isOpen && !fetched.current) {
            fetched.current = true; // Lista de comunas actualizados? SI
            await zonaGeografica.fetch(dependencies);
        }
    }
    const handleOnChange = (values) => {
        const sorted = values.sort((a,b) => a.value - b.value)
        onChange(sorted, name)
    }

    return (
        <MultiSelect 
            value={selected}
            overrideStrings={localization}
            onChange={handleOnChange}
            options={zonaGeografica.options}
            onMenuToggle={onToggle}
            disabled={isDisabled}
            isLoading={zonaGeografica.isFetching}

        />
    )
}

export default MultiSelectZonaGeografica