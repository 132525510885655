import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import TablaTickets from "./TablaTickets";
import ModalOpcionesTickets from "./ModalOpcionesTickets"

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Tickets</li>
                </ol>
            </div>
            <h4 className="page-title">Tickets recientes</h4>
        </div>
    )
    }

function PaginaGestionTickets(props) {
        useEffect(() => { actualizarInfoPagina(props) }, []);  // eslint-disable-line
        return (
            <div className='container'>
                <TituloPagina />
                <TablaTickets />
                <ModalOpcionesTickets />
                
            </div>
        )
}
export default PaginaGestionTickets