import React from "react";
import { Card, Button } from "react-bootstrap";
import Tabla from 'Components/Tabla/Tabla';
import toast from "react-hot-toast";

import { DatePicker, Calendar } from "react-multi-date-picker"
import { formatearMDA } from "Utils/funcionesFechaHora";
import Select from 'react-select';
// import datepicker from 'js-datepicker'
// import DatePicker from "react-date-picker";
// import "react-datepicker/dist/react-datepicker.css"
// import "Assets/css/app-creative.css"
import "./DatePicker.css"
import cadet from "Connection/transacciones/cadet";
import es_th from "./es";
import shadows from "@material-ui/core/styles/shadows";
import { CollectionsOutlined } from "@material-ui/icons";
import caled from "Connection/transacciones/caled";


const thClassName = "ps-1"

function CalendarizacionRevision({idCal}) {
  // console.log("idCal",idCal)

  const [initialBlue, setInitialBlue] = React.useState([]);
  const [initialRed, setInitialRed] = React.useState([]);
  const [grayDay, setGrayDay] = React.useState([])
  const [dayArray, setDayArray] = React.useState([])
  const [executionHour, setExecutionHour] = React.useState("")
  const [reset, setReset] = React.useState(false)
  const [status, setStatus] = React.useState("")

  const [checkBlue, setCheckBlue] = React.useState(false)
  const [checkRed, setCheckRed] = React.useState(false)

  const [toBlue, setToBlue] = React.useState([])
  const [toRed, setToRed] = React.useState([])

  const [locales, setLocales] = React.useState([])
  const [fetching, setIsFetching] = React.useState(false);

  const onBlueChange = (value) => {
    setToBlue(value)
    setCheckBlue(!checkBlue)
  }
  const onRedChange = (value) => {
    setToRed(value)
    setCheckRed(!checkRed)
  }
  const validBlue = React.useMemo(() => {
    if( toBlue.length === 0 ) return undefined
    else return true
  } ,[checkBlue])

  const validRed= React.useMemo(() => {
    if( toRed.length === 0 ) return undefined
    else return true
  } ,[checkRed])

  React.useEffect(() => {
    const run = async () => {
      setIsFetching(true);
      const request = await cadet({cid:Number(idCal)})
      if (request.ans.stx === "ok") {
        setToBlue([])
        setToRed([])
        setCheckBlue(!checkBlue)
        setCheckRed(!checkRed)
        setStatus(request.ans.cst)
        const locArray = [{}]
        for (let i = 0; i < request.ans.lol.length; i++) {
          // console.log({ "loc": request.ans.lol[i].split(":")[2], "cod": request.ans.lol[i].split(":")[1] })
          locArray.push({ loc: request.ans.lol[i].split(":")[2], cod: request.ans.lol[i].split(":")[1] })
        }
        setLocales(locArray)
        setExecutionHour(request.ans.tim)
        let redArray = []
        let grayArray = []
        let bluearray = []
        let allArray = []
        for (let i = 0; i < request.ans.dal.length; i++) {
          // console.log("req",request.ans.dal[i])
          if (request.ans.dal[i].split(":")[1] === "1") {
            grayArray.push(String(request.ans.dal[i].split(":")[0]))
            // allArray.push(new Date(formatearMDA(String(request.ans.dal[i].split(":")[0]))))
          }
          else if (request.ans.dal[i].split(":")[1] === "2") {
            // bluearray.push(new Date(formatearMDA(String(request.ans.dal[i].split(":")[0]))))
            bluearray.push(String(request.ans.dal[i].split(":")[0]))
            allArray.push(new Date(formatearMDA(String(request.ans.dal[i].split(":")[0]))))
            // console.log(String(request.ans.dal[i].split(":")[0]))
          }
          else if (request.ans.dal[i].split(":")[1] === "3") {
            redArray.push(String(request.ans.dal[i].split(":")[0]))
            allArray.push(new Date(formatearMDA(String(request.ans.dal[i].split(":")[0]))))
          }
        }
        setInitialBlue(bluearray)
        setInitialRed(redArray)
        setGrayDay(grayArray)
        setDayArray(allArray)
        setExecutionHour(request.ans.tim)

        setIsFetching(false);
      }
    }
    run();
  }, [reset])

  const columnas = React.useMemo(() => [
    {
      Header: "Codigo de Local",
      accessor: "cod",
    },
    {
      Header: "Nombre de Local",
      accessor: "loc",
    }
  ])

  const onDayChange = (date) => {
    let tempRed = toRed
    let tempBlue = toBlue
    if (initialRed.includes(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {

      if (toBlue.includes(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {
        tempBlue.splice(toBlue.indexOf(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day))), 1)
        onBlueChange(tempBlue)
      }
      else {
        tempBlue.push(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day)))
        onBlueChange(tempBlue)
      }

    }
    if (initialBlue.includes(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {
      if (toRed.includes(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {
        tempRed.splice(toRed.indexOf(String(date.year).substring(2, 4) +((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number)) + ((date.day < 10)? "0" + String(date.day):String(date.day))), 1)
        onRedChange(tempRed)
      }
      else {
        tempRed.push(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number)) + ((date.day < 10)? "0" + String(date.day):String(date.day)))
        onRedChange(tempRed)
      }
    }
  }
  // console.log(new Date("7/17/2023"))
  const handlerChange = async () => {
    // console.log(toRed)
    let update = []
    for (let i = 0; i < toRed.length; i++) {
      update.push(toRed[i] + ":3")
      // console.log(toRed+":3")
    }
    for (let i = 0; i < toBlue.length; i++) {
      update.push(toBlue[i] + ":2")
    }
    // console.log({ cid: idCal, dal: update })
    const request = await caled({
      cid: Number(idCal),
      dal: update 
    })
    if (request.ans.stx === "ok") {
      toast.success(request.ans.msg)
      setReset(!reset)
    } else if(request.ans.stx === "nk") {
      toast.error((t) => (<>
          {request.ans.msg}
      </>) )

  }
  }
  return (
    <>
      <Card>
        <Card.Header
          style={
            {
              // "font-size": "20px",
              "fontWeight": "bold",
              "backgroundColor": "#F1F3FA"
            }}>
          <a className="card-title"
            style={
              {
                "fontSize": "15px",
                "color": "#313A46",
                "textAlign": "center",
              }}
          >Calendarización {status.split(":")[1]}</a>
        </Card.Header>
        <Card.Body>
          <div className="row">

            <div className="col">
              <div className="form-group">

                <div className="row">
                  Día(s) de Ejecución
                </div>
                <div className="row" style={{overflowX: "auto", display: "flex"}}>
                  <div className="col">
                    <Calendar
                      multiple
                      value={dayArray}
                      locale={es_th}
                      numberOfMonths={3}
                      arrow={false}
                      disableMonthPicker={true}
                      highlightToday={false}
                      weekStartDayIndex={1}
                      mapDays={({ date, isSameDate, today }) => {
                        let props = {}

                        
                        if (grayDay.includes(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number)) + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {

                          return {
                            onClick: () => console.log(today),
                            style: {
                              backgroundColor: "darkgray",
                              color: "white"
                            },
                          }
                        }
                        if (initialBlue.includes(String(date.year).substring(2, 4) +((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {
                          if (toRed.includes(String(date.year).substring(2, 4) +((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number)) + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {
                            return {
                              style: {
                                backgroundColor: "red",
                                color: "white"
                              },
                              onClick: () => onDayChange(date),
                            }
                          }
                          if (((date.month.number+date.year*12)<(today.month.number+today.year*12) ) || ((date.day < today.day) && (date.month.number == today.month.number)) ){
                            return {
                              style: {
                                backgroundColor: "#3588FC",
                                color: "white"
                              },
                            }
                          }
                          return {
                            style: {
                              backgroundColor: "blue",
                              color: "white"
                            },
                            onClick: () => onDayChange(date),
                          }
                        }

                        if (initialRed.includes(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {
                          if (toBlue.includes(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {
                            return {
                              style: {
                                backgroundColor: "blue",
                                color: "white"
                              },
                              onClick: () => onDayChange(date),
                            }
                          }
                          if (((date.month.number+date.year*12)<(today.month.number+today.year*12) ) || ((date.day < today.day) && (date.month.number == today.month.number)) ){
                            return {
                              // onClick: () => onDayChange(date),
                              style: {
                                backgroundColor: "#FF5B5B",
                                color: "white"
                              },
                            }
                          }
                          return {
                            onClick: () => onDayChange(date),
                            style: {
                              backgroundColor: "red",
                              color: "white"
                            },
                          }

                        }
                        return {
                          style: {
                            backgroundColor: "white",
                            color: "gray",
                          },
                        }

                      }
                      }
                    />
                    
                    <style>{`
                      .rmdp-day span {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: gray;
                      }
                      .rmdp-week-day,.rmdp-header-values  {
                        color: gray;
                        font-family: "Nunito", sans-serif;
                        border-radius: 50%;
                        font-size: 13px;
                        line-height: 28px;
                        font-weight: bold;
                      }


                      .rmdp-calendar {
                        height: max-content;
                        padding: 0px;
                      }

                      .rmdp-day.rmdp-selected span:not(.highlight) {
                        box-shadow: 0 0 0px
                      }

                      .rmdp-arrow-container {
                        border-radius: 0%;
                        cursor: pointer;
                        display: flex;
                        height: 0px;
                        justify-content: center;
                        margin: 0 0px;
                        width: 0px;
                        
                      }
                      .rmdp-arrow {
                        border: solid #0074d9;
                        border-width: 0 0px 0px 0;
                        display: inline-block;
                        height: 0px;
                        margin-top: 0px;
                        padding: 0px;
                        width: 0px;
                      }
                      .rmdp-wrapper {
                        background-color: #fff;
                        border-radius: 0px;
                        direction: ltr;
                        text-align: center;
                        width: max-content;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                      }
                      .rmdp-shadow {
                        box-shadow: 0 0 0px #fff;
                      }
                      .rmdp-week {
                        margin-right: 20px;
                      }
                    `}</style>
                  </div>
                  <div className="col">
                  
                  </div>
                </div>

                <br></br>
                <div className="row">
                  <div className="col-sm-2">
                    <label>Horario de Ejecución </label>
                  </div>
                  <div className="col-sm-1" style={{ marginRight: "0px", padding: "0px" }}>
                    <Select
                      placeholder={"Seleccionar hora"}
                      value={{label:(executionHour.substring(0,2) + ":" + executionHour.substring(2,4)),value:1}}
                      isDisabled={true}
                    />

                  </div>
                  <div className="col-sm-5" style={{ textAlign: "right" }}>

                    <Button onClick={() => handlerChange()} disabled={!(validBlue||validRed)} >Confirmar cambios</Button>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </Card.Body>
        <Card.Header
          style={
            {
              // "font-size": "20px",
              "fontWeight": "bold",
              "backgroundColor": "#F1F3FA"
            }}>
          <a className="card-title"
            style={
              {
                "fontSize": "15px",
                "color": "#313A46",
                "textAlign": "center",
              }}
          >Puntos de Venta</a>
        </Card.Header>
        <Card.Body>

          <br></br>
          <div className="row">
            <div className="col-sm-8">
              <Tabla
                columns={columnas}
                data={locales}
                globalFilter={true}
                tableClass={"table table-centered table-hover  mb-0"}
                theadClassName={"table-light"}
                cellClassName={"p-1"}
                loading={fetching}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

    </>
  )
}
export default CalendarizacionRevision;
