import genie from "Connection/transacciones/genie"
import React from "react"

function useNielsen(){
    const [options, setOptions] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)

    const fetch = async(dependencies = {}) => {
        setIsFetching(true)
        const request = await genie(dependencies);
        if(request.ans.stx === "ok") {
            if(request.ans.nie) {
                const newValues = request.ans.nie.map(item => ({
                    "value": item.cod,
                    "label": item.nam,   
                }))
                setOptions(newValues)
            } else {
                setOptions([])
            }
        }
        setIsFetching(false)

    }



    return {
        options,
        isFetching,
        fetch
    }
}


export default useNielsen
