import { requestData } from "Connection/transacciones"

const cadet = async({cid}) => {
    const jsonBody = {
        hdr: {
            txi: 49,
            req: "cadet",
            ott: true,
        },
        dat: {
            cid: cid
        }
        
    }
    return await requestData(jsonBody)

}

export default cadet;