import useDatosCliente from 'Hooks/Datos/useDatosCliente'
import React from 'react'
import { Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { actualizarInfoPagina } from 'Utils/paginaInfo'
import EditarDatosCliente from "./EditarDatosCliente"
import GestionarCategorias from '../gestionarCategorias/GestionarCategoria'
// import GestionarLocales from '../gestionarLocales/GestionarLocales'
import LocalesAsignados from '../gestionarLocales/LocalesAsignados'

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={".."}>
                            Clientes
                        </Link>
                    </li>
                    <li className="breadcrumb-item active">
                        Perfil Cliente
                    </li>
                </ol>
            </div>
            <h4 className="page-title">Cliente</h4>
        </div>
    )
}

function LayoutUser(props) {
    React.useEffect(()=> actualizarInfoPagina(props),[])
    // const [activeItem] = React.useState("editar");
    const [activeItem, setActiveItem] = React.useState("editar")
    const Toogle = (e) =>{ 
        setActiveItem(e.target.name)
    }


    const location = useLocation()
    const detalleUsuario = useDatosCliente(location.state.clienteID)
    React.useEffect(() => {
        if(location.state !== null) {
            detalleUsuario.refreshData();
        }
    }, []);
    return (
        <div className="container">
            <TituloPagina/>
            <Card>
                <Row>
                    <Col md={2} className="pe-0">
                        <ListGroup variant="flush" defaultActiveKey="1">
                            <ListGroup.Item 
                                action
                                name={"editar"}
                                active={activeItem === "editar"}
                                onClick={Toogle}
                            >
                                <i className='mdi mdi-account me-2' /> 
                                Datos del cliente
                            </ListGroup.Item>
                            <ListGroup.Item 
                                action
                                name={"gestionaCategoria"}
                                active={activeItem ==="gestionaCategoria"}
                                onClick={Toogle}
                            >
                                <i className='mdi mdi-book-open me-2' />
                                Gestionar Categorias
                            </ListGroup.Item>

                            
                            <ListGroup.Item 
                                action
                                name={"gestionarLocales"}
                                active={activeItem === "gestionarLocales"}
                                onClick={Toogle}
                            >
                                <i className='mdi mdi-store me-2' /> 
                                Gestionar Locales
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={10} style={{borderleft: "1px solid #dbdbdb"}}>
                        <Card.Body>
                            {activeItem === "editar" && (
                                <>
                                {detalleUsuario.datos !== null && <EditarDatosCliente datosUsuario={detalleUsuario.datos} />}
                                </>
                            )}
                            {activeItem === "gestionaCategoria" && (
                                <>
                                {detalleUsuario.datos !== null && <GestionarCategorias datosUsuario={detalleUsuario.datos} />}
                                </>
                            )}
                            {activeItem === "gestionarLocales" && (
                                (detalleUsuario? <>
                                {/* {detalleUsuario.datos !== null && <GestionarLocales datosUsuario={detalleUsuario.datos} />} */}
                                {detalleUsuario.datos !== null && <LocalesAsignados idCliente={detalleUsuario.datos.cid} datoCliente={detalleUsuario.datos} />}
                                </>:
                                <Spinner animation="border" size="sm"/>)
                            )}
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}
    export default LayoutUser