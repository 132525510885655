import React from "react"
import { Button, Dropdown, Modal, ModalBody } from "react-bootstrap";
import { AnchorToggle } from "Components/Dropdown/CustomToggles";
import Tabla from "Components/Tabla/Tabla";
import useListaLocales from "Hooks/Listas/useListaLocales";
import FiltrosLocales from "Routes/locales/Tabla/FiltroLocales";
import { useEffect } from "react";
const ID_TOGGLE = "agregar-locales-a-usuario"

function MenuLocalesDeshabilitados ({
    agregarLocal = () => {}, 
    disabledOptions=[],
    locales = []
}) {
    const [localesDeshabilitados, setLocalesDeshabilitados] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)
    React.useEffect(() => {
        if(locales){
            setIsFetching(true)
            let array = []
            let localesArr = JSON.parse(JSON.stringify(locales))
            localesArr.map(local => {
                if(local.sta === 0){
                    array.push(local)
                }
            })
            array.map (local => {
                local.clu = local.clu + 10000000
            })
            setLocalesDeshabilitados(array)
            setIsFetching(false)
        } 
    },[locales])

    const localQuantity = (value) => {
        return value.length
    }
    // const locales = useListaLocales({
    //     dataOption:1
    // })
    // const compFiltros = React.useMemo( () =>
    //     <FiltrosLocales
    //         totalLocales={locales.values.length}
    //         fetchLocales={locales.fetchLocales}    
    //     />
    // ,[locales.fetchLocales, locales.values.length])

    const columnas = React.useMemo(() => [
        {
            Header: 'Código',
            accessor: 'cod',
            thClassName:"p-1",
            tdStyle: {width: "10%"}
        },
        {
            Header: 'Nombre',
            accessor: 'nam',
            thClassName:"ps-1",
            tdStyle: {width: "50%"}
        },
        {
            Header: 'Comuna',
            accessor: 'com',
            thClassName:"ps-1",
            tdStyle: {width: "30%"}
        },
        {
            Header: 'Acciones',
            thClassName: "text-start",
            // accessor: 'lid',
            Cell: props => {
                const {row} = props;
                const itemData = row.original
                const isDisabled = disabledOptions.includes(itemData.lid);
                return(
                    <div className="text-start">
                        <Button 
                            size="sm"
                            // variant={isDisabled?"secondary":"primary"}
                            onClick={() => agregarLocal(row.original)}
                            disabled={isDisabled}

                        >
                            {/* <i className="mdi mdi-plus" /> */} 
                            Asignar
                        </Button>
                    </div>
                )
            }
        }
    ],[disabledOptions]) // columnas, se actualiza cuando 
    
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const handleShow = React.useCallback(() => setIsModalOpen(true),[])
    const handleClose = React.useCallback(() => setIsModalOpen(false),[])

    function ButtonAgregarSeleccion() {
        return (
            <Button 
            onClick={() => agregarSelect(selction)}>
                <i className="mdi mdi-plus me-1" />
                Agregar Seleccion
            </Button>
        )
    
        
    }
    const agregarSelect=(value)=>{
        value.forEach((index)=>{
            if (disabledOptions.includes(index.lid)){}
            else{
            agregarLocal(index)
            }
            // handleClose()
        })
    }

    const [selction, setSelection] = React.useState([]);
    
    return (
        <>
            {(localQuantity(localesDeshabilitados) > 0)? 
                <a href="/#" onClick={(e) => {
                        e.preventDefault()
                        handleShow()
                    }}>
                    Locales Deshabilitados: {localQuantity(localesDeshabilitados)}
                </a>:
                <div>
                    <span className="text-muted me-2">Locales Deshabilitados: {localQuantity(localesDeshabilitados)}</span>
                </div>}
            <Modal show={isModalOpen} onHide={handleClose} size="xl">
                <Modal.Header>
                    <h4>
                        Locales Deshabilitados
                    </h4>
                </Modal.Header>
                <ModalBody>
                    <Tabla 
                        data={localesDeshabilitados}
                        columns={columnas}
                        globalFilter={true}
                        // itemsPerPageOptions={[5,10,]}
                        defaultItemPerPage={5}
                        // titleSection={compFiltros}
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        cellClassName={"p-1"}
                        loading={isFetching}
                        select
                        onSelected={(rows) => setSelection(rows) }
                    >

                    </Tabla>
                </ModalBody>
                <Modal.Footer>
                    <div className="text-end">
                        <Button variant="secondary" 
                        onClick={handleClose}
                        className="me-2">
                            Cerrar
                        </Button>
                        <ButtonAgregarSeleccion/>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default React.memo(MenuLocalesDeshabilitados);