import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function ModalConfirm({listOfTickets,Disabled}) {
    const navigation = useNavigate();
    const handleOnConfirm = () => {
        // localStorage.groupDetailList =  JSON.stringify(listOfTickets.map(ticket => ticket.tid));
        localStorage.groupDetailList =  (listOfTickets.map(ticket => ticket.tid));
        navigation("GroupDetail")
    }
    return (
        <div className="modal-footer">
            {/* {JSON.stringify(listOfTickets.tid)} */}
            <Button onClick={handleOnConfirm} disabled={!Disabled} >
                Agrupar Tickets
            </Button>
        </div>
    )
}

export default ModalConfirm