import React from 'react'
import { customValidateRut, format, rutRegex } from 'Utils/rut'

function useInputRut () {
    const [rut, setRut] = React.useState("")
    const [isRutValid, setIsRutValid] = React.useState(undefined)
    const handleOnChange = (e) => {
        const inputRut = e.target.value?.toUpperCase()
        if (rutRegex.test(inputRut)) {
            setRut(inputRut)
        }
    }
    const formatInput = (e) => {
        const inputRut = e.target.value?.toUpperCase()
        setRut(format(inputRut, {dots: false}))
    } 
    const validateInput = (e) => {
        const inputRut = e.target.value?.toUpperCase()
        setIsRutValid(customValidateRut(inputRut))
    }

    return {
        rut,
        setRut,
        isRutValid,
        handleOnChange,
        formatInput,
        validateInput,

    }
}

export default useInputRut