import { requestData } from "Connection/transacciones"

export const gezon = async(dep = {}) => {
    const jsonBody = {
        hdr: {
            txi: 68,
            req: "gezon",
        },
        dat: {
            ful: 1,
            hol: dep.hol,
            ret: dep.ret,
            zon: dep.zon,
            nie: dep.nie,
            reg: dep.reg,
        }
    }
    return await requestData (jsonBody)
}
export default gezon;