import React from "react"

function DivControl ({
    show=true,
    persistOnHide=true,
    className="",
    children,
}) {

    const [render, setRender] = React.useState(show)

    React.useEffect(() => {
        if(persistOnHide === true) {
            if(show === true) setRender(true)
        } else {
            setRender(show)
        }
    },[show, persistOnHide])


    return (
        <div className={className} style={{display: show === true? "block": "none"}}>
            {render && children}
        </div>
    )
}

export default React.memo(DivControl)