import React from 'react';
import ReactDOM from 'react-dom';

// import './index.css';
// import './App.css';

// // thir party css
import './Assets/css/vendor/dataTables.bootstrap4.css'
import './Assets/css/vendor/jquery-jvectormap-1.2.2.css'
import './Assets/css/vendor/responsive.bootstrap4.css'
import './Assets/css/vendor/buttons.bootstrap4.css'
import './Assets/css/vendor/select.bootstrap4.css'
// // App css
// import './assets/css/icons.min.css'
import './Assets/css/app-creative.min.css'
// import './assets/css/app-creative-dark.min.css'
import App from './App';

import { RecoilRoot } from 'recoil';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

