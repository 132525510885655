import { requestData } from "Connection/transacciones"

const cldat = async (idCliente) => {
    const jsonBody = {
        hdr:	{	
        "txi": 84,
        "req": "cldat",
		"ott": true,
	},	
    dat:	{	
        cid: idCliente
	}	
    }
    return await requestData(jsonBody)
}

export default cldat;