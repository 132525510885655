import { requestData } from "Connection/transacciones"
// import { fechaDefault, limpiarFecha } from "Utils/funcionesFechaHora"

const prdel = async (data) => {
    const jsonBody = {
        hdr: {
            txi: 97,
            req: "prdel",
            ott: true,
        },
        dat: {
            bid:data
        }
    }
    // console.log(jsonBody,data)
    // console.log(data)
    return await requestData(jsonBody)
}

export default prdel;