// import React from 'react';
import { atom, useRecoilValue, useRecoilState } from "recoil"
import { taskr } from "Connection/transacciones";
import React, { useState, useEffect} from "react";
import DetalleTareaRequerimientos from "./_ResumenTareas/TareaDetalleModalRequerimientos";


export const tareaSeleccionadoState = atom({
    key:"GroupTareaSeleccionadoState",
    default: undefined
})



function ModalOpcionesTickets(){
    const idTarea = useRecoilValue(tareaSeleccionadoState)
    const [tadet, setTadet] = useState(null);

    const getTadet = async(idTicket) => {
    setTadet(null) // limpia los datos de tadet
    
    if (idTicket){
    const data = await taskr({idDetalle:idTicket})
    setTadet(data)
    }
    }
    useEffect(() => {
        if(idTarea !== 0 && idTarea !== null){
            getTadet(idTarea)
        }
        
    }, [idTarea]) 
      
    return(
        <>
                    {tadet &&
                        <DetalleTareaRequerimientos tadet={tadet} />
                        }
        </>
    )
}
export default ModalOpcionesTickets