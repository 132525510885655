import { requestData } from "Connection/transacciones"

const taskt = async(type) => {

    const jsonBody = {	
        hdr:	{	
            txi: 30,
            req: "taskt",
            ott: true,
        },	
        dat:{	
            pag: 1,         // pagina solicitada (default 1) 
            sta: type.type
        }	
    }
    return await requestData(jsonBody)
}

export default taskt;