import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import Precios from './precios';

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={"/Presupuesto"} >Presupuesto</Link>
                    </li>
                    <li className="breadcrumb-item active">Ver Presupuesto</li>
                </ol>
            </div>
            <h4 className="page-title">Ver Presupuesto</h4>
        </div>
    )
    }

function PaginaPrecios(props) {
        useEffect(() => { actualizarInfoPagina(props) }, []);  // eslint-disable-line
        return (
            <div className='container'>
                <TituloPagina />
                <Precios />
                {/* <Sample /> */}
                
            </div>
        )
}
export default PaginaPrecios