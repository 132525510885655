import geret from "Connection/transacciones/geret"
import React from "react"

function useRetails(){
    const [options, setOptions] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)

    const fetch = async(dependencies = {}) => {
        setIsFetching(true)
        const request = await geret(dependencies);
        if(request.ans.stx === "ok") {
            if(request.ans.ret) {
                const newValues = request.ans.ret.map(item => ({
                    "value": item.cod,
                    "label": item.nam,   
                }))
                setOptions(newValues)
            } else {
                setOptions([])
            }
        }
        setIsFetching(false)

    }



    return {
        options,
        isFetching,
        fetch
    }
}


export default useRetails
