import React, {useEffect, useState} from 'react'
import { useRef } from 'react';
import { Button, CloseButton, Collapse, Form, InputGroup } from 'react-bootstrap'

function Alternativa (props) {
    return(
        <>
            <Form.Control
                maxLength={128}
                value={props.value}
                onChange={(e)=> props.onChange(e, props.index)}
                id={props.id}
                type='text'
                placeholder={`Ingrese opción ${props.index + 1}`}
                required
            />
        </>
    )
}

function Condicional({formulario, index}) {
    useEffect(() => {
        // Si no tiene alternativa las añade
        if(!('alternativa1' in formulario.values.listaRequerimientos[index])) { 
            const toUpdateReq = [...formulario.values.listaRequerimientos]
            toUpdateReq[index] = {
                id: toUpdateReq[index].id,
                tipo: toUpdateReq[index].tipo,
                titulo: toUpdateReq[index].titulo, // mantiene el titulo
                alternativa1: {value: ''},
                alternativa2: {value: ''},
                next: "",
                next2: ""
            }
            formulario.setFieldValue("listaRequerimientos",toUpdateReq)
        }
        console.log()
    }, []);
 
    const onTitleChange = (e) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index] = {
            id: toUpdateReq[index].id,
            tipo: toUpdateReq[index].tipo,
            titulo: e.target.value, // cambio de titulo
            alternativa1: toUpdateReq[index].alternativa1,
            alternativa2: toUpdateReq[index].alternativa2,
            next:toUpdateReq[index].next,
            next2:toUpdateReq[index].next2,
        }
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }

    const currentId = useRef(999); // parte en 1 pero ya tiene la opcion

    const onChangeOpcion = (e) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index].alternativa1.value = e.target.value 
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }
    const onChangeOpcion2 = (e) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index].alternativa2.value = e.target.value 
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }
    
    const onChangeOpcion3 = (e) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index].next = e.target.value 
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }
    
    const onChangeOpcion4 = (e) => {
        const toUpdateReq = [...formulario.values.listaRequerimientos]
        toUpdateReq[index].next2 = e.target.value 
        formulario.setFieldValue("listaRequerimientos",toUpdateReq)
    }
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className=''>
            <InputGroup className=''>
                <Form.Control
                    value={formulario.values.listaRequerimientos[index].titulo}
                    onChange={onTitleChange}
                    type='text'
                    placeholder={`Ingrese requerimiento ${index + 1}`}
                    required
                    maxLength={128}
                />
                <Button 
                    variant='outline-secondary'
                    onClick={() => setIsOpen(!isOpen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={isOpen}
                >
                    {isOpen?'▲': '▼'}
                </Button>

            </InputGroup>
            

            
            <Collapse in={isOpen}>
                <div className='mt-2'>
                    <div className="row mb-1"  >
                        <div className="col  d-flex align-items-center">
                            <strong>
                                Opción 1
                            </strong>
                        </div>
                        <div className="col-6">
                            <Form.Control
                                maxLength={128}
                                // value={item.value}
                                onChange={(e)=> onChangeOpcion(e)}
                                id={1}
                                type='text'
                                placeholder={`Ingrese opción 1`}
                                required
                            />
                        </div>
                        <div className="col-3">
                            <Form.Control
                                maxLength={128}
                                // value={item.value}
                                onChange={(e)=> onChangeOpcion3(e)}
                                id={3}
                                type='number'
                                placeholder={`N° Requerimiento`}
                                required
                            />  
                        </div>     
                    </div>
                    <div className="row mb-1"  >
                        <div className="col  d-flex align-items-center">
                            <strong>
                                Opción 2
                            </strong>
                        </div>
                        <div className="col-6">
                            <Form.Control
                                maxLength={128}
                                // value={item.value}
                                onChange={(e)=> onChangeOpcion2(e)}
                                id={2}
                                type='text'
                                placeholder={`Ingrese opción 2`}
                                required
                            />
                        </div>
                        <div className="col-3">
                            <Form.Control
                                maxLength={128}
                                // value={item.value}
                                onChange={(e)=> onChangeOpcion4(e)}
                                id={4}
                                type='number'
                                placeholder={`N° Requerimiento`}
                                required
                            />  
                        </div>                 
                    </div>

                </div>
            </Collapse>
            

        </div>
    )
}
 
export default Condicional