import { requestData } from "Connection/transacciones"

const weall = async({nType}) => {
    const jsonBody = {
        "hdr": {
                "txi": 56,
                "req": "weall",
                "ott": true,
        },
        "dat": {
            "ust": nType
            // "ust": "com"
                // "usr": sessionStorage.usrLogin
        }
    }

return await requestData(jsonBody)
}

export default weall;