import { requestData } from "Connection/transacciones"
// import { fechaDefault, limpiarFecha } from "Utils/funcionesFechaHora"

const prcal = async (date) => {
    const jsonBody = {
        hdr: {
            txi: 95,
            req: "prcal",
            ott: true,
        },
        dat: {
            ame:date
        }
    }
    console.log(jsonBody)
    return await requestData(jsonBody)
}

export default prcal;