import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import TablaUsuariosApp from './TablaUsuariosApp';

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Gestión Usuarios App</li>
                </ol>
            </div>
            <h4 className="page-title">Gestión de Usuarios App</h4>
        </div>
    )
}

function PaginaGestionUsuariosApp(props) {
    useEffect(() => { actualizarInfoPagina(props) }, []); // eslint-disable-line

    return (
        <div className='container'>
            <TituloPagina />
            <TablaUsuariosApp />
        </div>
            
    )
}

export default PaginaGestionUsuariosApp