import { requestData } from "Connection/transacciones"
import { fechaDefault, limpiarFecha } from "Utils/funcionesFechaHora"

const taskw = async ({
    idTicket,
    desdeFecha = fechaDefault(),
    hastaFecha = fechaDefault(),
    // vid,
    // sid,
}) => {
    console.log(desdeFecha, hastaFecha)
    const jsonBody = {
        hdr: {
            txi: 47,
            req: "taskw",
            ott: true,
        },
        dat: {
            tid: parseInt(idTicket),
            ind: desdeFecha !== ''? parseInt(limpiarFecha(desdeFecha)):'null',  // desde
            end: hastaFecha !== ''? parseInt(limpiarFecha(hastaFecha)):'null',  // hasta     
            // vid: parseInt(vid), // id vendedor  
            // sid: parseInt(sid), // id supervisor
        }
    }
    console.log(jsonBody)
    return await requestData(jsonBody)
}

export default taskw;