import React from 'react'
import Navbar  from './Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import ZolbitFooter from './ZolbitFooter'

function LayoutWrapper() {
    return (
        <div className="content-page">
            <div className="content">
                <Navbar />
                    <Outlet/>
                <ZolbitFooter/>
            </div>
        </div>
    )
}

export default LayoutWrapper