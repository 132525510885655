import React, { useState } from 'react';
import { useAsyncDebounce } from "react-table"

function GlobalFilter ({filter, setFilter}) {
    const [value, setValue] = useState(filter);

    const onChangeHandler = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 500)

    return (
        <span>
                {/* <label htmlFor="global-filter"> 
                </label> */}
                
                <div className='input-group'>
                    <input 
                        className='form-control '
                        placeholder='Buscar...'
                        id="global-filter"
                        type="text" 
                        value={value || ''}
                        onChange={(e) => {
                            setValue(e.target.value);
                            onChangeHandler(e.target.value);
                        }}
                    />
                    <span className=""></span>
                    <span className="input-group-text bg-secondary border-secondary text-white pt-0 pb-0 d-flex align-items-center">
                        <i className="mdi mdi-magnify search-icon font-18"></i>
                    </span>
                </div>
      </span>
  )
};

export default GlobalFilter