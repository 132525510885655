import React, { useState } from "react";
import Tabla from "Components/Tabla/Tabla";
import FiltroTareas from "./FiltroTareas";
import { Link } from 'react-router-dom';
import { useSetRecoilState } from "recoil"
import useListaTareas from "Hooks/Listas/useListaTareas";
import { formatearHoraFecha} from "Utils/funcionesFechaHora";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { downloadExcel } from "react-export-table-to-excel";
import Exportxls from "./export";



import { tareaSeleccionadoState } from './ModalTask';
import { isModalTareaOpenState, tareaNombreState } from "./_ResumenTareas/TareaDetalleModalRequerimientos";

function TablaAgrupacion () {

    const setIsTareaModalOpen = useSetRecoilState(isModalTareaOpenState)
    const setTareaSeleccionado = useSetRecoilState (tareaSeleccionadoState)
    const nombreTareaSeleccionado = useSetRecoilState (tareaNombreState)
    const abrirModal = (idTarea) => {
        setIsTareaModalOpen (true)
        setTareaSeleccionado(idTarea)
        // console.log("idt",idTarea)
    }


    const groupTareas = useListaTareas({
        dataOption:1
    })
    // console.log("loc",groupTareas.values)

    const compFiltros = (
        <FiltroTareas
            totalLocales={groupTareas.values.length}
            fetchLocales={groupTareas.fetchLocales}
        />
    )
    // mdi-record
    const columnas =React.useMemo(() => [
        {
            Header: "Fecha/Hora",
            accessor: item => formatearHoraFecha(item.wen),
            thClassName: "ps-1"
        },{
            Header: "Ubicacion",
            accessor: "ubi",
            Cell: (props) => {
                const {value, row} = props
                 return (
                    <>
                    { (value === "e")?
                    <OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">En el local</Tooltip>}>
                        <i 
                            className={"mdi mdi-map-marker-radius"}
                            style={{fontSize:"25px", color:"#638AFC", display:"flex", justifyContent: "center"}}
                            rel="tooltip"
                            data-toggle="tooltip" 
                            data-placement="top" >
                        </i></OverlayTrigger> 
                    :<OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">Fuera del local</Tooltip>}><i 
                        className={"mdi mdi-map-marker-off"}
                        style={{fontSize:"25px", color:"#FC6363", display:"flex", justifyContent: "center"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" >
                    </i></OverlayTrigger> }
                    </>
                 )
             },
            thClassName: "ps-1"
        },{
            Header: "Nombre Tarea",
            accessor: "tna",
            Cell: (props) => {
                const {value, row} = props
                 return (
                    <Link  to="#" onClick={(e)=>{
                        e.preventDefault()
                        nombreTareaSeleccionado(row.original.tna)
                        abrirModal(row.original.idt)
                        // console.log(row.original.idt)
                    }}> 
                    {value}
                    </Link>
                 )
             },
            thClassName: "ps-1"
        },{
            Header: "Retail",
            accessor: "ret",
            thClassName: "ps-1"
        },{
            Header: "Nombre Local",
            accessor: "loc",
            thClassName: "ps-1"
        },{
            Header: "Region",
            accessor: "reg",
            thClassName: "ps-1"
        },{
            Header: "Comuna",
            accessor: "com",
            thClassName: "ps-1"
        },{
            Header: "KAM",
            accessor: "kam",
            thClassName: "ps-1"
        },{
            Header: "KAS",
            accessor: "kas",
            thClassName: "ps-1"
        }
    ],[])
    
    const setDisabled = React.useMemo(()=>{
        if (groupTareas.values.length === 0) return true
        else return false
      },[groupTareas])

    return (
        <>
            <div className="card">
                <div className="card-body">
                   <Tabla
                        data={groupTareas.values}
                        columns={columnas}
                        globalFilter={true}
                        titleSection={compFiltros}
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        loading={groupTareas.isFetching}
                        cellClassName={"p-1"}
                    />
                </div>
            </div>
            <Exportxls tareas={groupTareas} isDis={setDisabled}> </Exportxls>
            {/* <div className="modal-footer">
                <Button onClick={asd}>
                    Exportar
                </Button>
            </div> */}
        </>
    )

}
export default TablaAgrupacion;