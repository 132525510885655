import React, { useState, useEffect } from "react";
import { formatearFechaHora } from "Utils/funcionesFechaHora";
import { formatearHoraFecha} from "Utils/funcionesFechaHora";
import tasko from "Connection/transacciones/tasko.js";
import {Modal} from "react-bootstrap";
import { atom, useRecoilValue, useRecoilState } from "recoil"
import { useSetRecoilState } from "recoil"
import { Form, Button } from 'react-bootstrap'
import { backend_request } from "Connection/backend_reduce";
import FotoTareaTicket from "./FotoTareaTicket";
import Tabla from 'Components/Tabla/Tabla';
import toast from 'react-hot-toast';
import { taskFetching } from "../TablaTareas";
import MostrarFotos from "./MostrarFotos";
const thClassName= "ps-1"


export const isModalTareaOpenState = atom({
    key: "isModalTareaOpen",
    default: false
})

export const tareaNombreState = atom({
    key:"TareaNombreState",
    default: undefined
})

const SeleccionMultiple = ({alternativasSeleccionadas}) => {
    return(
        <>
            {alternativasSeleccionadas.split("~").map((item,index)=>(
                <li key={"alt-"+index}>
                    {item}
                </li>
            ))}
        </>
    )
}


function Foto ({idRequerimiento, idTarea, graph}) {
    return(
        <FotoTareaTicket 
            idTicket={idTarea}
            idRequerimiento={idRequerimiento}
            arrGraph={graph}
        />
    )
}

const FechaHora = ({fechaYHora}) => {
    return(formatearHoraFecha(fechaYHora)
        
    )
}


function SwitchResponse({type, response, item, ticketId}) {
    switch (type) {
        case 1:
            return response
        case 2:
            return response
        case 3:
            return <SeleccionMultiple alternativasSeleccionadas={response}/>
        case 4:
            return response
        case 7:
            return "Reposición realizada"
        case 5:
            return <Foto 
                        idTarea={ticketId}
                        idRequerimiento={item.qid}
                        // graph={arrGraph}
                        // fotoBase64={item.ant} 
                    />
        case 6:
            return <FechaHora  fechaYHora={response} />
        default:
            return response
        
    }
}

function DetalleTareaRequerimientos ({tadet}) {
    let graph=[]
    for( let i=0; i< tadet.ans.tas.length; i++){
        if(tadet.ans.tas[i].aty===5){
            graph.push(tadet.ans.tas[i].qid)
        }
    }
    tadet.ans.graph=graph
    // console.log("tades+t",tadet.ans)

    // const [fetching, setIsFetching] = React.useState(false);
    const [detalles, setDetalle] = React.useState(tadet.ans.cdr);
    const [selection, setSelection] = React.useState([])
    const [isOpen, setIsOpen] = useRecoilState(isModalTareaOpenState)
    // const setTask = useSetRecoilState(taskFetching)
    // const idTarea = useRecoilValue(tareaSeleccionadoState)
    const nombreTarea = useRecoilValue(tareaNombreState)
    const [taskFetch, setTaskFetch] = useRecoilState(taskFetching) 
    // console.log("isop",isOpen)

    const onChangeDetalle = (value) => setDetalle(value.target.value);

    const detalleValido = React.useMemo(()=> {
        if(detalles === "") return undefined
        else return true;
    },[detalles])

    
    const seleccionValida = React.useMemo(()=> {
        if(selection.length === 0) return undefined
        else return true;
    },[selection])


    const handleSubmit = async(response) => {
        const request = await tasko({
            idTarea: tadet.ans.tid,
            resp: response,
            detail: detalles,
            ob: selection
        })
        
        if(request.ans.stx === "ok") {
            toast.success(request.ans.msg)
            setTaskFetch(!taskFetch)
            handleClose()
        } else if(request.ans.stx === "nk") {
            toast.error((t) => (<>
                {request.ans.msg}
            </>) )
        }
            // handleClose()
        // setTask(false)
        // console.log("ha")
    }
    
    
    const handleClose = () => {
        setIsOpen(false)
    }
    
    let thiscolumn =[
        {
            Header: 'Requerimientos',
            accessor: 'qid',
            thClassName
        },{
            Header: 'Descripción',
            accessor: 'tiq',
            thClassName
        },{
            Header: 'Respuesta',
            accessor: 'aty',
            thClassName,
            Cell: props => {
                const {value,row}=props;
                return <SwitchResponse type={value} response={row.original.ant} item={row.original} ticketId={tadet.ans.tid}/>
            }
        }
    ]

    if (tadet.ans.tat===5){
        thiscolumn.push({
                Header: 'Estado',
                accessor: 'obj',
                thClassName,
                Cell: props => {
                    const {value,row}=props;
                    
                    if (value===2){
                        return (<>Objetado</>)}
                    else if (value===1){
                        return (<>Aceptado</>)}
                    else{
                        return (<>Nulo</>)}
                }
            })
    }
    // else if( tadet.ans.tat===8 ){
    //     thiscolumn.push({
    //             Header: 'Estado',
    //             accessor: 'obj',
    //             thClassName,
    //             Cell: props => {
    //                 const {value,row}=props;
                    
    //                 if (value===2){
    //                     return (<>Rechazado</>)}
    //                 else if (value===1){
    //                     return (<>Aceptado</>)}
    //                 else{
    //                     return (<>Nulo</>)}
    //             }
    //         })

    // }
    const columnas = React.useMemo(() => thiscolumn ,[tadet])



    const mostrarFotos = (tadet)=>{

    }

    //Enviadas----------v
    if(tadet.ans.tat===4){
        return(
        <>
        <Modal         
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Tarea {nombreTarea} </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className="row text-center">
                        <h3>{tadet && tadet.ans.lid.split(":")[2]}</h3>                                
                    </div>
                    <div className="row text-center">
                        <h3>{tadet && tadet.ans.adr}</h3>                                
                    </div>
                    <div className="row text-center">
                        <h3>{tadet && tadet.ans.reg}</h3>
                    </div>
                    <Tabla
                        columns={columnas}
                        globalFilter={true}
                        data={tadet.ans.tas}
                        titleSection={
                            <>
                                {(tadet.ans.graph.length > 1)? <div style={{"textAlign":"left"}}>
                                    {console.log("tadet",tadet)}
                                    <MostrarFotos idTicket={tadet.ans.tid} arrGraph={tadet.ans.graph}/>
                                </div>:<></> }
                            </>
                            }
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        cellClassName={"p-1"}
                        select
                        onSelected={(rows) => setSelection(rows.map(row => row.qid))}
                    />
                    <div className="row mb-2">
                        <div className='col-2'>
                            <label htmlFor="descripcion" className="col-form-label">
                                Descripción
                            </label>
                        </div>
                        <Form.Control
                            as='textarea'
                            id='descripcion' 
                            label="Detalle"
                            rows={3}
                            onChange={onChangeDetalle} 
                            type='text'
                            maxLength={512}
                            required>
                        </Form.Control>
                    </div>
                <Modal.Footer>
                    <Button  variant="secondary" onClick={()=>handleClose()}>Cerrar</Button> 
                    <Button variant="success" onClick={()=>handleSubmit(1)}>Aceptar</Button>
                    <Button  variant="warning" disabled={!(detalleValido && seleccionValida)} onClick={()=>handleSubmit(2) }>Objetar</Button> 
                    <Button  variant="danger" disabled={!(detalleValido)} onClick={()=>handleSubmit(3) }>Rechazar</Button> 
                </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
        
    )}
    //En Revision----------v
    else if(tadet.ans.tat===5){return( 
        <>
        <Modal         
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Tarea {nombreTarea} </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className="row text-center">
                        <h3>Tarea Objetada</h3>                                
                    </div>
                    <div className="row text-center">
                        <h4>{tadet && tadet.ans.lid.split(":")[2]}</h4>                                
                    </div>
                    <div className="row text-center">
                        <h4>{tadet && tadet.ans.adr}</h4>                                
                    </div>
                    <div className="row text-center">
                        <h4>{tadet && tadet.ans.reg}</h4>
                    </div>
                    <Tabla
                        columns={columnas}
                        globalFilter={true}
                        data={tadet.ans.tas}
                        titleSection={
                            <>
                                {(tadet.ans.graph.length > 1)? <div style={{"textAlign":"left"}}>
                                    {console.log("tadet",tadet)}
                                    <MostrarFotos idTicket={tadet.ans.tid} arrGraph={tadet.ans.graph}/>
                                </div>:<></> }
                            </>}
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        cellClassName={"p-1"}
                    />
                    <div className="row mb-2">
                        <div className='col-2'>
                            <label htmlFor="descripcion" className="col-form-label">
                                Descripción
                            </label>
                        </div>
                        <Form.Control
                            value={detalles}
                            as='textarea'
                            id='descripcion' 
                            label="Detalle"
                            rows={3}
                            onChange={onChangeDetalle} 
                            type='text'
                            maxLength={512}
                            required>
                        </Form.Control>
                    </div>
                <Modal.Footer>
                    <Button  variant="secondary" onClick={()=>handleClose()}>Cerrar</Button> 
                    <Button variant="success" onClick={()=>handleSubmit(1)}>Aceptar</Button>
                    <Button  variant="danger" disabled={!(detalleValido)} onClick={()=>handleSubmit(3) }>Rechazar</Button> 
                </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
        
    )}
    
    //Rechazadas----------v
    else if (tadet.ans.tat===8){return( 
        <>
        <Modal         
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Tarea {nombreTarea} </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className="row text-center">
                        <h3>Tarea Rechazada</h3>                                
                    </div>
                    <div className="row text-center">
                        <h4>{tadet && tadet.ans.lid.split(":")[2]}</h4>                                
                    </div>
                    <div className="row text-center">
                        <h4>{tadet && tadet.ans.adr}</h4>                                
                    </div>
                    <div className="row text-center">
                        <h4>{tadet && tadet.ans.reg}</h4>
                    </div>
                    <Tabla
                        columns={columnas}
                        globalFilter={true}
                        data={tadet.ans.tas}
                        titleSection={
                            <>
                                {(tadet.ans.graph.length > 1)? <div style={{"textAlign":"left"}}>
                                    {console.log("tadet",tadet)}
                                    <MostrarFotos idTicket={tadet.ans.tid} arrGraph={tadet.ans.graph}/>
                                </div>:<></> }
                            </>}
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        cellClassName={"p-1"}
                    />
                    <div className="row mb-2">
                        <div className='col-2'>
                            <label htmlFor="descripcion" className="col-form-label">
                                Descripción
                            </label>
                        </div>
                        <h5> {tadet.ans.cdr}</h5>
                    </div>
                <Modal.Footer>
                    <Button  variant="secondary" onClick={()=>handleClose()}>Cerrar</Button>
                </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
        
    )}
    //Eliminadas----------v
    else {return( 
        <>
        <Modal         
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Tarea {nombreTarea} </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className="row text-center">
                        <h3>Tarea Eliminada</h3>                                
                    </div>
                    <div className="row text-center">
                        <h4>{tadet && tadet.ans.lid.split(":")[2]}</h4>                                
                    </div>
                    <div className="row text-center">
                        <h4>{tadet && tadet.ans.adr}</h4>                                
                    </div>
                    <div className="row text-center">
                        <h4>{tadet && tadet.ans.reg}</h4>
                    </div>
                    <Tabla
                        columns={columnas}
                        globalFilter={true}
                        data={tadet.ans.tas}
                        titleSection={""}
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        cellClassName={"p-1"}
                    />
                    <div className="row mb-2">
                        <div className='col-2'>
                            <label htmlFor="descripcion" className="col-form-label">
                                Descripción
                            </label>
                        </div>
                        <h5> {tadet.ans.cdr}</h5>
                    </div>
                <Modal.Footer>
                    <Button  variant="secondary" onClick={()=>handleClose()}>Cerrar</Button>
                </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
        
    )}
}

export default DetalleTareaRequerimientos;