import { getProfileOptions } from "Authentication/Session";
import React, { useEffect } from "react";
import { actualizarInfoPagina } from "Utils/paginaInfo";
import {logout} from "Authentication/Session"
import  { Spinner } from "react-bootstrap"
import AccionesUsuario from "./AccionesUsuario";
import AccionesAdministraciónRepoflex from "./AccionesAdministraciónRepoflex";
function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item active">Dashboard</li>
                </ol>
            </div>
            <h4 className="page-title">Dashboard</h4>
        </div>
    )
}

// primary
// yellow
// purple
// cyan
// red
// green
// orange
// blue
// SeaGreen



function PaginaDashboard ({options}) {
    

    
    
    const CREAR_TICKET =                React.useRef(Number(options[0]) === 1)
    const TICKETS =                     React.useRef(Number(options[1]) === 1)
    const CALENDARIZACIONES =           React.useRef(Number(options[2]) === 1)

    const GESTIONAR_SOLICITUDES =       React.useRef(Number(options[3]) === 1)
    const GESTIONAR_USUARIOS_WEB =      React.useRef(Number(options[4]) === 1)
    const GESTIONAR_USUARIOS_APP =      React.useRef(Number(options[5]) === 1)
    const LOCALES =                     React.useRef(Number(options[6]) === 1)
    const CLIENTES =                    React.useRef(Number(options[7]) === 1)
    const GESTIONAR_USUARIOS_COM =      React.useRef(Number(options[8]) === 1)
    const RTAREAS =                    React.useRef(Number(options[9]) === 1)
    const REPORTE =                    React.useRef(Number(options[10]) === 1)
    const PRECIOS =                    React.useRef(Number(options[11]) === 1)
    
    // console.log(LOCALES)
    return(

        <div className="container">
            <TituloPagina/>
            <AccionesUsuario 
                crearTicket={CREAR_TICKET.current}
                tickets={TICKETS.current}
                calendarizaciones={CALENDARIZACIONES.current}
                reporte={REPORTE.current}
            />
            <AccionesAdministraciónRepoflex 
                gestionSolicitudes={GESTIONAR_SOLICITUDES.current}
                gestionUsuariosWeb={GESTIONAR_USUARIOS_WEB.current}
                gestionUsuariosCom={GESTIONAR_USUARIOS_COM.current}
                gestionUsuariosApp={GESTIONAR_USUARIOS_APP.current}
                locales={LOCALES.current}
                clientes={CLIENTES.current}
                rTareas={RTAREAS.current}
                precios={PRECIOS.current}
            />
        </div>
    )
}

function LayoutDashboard (props) {
    useEffect(()=>{ actualizarInfoPagina(props)},[props])
    const options = React.useRef(getProfileOptions())
    if (options.current === undefined) {
        logout();
        return (
            <div>
                <Spinner animation="border"/>
            </div>
        )
    } 
    return (<PaginaDashboard options={options.current} />)
}


export default LayoutDashboard;