import React from 'react';
import BottonToggleFiltro from 'Components/Botones/BotonToggleFiltro';
import { Button, Collapse } from 'react-bootstrap';
import MultiSelectHolding from 'Components/Filtros/MultiSelectHolding';
import MultiSelectRetails from 'Components/Filtros/MultiSelectRetails';
import { filtrosValores } from 'Utils/filtrosHandler';
import MultiSelectLocales from 'Components/Filtros/MultiSelectLocales';
import MultiSelectNielsen from 'Components/Filtros/MultiSelectNielsen';
import MultiSelectRegion from 'Components/Filtros/MultiSelectRegion';
import MultiSelectCiudad from 'Components/Filtros/MultiSelectCiudad';
import MultiSelectComuna from 'Components/Filtros/MultiSelectComuna';
import MultiSelectZonaGeografica from 'Components/Filtros/MultiSelectZonaGeografica';
import useFiltros from 'Hooks/Custom/useFiltros';
import { useLocation } from 'react-router-dom';

function FiltrosLocales({ totalLocales = 0, fetchLocales }) {
    // Vista de filtros
    const [filtrosVisibles, setFiltrosVisibles] = React.useState(true);            // State para mostrar el collapse
    const toggleFiltros = () => setFiltrosVisibles(!filtrosVisibles);
    const location = useLocation()

    const {
        // filtrosAplicados,
        isFiltrosUpdated,
        filtrosSelected, 
        handleOnChange, 
        aplicarFiltros,
        reiniciarFiltros
    } = useFiltros({
        cacheKey: "f-locales",
        session: location.key
    })

 
    return (
        <div>
            <div className='d-flex align-items-baseline mb-2'>
                {/* <div className='me-2'>
                </div> */}
                <span className='me-2'>
                    Mostrando <strong>{totalLocales}</strong> resultados
                </span>
                <span className='me-2'>
                    <BottonToggleFiltro onClick={toggleFiltros} >
                        Filtros{" "}
                    </BottonToggleFiltro>
                </span>
                <span className='text-warning ms-2'>
                    {!isFiltrosUpdated &&
                        <h4>
                            <span className="badge badge-danger-lighten">Filtros sin aplicar</span>
                        </h4>
                    }
                </span>
                
            </div>
            <Collapse in={filtrosVisibles} > 
                <div className=''>
                    <div className="row">
                        <div className='col-3 mb-2'>
                            Holding
                            <MultiSelectHolding
                                selected={filtrosSelected.hol}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="hol"
                            />
                            {/* <FiltroHolders filtroOnChange={filtroOnChange} /> */}
                        </div>
                        <div className='col-3 mb-2'>
                            Retails
                            <MultiSelectRetails 
                                selected={filtrosSelected.ret}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="ret"
                            />
                            
                        </div>
                        <div className='col-3 mb-2'>
                            Zona Geográfica
                            <MultiSelectZonaGeografica 
                                selected={filtrosSelected.zon}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="zon"
                            />
                        </div>
                        <div className='col-3 mb-2'>
                            Zona Nielsen
                            <MultiSelectNielsen 
                                selected={filtrosSelected.nie}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="nie"
                            />
                        </div>
                        
                        
                        <div className='col-3 mb-2'>
                            Regiones
                            <MultiSelectRegion 
                                selected={filtrosSelected.reg}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="reg"
                            />
                        </div>
                        <div className='col-3 mb-2'>
                            Ciudades
                            <MultiSelectCiudad 
                                selected={filtrosSelected.ciu}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="ciu"
                            />
                        </div>
                        <div className='col-3 mb-2'>
                            Comunas
                            <MultiSelectComuna 
                                selected={filtrosSelected.com}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="com"
                            />
                        </div>
                        <div className='col-3 mb-2'>
                            Locales
                            <MultiSelectLocales 
                                selected={filtrosSelected.loc}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="loc"
                            />
                        </div>
                        
                        
                    </div>
                    <div className='text-end mb-2' >
                        <Button type='button' onClick={reiniciarFiltros} className='btn btn-secondary me-2'>
                            Limpiar filtros
                        </Button>
                        <Button type='button' variant='info' onClick={() => aplicarFiltros(fetchLocales)} disabled={isFiltrosUpdated }>
                            Aplicar filtros
                        </Button>
                    </div>

                    
                </div>
                
            </Collapse>
            

        </div>
    )
}

export default FiltrosLocales;

