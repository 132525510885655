import React from "react";
import { formatearFechaHora } from "Utils/funcionesFechaHora";
const iconStyle = {fontSize: "20px"}

function TiempoTarea ({sumItem}) {
    return(
        <div>
            <h5>
                Finalización de tarea
            </h5>

            
            <h3>
                {sumItem.txt}
            </h3>
            <div className="row">
                <div className="col-6 text-center">
                    <i className="uil uil-stopwatch text-muted" style={iconStyle}></i>
                    <h4>
                        <span>
                            {formatearFechaHora(sumItem.min.toString())}
                        </span>
                    </h4>
                    <p className="text-muted font-15 mb-0">
                        Menor
                    </p>
                </div>
                <div className="col-6 text-center">
                    <i className="uil uil-stopwatch text-muted" style={iconStyle}></i>
                    <h4>
                        <span>
                            {formatearFechaHora(sumItem.max.toString())}
                        </span>
                    </h4>
                    <p className="text-muted font-15 mb-0">
                        Mayor
                    </p>
                </div>
            </div>

        </div>
    )
}
export default TiempoTarea