import React, {useEffect} from "react";
import { actualizarInfoPagina } from "Utils/paginaInfo";
import { Button, Spinner } from 'react-bootstrap'
import TablaReporte from "./Tabla/TablaReporte";
import locrp from "Connection/transacciones/locrp";
import matrx from "Connection/transacciones/matrx";
import useDatosUsuarioWeb from 'Hooks/Datos/useDatosUsuarioWeb';

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="/Dashboard">Dashboard</a></li>
                    <li className="breadcrumb-item active">Reporte</li>
                </ol>
            </div>
            <h4 className="page-title">Reporte</h4>
        </div>
    )
}

function PaginaReporte(props){
    useEffect(() => { actualizarInfoPagina(props) }, [props]);
    const detalleUsuarioWeb = useDatosUsuarioWeb();
    const thisstry = async() => {
        const request = await locrp();
        console.log(request);
    }
    
    useEffect(async() => {
        await detalleUsuarioWeb.refreshData();
        // const request = await matrx({
        //     trx: "locus",
        //     lid:0
        // })
        // ;
    }, []);

    if (detalleUsuarioWeb.isFetching) {
        return (
            <div className="container">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <Spinner animation='border'></Spinner>                
                </div>
            </div>
        )
    }
    if (detalleUsuarioWeb.datos === null) {
        return null
    }
    return (
        <div className="container">
            <TituloPagina /> 
            <TablaReporte cliente={detalleUsuarioWeb.datos.clie}/>
            {/* <Button onClick={()=> thisstry()}></Button> */}

        </div>

    
    )
}

export default PaginaReporte;