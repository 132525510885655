import React from "react"
import { Button, Dropdown, Modal, ModalBody } from "react-bootstrap";
import { AnchorToggle } from "Components/Dropdown/CustomToggles";
import Tabla from "Components/Tabla/Tabla";
import useListaLocales from "Hooks/Listas/useListaLocales";
import FiltrosLocales from "Routes/locales/Tabla/FiltroLocales";
const ID_TOGGLE = "agregar-locales-a-usuario"

function MenuAgregarLocales ({
    agregarLocal = () => {}, 
    disabledOptions=[]
}) {
    const locales = useListaLocales({
        dataOption:1
    })
    const compFiltros = React.useMemo( () =>
        <FiltrosLocales
            totalLocales={locales.values.length}
            fetchLocales={locales.fetchLocales}    
        />
    ,[locales.fetchLocales, locales.values.length])

    const columnas = React.useMemo(() => [
        {
            Header: 'Código',
            accessor: 'cod',
            thClassName:"p-1",
            tdStyle: {width: "10%"}
        },
        {
            Header: 'Nombre',
            accessor: 'nam',
            thClassName:"ps-1",
            tdStyle: {width: "50%"}
        },
        {
            Header: 'Comuna',
            accessor: 'com',
            thClassName:"ps-1",
            tdStyle: {width: "30%"}
        },
        {
            Header: 'Acciones',
            thClassName: "text-start",
            // accessor: 'lid',
            Cell: props => {
                const {row} = props;
                const itemData = row.original
                const isDisabled = disabledOptions.includes(itemData.lid);
                return(
                    <div className="text-start">
                        <Button 
                            size="sm"
                            // variant={isDisabled?"secondary":"primary"}
                            onClick={() => agregarLocal(row.original)}
                            disabled={isDisabled}

                        >
                            {/* <i className="mdi mdi-plus" /> */} 
                            Asignar
                        </Button>
                    </div>
                )
            }
        }
    ],[disabledOptions]) // columnas, se actualiza cuando 
    
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const handleShow = React.useCallback(() => setIsModalOpen(true),[])
    const handleClose = React.useCallback(() => setIsModalOpen(false),[])

    function ButtonAgregarSeleccion() {
        return (
            <Button 
            onClick={() => agregarSelect(selction)}>
                <i className="mdi mdi-plus me-1" />
                Agregar Seleccion
            </Button>
        )
    
        
    }
    const agregarSelect=(value)=>{
        value.forEach((index)=>{
            if (disabledOptions.includes(index.lid)){}
            else{
            agregarLocal(index)
            }
            // handleClose()
        })
    }

    const [selction, setSelection] = React.useState([]);
    
    // const wasOpened = React.useRef(false)
    // const fetchData = () => locales.fetchLocales()
    return (
        <>
            <a href="/#" onClick={(e) => {
                e.preventDefault()
                handleShow()
            }}>
                Agregar local
            </a>
            <Modal show={isModalOpen} onHide={handleClose} size="xl">
                <Modal.Header>
                    <h4>
                        Locales
                    </h4>
                </Modal.Header>
                <ModalBody>
                    <Tabla 
                        data={locales.values}
                        columns={columnas}
                        globalFilter={true}
                        // itemsPerPageOptions={[5,10,]}
                        defaultItemPerPage={5}
                        titleSection={compFiltros}
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        cellClassName={"p-1"}
                        loading={locales.isFetching}
                        select
                        onSelected={(rows) => setSelection(rows) }
                    >

                    </Tabla>
                </ModalBody>
                <Modal.Footer>
                    <div className="text-end">
                        <Button variant="secondary" 
                        onClick={handleClose}
                        className="me-2">
                            Cerrar
                        </Button>
                        <ButtonAgregarSeleccion/>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default React.memo(MenuAgregarLocales);