
import sndma from "Connection/transacciones/sndma";
import React, { useState } from "react";
import toast from "react-hot-toast";

const generaAlertas = ({stx, msg}) => {
    switch (stx) {
        case "ok":
            toast.success(msg, {
                hideAfter: 5000,
                showHideTransition: 'slide',
                allowToastClose: true,
            })
            break;
        case "uu":
            toast.error(msg, {
                hideAfter: 5000,
                showHideTransition: 'slide',
                allowToastClose: true,
            })
            break
        default:
            break;
    }
}


function FormularioRecuperarPassword({setForm}){
    
    const [formulario, setFormulario] = useState({
        rutUsuario:''
    })
    const handleInputChange = (event) => {
        setFormulario({
            ...formulario,
            [event.target.name] : event.target.value.toUpperCase(),
        })
    }
    const backendRecuperaPassword = async({rutUsuario}) => {
        const data = await sndma(rutUsuario);
        generaAlertas(data.ans)
        localStorage.usuarioRecuperar = formulario.rutUsuario
        setForm("nuevaPassword");

    }
    const submitRecuperarPassword = (event) => {
        event.preventDefault();
        backendRecuperaPassword(formulario)
    }
    const irLogin = (event) => {
        event.preventDefault();
        setForm("login")
    }
    return (
        <>
            <h4 className="mt-0">
                Recuperar contraseña
            </h4>
            <p className="text-muted mb-4">
                Ingresa tu Rut y nosotros te enviaremos un correo con un código de verificación.
            </p>
            <form onSubmit={submitRecuperarPassword}>
                <div className="mb-3">
                    <label htmlFor="usuarioRecuperar">
                        Usuario
                    </label>
                    <input 
                        type="text"
                        id="usuarioRecuperar"
                        className="form-control"
                        required
                        maxLength={"12"}
                        placeholder="Ingresar Rut"
                        name="rutUsuario"
                        value={formulario.rutUsuario}
                        onChange={handleInputChange}
                        
                    />
                </div>
                <div className="d-grid mb-0 text-center">
                    <button className="btn btn-primary" type="submit" >
                        <i className="mdi mdi-lock-reset"></i> Recuperar contraseña 
                    </button>
                </div>
            </form>

            <div className="d-flex justify-content-center mt-3">
                <div>
                    <p className="text-muted">Volver al 
                        <a  href="/#" onClick={irLogin} className="text-muted ms-1"> 
                            <b>
                                Inicio de Sesión
                            </b>
                        </a>
                    </p>
                </div>
            </div>

        </>
    )
}


export default FormularioRecuperarPassword;