import Tabla from 'Components/Tabla/Tabla';
import useSolicitudesRegistroApp from 'Hooks/Listas/useSolicitudesRegistroApp'
import React from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { state } from './store';
import { format } from 'Utils/rut'

function TablaSolicitudesPendientes({status,update}) {
    const {
        solicitudes,
        fetchData,
        isFetching,
        isSubmiting,
        aprobarSolicitudes, 
        rechazarSolictudes
    } = useSolicitudesRegistroApp(status);
    React.useEffect(() => {
        fetchData()
    },[update])
    // console.log(asd)
    const columnas = React.useMemo(() => [
        {
            Header: 'Nombre',
            accessor: 'nam',
            Cell: props => {
                const {row, value} = props 
                return (
                    <Link to={"/#"} onClick={e => {
                        e.preventDefault()
                        window.$('#modalUsuario').modal('show')
                        state.usuarioSeleccionado = row.original.uid
                    }}>
                        {value}
                    </Link>
                )
            } ,
            thClassName:"p-1",
        },
        {
            Header: 'Rut',
            accessor: 'rut',
            Cell: props =>{
                const {value} = props 
                return(format(value))
            },
            thClassName:"p-1",
        },
        {
            Header: 'N° Documento',
            accessor: 'doc',
            thClassName:"p-1",
        }
    ],[])
    
    const [usuariosSeleccionados, setUsuarioSeleccionados] = React.useState([])

    return (
        <div className='card'>
            <div className='card-body'>
                {status===2 && <Tabla 
                    columns={columnas}
                    data={solicitudes}
                    globalFilter={true}
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={isFetching}
                    select
                    onSelected={(rows) => setUsuarioSeleccionados(rows.map(row => row.uid))}
                />}
                {status!==2 && <Tabla 
                    columns={columnas}
                    data={solicitudes}
                    globalFilter={true}
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={isFetching}
                />}
                <div className='mt-3 d-flex justify-content-end'>
                    {status===2 && <Button 
                        disabled={usuariosSeleccionados.length === 0 || isSubmiting}
                        variant='outline-danger' 
                        className='me-2' 
                        onClick={() => rechazarSolictudes(usuariosSeleccionados)}>
                        
                        <div style={{width: "64px"}}>
                            {isSubmiting?(
                                <Spinner animation='border' size='sm'/>
                            ): ("Rechazar")}
                            
                        </div>
                    </Button>}
                    {status===2 && <Button 
                        disabled={usuariosSeleccionados.length === 0 || isSubmiting}
                        variant='success' 
                        onClick={() => aprobarSolicitudes(usuariosSeleccionados)}
                    >
                        <div style={{width: "84px"}}>
                            {isSubmiting?(
                                <Spinner animation='border' size='sm'/>
                            ): ("Aprobar")}
                            
                        </div>
                    </Button>}
                </div>
            </div>

        </div>
    )
}

export default TablaSolicitudesPendientes