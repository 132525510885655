class CacheApp {
   
    setCacheApp =({key, value, sessionKey = "", exp = -1}) => {
        const toStore = {
            sessionKey,
            value,
            exp: exp === -1? undefined: Number(new Date()) + exp // calcula cuando vence
        }
        localStorage[key] = JSON.stringify(toStore);
    }

    getCacheApp = ({key, sessionKey = ""}) => {
        const storedString = localStorage[key];
        if (storedString) {
            const storedData = JSON.parse(storedString);
            if(storedData.sessionKey === sessionKey && (storedData.exp === undefined || storedData.exp >= Number(new Date()) )) {
                return storedData.value
            }
        }   
        return undefined
    }

    setCacheTab = ({key, value, sessionKey = "", exp = -1}) => {
        const toStore = {
            sessionKey,
            value,
            exp: exp === -1? undefined: Number(new Date()) + exp // calcula cuando vence
        }
        sessionStorage[key] = JSON.stringify(toStore);
    }

    getCacheTab = ({key, sessionKey = ""}) => {
        const storedString = sessionStorage[key];
        if (storedString) {
            const storedData = JSON.parse(storedString);
            if(storedData.sessionKey === sessionKey && (storedData.exp === undefined || storedData.exp >= Number(new Date()) )) {
                return storedData.value
            }
        }   
        return undefined
    }
}
export default new CacheApp();