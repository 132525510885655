
import React, { useEffect, useState } from "react";
import { actualizarInfoPagina } from "Utils/paginaInfo";
import ModalUsuario from "./ModalUsuario/ModalUsuario";
import Nav  from 'react-bootstrap/Nav';
import DivControl from 'Components/DivControl';
import TablaSolicitudesPendientes from "./TablaSolicitudesPendientes";


function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="/Dashboard">Dashboard</a></li>
                    <li className="breadcrumb-item active">Inscripciones</li>
                </ol>
            </div>
            <h4 className="page-title">Inscripciones</h4>
        </div>
    )
}


function PaginaSolicitudes (props) {
    useEffect(()=> {
        actualizarInfoPagina(props);
    },[props])

    const [activeItem, setActiveItem] = React.useState("1")
    const [update1, updating1] = React.useState(true)
    const [update2, updating2] = React.useState(true)
    const [update3, updating3] = React.useState(true)
    function Toogle(eventKey){
        switch (eventKey){
            case "0":
                updating1(!update1)
                break;
            case "2":
                updating3(!update2)
                break;
            case "3":
                updating3(!update3)
                break;
            default:
                break;
        }
        // console.log(parseInt(eventKey,10))
        setActiveItem(eventKey)
    }

    return (
        <div className="container">
            <TituloPagina/>
            <ModalUsuario/>
            
            <div className='container'>
                    <Nav justify variant="tabs" defaultActiveKey="1" onSelect={Toogle}>
                        <Nav.Item>
                            <Nav.Link eventKey="1">
                                Solicitudes 
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="0">
                                Pre-Aprobacion
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2">
                                Post-Aprobacion
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3">
                                Rechazadas
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <DivControl show={activeItem==="1"}>
                        <TablaSolicitudesPendientes status={2} />
                    </DivControl>
                    <DivControl show={activeItem==="0"}>
                        <TablaSolicitudesPendientes status={1} update={update1} />
                    </DivControl>
                    <DivControl show={activeItem==="2"}>
                        <TablaSolicitudesPendientes status={3} update={update2}/>
                    </DivControl>
                    <DivControl show={activeItem==="3"}>
                        <TablaSolicitudesPendientes status={4} update={update3}/>
                    </DivControl>
                </div>

        </div>
    )
}


export default PaginaSolicitudes;