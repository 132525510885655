import React, { useEffect, useState} from "react";
import { backend_request } from "Connection/backend_reduce";
import { useSnapshot } from "valtio";
import { state } from "../store";
import PerfilUsuario from "./PerfilUsuario";
import FotoDocumento from './FotoDocumento';

function ModalUsuario () {
    const snap = useSnapshot(state)
    const [datosUsuario, setDatosUsuario] = useState({});
    const [isFetching, setIsFetching] = useState(false);

    const backendGetUsdat = async(idUsuario) => {
        const jsonBody = {
            "hdr": {
                "txi": 9,
                "req": "usdat",
                "ott": localStorage.ott
            },
            "dat": {
                "uid" : parseInt(idUsuario, 10),
            }
        }
        await backend_request(jsonBody, (data) => {
            if (data.ans.stx === "ok") {
                state.usuariosCache[idUsuario.toString()] = data.ans
                setDatosUsuario(data.ans)
            }
        })
    }

    useEffect(() => {
        const cambiarUsuario = async()=>{
            if(snap.usuarioSeleccionado !== null) {
                if(snap.usuariosCache[snap.usuarioSeleccionado.toString()]){
                    setDatosUsuario(snap.usuariosCache[snap.usuarioSeleccionado])
                } else {
                    try {
                        setIsFetching(true);
                        await backendGetUsdat(snap.usuarioSeleccionado);
                    } catch (error) {
                        // console.log(error)
                    } finally {
                        setIsFetching(false);
                    }   
                }
            }
        }
        cambiarUsuario();
        // console.log("dt",datosUsuario)

        // console.log("user",datosUsuario.uid)
    }, [snap.usuarioSeleccionado, snap.usuariosCache]);
    return (
    <>
    <div className="modal fade" id="modalUsuario" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div  className="modal-dialog modal-xl" role="document">
            <div className="modal-content" style={{paddingLeft:"10px"}}>
                <div className="modal-header">
                </div>
                <div className="modal-body">
                {/* <div className="modal-body" class="container"> */}
                    <div className="row">
                        <div className="col-lg-4" >
                            <h3 className="header-title mt-0 mb-3">
                                <i className="mdi mdi-account" style={{marginRight:"7px"}}></i>
                                Información del usuario
                            </h3>
                            {isFetching === false ? (
                                <PerfilUsuario datosUsuario={datosUsuario}/>
                                ): (
                                <div className="text-center">
                                    <span className="spinner-border spinner-border-lg" role="status"></span>
                                </div>
                                    )
                            }
                        </div>

                        <div className="col-lg-7 ">
                            <h3 className="header-title mt-0 mb-3">
                                    <i className="mdi mdi-file-document-multiple" style={{marginRight:"7px"}}></i>
                                    Documentos del usuario
                            </h3>
                            {/* <div className="tablinks" onClick={wena(datosUsuario.uid,0)}> primero</div>
                            <button className="tablinks" onClick={wena(datosUsuario.uid,1)}> segundo</button> */}
                            <FotoDocumento usuarioId={datosUsuario.uid}/>
                            {/* <button className="tablinks" onClick={wena(datosUsuario.uid,1)}>asd</button> */}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={()=> window.$('#modalUsuario').modal("toggle")}>Cerrar</button>
                </div>
            </div>
        </div>
    </div>
        
        
        </>
    )

}

export default ModalUsuario;