        
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const validarEmail = (email) => {
    const _email = email.toLowerCase();
    if(emailRegex.test(email.toLowerCase())) {
        return true
    } else {
        return false;
    }
    // return String(email)
    //   .toLowerCase()
    //   .match(
    //   );
  };

export const phoneRegex = /^[0-9]{0,12}$/

export const coordinatesRegex =  /^-?[0-9.]*$/;
