import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

function FormGroupLabel(props) {
    const {asRow, label, size, labelSize} = props;
    return(
        <Form.Group as={asRow? Row: undefined} className="position-relative">
            {/* Label */}
            <Form.Label
                column={asRow? (size?size:true):false}
                sm={asRow? (labelSize || 6): undefined}
            >
                {label}
            </Form.Label>
            {/* Form element as Children */}
            {asRow?(
                <Col sm={labelSize? 12 -labelSize : 6}>
                    {props.children}
                </Col>
            ):(<>{props.children}</>)}

        </Form.Group>
    )
}

export default FormGroupLabel;