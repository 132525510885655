import React,{useEffect, useState} from "react";
import Requerimientos from "./Requerimientos";
import DatosGenerales from "./DatosGenerales";
import { Link, useLocation } from 'react-router-dom';
import { backend_request } from "Connection/backend_reduce";
import ticks from "Connection/transacciones/ticks";

import "Assets/css/detalleTicketStyle.css"
import { actualizarInfoPagina } from "Utils/paginaInfo";
import { Spinner } from "react-bootstrap";


function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to={"/Dashboard"} >Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to={"/ListaTickets"} >Tickets</Link></li>
                    <li className="breadcrumb-item active">Detalle de Ticket</li>
                </ol>
            </div>
            <h4 className="page-title">Detalle de ticket</h4>
        </div>
    )
}

const PaginaDetalleTicket = (props) => {
    useEffect(() => {
        actualizarInfoPagina(props);
    }, [props]);
    const [datosTicket, setDatosTicket] = useState();

    const location = useLocation();

    const [isFetching, setIsFetching] = React.useState(true);
    React.useEffect(async() => {
        let idTicket = sessionStorage.getItem(location.key);
        if(!idTicket) {
            idTicket = localStorage.getItem("tic_id");
            sessionStorage.setItem(location.key, idTicket);
            localStorage.removeItem("tic_id");
        }
        if(idTicket) {
            const request = await ticks({
                idTicket: idTicket,
                cantidad: 1
            })
            // console.log("ans",request.ans)
            if(request.ans.stx === "ok") {
                setDatosTicket(request.ans.tas[0]);
            }
        }
        setIsFetching(false)
    },[])

    return(
        <div className="container">
            <TituloPagina/>
            {isFetching && (
                <div className="text-center">
                    <Spinner animation="border"/>
                </div>
            )}
            {!isFetching && (<>
                
            </>)}
                    {datosTicket &&
                    <div className="card">
                        <DatosGenerales datosTicket={datosTicket}/>
                        <Requerimientos datosTicket={datosTicket}/>
                    </div>
                    } 
                    {(!datosTicket && !isFetching) &&
                    <div className="alert alert-warning alert-dismissible fade show d-flex justify-content-center" role="alert">
                        No se han encontrado datos del Ticket.
                    </div>
                    }
                </div>
    )
}

export default PaginaDetalleTicket;