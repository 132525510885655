import { requestData } from "Connection/transacciones"

const caled = async(dat) => {
    const jsonBody = {
        hdr: {
            txi: 50,
            req: "caled",
            ott: true,
        },
        dat: dat
        
        
    }
    // console.log("caled",dat)
    // console.log(jsonBody)
    return await requestData(jsonBody)

}

export default caled;