import React from "react";
import { Card, Button } from "react-bootstrap";
import Tabla from 'Components/Tabla/Tabla';
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

import { DatePicker, Calendar } from "react-multi-date-picker"
import { formatearMDA } from "Utils/funcionesFechaHora";
import Select from 'react-select';
import useListaLocalesCalen from "Hooks/Listas/useListaLocalesCalen";
import FiltrosLocalesCalen from "./FiltrosLocalesCalen";
// import "./DatePicker2.css"
import es_th from "./es";
import calen from "Connection/transacciones/calen";
import { set } from "lodash";


const thClassName = "ps-1"

// function CalendarizarTicket({idCal}) {
  function CalendarizarTicket({idCal}) {
  const navigation = useNavigate()
  // console.log("idCal",idCal) 

  const hourOpt = [{label:"00",value:0},{label:"01",value:1},{label:"02",value:2},{label:"03",value:3},{label:"04",value:4}
                  ,{label:"05",value:5},{label:"06",value:6},{label:"07",value:7},{label:"08",value:8},{label:"09",value:9},{label:"10",value:10}
                  ,{label:"11",value:11},{label:"12",value:12},{label:"13",value:13},{label:"14",value:14},{label:"15",value:15},{label:"16",value:16}
                  ,{label:"17",value:17},{label:"18",value:18},{label:"19",value:19},{label:"20",value:20},{label:"21",value:21},{label:"22",value:22}
                  ,{label:"23",value:23}]
  const minOpt = [{label:"00",value:0},{label:"15",value:15},{label:"30",value:30},{label:"45",value:45}]
  const selectOptions = [{label:"Mes completo",value:0},{label:"Semana 1",value:1},{label:"Semana 2",value:2},{label:"Semana 3",value:3},
                        {label:"Semana 4",value:4},{label:"Semana 5",value:5},{label:"Semana 6",value:6},{label:"Lunes",value:"L"},{label:"Martes",value:"M"},
                        {label:"Miercoles",value:"Mi"},{label:"Jueves",value:"J"},{label:"Viernes",value:"V"},{label:"Sabado",value:"S"},{label:"Domingo",value:"D"}]
  const [selectedOption, setSelectedOption] = React.useState(selectOptions[0]);
  const [selectedOption2, setSelectedOption2] = React.useState(selectOptions[0]);
  const [selectedOption3, setSelectedOption3] = React.useState(selectOptions[0]);

  const [selectedItems, setSelectedItems] = React.useState([]);
  const [hour,setHour] = React.useState(hourOpt[8])
  const [min,setMin] = React.useState(minOpt[0])

  const [checkBlue, setCheckBlue] = React.useState(false)
  const [checkLoc, setCheckLoc] = React.useState(false)

  const [toBlue, setToBlue] = React.useState([])

const  calendarSelector = (value,action) => {
  let today = new Date()
  let year = today.getFullYear()
  let month = today.getMonth()+1 // getMonth() => 0-11
  let firstMonthDay = new Date(year, month-1, 1);
  let lastMonthDay = new Date(year, month, 0);
  let ajuste = firstMonthDay.getDay() === 0 ? 6 : firstMonthDay.getDay() - 1;
  let selectedCalendar = []
  let selectedDays = toBlue
  // console.log("today",today, "year",year,"month", month, "firstMonthDay", firstMonthDay, "lastMonthDay", lastMonthDay)
  
  switch (value) {
    case 0:
      selectedCalendar = selectedOption
      break;
    case 1:
      selectedCalendar = selectedOption2
      month === 12? year = year+1 : year = year
      month === 12? month = 1 : month = month+1
      firstMonthDay = new Date(year, month-1, 1);
      ajuste = firstMonthDay.getDay() === 0 ? 6 : firstMonthDay.getDay() - 1;
      lastMonthDay = new Date(year, month, 0);
      break;
    case 2:
      selectedCalendar = selectedOption3
      if (month === 11 || month === 12) {
        year = year+1
        month === 11 ? month = 1 : month = 2
        firstMonthDay = new Date(year, month-1, 1);
        ajuste = firstMonthDay.getDay() === 0 ? 6 : firstMonthDay.getDay() - 1;
        lastMonthDay = new Date(year, month, 0);
        break;
      }
      else{
        month = month+2
        firstMonthDay = new Date(year, month-1, 1);
        ajuste = firstMonthDay.getDay() === 0 ? 6 : firstMonthDay.getDay() - 1;
        lastMonthDay = new Date(year, month, 0);
        break;
      }
    default:
      break;
  }




  for (let i = 1; i < lastMonthDay.getDate()+1; i++) {
    // console.log(selectedCalendar.value)
      let day = (String(year).substring(2, 4) + ((month < 10) ? "0" + String(month) : String(month)) + ((i < 10) ? "0" + String(i) : String(i)))
      if ( month === today.getMonth()+1 && i <= today.getDate()) {
        continue
      }
      else if (selectedDays.includes(day) && action) {
        continue}
      else if (!selectedDays.includes(day) && !action) {
        continue
      }
      else if(selectedCalendar.value === 0){
        ((action)? selectedDays.push(day) :
        selectedDays.splice(selectedDays.indexOf(day), 1))
      }
      else if(selectedCalendar.value === 1){
        if ((Math.ceil((i + ajuste) / 7) === 1)) {
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }}
      else if(selectedCalendar.value === 2){
        if ((Math.ceil((i + ajuste) / 7) === 2)) {
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }}
      else if(selectedCalendar.value === 3){
        if ((Math.ceil((i + ajuste) / 7) === 3)) {
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }}
      else if(selectedCalendar.value === 4){
        if ((Math.ceil((i + ajuste) / 7) === 4)) {
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }}
      else if(selectedCalendar.value === 5){
        if ((Math.ceil((i + ajuste) / 7) === 5)) {
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }}
      else if(selectedCalendar.value === 6){
        if ((Math.ceil((i + ajuste) / 7) === 6)) {
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }}
      else if (selectedCalendar.value === "L") {
        if( (new Date(year, month-1, i).getDay()) === 1){
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }
      }
      else if (selectedCalendar.value === "M") {
        if( (new Date(year, month-1, i).getDay()) === 2){
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }
      }
      else if (selectedCalendar.value === "Mi") {
        if( (new Date(year, month-1, i).getDay()) === 3){
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }
      }
      else if (selectedCalendar.value === "J") {
        if( (new Date(year, month-1, i).getDay()) === 4){
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }
      }
      else if (selectedCalendar.value === "V") {
        if( (new Date(year, month-1, i).getDay()) === 5){
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }
      }
      else if (selectedCalendar.value === "S") {
        if( (new Date(year, month-1, i).getDay()) === 6){
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }
      }
      else if (selectedCalendar.value === "D") {
        if( (new Date(year, month-1, i).getDay()) === 0){
          ((action)? selectedDays.push(day) :
          selectedDays.splice(selectedDays.indexOf(day), 1))
        }
      }
      
    }
      onBlueChange(selectedDays)
      
    
  

  // console.log(value,action)
  // const primerDiaMes = new Date(today.getFullYear(), today.getMonth(), 1); // Primer día del mes
  // const primerDiaMesSiguiente = new Date(today.getFullYear(), today.getMonth() + 1, 1); // Primer día del próximo mes
    

  // if (value === 1) {
  //   const primerDiaMes = new Date(today.getFullYear(), today.getMonth()+1, 1); // Primer día del mes
  //   const ajusteInicioSemana = primerDiaMes.getDay() === 0 ? 6 : primerDiaMes.getDay() - 1;


    
  //   let lastDay = new Date(today.getFullYear(), today.getMonth() + 2, 0).getDate();
  //   let temp = toBlue
  //   for (let i = 1; i < lastDay+1; i++) {
  //     let day = (String(today.getFullYear()).substring(2, 4) + ((today.getMonth() + 2 < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i)))
  //     // console.log(i)
  //     if ( temp.includes() && action) {{
  //       continue}
  //     }
  //     else if(selectedOption2.value === 0){
  //     ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))) :
  //     temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //   }
  //     else if(selectedOption2.value === "J"){
  //       if (i%7 === 0) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }
  //     }
  //     else if(selectedOption2.value === "V"){
  //       if (i%7 === 1) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }
  //     }
  //     else if(selectedOption2.value === "S"){
  //       if (i%7 === 2) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }
  //     }
  //     else if(selectedOption2.value === "D"){
  //       if (i%7 === 3) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }
  //     }
  //     else if(selectedOption2.value === "L"){
  //       if (i%7 === 4) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }
  //     }
  //     else if(selectedOption2.value === "M"){
  //       if (i%7 === 5) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }
  //     }
  //     else if(selectedOption2.value === "Mi"){
  //       if (i%7 === 6) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }
  //     }
      
  //     else if(selectedOption2.value === 1){
  //       if ((Math.ceil((i + ajusteInicioSemana) / 7) === 1)) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth() + 2) : String(today.getMonth() + 2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }}
  //     else if(selectedOption2.value === 2){
  //       if ((Math.ceil((i + ajusteInicioSemana) / 7) === 2)) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10) ? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10)? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }}
  //     else if(selectedOption2.value === 3){
  //       if ((Math.ceil((i + ajusteInicioSemana) / 7) === 3)) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10)? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10)? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }}
  //     else if(selectedOption2.value === 4){
  //       if ((Math.ceil((i + ajusteInicioSemana) / 7) === 4)) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10)? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10)? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }}
  //     else if(selectedOption2.value === 5){
  //       if ((Math.ceil((i + ajusteInicioSemana) / 7) === 5)) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10)? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10)? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }}
  //     else if(selectedOption2.value === 6){
  //       if ((Math.ceil((i + ajusteInicioSemana) / 7) === 6)) {
  //         ((action)? temp.push(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10)? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))):
  //         temp.splice(temp.indexOf(String(today.getFullYear()).substring(2, 4) + ((today.getMonth()  < 10)? "0" + String(today.getMonth()+2) : String(today.getMonth()+2)) + ((i < 10) ? "0" + String(i) : String(i))), 1))
  //       }}
  //   }
  //   onBlueChange(temp)
  // }

}

  const onBlueChange = (value) => {
    setToBlue(value)
    setCheckBlue(!checkBlue)
  }
  const onLocChange = (value) => {
    setSelectedItems(value)
    setCheckLoc(!checkLoc)
  }
  const validBlue = React.useMemo(() => {
    if( toBlue.length === 0 ) return undefined
    else return true
  } ,[checkBlue])

  const validLoc = React.useMemo(() => {
    if( selectedItems.length === 0 ) return undefined
    else return true
  } ,[checkLoc])


  const columnas = React.useMemo(() => [
    {
      Header: "Codigo de Local",
      accessor: "cod",
      thClassName
    },
    {
      Header: "Nombre de Local",
      accessor: "nam",
      thClassName
    },
    {
      Header: "Comuna",
      accessor: "com",
      thClassName
    },
    {
      Header: "Cluster",
      accessor: "clu",
      thClassName
    }
  ],[])

  const onDayChange = (date) => {
    let a = new Date()
    // console.log(a.getHours(), a.getMinutes(),hour.value,min.value)
    // console.log((a.getMonth()+1 === date.month.number), (a.getDate() === date.day),((hour - a.getHours()) === 0 ), (min - a.getMinutes()> 15) )
    // console.log((a.getMonth()+1 === date.month.number), (a.getDate() === date.day), (min.value + hour.value*60  - a.getMinutes()- a.getHours()*60 > 60))
    // console.log((String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + String(date.day)))
    let tempBlue = toBlue
    // if (initialRed.includes(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + String(date.day))) {

      if (toBlue.includes(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {
        tempBlue.splice(toBlue.indexOf(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  +((date.day < 10)? "0" + String(date.day):String(date.day))), 1)
        onBlueChange(tempBlue)
      }
      else {
        tempBlue.push(String(date.year).substring(2, 4) + ((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number)) + ((date.day < 10)? "0" + String(date.day):String(date.day)))
        onBlueChange(tempBlue)
      }
  }
  const locales = useListaLocalesCalen({
    dataOption:1
    });
  const compFiltros = (
      <><FiltrosLocalesCalen
          totalLocales={locales.values.length}
          fetchLocales={locales.fetchLocales}
      />
      </>
  )
  const handlerChange = async () => {
    // console.log(toRed)
    let update = []
    for (let i = 0; i < toBlue.length; i++) {
      update.push(toBlue[i] + hour.label + min.label)
    }
    const request = await calen({
      tid: Number(idCal),
      dti: update, 
      loc: selectedItems.map((item) => item.lid) 
    })
    if (request.ans.stx === "ok") {
      toast.success(request.ans.msg)
      navigation("/Calendarizaciones")

    } 
    else if(request.ans.stx === "nk") {
    toast.error((t) => (<>
        {request.ans.msg}
    </>) )
    }
  }
  return (
    <>
      <Card>
        <Card.Header
          style={
            {
              // "font-size": "20px",
              "fontWeight": "bold",
              "backgroundColor": "#F1F3FA"
            }}>
          <a className="card-title"
            style={
              {
                "fontSize": "15px",
                "color": "#313A46",
                "textAlign": "center",
              }}
          >Calendarización</a>
        </Card.Header>
        <Card.Body>
          <div className="row">

            <div className="col">
              <div className="form-group">

                <div className="row">
                  Día(s) de Ejecución
                </div>
                <div className="row" style={{overflowX: "auto", display: "flex"}}>
                  <div className="col">
                    <Calendar
                      multiple
                      value={[]}
                      locale={es_th}
                      numberOfMonths={3}
                      disableMonthPicker={true}
                      highlightToday={false}
                      weekStartDayIndex={1}
                      // formatMonth={month => {onClick: () => {return month.name}}}}
                      mapDays={({ date, isSameDate, today }) => {
                        let props = {}
                        // if (initialBlue.includes(String(date.year).substring(2, 4) +((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number))  + String(date.day))) {
                          if (toBlue.includes(String(date.year).substring(2, 4) +((date.month.number < 10)? "0" + String(date.month.number):String(date.month.number)) + ((date.day < 10)? "0" + String(date.day):String(date.day)))) {
                            return {
                              style: {
                                backgroundColor: "blue",
                                color: "white"
                              },
                              onClick: () => onDayChange(date),
                            }
                          }

                        // }
                          if ((((date.year === today.year))&&(date.month.number === today.month.number) && (date.day >= today.day))||(((date.month.number+date.year*12-today.month.number-today.year*12 )>0)&&((date.month.number+date.year*12-today.month.number-today.year*12) < 3))) {
                            
                            return {
                              onClick: () => onDayChange(date),
                              style: {
                                backgroundColor: "white",
                                color: "gray"
                              },
                            }
                          }
                        return {
                          style: {
                            backgroundColor: "white",
                            color: "gray",
                          },
                        }

                      }
                      }
                    />
                    <style>{`
                      .rmdp-day span {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: gray;
                      }
                      .rmdp-week-day,.rmdp-header-values  {
                        color: gray;
                        font-family: "Nunito", sans-serif;
                        border-radius: 50%;
                        font-size: 13px;
                        line-height: 28px;
                        font-weight: bold;
                      }


                      .rmdp-calendar {
                        height: max-content;
                        padding: 0px;
                      }

                      .rmdp-day.rmdp-selected span:not(.highlight) {
                        box-shadow: 0 0 0px
                      }

                      .rmdp-arrow-container {
                        border-radius: 0%;
                        cursor: pointer;
                        display: flex;
                        height: 0px;
                        justify-content: center;
                        margin: 0 0px;
                        width: 0px;
                        
                      }
                      .rmdp-arrow {
                        border: solid #0074d9;
                        border-width: 0 0px 0px 0;
                        display: inline-block;
                        height: 0px;
                        margin-top: 0px;
                        padding: 0px;
                        width: 0px;
                      }
                      .rmdp-wrapper {
                        background-color: #fff;
                        border-radius: 0px;
                        direction: ltr;
                        text-align: center;
                        width: max-content;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                      }
                      .rmdp-shadow {
                        box-shadow: 0 0 0px #fff;
                      }
                      .rmdp-week {
                        margin-right: 20px;
                      }
                    `}</style>
                  </div>
                  <div className="col">
                  
                  </div>
                </div>

                <br></br>
                <div className="row" style={{textAlign:"left", width:"806px"}}>
                  <div className="col" >
                    <Select
                      placeholder={"Seleccionar Rango"}
                      value={selectedOption}
                      options={selectOptions}
                      onChange={opt => setSelectedOption(opt)}
                    />
                    <br/>
                    <div className="row">
                      <div className="col" style={{textAlign:"center"}}>
                        <Button variant="outline-primary" onClick={() => calendarSelector(0, true)}>Agregar</Button>
                      </div>
                      <div className="col">
                        <Button variant="outline-danger" onClick={() => calendarSelector(0, false)}>Quitar</Button>
                      </div>
                    </div>
                  </div>
                  <div className="col" >
                    <Select
                      placeholder={"Seleccionar Rango"}
                      value={selectedOption2}
                      options={selectOptions}
                      onChange={opt => setSelectedOption2(opt)}
                    />
                    <br/>
                    <div className="row">
                      <div className="col" style={{textAlign:"center"}}>
                        <Button variant="outline-primary" onClick={() => calendarSelector(1, true)}>Agregar</Button>
                      </div>
                      <div className="col">
                        <Button variant="outline-danger" onClick={() => calendarSelector(1, false)}>Quitar</Button>
                      </div>
                    </div>
                  </div>
                  <div className="col" >
                    <Select
                      placeholder={"Seleccionar Rango"}
                      value={selectedOption3}
                      options={selectOptions}
                      onChange={opt => setSelectedOption3(opt)}
                    />
                    <br/>
                    <div className="row">
                      <div className="col" style={{textAlign:"center"}}>
                        <Button variant="outline-primary" onClick={() => calendarSelector(2, true)}>Agregar</Button>
                      </div>
                      <div className="col">
                        <Button variant="outline-danger" onClick={() => calendarSelector(2, false)}>Quitar</Button>
                      </div>
                    </div>
                  </div>
                  </div>
                  <br></br>
                <div className="row">
                  <div className="col-sm-2">
                    <label>Horario de Ejecución </label>
                  </div>
                  <div className="col-sm-1" style={{ marginRight: "0px", padding: "0px" }}>
                    <Select
                      placeholder={"Seleccionar hora"}
                      value={hour}
                      options={hourOpt}
                      onChange={opt => setHour(opt)}
                      // isDisabled={true}
                    />

                  </div>
                  <div className="col" style={{maxWidth:"35px", marginRight: "0px", padding: "0px" }}>
                    <Button  style={{color:"black",backgroundColor: "#EEF2F7", borderBlockColor:"#DEE2E6", borderColor:"#DEE2E6"}} disabled={true}>:</Button>

                  </div>
                  <div className="col-sm-1" style={{ marginLeft: "0px", padding: "0px" }}>
                    <Select
                      placeholder={"Seleccionar hora"}
                      value={min}
                      options={minOpt}
                      onChange={opt => setMin(opt)}
                    />

                  </div>

                </div>
              </div>
            </div>
          </div>

        </Card.Body>
        <Card.Header
          style={
            {
              // "font-size": "20px",
              "fontWeight": "bold",
              "backgroundColor": "#F1F3FA"
            }}>
          <a className="card-title"
            style={
              {
                "fontSize": "15px",
                "color": "#313A46",
                "textAlign": "center",
              }}
          >Puntos de Venta</a>
        </Card.Header>
        <Card.Body>
          <br></br>
          <div className="row">
            <div className="col-sm-11">
              <Tabla
                columns={columnas}
                data={locales.values} 
                // data={[]}
                globalFilter={true}
                titleSection={compFiltros}
                tableClass={"table table-centered table-hover  mb-0"}
                theadClassName={"table-light"}
                cellClassName={"p-1"}
                loading={locales.isFetching}
                select
                onSelected={(selected) => onLocChange(selected)}
              />
            </div>
          </div>
          <br/>
          <div style={{ textAlign: "right" }}>
            <Button onClick={() => handlerChange()} disabled={!(validBlue&&validLoc)} >Calendarizar</Button>
            {/* <Button style={{ marginLeft: "10px" }} onClick={() => {console.log(toBlue)}}>see</Button> */}
          </div>
        </Card.Body>
      </Card>

    </>
  )
}
export default CalendarizarTicket;
