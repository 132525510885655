import OpcionItem from 'Components/Dashboard/DashboardItem'
import OpcionModal from 'Components/Dashboard/DashboardModal'
import React from 'react'
import { Row } from 'react-bootstrap'

function AccionesAdministraciónRepoflex({
    gestionSolicitudes,
    gestionUsuariosWeb,
    gestionUsuariosCom,
    gestionUsuariosApp,
    locales,
    clientes,
    rTareas,
    precios

}) {
    if (!(gestionSolicitudes || gestionUsuariosApp || gestionUsuariosCom || gestionUsuariosWeb || locales || clientes || rTareas || precios)) {
        return (null)
    }
    return (
        <>
            <h5 className="page-title">Administración Repoflex</h5>
            <Row>

                {gestionSolicitudes && (
                    <OpcionItem
                        titulo="Gestionar inscripciones"
                        descripcion={"Gestiona las inscripciones de los Zolbers."}
                        icono="mdi-account-box-multiple mt-2"
                        color="purple"
                        link="/Solicitudes"
                    />
                )}
                {gestionUsuariosWeb && (
                    <OpcionItem
                        titulo="Gestionar usuarios Web"
                        descripcion={"Gestiona los datos de usuarios web."}
                        icono="mdi-account-supervisor mt-2"
                        color="cyan"
                        link="/GestionUsuarios/Web"
                    />
                )}
                {gestionUsuariosCom && (
                    <OpcionItem
                        titulo="Gestionar usuarios comerciales"
                        descripcion={"Gestiona los datos de usuarios comerciales."}
                        icono="mdi-account mt-2"
                        color="blue"
                        link="/GestionUsuarios/Comercial"
                    />
                )}
                {gestionUsuariosApp && (
                    <OpcionItem
                        titulo="Gestionar usuarios App"
                        descripcion={"Gestiona los datos de usuarios App."}
                        icono="mdi-account mt-2"
                        color="orange"
                        link="/GestionUsuarios/App"
                    />
                )}
                {locales && (
                    <OpcionItem
                        titulo="Locales"
                        descripcion={"Consulta de locales."}
                        icono="mdi-store mt-2"
                        color="red"
                        link="/Locales"
                    />
                )}
                {clientes && (
                        <OpcionItem
                            titulo="Clientes"
                            descripcion={"Gestiona los datos de clientes."}
                            icono="mdi-clipboard-account mt-2"
                            color="green"
                            link="/GestionClientes/Clientes"
                        />
                    )}
                {rTareas && (
                    <OpcionItem
                        titulo="Gestion Tareas Enviadas"
                        descripcion={"Revisa tareas enviadas."}
                        icono="mdi-magnify mt-2"
                        color="purple"
                        link="/RevisionTareas"
                    />
                )}
                {precios && (
                    <OpcionItem
                        titulo="Presupuesto"
                        descripcion={"Gestiona los Presupuesto."}
                        icono="mdi-currency-usd mt-2"
                        color="yellow"
                        link="/Presupuesto"
                    />
                )}
                {/* {precios && (
                    <OpcionModal
                        titulo="Presupuesto"
                        descripcion={"Gestiona los Presupuesto."}
                        icono="mdi-currency-usd mt-2"
                        color="yellow"
                        link="/Precios"
                        menu={[{titulo:"Ver Presupuesto", link:"/Presupuesto"},{titulo:"Cargar Presupuesto", link:"/Presupuesto/CargarPresupuesto"}]}
                    />
                )} */}
                {/* {reporte && (
                    <OpcionItem
                        titulo="Reporte Repoflex"
                        descripcion={"Revisa reportes."}
                        icono="mdi-chart-box mt-2"
                        color="yellow"
                        link="/Reportes"
                    />
                )} */}
                    
            </Row>
            
        </>
    )
}

export default AccionesAdministraciónRepoflex