const es_th = {
    name: "es_th",
    months: [
      ["Enero", "Ene"],
      ["Febrero", "Feb"],
      ["Marzo", "Mar"],
      ["Abril", "Abr"],
      ["Mayo", "May"],
      ["Junio", "Jun"],
      ["Julio", "Jul"],
      ["Agosto", "Ago"],
      ["Septiembre", "Sep"],
      ["Octubre", "Oct"],
      ["Noviembre", "Nov"],
      ["Diciembre", "Dic"],
    ],
    weekDays: [
        ["Sábado", "Sa"],
        ["Domingo", "Do"],
        ["Lunes", "Lu"],
        ["Martes", "Ma"],
        ["Miércoles", "Mi"],
        ["Jueves", "Ju"],
        ["Viernes", "Vi"]
    ],
    meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
      ],
    
    digits: ["0","1", "2", "3", "4", "5", "6", "7", "8", "9"]

}
  
  export default es_th