import React from 'react'
import { MultiSelect } from 'react-multi-select-component';
import localization from "Assets/localization/multiSelect.json"
import useLocalesOpciones from 'Hooks/Selects/useLocalOpciones';

function MultiSelectLocales({
    isDisabled,
    dependencies = {}, 
    onChange = () => {},
    selected=[],
    name
}) {
    const locales = useLocalesOpciones();
    const fetched = React.useRef(false) 
    React.useEffect(() => {
        fetched.current = false; // si se actualiza una dependencia se debe menciar que esta desactualizado 
    }, [
        JSON.stringify(dependencies.hol),
        JSON.stringify(dependencies.ret),
        JSON.stringify(dependencies.zon),
        JSON.stringify(dependencies.nie),
        JSON.stringify(dependencies.reg),
        JSON.stringify(dependencies.ciu),
        JSON.stringify(dependencies.com),
    ])

    const onToggle = async(isOpen) => {
        if(isOpen && !fetched.current) {
            fetched.current = true; // Lista de comunas actualizados? SI
            await locales.fetch(dependencies);
        }
    }
    const handleOnChange = (values) => {
        const sorted = values.sort((a,b) => a.value - b.value)
        onChange(sorted, name)
    }

    return (
        <MultiSelect 
            value={selected}
            overrideStrings={localization}
            onChange={handleOnChange}
            options={locales.options}
            onMenuToggle={onToggle}
            disabled={isDisabled}
            isLoading={locales.isFetching}
        />
    )
}

export default MultiSelectLocales