import FirstPage from "Components/Botones/Firstpage";
import LastPage from "Components/Botones/LastPage";
import NextPage from "Components/Botones/NextPage";
import PrevPage from "Components/Botones/PrevPage";
import React, { useMemo } from "react";
import { Form, Spinner } from "react-bootstrap";
import { 
    useSortBy, 
    useTable,
    useRowSelect,
    useGlobalFilter, 
    useFilters,
    usePagination
} from "react-table";
import ColumnFilter from "./ColumnFilter";
import GlobalFilter from "./GlobalFilter";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

function TableRows ({page, prepareRow, tableData, cellClassName, loading }) {

    if (loading === true) {
        return (
            <tr>
                <td colSpan={"100%"} className="text-center">
                    <Spinner animation="border"/>
                </td>
            </tr>
        )
    }
    if(tableData.length === 0) {
        return (
            <tr>
                <td colSpan={"100%"} className="text-center">
                    Sin resultados
                </td>
            </tr>
        )
    }
    if(page.length === 0) {
        return (
            <tr>
                <td colSpan={"100%"} className="text-center">
                    No hay coincidencias
                </td>
            </tr>
        )
    }
    return(<>
        {// Loop over the table rows
        page.map(row => {
            // Prepare the row for display
            prepareRow(row)
            return (
                // Apply the row props
                <tr {...row.getRowProps()} >
                {// Loop over the rows cells
                row.cells.map(cell => {
                    // Apply the cell props
                    return (
                    <td className={cellClassName} {...cell.getCellProps([
                        {
                            className: cell.column.tdClassName,
                            style: cell.column.tdStyle
                        }
                    ])}>
                        {// Render the cell contents
                        cell.render('Cell')}
                    </td>
                    )
                })}
                </tr>
            )
            })}
    </>)

    
}

function Tabla({
    data,
    columns,
    globalFilter: hasGlobalFilter,
    titleSection,
    tableClass,
    theadClassName,
    cellClassName,
    itemsPerPageOptions = [5,10,15,20,50,100],
    defaultItemPerPage = 10,
    loading = false,
    select = false,
    onSelected = () => {}
    }){
    const tableData = useMemo(()=>data,[data]);
    const tableColumns = useMemo(()=> columns,[columns])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        prepareRow,
        state,
        pageOptions,
        gotoPage,
        // pageCount,
        setGlobalFilter,
        selectedFlatRows,
        state: {
            selectedRowIds,
        }
    } = useTable({
        columns: tableColumns, 
        data: tableData,
        initialState: {
            pageIndex: 0,
            pageSize: defaultItemPerPage
        },
        
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
        if(select === true) {
            hooks.visibleColumns.push(columns => [
                ...columns,
                // Let's make a column for selection
                {
                  id: 'selection',
                  // The header can use the table's getToggleAllRowsSelectedProps method
                  // to render a checkbox
                  Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div className="text-center">
                      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    </div>
                  ),
                  // The cell can use the individual row's getToggleRowSelectedProps method
                  // to the render a checkbox
                  Cell: ({ row }) => (
                    <div className="text-center">
                      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    </div>
                  ),
                },
                
            ])
        }
    }
    )
    const { globalFilter, pageIndex, pageSize } = state;

    React.useEffect(() => {
        const selected = selectedFlatRows.map(
            d => d.original
        )
        onSelected(selected)
        return selected
    },[selectedFlatRows])

    return (
        // apply the table props
        <>  
            <div >
                {hasGlobalFilter?(
                <div className="mb-2 float-end">
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/> 
                </div>
                ):null}
                <div>
                    {titleSection}
                </div>
            </div>

            <table {...getTableProps()} className={tableClass} >
                <thead className={theadClassName}>
                    {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {// Loop over the headers in each row
                        headerGroup.headers.map(column => (
                        // Apply the header cell props
                        <th {...column.getHeaderProps([
                            {
                                className: column.thClassName,
                                style: column.thStyle
                            },
                        ])}
                        >
                            <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? column.isSortedDesc ? '▼' : '▲': ''}
                                </span>
                            </div>
                            <div>
                                {column.filter? column.render(ColumnFilter):null}
                            </div>


                            {/* <span>
                                <i className={`mdi ${column.isSorted? (column.isSortedDesc? 'mdi-sort-ascending':'mdi-sort-descending'): ''}`} />

                            </span> */}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                    <TableRows 
                        page={page} 
                        prepareRow={prepareRow} 
                        tableData={tableData} 
                        cellClassName={cellClassName}
                        loading={loading}
                    />
                </tbody>
            </table>
            <div className="mt-2 d-flex align-items-baseline">
                <FirstPage
                    className="me-1"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                />
                <PrevPage 
                    className="me-1"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                />
                
                <NextPage 
                    className="me-1"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                />
                <LastPage 
                    className="me-1"
                    onClick={() => gotoPage(pageOptions.length-1)}
                    disabled={!canNextPage}
                />
                <span className="ms-1 me-1">
                    Página
                </span>

                <span>
                    <strong>
                        
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>
                </span>
                <span className="ms-auto"></span>
                <span className="me-2">
                    Ir a 
                </span>
                <span className="me-1">
                    <Form.Control 
                        size="sm"
                        type="number" 
                        defaultValue={pageIndex + 1}
                        style={{width: '4rem'}}
                        max={pageOptions.length}
                        onChange={e => {
                            console.log(e.target.value)
                            const pageNumber = e.target.value? Number(e.target.value) - 1: 0
                            console.log(pageNumber)
                            gotoPage(pageNumber)
                        }}
                    />
                </span>
                <span className="me-1">
                    Mostrar
                </span>
                <span>
                    <Form.Select 
                        value={pageSize} 
                        size="sm"
                        onChange={e => {
                            const size = Number(e.target.value);
                            setPageSize(size)
                        }}
                    >
                        {itemsPerPageOptions.map(num => <option key={num} value={num}>{num}</option>)}


                    </Form.Select>
                </span>
                
            </div>
                {/* {JSON.stringify({
                    selectedRowIds: selectedRowIds,
                    selectedFlatRows.map(
                        d => d.original
                    )
                })
                } */}
        </>
    )
}

export default Tabla;


// const columnas = [
//     {
//          Header: 'Código',
//          accessor: 'cod',
//          disableSortBy: true
//          thClassName: "some-class"
//          filter: true 
//     },
//      ...
//      ...
// ]


