import React from "react"
import { Modal } from "react-bootstrap"
import { atom, useRecoilValue, useRecoilState } from "recoil"

export const isDangerModalOpenState = atom({
    key: "isDangerModalOpen",
    default: false
})

export const dangerModalContentState = atom({
    key:"dangerModalContent",
    default: ""
})

function DangerModal () {
    const [isOpen, setIsOpen] = useRecoilState(isDangerModalOpenState)
    const contentModal = useRecoilValue(dangerModalContentState)
    const handleClose = () => setIsOpen(false);
    return (
        <Modal show={isOpen} onHide={handleClose} size="sm">
            <Modal.Body className="bg-danger text-white">
                <div className="text-center">

                    <i className="dripicons-wrong h1"></i>
                    <h4 className="mt-2">
                        ¡Peligro!
                    </h4>
                    {contentModal}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DangerModal