import clact from 'Connection/transacciones/clact'
import useFormulario from 'Hooks/Custom/useFormulario'
import InputTexto from "Components/FormElements/InputTexto"
import React from 'react'
import { Button, Spinner, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
import { validarEmail } from 'Utils/validations'
import Select from 'react-select';
import { Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { dangerModalContentState, isDangerModalOpenState } from 'Components/Modal/DangerModal'

const propsStyles = {
    asRow: true,
    labelSize:3,
    size: "sm"
}

function EditarDatosWeb ({datosUsuario}){
    const paises = [
        {
            label: "Chile",
            value: 56
        },{
            label: "Mexico",
            value: 52
        }
    ]
    // console.log(datosUsuario)
    const [isDisabled, setIsDisabled] = React.useState(false);
    const formulario = useFormulario({
        initialValues: {
            "name": datosUsuario.name,  // input
            "mail": datosUsuario.mail,  // input
            "phon": datosUsuario.phon,  // phon
            "ndoc": datosUsuario.rut,  // ndoc
            // "rex": datosUsuario.rex  // rex
            // "pai": datosUsuario.pai.split("-")[1]  // rex
            "pai": paises.filter(index=>index.value==datosUsuario.pai.split("-")[0] ) // rex
        },
        onSubmit: async({dirtyValues}) => {
            // console.log(dirtyValues)
            setIsDisabled(true)
            const request = await clact(datosUsuario.cid, dirtyValues);

            if(request.ans.stx === "ok"){
                toast.success(request.ans.msg)
                formulario.updateInitialValues();
                
                formulario.resetFieldValidation();
                // refreshUsers()
            }
            setIsDisabled(false)

        },

        validateOnSubmit: ({dirtyValues}) => {
            const errors = {}
            if(Object.keys(dirtyValues).length === 0){
                errors.submitValues = "No hay cambios que registrar."
                toast.error(errors.submitValues)
            }   
            return errors;
        },
    })

    // const defaultCheck = React.useMemo(() => {
    //     if(datosUsuario.rex === "si") return true
    //     else return false
    // },[datosUsuario.rex])
        
    const onNdocChange = React.useCallback((value) => {
        const FIELD = "ndoc"
        if (value.length <= 12){
            const re = /^[0-9-.k\b]+$/;
            if ((value === '' || re.test(value))) {
                formulario.setFieldValue(FIELD, value);       
                if(value === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== value) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
            }
        }
    },[])
        // Inputs formulario
        const onNameChange = React.useCallback((value) => {
            const FIELD = "name"
            if (value.length <= 50) {
                if(value === "") { 
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== value){
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
                formulario.setFieldValue(FIELD, value)
            }
        },[])
    
    
        const onMailChange = React.useCallback((value) => {
            const FIELD = "mail"
            if (value.length <= 48) {
                if(!validarEmail(value)){
                    formulario.setFieldIsInvalid(FIELD) // false: hay error
                } else if (formulario.initialValues[FIELD] !== value){
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
                formulario.setFieldValue(FIELD, value)
            }
        },[])
    
        const onPhonChange = React.useCallback((value) => {
            const FIELD = "phon"
            if (value.length <= 12) {
                const re = /^[0-9\b]+$/;
                if ((value === '' || re.test(value))) {
                    formulario.setFieldValue(FIELD, value);
                    if(value === '') {
                        formulario.setFieldIsInvalid(FIELD)
                    } else if (formulario.initialValues[FIELD] !== value) {
                        formulario.setFieldIsValid(FIELD)
                    } else {
                        formulario.setFieldIsUndefined(FIELD)
                    }
                }
            }
        },[])
        
        // const onRexChange = React.useCallback((value) => {
        //     const FIELD = "rex"
        //     if(value === "") { 
        //         formulario.setFieldIsInvalid(FIELD)
        //     } else if (formulario.initialValues[FIELD] !== value){
        //         formulario.setFieldIsValid(FIELD)
        //     } else {
        //         formulario.setFieldIsUndefined(FIELD)
        //     }
        //     formulario.setFieldValue(FIELD, value)
        // },[])
        
        const onPaisChange = React.useCallback((value) => {
            const FIELD = "pai"
            if(value === "") { 
                formulario.setFieldIsInvalid(FIELD)
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        },[])
            
        
return (
    <>
    <h4>
         Datos del Cliente
    </h4>
    <div>
    <div className="mb-1">
    </div>
    <div className="mb-1">
                            <InputTexto 
                                {...propsStyles}
                                label="Rut"
                                name="ndoc"
                                disabled={isDisabled}
                                value={formulario.values.ndoc}
                                validation={formulario.fieldValidation.ndoc}
                                onChange={onNdocChange}
                            />   
                        </div>
    <div className="mb-1">
                 <InputTexto
                        {...propsStyles}
                        label="Nombre(s)"
                        name={"name"}
                        value={formulario.values.name}
                        onChange={onNameChange}
                        validation={formulario.fieldValidation.name}
                        disabled={isDisabled}
                 />
    </div>
    <div className="row">
                            <div className="col-sm-3">
                                <label> Pais</label>
                            </div>
                            <div className="col-sm-9">
                                <Select
                                    placeholder="Elegir un pais"
                                    value={formulario.values.pai}
                                    onChange={ onPaisChange}
                                    options={paises}
                                    // validation={clienteValido}
                                />
                            
                            </div>
                        </div>    
                {/* <div className="mb-1">
                    <Form>
                        <Form.Label column sm={propsStyles.labelSize}> ¿Reponedores exclusivos?</Form.Label>
                         
                        <Form.Check
                            inline
                            label="Si"
                            name="group1"
                            defaultChecked={defaultCheck}
                            onChange={()=>onRexChange("si")}
                            type={"radio"}
                            // id={`inline-radio`}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="group1"
                            defaultChecked={!defaultCheck}
                            onChange={()=>onRexChange("no")}
                            type={"radio"}
                        />
                    </Form>
                </div> */}
    
             <h4>
                 Contacto
             </h4>
             <div className="mb-1">
                 <InputTexto 
                        {...propsStyles}
                        label={"Mail"}
                        name={"mail"}
                        disabled={isDisabled}
                        value={formulario.values.mail}
                        validation={formulario.fieldValidation.mail}
                        onChange={onMailChange}
                 />
             </div>
             <div className="mb-1">
                 <InputTexto 
                        {...propsStyles}
                        label="Teléfono"
                        name="phon"
                        disabled={isDisabled}
                        value={formulario.values.phon}
                        onChange={onPhonChange}
                        validation={formulario.fieldValidation.phon}
                 />   
             </div>
            </div>
            <div className='d-flex justify-content-end'>
                    <Button variant="info" onClick={formulario.onSubmit} disabled={formulario.haveInvalidFields || !formulario.haveValidFields }>
                        Guardar cambios
                    </Button>
                </div>
            </>
            )
}

export default EditarDatosWeb;
