import React from "react";
import { Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import Tabla from 'Components/Tabla/Tabla';
import FiltroPrecios from "./filtroPrecios";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import toast from "react-hot-toast";
import { formatearMDA } from "Utils/funcionesFechaHora";
import Select from 'react-select';
import useListaLocalesPrecios from "Hooks/Listas/useListaLocalesPrecios";
import calen from "Connection/transacciones/calen";
import { assign } from "lodash";
import { formatPrice } from "Utils/precio";
const thClassName = "ps-1"

function Precios() {
    const navigate = useNavigate()


    const [selectedOption, setSelectedOption] = React.useState(null)
    const [unicCalenda, setUnicCalenda] = React.useState(null)
    const [tableResumen, setTableResumen] = React.useState([])


    const getData = useLocation().state
    React.useEffect(() => {
        const run = async () => {
            setSelectedOption(getData.fechaSelected)
            setUnicCalenda(getData.unicCalenda)
        }
        run()
        // console.log(getData.fechaSelected)
        // console.log(unicCalenda)

    }
        , [getData])

    const Locales = useListaLocalesPrecios({
        dataOption: 1
    })

    const columnasResumen = React.useMemo(() => [
        {
            Header: 'Tareas Calendarizadas',
            accessor: 'ttc',
            thClassName
        }, {
            Header: 'Tareas Finalizadas',
            accessor: 'ttf',
            Cell: (props) => {
                const {value, row} = props
                return (
                    <div className="progress" style={{ width: "120px", backgroundColor: "lightGray", textAlign: "center" }}>
                        <div className="progress-bar progress-bar-striped" style={{ width: String(((row.original.ttf / row.original.ttc) * 100).toFixed(0)) + "%", textAlign: "center" }}>{row.original.ttf}</div>
                    </div>
                )
            },
            thClassName
        }, {
            Header: 'Presupuesto Total',
            accessor: 'prt',
            Cell: (props) => {
                const {value, row} = props
                return (
                    <div>
                        {formatPrice(value)}
                    </div>
                )
            },
            thClassName
        }, {
            Header: 'Presupuesto Disponible',
            accessor: 'pds',
            Cell: (props) => {
                const {value, row} = props
                return (
                    <div>
                        {formatPrice(value)}
                    </div>
                )
            },
            thClassName
        }, {
            Header: 'Total a pagar',
            accessor: 'tap',
            Cell: (props) => {
                const {value, row} = props
                return (
                    <div>
                        {formatPrice(value)}
                    </div>
                )
            },
            thClassName
        },
    ], [])


    const compFiltros = (
        <>
            {unicCalenda ? <FiltroPrecios
                totalLocales={Locales.values.length}
                fetchLocales={Locales.fetchLocales}
                fecha={selectedOption}
                calenda={unicCalenda}
                timeStamp={Locales.totalValues.tst}
            /> : <></>}
            {(Locales.totalValues.prt) ?
                <>
                    
                    
                    <div className="row" style={{ fontSize: "17px" }}>
                    <div className="col" style={{ textAlign: "right" }}>
                                    <OverlayTrigger placement={"left"} overlay={
                                        <Tooltip id="tooltip-disabled" >
                                            <div style={{ fontSize: "12.5px" }}>NTC: N° Tareas Calendarizadas</div>
                                            <div style={{ fontSize: "12.5px" }}>NTF: N° Tareas Finalizadas</div>
                                        </Tooltip>}>
                                        <i className={"mdi mdi-help-circle-outline"}
                                            style={{ fontSize: "30px", color: "#44BADC" }}
                                            rel="tooltip"
                                            data-toggle="tooltip"
                                            data-placement="top" >
                                        </i>
                                    </OverlayTrigger>
                                </div>
                    </div>
                    {/* <br /> */}
                    <div>
                        {/* <div className="card-header">
                    <div className="row">
                        <div className="col" style={{fontWeight:"bold", fontSize:"25px", textAlign:"center"}}>
                            Resumen
                        </div>
                    </div>
                </div> */}
                        {/* <Tabla2
                            columns={columnasResumen}
                            data={[Locales.totalValues]}
                            globalFilter={true}
                            // titleSection={}
                            tableClass={"table table-centered table-hover  mb-0"}
                            theadClassName={"table-light"}
                            cellClassName={"p-1"}
                            loading={Locales.isFetching}
                            defaultItemPerPage={1}

                        /> */}
                        <table className="table table-centered table-hover  mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th className="ps-1" style={{ textAlign: "center" }}>Tareas Calendarizadas:</th>
                                    <th className="ps-1" style={{ textAlign: "center"}}>{Locales.totalValues.ttc}</th>
                                    <th className="ps-1" style={{ textAlign: "center",backgroundColor:"white"  }}>Tareas Finalizadas:</th>
                                    <th className="ps-1" style={{ textAlign: "center",backgroundColor:"white"  }}>
                                        <div className="progress" style={{ width: "120px", backgroundColor: "lightGray", textAlign: "center" }}>
                                            <div className="progress-bar progress-bar-striped" style={{ width: String(((Locales.totalValues.ttf / Locales.totalValues.ttc) * 100).toFixed(0)) + "%", textAlign: "center" }}>{Locales.totalValues.ttf}</div>
                                        </div>
                                    </th>
                                    <th className="ps-1" style={{ textAlign: "center" }}>Presupuesto Total:</th>
                                    <th className="ps-1" style={{ textAlign: "center" }}>{formatPrice(Locales.totalValues.prt)}</th>
                                    <th className="ps-1" style={{ textAlign: "center",backgroundColor:"white"  }}>Presupuesto Disponible:</th>
                                    <th className="ps-1" style={{ textAlign: "center",backgroundColor:"white"  }}>{formatPrice((Locales.totalValues.prt - Locales.totalValues.tap))}</th>
                                    <th className="ps-1" style={{ textAlign: "center" }}>Total a pagar:</th>
                                    <th className="ps-1" style={{ textAlign: "center" }}>{formatPrice(Locales.totalValues.tap)}</th>
                                    
                                </tr>
                            </thead>
                        </table>
                        <div>
                            <div className="row" style={{ fontSize: "17px" }}>
                                {/* <div className="col" style={{ fontWeight: "bold", verticalAlign: "" }}>
                                    Tareas Calendarizadas:
                                </div>
                                <div className="col" style={{ textAlign: "left", height: "34px", verticalAlign: "center" }}>
                                    {Locales.totalValues.ttc}
                                </div>
                                <div className="col" style={{ fontWeight: "bold" }}>
                                    Tareas Finalizadas:
                                </div>
                                <div className="col">
                                    <div className="progress" style={{ width: "120px", backgroundColor: "lightGray", textAlign: "center" }}>
                                        <div className="progress-bar progress-bar-striped" style={{ width: String(((Locales.totalValues.ttf / Locales.totalValues.ttc) * 100).toFixed(0)) + "%", textAlign: "center" }}>{Locales.totalValues.ttf}</div>
                                    </div>
                                </div>
                                <div className="col" style={{ fontWeight: "bold" }}>
                                    Presupuesto Total:
                                </div>
                                <div className="col" style={{ textAlign: "left" }}>
                                    {formatPrice(Locales.totalValues.prt)}
                                </div>
                                <div className="col" style={{ fontWeight: "bold" }}>
                                    Presupuesto Disponible:
                                </div>
                                <div className="col" style={{ textAlign: "left" }}>
                                    {formatPrice((Locales.totalValues.prt - Locales.totalValues.tap))}
                                </div>

                                <div className="col" style={{ fontWeight: "bold" }}>
                                    Total a pagar:
                                </div>
                                <div className="col" style={{ textAlign: "left" }}>
                                    {formatPrice(Locales.totalValues.tap)}
                                </div> */}

                                {/* <div className="col" style={{ textAlign: "right" }}>
                                    <OverlayTrigger placement={"left"} overlay={
                                        <Tooltip id="tooltip-disabled" >
                                            <div style={{ fontSize: "12.5px" }}>NTC: N° Tareas Calendarizadas</div>
                                            <div style={{ fontSize: "12.5px" }}>NTF: N° Tareas Finalizadas</div>
                                        </Tooltip>}>
                                        <i className={"mdi mdi-help-circle-outline"}
                                            style={{ fontSize: "30px", color: "#44BADC" }}
                                            rel="tooltip"
                                            data-toggle="tooltip"
                                            data-placement="top" >
                                        </i>
                                    </OverlayTrigger>
                                </div> */}
                            </div>
                            {/* <br/>
                        <div className="row" style={{textAlign:"center", fontSize:"17px"}}>
                            <div className="col" style={{fontWeight:"bold"}}>
                                Presupuesto Total:
                            </div>
                            <div className="col" style={{textAlign:"left"}}>
                                {Locales.totalValues.prt}
                            </div>
                            <div className="col" style={{fontWeight:"bold"}}>
                                Total a pagar:
                            </div>
                            <div className="col" style={{textAlign:"left"}}>
                                {Locales.totalValues.tap}
                            </div>
                        </div> */}
                        </div>
                    </div>
                </>
                : <>

                    <div className="row">
                        <div className="col" style={{ textAlign: "right" }}>
                            <OverlayTrigger placement={"left"} overlay={
                                <Tooltip id="tooltip-disabled" >
                                    <div style={{ fontSize: "12.5px" }}>NTC: N° Tareas Calendarizadas</div>
                                    <div style={{ fontSize: "12.5px" }}>NTF: N° Tareas Finalizadas</div>
                                </Tooltip>}>
                                <i className={"mdi mdi-help-circle-outline"}
                                    style={{ fontSize: "30px", color: "#44BADC" }}
                                    rel="tooltip"
                                    data-toggle="tooltip"
                                    data-placement="top" >
                                </i>
                            </OverlayTrigger>
                        </div>
                    </div>
                </>

            }

            {/* <div className="row">
                <div className="col" style={{textAlign:"right"}}>
                    <OverlayTrigger placement={"left"} overlay={
                        <Tooltip id="tooltip-disabled" >
                            <div style={{fontSize:"12.5px"}}>NTC: N° Tareas Calendarizadas</div>
                            <div style={{fontSize:"12.5px"}}>NTF: N° Tareas Finalizadas</div>
                        </Tooltip>}>
                        <i className={"mdi mdi-help-circle-outline"}
                            style={{fontSize:"30px", color:"#44BADC"}}
                            rel="tooltip"
                            data-toggle="tooltip" 
                            data-placement="top" >
                        </i>
                    </OverlayTrigger>
                </div>
            </div> */}
            <br />
        </>
    )

    const columnas = React.useMemo(() => [
        {
            Header: 'Codigo Local',
            accessor: 'cod',
            thClassName
        },
        {
            Header: 'Nombre Local',
            accessor: 'nam',
            thClassName
        },
        {
            Header: 'Direccion',
            accessor: 'adr',
            thClassName
        },
        {
            Header: 'Comuna',
            accessor: 'com',
            thClassName
        },
        {
            Header: 'NTC',
            accessor: 'ntc',
            thClassName
        },
        {
            Header: 'NTF',
            accessor: 'ntf',
            Cell: (props) => {
                const { value, row } = props
                return (
                    <div className="progress" style={{ width: "120px", backgroundColor: "lightGray" }}>
                        <div className="progress-bar progress-bar-striped" style={{ width: String(((value / row.original.ntc) * 100).toFixed(0)) + "%" }}>{value}</div>
                        {/* <div className="progress-bar progress-bar-striped" style={{width:String(((value/row.original.ntc)*100).toFixed(0))+"%"}}>{((value/row.original.ntc)*100).toFixed(0)}%</div> */}
                    </div>
                )
            },
            thClassName
        },
        // {
        //     Header: 'NTF',
        //     accessor: 'ntf',
        //     thClassName
        // },
        {
            Header: '$ Local',
            accessor: 'prv',
            Cell: (props) => {
                const { value } = props
                return (
                    <div>
                        {formatPrice(value)}
                    </div>
                )
            },
            thClassName
        },
        {
            Header: '$ Tarea',
            accessor: 'put',
            Cell: (props) => {
                const { value } = props
                return (
                    <div>
                        {formatPrice(value)}
                    </div>
                )
            },
            thClassName
        },
        {
            Header: 'Valor a Pagar',
            accessor: 'vap',
            Cell: (props) => {
                const { value } = props
                return (
                    <div>
                        {formatPrice(value)}
                    </div>
                )
            },
            thClassName
        },

    ], [])






    if (getData === undefined) {
        navigate("/presupuesto")
    }
    else {
        return (
            <>
                <Card>
                    <Card.Body>
                        <Tabla
                            columns={columnas}
                            data={Locales.values}
                            globalFilter={true}
                            titleSection={compFiltros}
                            tableClass={"table table-centered table-hover  mb-0"}
                            theadClassName={"table-light"}
                            cellClassName={"p-1"}
                            loading={Locales.isFetching}
                        />
                    </Card.Body>
                </Card>
                {/* <Button onClick={()=>console.log(Locales)}></Button> */}
            </>
        )
    }
}
export default Precios