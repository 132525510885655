import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import { formatearFechaHora, formatearHoraFecha} from "Utils/funcionesFechaHora";
import FotoTareaTicket from '../ResumenTickets/_ResumenTareas/FotoTareaTicket';
import Tabla from 'Components/Tabla/Tabla';
import FotoTareaTicketDisplay from './PicDisplay';


const thClassName= "ps-1"

const SeleccionMultiple = ({alternativasSeleccionadas}) => {
    return(
        <>
            {alternativasSeleccionadas.split("~").map((item,index)=>(
                <li key={"alt-"+index}>
                    {item}
                </li>
            ))}
        </>
    )
}

function Foto ({idRequerimiento, idTarea }) {
    // console.log('foto', idRequerimiento, idTarea)
    return(
        <FotoTareaTicket 
            idTicket={idTarea}
            idRequerimiento={idRequerimiento}
        />
            // <FotoTareaTicketDisplay 
            // idTicket={539}
            // idRequerimiento={1}
            // />
    )
}

const FechaHora = ({fechaYHora}) => {
    return(formatearHoraFecha(fechaYHora)
        
    )
}


function SwitchResponse({type,response, item, ticketId}) {
    switch (type) {
        case 1:
            return response
        case 2:
            return response
        case 3:
            return <SeleccionMultiple alternativasSeleccionadas={response}/>
        case 4:
            return response
        case 7:
            return "Reposición realizada"
        case 5:
            return <Foto 
                        idTarea={ticketId}
                        idRequerimiento={item.qid} 
                        // fotoBase64={item.ant} 
                    />
        case 6:
            return <FechaHora  fechaYHora={response} />
        default:
            return response
        
    }
}

function ImagenDisplay ({ticketReq, numReq}){
    // console.log("task: ", ticketReq, "req: ", numReq)
    const [firstDisplay, setFirstDisplay] = useState(true)

    const columnas = React.useMemo(() => [
        {
            Header: 'Requerimientos',
            accessor: 'qid',
            thClassName
        },{
            Header: 'Descripción',
            accessor: 'tiq',
            thClassName
        },{
            Header: 'Respuesta',
            accessor: 'aty',
            thClassName,
            Cell: props => {
                const {value,row}=props;
                return <SwitchResponse type={value} response={row.original.ant} item={row.original} ticketId={ticketReq.ans.tid}/>
            }
        }

    ] ,[ticketReq])

    return (
        <>
            <FotoTareaTicketDisplay 
            idTicket={Number(ticketReq.ans.tid)}
            idRequerimiento={Number(numReq)}
            />

            <Card>
                <br></br>
                <br></br>
            <div className="row text-center">
                            <h3>
                                <span className="text-info">{ticketReq && ticketReq.ans.lid.split(":")[2]}</span>
                            </h3>
                    </div>
                    <div className="row text-center">
                         <Row>
                        <Col>
                        <div style={{fontSize:"16px"}}>
                            <b>Direccion:</b> {ticketReq && ticketReq.ans.adr}
                        </div>
                        <div style={{fontSize:"16px"}}>
                            <b>Region:</b> {ticketReq && ticketReq.ans.reg}
                        </div>
                        </Col> 
                        <Col>
                        <div style={{fontSize:"16px"}}>
                            <b>Inicio tarea:</b> {formatearFechaHora(ticketReq.ans.tsi)} {(ticketReq.ans.ubi === "e")? <OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">En el local</Tooltip>}>
                        <i
                            className={"mdi mdi-map-marker-radius"}
                            style={{fontSize:"25px", color:"#638AFC"}}
                            rel="tooltip"
                            data-toggle="tooltip" 
                            data-placement="top" >
                        </i></OverlayTrigger> 
                    :<OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">Fuera del local</Tooltip>}><i 
                        className={"mdi mdi-map-marker-off"}
                        style={{fontSize:"25px", color:"#FC6363"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" >
                    </i></OverlayTrigger>} ({ticketReq.ans.dse} metros)                        
                        </div>
                        <div style={{fontSize:"16px"}}>
                            <b>Fin tarea:</b> {formatearFechaHora(ticketReq.ans.tse)} {(ticketReq.ans.ube === "e")? <OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">En el local</Tooltip>}>
                        <i 
                            className={"mdi mdi-map-marker-radius"}
                            style={{fontSize:"25px", color:"#638AFC"}}
                            rel="tooltip"
                            data-toggle="tooltip" 
                            data-placement="top" >
                        </i></OverlayTrigger> 
                    :<OverlayTrigger placement={"left"} overlay={<Tooltip id="tooltip-disabled">Fuera del local</Tooltip>}><i 
                        className={"mdi mdi-map-marker-off"}
                        style={{fontSize:"25px", color:"#FC6363"}}
                        rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top" >
                    </i></OverlayTrigger>} ({ticketReq.ans.dsi} metros)                            
                        </div>
                        </Col> 
                    </Row>
                    <Card.Body>
                        <Row className="justify-content-md-center">
                            <Col md="10"  >
                    <Tabla
                        columns={columnas}
                        data={ticketReq.ans.tas}
                        titleSection={""}
                        tableClass={"table table-centered table-hover  mb-0"}
                        theadClassName={"table-light"}
                        cellClassName={"p-1"}
                    />
                    </Col>
                    </Row>
                    </Card.Body>
                    </div>
                    </Card>
        </>
    )
}
export default ImagenDisplay