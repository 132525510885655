import wenew from "Connection/transacciones/wenew.js";
import geprf from "Connection/transacciones/geprf.js";
import {Button} from "react-bootstrap";
import toast from 'react-hot-toast';
import useFormulario from 'Hooks/Custom/useFormulario';
import InputTexto from "Components/FormElements/InputTexto";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import React from 'react';
import { getClientID, getProfileOptions, getUser } from "Authentication/Session";
import { MultiSelect } from "react-multi-select-component";
import localization from "Assets/localization/multiSelect.json";
import { customValidateRut, rutRegexDots } from "Utils/rut";
import { phoneRegex, validarEmail } from 'Utils/validations';
// import "./styles.css";

const propsStyles = {
    asRow: true,
    labelSize:3,
    size: "sm"
}

function AgregarUsuario(Clientes){
    const thisProfileOptions = React.useRef(getProfileOptions());
    const [perfilOpc, setperfilOpc] = React.useState([])//perfiles del usuario
    React.useEffect(() => {
        const run = async() => { 
            const geprfRes = await  geprf({});
            const perfilArray = thisProfileOptions.current.split("")    
            let optionsList = [] 
            if (geprfRes.ans.stx === "ok") {
                const options = geprfRes.ans.prf.map(opt => ({label: opt.nam, value: opt.cod})); 
                options.forEach((value, index)=> {
                if (perfilArray[index] === '1') { //
                    optionsList.push(value);
                    }
                })
                setperfilOpc(optionsList)   
                }
            }
        run(); 
    }, []);
    
    // console.log(Clientes.Clientes)
    const navigation = useNavigate()
    const [isDisabled, setIsDisabled] = React.useState(false);
    //Inputs Formulario
    const [rut, setRut] = React.useState("");
    const [nombre, setNombre] = React.useState("");
    const [apellidos, setApellidos] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [perfilUsuario, setPerfilUsuario] = React.useState([]);
    const [cliente, setCliente] = React.useState([]);
    // Handle onChange
    const onChangeRut = (value) => {
        if (rutRegexDots.test(value)) setRut(value.toUpperCase());
    }
    const onChangeNombre = (value) => setNombre(value);
    const onChangeApellido = (value) => setApellidos(value);
    const onChangeEmail = (value) => setEmail(value);
    const onChangeTelefono = (value) => {
        if(phoneRegex.test(value)) setTelefono(value)
    } 

    // Validadores
    const rutValido = React.useMemo(()=> customValidateRut(rut),[rut]);
    const nombreValido = React.useMemo(() => {
        if(nombre === "") return undefined
        else return true
    } ,[nombre])
    const apellidoValido = React.useMemo(() => {
        if(apellidos === "") return undefined
        else return true
    } ,[apellidos])
    const emailValido = React.useMemo(()=> {
        if(email === "") return undefined
        else return validarEmail(email);
    },[email])
    const telefonoValido = React.useMemo(()=> {
        if(telefono === "") return undefined
        else return true;
    },[telefono])
    const perfilValido = React.useMemo(()=> {
        if(perfilUsuario.length === 0) return undefined
        else return true;
    },[perfilUsuario])
    const clienteValido = React.useMemo(()=> {
        if (getClientID()===0){
            if(cliente.value) return true
            else return undefined;}
        else return true;
    },[cliente])


    //Handler
    const [isSubmiting, setIsSubmiting] = React.useState(false);
    const handleSubmit = async() => {
        setIsSubmiting(true);
        const request = await wenew({
            newRut: rut,
            newName: nombre,
            newSnam: apellidos,
            newPhon: telefono,
            newMail: email,
            newProfile : perfilUsuario.reduce((total, currentValue) => total = total + currentValue.value,0),
            newClient : cliente.value,
            newUst: "web"
        })
        // console.log("cliente",cliente)
        // console.log("request",request)
        if(request.ans.stx === "ok") {
            toast.success(request.ans.msg)
            navigation('..')
        } else if(request.ans.stx === "rk") {
            toast.error((t) => (<>
                {request.ans.msg}
            </>) )

        }
        setIsSubmiting(false);
    }

    return (
      <>
        <div className="card">
            <div className="card-body">
                    <div className="card-header detalle-ticket-header">
                        <h5 className="card-title mb-0" >
                            Datos generales
                            </h5>
                        </div>
                <div className="card-body pb-1 pt-2 detalle-ticket-body">
                    <div className="mb-1">
                        <InputTexto 
                            {...propsStyles}
                            label="Rut"
                            disabled={isSubmiting}
                            value={rut}
                            onChange={onChangeRut}
                            validation={rutValido}
                        />   
                    </div>
                    <div className="mb-1">
                        <InputTexto
                                {...propsStyles}
                                label="Nombre(s)"
                                value={nombre}
                                disabled={isSubmiting}
                                onChange={onChangeNombre}
                                validation={nombreValido}
                        />
                    </div>
                    <div className="mb-1">
                        <InputTexto
                            {...propsStyles}
                            label={"Apellidos"}
                            onChange={onChangeApellido}
                            disabled={isSubmiting}
                            value={apellidos}
                            validation={apellidoValido}
                        />
                    </div>
                    <div className="mb-1">
                        <h5>
                            Cuenta
                            </h5>
                        </div>
                    <div className="mb-2">
                        <div className="row">
                            <div className="col-sm-3">
                                <label> Perfil de usuario</label>
                            </div>
                            <div className="col-sm-9">
                                <MultiSelect
                                    overrideStrings={localization}
                                    name="perfil"
                                    placeholder="Test"
                                    value={perfilUsuario}
                                    selected = {perfilUsuario}
                                    onChange={setPerfilUsuario}
                                    options={perfilOpc}
                                    validation={perfilValido}
                                />
                            </div>
                        </div> 
                    </div>
                    {(getClientID()) === '0' &&                    
                    <div className="mb-2">
                        <div className="row">
                            <div className="col-sm-3">
                                <label> Cliente</label>
                            </div>
                            <div className="col-sm-9">
                                <Select
                                    placeholder="Elegir un cliente"
                                    value={cliente}
                                    onChange={ opt => setCliente(opt)}
                                    options={Clientes.Clientes}
                                    validation={clienteValido}
                                />
                            </div>
                        </div> 
                    </div>
                  }

                    <div className="mb-1">
                                        <h5>
                                            Contacto
                                            </h5>
                        </div>
                    <div className="mb-1">
                        <InputTexto 
                            {...propsStyles}
                            label={"Mail"}
                            disabled={isSubmiting}
                            value={email}
                            onChange={onChangeEmail}
                            validation={emailValido}
                        />
                    </div>
                    <div className="mb-1">
                        <InputTexto 
                                {...propsStyles}
                                label="Teléfono"
                                disabled={isSubmiting}
                                value={telefono}
                                onChange={onChangeTelefono}
                                validation={telefonoValido}
                        />   
                    </div>
                <div className="d-flex justify-content-end">
                <Button variant="primary"  onClick={handleSubmit} disabled={!(rutValido&&
                                                                            nombreValido&&
                                                                            emailValido&&telefonoValido&&
                                                                            perfilValido&&clienteValido)}>
                Guardar Usuario
                </Button>
                </div>
                </div>
            {/* <Button onClick={()=>console.log(perfilOpc)}>asdasd</Button> */}
            </div>
        
        </div>
    </>

  );

}

export default AgregarUsuario;