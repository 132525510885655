import React from "react";
import ImageViewer from "react-simple-image-viewer";
import buttonRF from "Assets/images/rpbtn.jpg"
import { Spinner } from "react-bootstrap";
import gepic from "Connection/transacciones/gepic";

function FotoTareaTicket ({idTicket, idRequerimiento}) {
    const [srcFoto, setSrcFoto] = React.useState(null);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(false)
    const imageFetched = React.useRef(false);

    const handleOnClick = async() => {
        if(imageFetched.current === false ) {
            setIsFetching(true);
            const request = await gepic(idTicket, idRequerimiento);
            if(request.ans.stx === "ok") {
                setSrcFoto([`data:image/jpg;base64,${request.ans.pic}`])
                imageFetched.current = true;
            }
            setIsFetching(false)
        }
        setIsViewerOpen(true);
    }

    return (
        <>
        {
            isFetching? (
                <Spinner animation="border" size="sm" />
            ):(
                <img
                    src={buttonRF}
                    onClick={handleOnClick}
                    alt=""
                    type="button"
                />
            )
        }
            
            {isViewerOpen && (
                <ImageViewer
                    src={srcFoto}
                    currentIndex={0}
                    onClose={() => setIsViewerOpen(false)}
                    disableScroll={false}
                    backgroundStyle={{backgroundColor: "rgba(0,0,0,0.9)"}}
                    closeOnClickOutside={true}
                />
            )

            } 
        </>
    )
}

export default FotoTareaTicket