import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import TablaClientes from "./TablaClientes";
import { actualizarInfoPagina } from "Utils/paginaInfo";

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Clientes</li>
                </ol>
            </div>
            <h4 className="page-title">Clientes</h4>
        </div>
    )
    }

    function PaginaGestionUsuariosWeb(props) {
        useEffect(() => { actualizarInfoPagina(props) }, [props]);
        return (           
            <div className='container'>    
                <TituloPagina />
                <TablaClientes />              
            </div>
        )
}
export default PaginaGestionUsuariosWeb