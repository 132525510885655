import React from "react"
import { Link } from "react-router-dom";

// function MenuTickets ({creacionTicket, tickets, calendarizaciones}){
    function MenuTickets ({creacionTicket, tickets, calendarizaciones, reporte}){
    // if(!(creacionTicket || tickets || calendarizaciones)) {
        if(!(creacionTicket || tickets || calendarizaciones || reporte)) {
        return null
    }
    return (
        <li className="nav-item dropdown">
            <a href="/#" className="nav-link dropdown-toggle arrow-none" id="topnav-apps" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="uil-apps me-1"></i>Tickets
                <div className="arrow-down"></div>
            </a>
            <div className="dropdown-menu" aria-labelledby="topnav-apps">
                {creacionTicket &&  <Link to={"/CrearTicket"} className="dropdown-item">Creación de Ticket</Link>}
                {tickets && <Link to={"/ListaTickets"} className="dropdown-item">Lista de Tickets</Link>}
                {calendarizaciones && <Link to={"/Calendarizaciones"} className="dropdown-item">Lista de Calendarizaciones</Link>}
                {reporte && <Link to="/Reportes" className="dropdown-item">Reporte Repoflex</Link>}
            </div>
        </li>
    )
}

export default MenuTickets