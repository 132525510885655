import geloc from "Connection/transacciones/geloc"
import React from "react"

function useLocalesOpciones(initialValues = []){
    const [options, setOptions] = React.useState(initialValues)
    const [isFetching, setIsFetching] = React.useState(false)

    const fetch = async(dependencies = {}) => {
        setIsFetching(true)
        const request = await geloc(dependencies);
        if(request.ans.stx === "ok") {
            if(request.ans.loc) {
                const newValues = request.ans.loc.map(item => ({
                    "value": item.lid,
                    "label": item.nam,   
                }))
                setOptions(newValues)
            } else {
                setOptions([])
            }
        }
    
        setIsFetching(false)

    }

    return {
        options,
        isFetching,
        fetch
    }
}


export default useLocalesOpciones
