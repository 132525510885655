import { requestData } from "Connection/transacciones"
import { fechaDefault, limpiarFecha } from "Utils/funcionesFechaHora"

const tasko = async (idTarea) => {
    const jsonBody = {
        hdr: {
            txi: 51,
            req: "tasko",
            ott: true,
        },
        dat: {
            tid : idTarea.idTarea,
            res : idTarea.resp,
            cdr : idTarea.detail,
            obj : idTarea.ob
        }
    }
    // console.log("tasko",jsonBody) 
    return await requestData(jsonBody)
}

export default tasko;