import React from "react";


const coloresPorEstrellas = [
    [],
    ["#57bb8a","#e67c73"],
    ["#57bb8a","ffd666","e67c73"],
    ["#57bb8a","#c7cd72","#f7b96a","#e67c73"],
    ["#57bb8a","#abc878","#ffd666","#f3a96c","#e67c73"],
    ["#57bb8a","#9ac57c","#ddd06e","#fbc568","#f0a06d","#e67c73"],
    ["#57bb8a","#8fc47e","#c7cd72","#ffd666","#f7b96a","#ef9b6e","#e67c73"],
    ["#57bb8a","#81c181","#abc878","#d5cf6f","#ffd666","#f3a96c","#ed936f","##e67c73"],
    ['#57bb8a','#81c181','#abc878','#d5cf6f','#ffd666','#f9c069','#f3a96c','#ed936f','#e67c73'],
    ["#57bb8a","#7cc182","#a1c77a","#c7cd72","#ecd36a","#fdcc67","#f7b96a","#f2a46d","#ec9170","#e67c73"],
]


function ValorEstrellas ({sumItem}) {
    // const add = (accumulator, a) => accumulator + a; // funcion para contar los votos
    const maxEstrellas = sumItem.fre.length;
    const full = Math.floor(sumItem.avg/1);     // estrellas enteras
    const half = sumItem.avg % 1 > 0? 1:0       // media estrella
    const empty = maxEstrellas - (full + half)  // estrella vacia
    const bgColores = coloresPorEstrellas[maxEstrellas - 1];
    const moda = sumItem.mod.split("-")[0];
    const maxLargo = 100;
    const arrayEstrellas = []
    for (let i = 0; i < full; i++) 
        arrayEstrellas.push(<span className="mdi mdi-star" key={"full-"+ i}></span>);
    for (let i = 0; i < half; i++) 
        arrayEstrellas.push(<span className="mdi mdi-star-half-full" key={"half-"+ i}></span>);
    for (let i = 0; i < empty; i++) 
        arrayEstrellas.push(<span className="mdi mdi-star-outline" key={"empty-"+ i}></span>);
    
    
        return(
            <div>
            <h5>
                Valorización
            </h5>
            <h3>
                {sumItem.txt}
            </h3>
            
            <div className="row">
                <div className="col text-center">
                    <h1>
                        {sumItem.avg}
                    </h1>
                    <span className="text-warning">

                        {arrayEstrellas}
                    </span>
                    <br />
                    <small>
                        Total de calificaciones: {sumItem.add} 
                    </small>
                </div>
                <div className="col">
                    {
                        sumItem.fre.slice(0).reverse().map((frecuencia, index)=>{

                            return (
                                <div className="row d-flex align-items-center" key={index}>
                                    <div className="col-2 d-flex justify-content-end">
                                    <span className="mdi mdi-star"></span> {5-index}
                                    </div>
                                    <div className="col">
                                        <div className="progress mt-1 progress-xl" style={{ height:"23px"}}>
                                            <div className="progress-bar bg-successs" role="progressbar" style={{width: `${(frecuencia/sumItem.fre[moda-1])*maxLargo}%`, backgroundColor: bgColores[index]}}>
                                                <strong>
                                                    {frecuencia}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default ValorEstrellas