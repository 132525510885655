import React,{ useEffect } from "react";
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import AgregarUsuario from './AgregarUsuario'
import clall from "Connection/transacciones/clall";
import { result } from "lodash";


function TituloPagina() {
  return (
      <div className="page-title-box">
          <div className="page-title-right">
              <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to={"/Dashboard"} >Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item"><Link to={"/GestionUsuarios/Comercial"} >Usuarios Comercial</Link></li>
                  <li className="breadcrumb-item active">Creación de Usuario Comercial</li>
              </ol>
          </div>
          <h4 className="page-title">Creación de Usuario Comercial</h4>
      </div>
  )
}


function LayoutAgregarUsuario(props) {
useEffect(() => { actualizarInfoPagina(props) }, []);
const [Clientes, setClientes] = React.useState([])
React.useEffect(() => {
    clall(0).then(value => {
        setClientes(value.ans.wus || [])               
    })

}, []);
const NClientes = Clientes.map(opt => ({label: opt.name, value: opt.cid}))
return(
    <div className='container'>    
    <TituloPagina />
    {NClientes !== null && <AgregarUsuario Clientes={NClientes}/> }              
</div>
)

}


export default LayoutAgregarUsuario;

