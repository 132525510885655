import { requestData } from "Connection/transacciones"

const clall = async(ful) => {
    const jsonBody = {
        "hdr": {
                "txi": 82,
                "req": "clall",
                "ott": true,
        },
        "dat": {
            "ful": ful,
                // "usr": sessionStorage.usrLogin
        }
    }

return await requestData(jsonBody)
}

export default clall;