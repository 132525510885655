import useDatosUsuarioWeb from 'Hooks/Datos/useDatosUsuarioWeb'
import React from 'react'
import { Link, useLocation} from 'react-router-dom'
import { Spinner } from 'react-bootstrap';
import FormularioEditarDatos from './FormularioEditarDatos';
import clall from "Connection/transacciones/clall";
import geprf from "Connection/transacciones/geprf";
import aupon from "Connection/transacciones/aupon";

// elemento de control
function EditarDatos() { 
    const [clientes, setClientes] = React.useState([]);
    const location = useLocation();
    const detalleUsuarioWeb = useDatosUsuarioWeb();
    // const detalleUsuarioWeb = useDatosUsuarioWeb(location.state.userID)
    const [perfilOpc, setperfilOpc] = React.useState([])
    const nClientes = React.useMemo(() => {
        return clientes.map(opt => ({label: opt.name, value: opt.cid}))
        },[clientes]);
    // React.useEffect(() => {
    //     datosUsuario.refreshData();
    // },[])
    React.useEffect(() => { 
        const run = async() => {
            await detalleUsuarioWeb.refreshData(); // obtiene los datos del usuario
            const clallRes = await clall(0);    // respuesta de los clientes
            if(clallRes.ans.stx === "ok") {
                setClientes(clallRes.ans.wus || [])               
            } 
            const geprfRes = await geprf(0);     // lista de opciones perfil usuario
            if (geprfRes.ans.stx === "ok") { 
                console.log("he",geprfRes.ans.prf )
                const options = geprfRes.ans.prf.map(opt => ({label: opt.nam, value: opt.cod})); 
                setperfilOpc(options || []);               
            }
            
            // const getpr = await aupon();
            // if (getpr.ans.stx === "ok") { 
            //     console.log("prf",getpr)
            // }
             
        }
        run();
    }, []); // eslint-disable-line
    if (detalleUsuarioWeb.isFetching) {
        return (
            <div className='text-center'>
                <Spinner animation='border'></Spinner>                
            </div>
        )
    }
    if (detalleUsuarioWeb.datos === null) {
        return null
    }
    return (
        <FormularioEditarDatos datosUsuario={detalleUsuarioWeb.datos} clientes={nClientes} opciones={perfilOpc} />

    )
}

export default EditarDatos