// import useKam from 'Hooks/Selects/useHolding'
import useKam from 'Hooks/Selects/useKam';
import weall from 'Connection/transacciones/weall';
import React from 'react'
import { MultiSelect } from 'react-multi-select-component';
import localization from "Assets/localization/multiSelect.json"

function MultiSelectKam({
    isDisabled,
    dependencies = {}, 
    onChange = () => {},
    selected=[],
    name
}) {
    const kam = useKam();
    const fetched = React.useRef(false) 
    React.useEffect(() => {
        fetched.current = false; // si se actualiza una dependencia se debe menciar que esta desactualizado 
    }, [])

    const onToggle = async(isOpen) => {
        if(isOpen && !fetched.current) {
            fetched.current = true; // Lista de comunas actualizados? SI
            await kam.fetch(dependencies);
        }
    }
    const handleOnChange = (values) => {
        const sorted = values.sort((a,b) => a.value - b.value)
        console.log(sorted, name)
        onChange(sorted, name)
    }

    return (
        <MultiSelect 
            value={selected}
            overrideStrings={localization}
            onChange={handleOnChange}
            options={kam.options}
            onMenuToggle={onToggle}
            disabled={isDisabled}
            isLoading={kam.isFetching}

        />
    )
}

export default MultiSelectKam