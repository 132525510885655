import usdat from 'Connection/transacciones/usdat';
import React from 'react'
import { useLocation } from 'react-router-dom';
import CacheApp from 'Utils/CacheApp';
function useDatosUsuario({userID}) {
    const location = useLocation();
    
    const [datos, setValue] = React.useState(
        CacheApp.getCacheTab({
            key: userID + location.key,
        }) || null );

    const fetched = React.useRef(datos? true: false)

    const [isFetching, setIsFetching] = React.useState(false);

    const refreshData = async() => {
        setIsFetching(true);
        // console.log("id",userID)
        const request = await usdat({userID});
        fetched.current = true
        // console.log("req",request)
        if(request.ans.stx === "ok") {
            setValue(request.ans)
            CacheApp.setCacheTab({
                key: userID + location.key,
                exp: 180000,
                value: request.ans
            })
        }
        setIsFetching(false)
    }
    return {
        userID,
        datos,
        fetched: fetched.current,
        isFetching,
        refreshData,
    }
}

export default useDatosUsuario