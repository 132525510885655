import aupen from 'Connection/transacciones/aupen'
import aupon from 'Connection/transacciones/aupon'
import React from 'react'
import toast from 'react-hot-toast'


const APROBAR = 1
const RECHAZAR = 2

function useSolicitudesRegistroApp(status) {
    const [solicitudes, setSolicitudes] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)
    const [isSubmiting, setIsSubmiting] = React.useState(false)

    const fetchData = React.useCallback(async() => {
        setIsFetching(true)
        const request =  await aupen(status);
        // console.log("aupen", request)
        setIsFetching(false)
        if(request.ans.stx === "ok"){
            setSolicitudes(request.ans.pen || [] )
        }
    },[] )
    const backendConfirmar = async(listaUsuarios, opcion) => {
        setIsSubmiting(true)
        const request = await aupon({
            userList: listaUsuarios,
            action: opcion
        })
        setIsSubmiting(false)

        if (request.ans.stx === "ok") {
            toast.success(request.ans.msg)
            fetchData();
        } else {
            toast.error(request.ans.msg)
        }
    }

    const aprobarSolicitudes = (listaUsuarios) =>  backendConfirmar(listaUsuarios, APROBAR)

    const rechazarSolictudes = (listaUsuarios) => backendConfirmar(listaUsuarios, RECHAZAR)


    
   
    return {
        solicitudes,
        fetchData,
        isFetching,
        isSubmiting,
        aprobarSolicitudes,
        rechazarSolictudes,
    }
}

export default useSolicitudesRegistroApp