import { requestData } from "Connection/transacciones"

const locus = async({lid}) => {
    const jsonBody = {
        hdr: {
            txi: 79,
            req: "locus",
            ott: true,
        },
        dat: {
            lid:lid
        }
    }
    console.log(jsonBody)
    return await requestData(jsonBody)

}

export default locus;