import gecom from 'Connection/transacciones/gecom';
import React from 'react'

function useComuna() {
    const [value, setValue] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)
    
    const mounted = React.useRef(true)
    React.useEffect(() => {
        return () => {
            mounted.current = false
        };
    }, []);

    const refreshComunas = async(dependencies = {}) => {
        if(mounted.current === true) setIsFetching(true);
        const requestComunas = await gecom(dependencies);
        if(requestComunas.ans.stx === "ok" && mounted.current === true){

            const newValues = requestComunas.ans.com.map(item => ({
                "value": item.cod,
                "label": item.nam,   
            }))
            setValue(newValues)
        }
        if(mounted.current === true) setIsFetching(false);
    }
    
    return {
        value,
        refreshComunas,
        isFetching
    }
}

export default useComuna