import React, { useEffect } from 'react'
import { actualizarInfoPagina } from 'Utils/paginaInfo';
import FormularioCrearTicket from './formulario/FormularioCrearTicket';
import {Link} from 'react-router-dom'

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={"/ListaTickets"}>
                            Tickets
                        </Link>
                    </li>
                    <li className="breadcrumb-item active">Creación de Ticket</li>
                </ol>
            </div>
            <h4 className="page-title">Creación de Ticket</h4>
        </div>
    )
}



function PaginaCrearTicket(props) {
    useEffect(() => { actualizarInfoPagina(props) }, [props]);

	return (
		<div className='container'>
			<TituloPagina/>
            <FormularioCrearTicket />
		</div>
	)
}

export default PaginaCrearTicket