import Tabla from 'Components/Tabla/Tabla';
import useUsuariosApp from 'Hooks/Listas/useListaUsuariosApp';
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FiltrosUsuariosApp from './FiltrosUsuariosApp';
import { format } from 'Utils/rut'

const thClassName= "ps-1"

function TablaUsuariosApp () {
    const usuariosApp = useUsuariosApp();
    const columnas = React.useMemo(() => [
        {
            Header: 'Nombre',
            accessor: item => `${item.name}, ${item.snam}`,
            Cell: (props) => {
                const {value, row} = props
                return (
                    <Link to={"User"} state={{userID: row.original.uid}} >
                        {value}
                    </Link>
                )
            },
            thClassName,
        },

        {
            Header: 'Rut',
            accessor: 'rut',
            Cell: props =>{
                const {value} = props 
                return(format(value))
            },
            thClassName
        },

        {
            Header: 'Comuna',
            accessor: 'comuna',
            thClassName
        },
        {
            Header: 'Región',
            accessor: 'region',
            thClassName
        },
     
        {
            Header: 'Banco',
            accessor: 'banco',
            thClassName
        }, 
    ],[])
    // console.log("appuser",usuariosApp)
    return (
        <div className='card'>
            <div className="card-body">
                <Tabla
                    columns={columnas}
                    data={usuariosApp.values}
                    globalFilter={true}
                    titleSection={
                        <FiltrosUsuariosApp 
                            totalUsuarios={usuariosApp.values.length}
                            refreshUsers={usuariosApp.refreshUsers}    
                        />
                    }
                    tableClass={"table table-centered table-hover mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={usuariosApp.isFetching}
                />
            </div>

        </div>
        
    )
}


export default TablaUsuariosApp