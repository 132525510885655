import usact from 'Connection/transacciones/usact'
import usdes from 'Connection/transacciones/usdes'
import useFormulario from 'Hooks/Custom/useFormulario'
import InputTexto from "Components/FormElements/InputTexto"
import SelectComuna from "Components/FormElements/SelectComuna"
import SelectRegion from "Components/FormElements/SelectRegion"
import SelectTipoCuenta from "Components/FormElements/SelectTipoCuenta"
import SelectTipoBanco from "Components/FormElements/SelectTipoBanco"
import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
import { validarEmail } from 'Utils/validations'
import { Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { dangerModalContentState, isDangerModalOpenState } from 'Components/Modal/DangerModal'
import useDatosUsuario from 'Hooks/Datos/useDatosUsuario'
import { format } from 'Utils/rut'

const propsStyles = {
    asRow: true,
    labelSize:3,
    size: "sm"
}

function FormularioEditarDatos ({datosUsuario}) {
    const [isDisabled, setIsDisabled] = React.useState(false);
    const mounted = React.useRef(true)
    React.useEffect(() => {
        return mounted.current = false
    },[])
    const formulario = useFormulario({
        initialValues: {
            "name": datosUsuario.name,  // input
            "snam": datosUsuario.snam,  // input
            "mail": datosUsuario.mail,  // input
            "phon": datosUsuario.phon,  // phon
            "ndoc": datosUsuario.ndoc,
            "regi": datosUsuario.regi,
            "comu": datosUsuario.comu,
            "addr": datosUsuario.addr,
            "bank": datosUsuario.bank,
            "acty": datosUsuario.acty,
            "acnu": datosUsuario.acnu
        },
        onSubmit: async({dirtyValues}) => {
            // console.log(dirtyValues)
            setIsDisabled(true)
            const request = await usact(datosUsuario.uid, dirtyValues);

            if(request.ans.stx === "ok"){
                toast.success(request.ans.msg)
                formulario.updateInitialValues();
                
                formulario.resetFieldValidation();
                // refreshUsers()
            }
            setIsDisabled(false)

        },

        validateOnSubmit: ({dirtyValues}) => {
            const errors = {}
            if(Object.keys(dirtyValues).length === 0){
                errors.submitValues = "No hay cambios que registrar."
                toast.error(errors.submitValues)
            }   
            return errors;
        },
    })
    const [dependencias, setDependencias] = React.useState({
        "reg": [datosUsuario.regi]
    })
     
    // Inputs formulario
    const onNameChange = React.useCallback((value) => {
        const FIELD = "name"
        if (value.length <= 50) {
            if(value === "") { 
                formulario.setFieldIsInvalid(FIELD)
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        }
    },[])

    const onSnamChange = React.useCallback((value) => {
        const FIELD = "snam"
        if (value.length <= 50) {
            if(value === "") { 
                formulario.setFieldIsInvalid(FIELD)
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        }
    },[])

    const onMailChange = React.useCallback((value) => {
        const FIELD = "mail"
        if (value.length <= 48) {
            if(!validarEmail(value)){
                formulario.setFieldIsInvalid(FIELD) // false: hay error
            } else if (formulario.initialValues[FIELD] !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
            formulario.setFieldValue(FIELD, value)
        }
    },[])

    const onPhonChange = React.useCallback((value) => {
        const FIELD = "phon"
        if (value.length <= 12) {
            const re = /^[0-9\b]+$/;
            if ((value === '' || re.test(value))) {
                formulario.setFieldValue(FIELD, value);
                if(value === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== value) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
            }
        }
    },[])
    
    const onNdocChange = React.useCallback((value) => {
        const FIELD = "ndoc"
        if (value.length <= 20){
            const re = /^[0-9\b]+$/;
            if ((value === '' || re.test(value))) {
                formulario.setFieldValue(FIELD, value);
                if(value === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== value) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
            }
        }
    },[])

    const onRegiChange = React.useCallback((value)=> {
        const FIELD = "regi"
        setDependencias({"reg": Number(value) !== 0? [Number(value)]: []})
        formulario.setFieldValue(FIELD, value);
        if (formulario.initialValues[FIELD] !== Number(value) && Number(value) !== 0) { 
            // si hay cambios
            formulario.setFieldIsValid(FIELD)
            formulario.setFieldValue("comu", 0)            
            formulario.setFieldIsInvalid("comu")

        } else { 
            // No hay cambios o se elige mostrar todas las comunas
            formulario.setFieldIsUndefined(FIELD)
            formulario.setFieldValue("comu", formulario.initialValues.comu)            
            formulario.setFieldIsUndefined("comu")
        }
    },[])
    
    const onComuChange = React.useCallback((value)=> {
        const FIELD = "comu";
        formulario.setFieldValue(FIELD, Number(value))
        if(Number(value) === 0) {
            formulario.setFieldIsInvalid(FIELD);
        } else if (formulario.initialValues[FIELD] !== Number(value)) {
            formulario.setFieldIsValid(FIELD);
        } else {
            formulario.setFieldIsValid(FIELD);
        }
    }, [])

    const onAddrChange = React.useCallback((value) => {
        const FIELD = "addr"
        if(value.length <= 128) {
            formulario.setFieldValue(FIELD, value)
            if(value === "") { 
                formulario.setFieldIsInvalid(FIELD)
            } else if (formulario.initialValues.name !== value){
                formulario.setFieldIsValid(FIELD)
            } else {
                formulario.setFieldIsUndefined(FIELD)
            }
        }
    },[])

    const onBankChange = React.useCallback((value) => {
        const FIELD = "bank";
        formulario.setFieldValue(FIELD, Number(value))
        if(Number(value) === 0) {
            formulario.setFieldIsInvalid(FIELD);
        } else if (formulario.initialValues[FIELD] !== Number(value)) {
            formulario.setFieldIsValid(FIELD);
        } else {
            formulario.setFieldIsValid(FIELD);
        }
    }, [])

    const onActyChange = React.useCallback((value) => {
        const FIELD = "acty";
        formulario.setFieldValue(FIELD, Number(value))
        if(Number(value) === 0) {
            formulario.setFieldIsInvalid(FIELD);
        } else if (formulario.initialValues[FIELD] !== Number(value)) {
            formulario.setFieldIsValid(FIELD);
        } else {
            formulario.setFieldIsValid(FIELD);
        }
    }, [])

    const onAcnuChange =React.useCallback((value) => {
        const FIELD = "acnu"
        if (value.length <= 20) {
            const re = /^[0-9\b]+$/;
            if ((value === '' || re.test(value))) {
                formulario.setFieldValue(FIELD, value);
                if(value === '') {
                    formulario.setFieldIsInvalid(FIELD)
                } else if (formulario.initialValues[FIELD] !== value) {
                    formulario.setFieldIsValid(FIELD)
                } else {
                    formulario.setFieldIsUndefined(FIELD)
                }
            }
        }
    },[])

    const setDangerContent = useSetRecoilState(dangerModalContentState)


    // const [modalIsOpen, setModalIsOpen] = useRecoilState(isDangerModalOpenState)
    const setModalIsOpen = useSetRecoilState(isDangerModalOpenState)
    const abrirModalAlertaDanger = () => setModalIsOpen(true)
    const [deshabilitandoBackend, setDeshabilitandoBackend] = React.useState(false)

    const navigation = useNavigate()
    const confirmarDeshabilitarUsuario =  React.useCallback(async () => {
        if(mounted.current === true) setDeshabilitandoBackend(true)
    
        const request = await usdes({uid: datosUsuario.uid})
        if(request.ans.stx === "ok"){
            toast.success(request.ans.msg);
            setModalIsOpen(false)
            navigation('..')
        }
    
        if(mounted.current === true) setDeshabilitandoBackend(false)
    },[])

    React.useEffect(() => {
        setDangerContent(<>
            <div className='text-white text-center'>
                Una vez que se deshabilite esta cuenta no se podrá volver a activar.
            </div>
            <Button variant='light' className='text-danger my-3' onClick={() => confirmarDeshabilitarUsuario()} disabled={deshabilitandoBackend} >
                <div style={{width: "100px"}}>
                    {deshabilitandoBackend === true ? (
                        <Spinner 
                            as={"span"}
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ):"Confirmar"}
                </div>
            </Button>
        </>)
    },[deshabilitandoBackend])
    


    return (
       <>
        <div>
    <h4>
         Datos del usuario <span className="text-info">{format(datosUsuario.rut)}</span>
    </h4>
            
                <div className="mb-1">
                    <InputTexto
                        {...propsStyles}
                        label="Nombre(s)"
                        name={"name"}
                        value={formulario.values.name}
                        onChange={onNameChange}
                        validation={formulario.fieldValidation.name}
                        disabled={isDisabled}

                    />
                </div>
                <div className="mb-1">
                    <InputTexto
                        {...propsStyles}
                        label={"Apellidos"}
                        onChange={onSnamChange}
                        name={"snam"}
                        disabled={isDisabled}
                        value={formulario.values.snam}
                        validation={formulario.fieldValidation.snam}

                    />
                </div>
                <h4>
                    Contacto
                </h4>
                <div className="mb-1">
                    <InputTexto 
                        {...propsStyles}
                        label={"Correo"}
                        name={"mail"}
                        disabled={isDisabled}
                        value={formulario.values.mail}
                        validation={formulario.fieldValidation.mail}
                        onChange={onMailChange}
                    />
                </div>
                <div className="mb-1">
                    <InputTexto 
                        {...propsStyles}
                        label="Teléfono"
                        name="phon"
                        disabled={isDisabled}
                        value={formulario.values.phon}
                        onChange={onPhonChange}
                        validation={formulario.fieldValidation.phon}
                    

                    />   
                </div>
                <div className="mb-1">
                    <InputTexto 
                        {...propsStyles}
                        label="N° de Documento"
                        name="ndoc"
                        disabled={isDisabled}
                        value={formulario.values.ndoc}
                        validation={formulario.fieldValidation.ndoc}
                        onChange={onNdocChange}
                    />   

                
                </div>
                <h4>
                    Domicilio
                </h4>
                <div className="mb-1">
                    <SelectRegion 
                        {...propsStyles}
                        label={"Región"}
                        onChange={onRegiChange}
                        name={"regi"}
                        disabled={isDisabled}
                        value={Number(formulario.values.regi)}
                        validation={formulario.fieldValidation.regi}
                    />
                </div>
                <div className="mb-1">
                    <SelectComuna 
                        {...propsStyles}
                        onChange={onComuChange}
                        disabled={isDisabled}
                        label={"Comuna"}
                        name={"comu"}
                        validation={formulario.fieldValidation.comu}
                        value={formulario.values.comu}
                        dependsOn={dependencias}
                    />
                </div>
                <div className="mb-1">
                    <InputTexto 
                        {...propsStyles}
                        label={"Dirección"}
                        name={"addr"}
                        disabled={isDisabled}
                        value={formulario.values.addr}
                        validation={formulario.fieldValidation.addr}
                        onChange={onAddrChange}
                    />
                </div>
                <h4>
                    Datos bancarios
                </h4>
                <div className="mb-1">
                    <SelectTipoBanco 
                        {...propsStyles}
                        label={"Banco"}
                        onChange={onBankChange}
                        name={"bank"}
                        disabled={isDisabled}
                        value={formulario.values.bank}
                        validation={formulario.fieldValidation.bank}
                    />
                </div>
                <div className="mb-1">
                    <SelectTipoCuenta 
                        {...propsStyles}
                        label={"Tipo de Cuenta"}
                        onChange={onActyChange}
                        name={"acty"}
                        disabled={isDisabled}
                        value={formulario.values.acty}
                        validation={formulario.fieldValidation.acty}
                    />
                </div>
                <div className="mb-1">
                    <InputTexto 
                        {...propsStyles}
                        label={"N° de cuenta"}
                        onChange={onAcnuChange}
                        name={"acnu"}
                        disabled={isDisabled}
                        value={formulario.values.acnu}
                        validation={formulario.fieldValidation.acnu}
                    

                    />
                </div>
                <div className='d-flex justify-content-between mt-3'>
                    <Link to={"/#"} onClick={(e) => {
                        abrirModalAlertaDanger()
                        e.preventDefault()
                    }} className="text-danger">
                        Deshabilitar usuario
                    </Link>
                    <Button variant="info" onClick={formulario.onSubmit} disabled={formulario.haveInvalidFields || !formulario.haveValidFields }>
                        Guardar cambios
                    </Button>
                </div>
    
            
        </div>
    </>
    )
}

const CACHE_KEY = 'detalle-usuario-app'
function EditarDatos({userId, display}) {
    const location = useLocation();
    const { datos, isFetching, refreshData, fetched } = useDatosUsuario({
        userID: userId,
        cacheKey: CACHE_KEY,
        sessionKey: location.key
    })
    
    React.useEffect(() => {
        if(display && fetched === false&& userId){
            refreshData()
        }
        // eslint-disable-next-line
    },[display])
    if (userId === undefined) return (
        "no user selected"
    )
    return (
        <div style={{display:display? "block": "none"}}>
            <h4>
                Datos de usuario
            </h4>
            {isFetching && (
                <div className='text-center mt-2'>
                    <Spinner animation="border" role="status" />
                </div>
            )}
            {datos && <FormularioEditarDatos datosUsuario={datos} />}

            
        </div>
    )
}


export default FormularioEditarDatos;