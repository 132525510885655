import React from 'react'
import toast from 'react-hot-toast';
import { taskr } from 'Connection/transacciones';
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import ImagenDisplay from './ReqLink';
import { toApple, toBannana } from 'Utils/excelutils';

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="/Dashboard">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/ListaTickets">Tickets</a></li>
                    <li className="breadcrumb-item active">Agrupar Tickets</li>
                </ol>
            </div>
            <h4 className="page-title">Consulta Tarea </h4>
        </div>
    )
}

function ImagenTicket() {
    const { id } = useParams();
    const [idTask, reqNum] = toBannana(id).split("&")
    const [ticketReq, setTicketReq] = React.useState(null)
    // const [isSubmiting, setIsSubmiting] = React.useState(false);
    const [isError, setIsError] = React.useState(false);


    React.useEffect(() => {
        const run = async () => {
            // setIsSubmiting(true);
            if (idTask === undefined || idTask ==="error"){ 
                setIsError(true)
                toast.error("Error al procesar la solicitud")

            }
            else {
                const request = await taskr({idDetalle:idTask, tipoDetalle:0})
                setTicketReq(request)
            }
            // setIsSubmiting(false);
        }
        run()
    },[])
    return (
        <div className='container'>
            <TituloPagina />
            {isError?<Navigate to="/Dashboard" />:<></>}
            {ticketReq? <ImagenDisplay ticketReq={ticketReq} numReq={reqNum}/>:<></>}

        </div>)
}

export default ImagenTicket