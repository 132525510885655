
import React from 'react'
import { Form } from 'react-bootstrap';
import FormGroupLabel from './FormGroupLabel';
import useTipoBanco from 'Hooks/Selects/useTipoBanco';

function SelectTipoBanco(props) {
    const {onChange, value, name, size, disabled, validation} = props;
    
    const tipoDeBanco = useTipoBanco();
    return (
        <FormGroupLabel {...props}>
            <Form.Select
                disabled={disabled}
                onChange={(e) => onChange(e.target.value, name)}
                size={size}
                value={value}
                isInvalid={validation === false}
                isValid={validation === true}
            >
                <option value={0}>Seleccionar Banco</option>
                {tipoDeBanco.values.map((opcion, idx) => <option key={idx} value={opcion.value}>{opcion.label}</option>)}
            </Form.Select>
        </FormGroupLabel>
    )
}

export default React.memo(SelectTipoBanco)