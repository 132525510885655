import React from "react";
import { Alert } from "react-bootstrap";
import Alternativas from "./_RequerimientosResumenTicket/Alternativas";
import TiempoTarea from "./_RequerimientosResumenTicket/TiempoTarea";
import ValorEstrellas from "./_RequerimientosResumenTicket/ValoracionEstrellas";

// import { ValorEstrellas } from "./ResumenTicketRequerimientos";


function ResumenTicket ({taskw}) {
    const contenidoDelResumen = taskw.ans.sum;
    return(
        <div className="card">
            <div className="card-body">

                { contenidoDelResumen &&

                    contenidoDelResumen.map((contenido, index) => {
                        let componente = [];
                        if (index !== 0) 
                            componente.push(<hr key={"hr-"+index}/>)
                        switch (contenido.aty) {
                            case 0:
                                componente.push(<>
                                    <Alert variant="warning" >
                                        <div className="text-center">
                                            No hay estadísticas que mostrar
                                        </div>
                                    </Alert>
                                </>)
                                break
                            case 2:
                            case 3:
                                componente.push(<Alternativas sumItem={contenido} chartId={"chart-"+index} key={"sumario-"+index}/>)
                                break 
                            case 4:
                                componente.push(<ValorEstrellas sumItem={contenido} key={"sumario-"+index}/> )
                                break
                            case 6:
                                componente.push(<TiempoTarea sumItem={contenido} key={"sumario-"+index}/>)
                                break 
                            default:
                                componente.push("otra \n")
                                break
                        }
                        return componente
                    })
                }
            </div>
        </div>
    )
}

export default ResumenTicket