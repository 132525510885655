import useLocalesAsignadosUsuario from "Hooks/Listas/useLocalesAsignadosUsuario"
import React from "react"
// import { useLocation } from "react-router-dom"
import { Col, Row, Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";

import MenuAgregarLocales from "./TablaAgregarLocales";
// import ListaLocalesAsingados from "./ListaLocalesAsignados";
// import useDatosUsuario from "Hooks/useDatosUsuario";
import Tabla from "Components/Tabla/Tabla";
import {useLocation} from "react-router-dom";
import { format } from 'Utils/rut'

function LocalesAsignados ({userID, userData}) {
    const {
        localesAsignados, 
        isFetching, 
        fetch, 
        agregarLocal,
        eliminarLocal,
        localQuantity,
        hasChanged,
        confirmarCambios,
        cancelarCambios,
        isUpdating,
    } = useLocalesAsignadosUsuario({
        uid: userID
    })
    React.useEffect(() => {
        if(userID) {
            
            fetch();
        }
        // eslint-disable-next-line
    },[])
    const columnas = React.useMemo(() => [
        {
            Header: 'Código',
            accessor: 'cod',
            thClassName:"p-1",
        },
        {
            Header: 'Nombre',
            accessor: 'nam',
            thClassName:"ps-1",
        },
        {
            Header: 'Región',
            accessor: 'reg',
            thClassName:"ps-1",
        },
        {
            Header: 'Comuna',
            accessor: 'com',
            thClassName:"ps-1",
        },
        {
            Header: 'Dirección',
            accessor: 'adr',
            thClassName:"ps-1",
        },
        {
            Header: 'Retail',
            accessor: 'ret',
            thClassName:"ps-1",
        },
        {
            Header: 'Acción',
            accessor: 'lid',
            thClassName:"ps-1",
            tdStyle: {width: "3rem"},
            Cell: props => {
                const { value } = props;
                return(
                    <div className="text-center">
                        <Button variant="danger" size="sm" onClick={() => eliminarLocal(value)}>
                            <i className="mdi mdi-trash-can-outline"></i>
                        </Button>
                    </div>
                )
            }
        }
    ],[])
    return (
        <div>
            <div className="mb-2">
                
                <h4>
                    {/* Locales asignados: <span className="text-info">{userData.name} {userData.snam}</span> */}
                    Locales asignados al usuario <span className="text-info">{format(userData.rut)}</span>
                </h4>
                <div className="">
                    
                </div>
                
            </div>
            
            <div>
                <Tabla
                    columns={columnas}
                    data={localesAsignados}
                    globalFilter={true}                    
                    itemsPerPageOptions={[5,10,15,20]}
                    defaultItemPerPage={10}
                    titleSection=
                    {
                        <>
                        <Row>
                        <Col>
                        <MenuAgregarLocales 
                            disabledOptions={localesAsignados.map(loc => loc.lid)}
                            agregarLocal={agregarLocal}
                            eliminarLocal={eliminarLocal}
                        />
                        </Col>
                        <Col>
                            <div>
                                <span className="text-muted me-2">Cantidad de locales: {localQuantity}</span>
                            </div>
                        </Col>
                        </Row>
                        </>

                    }
                    tableClass={"table table-centered table-hover  mb-0"}
                    theadClassName={"table-light"}
                    cellClassName={"p-1"}
                    loading={isFetching}
                />
                <Row>
                    <Col> 
                    <div className="text-end mt-2">
                        <Button 
                            className="me-2"
                            variant="secondary" 
                            onClick={() => cancelarCambios()} 
                            disabled={!hasChanged || isUpdating}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            style={{width: "9rem"}}
                            variant="success" 
                            onClick={() => confirmarCambios()} 
                            disabled={!hasChanged || isUpdating}
                        >
                                

                            {isUpdating? (
                                <Spinner animation="border" size="sm"/>
                            ):("Guardar cambios")}
                            
                        </Button>
                    </div>
                    </Col> 
                </Row>  
            </div>
        </div>
    )
}
export default LocalesAsignados