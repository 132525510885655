import React,{ useEffect } from "react";
import { Link } from 'react-router-dom';
import { actualizarInfoPagina } from 'Utils/paginaInfo';
// import clall from "Connection/transacciones/clall";
import { result } from "lodash";
import locid from "Connection/transacciones/locid";
import GestionarLocal from "./GestionarLocal";
import { Button } from "react-bootstrap";


function TituloPagina() {
  return (
      <div className="page-title-box">
          <div className="page-title-right">
              <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to={"/Dashboard"} >Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item"><Link to={"/Locales"} >Locales</Link></li>
                  <li className="breadcrumb-item active">Gestionar Local</li>
              </ol>
          </div>
          <h4 className="page-title">Gestionar Local</h4>
      </div>
  )
}


function LayoutGestionarLocal(props) {
    // console.log(props)
    useEffect(() => { actualizarInfoPagina(props) }, []);
    const [LocalData, setLocalData] = React.useState([])
    const [isSubmiting, setIsSubmiting] = React.useState(false);

    React.useEffect(async() => {
        setIsSubmiting(true)
        let lid = localStorage.getItem("lid")
        // console.log("lid",localStorage.getItem("lid"))
        const request = await locid({lid})
        if (request.ans.stx === "ok"){
            setLocalData(request.ans)
        }
        setIsSubmiting(false)
    }, []);
    return(
        <div className='container'>    
        <TituloPagina />
        {/* <Button onClick={()=>console.log(LocalData.nam)}>asaaaaa</Button> */}
        {(LocalData.nam) && (<GestionarLocal localData={LocalData}></GestionarLocal>)}
        {/* <AgregarLocal></AgregarLocal> */}
        {/* {NClientes !== null && <AgregarUsuario Clientes={NClientes}/> }               */}
    </div>
    )

}


export default LayoutGestionarLocal;

