import { Button } from 'react-bootstrap'
import React from 'react'

function LastPage({
    onClick,
    disabled = false,
    className = ''
}) {
  return (
    <Button variant='light' className={`page-link ${className}`} onClick={onClick} disabled={disabled}>
        {">>"}
    </Button>
  )
}

export default LastPage