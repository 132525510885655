import { taskr } from "Connection/transacciones";
import React, { useState, useEffect} from "react";
import DetalleTareaRequerimientos from "./TareaDetalleModalRequerimientos";

function ModalDetalleTarea ({idTarea, setIdQuest,setTareaSeleccionada}) { //Modal

    // tadet
    const [tadet, setTadet] = useState(null);

    const getTadet = async(idDetalle) => {
        setTadet(null) // limpia los datos de tadet

        const data = await taskr({idDetalle:idDetalle})
        setTadet(data)
       
    }

    useEffect(() => {
        if(idTarea !== 0 && idTarea !== null){
            getTadet(idTarea)
        }
        
    }, [idTarea])    
     
    // console.log("hehe",tadet)

    return (
        <div className="modal fade" id="modalDetalle" tabIndex="-1" aria-labelledby="modalDetalleLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="modalDetalleLabel">Información de tarea {idTarea}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="row text-center">
                                <h1>{tadet && tadet.ans.lid.split(":")[2]}</h1>                                
                            </div>
                            <div className="row text-center">
                                <h2>{tadet && tadet.ans.adr}</h2>                                
                            </div>
                            <div className="row text-center">
                                <h2>{tadet && tadet.ans.reg}</h2>
                            </div>
                            {/* <br></br>
                            <div className="row text-center">
                                <h3 className="col-2">Req</h3>
                                <h3 className="col-7">Descripción</h3>
                                <h3 className="col-3">Respuesta</h3>
                            </div>
                            <br></br> */}
                            {tadet &&
                                <DetalleTareaRequerimientos tadet={tadet}/>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetalleTarea;