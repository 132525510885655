import React from 'react';
import {Modal, Button} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { atom, useRecoilValue, useRecoilState } from "recoil"
export const isModalOpcionesOpenState = atom({
    key: "isModalOpcionesOpen",
    default: false
})

export const ticketSeleccionadoState = atom({
    key:"TicketSeleccionadoState",
    default: undefined
})

export const ticketNombreState = atom({
    key:"ticketNombreState",
    default: undefined
})

function ModalOpcionesTickets(){
    const [isOpen, setIsOpen] = useRecoilState(isModalOpcionesOpenState)
    const idTicket = useRecoilValue(ticketSeleccionadoState)
    const nombreTicket = useRecoilValue(ticketNombreState)
    const handleClose = () => {
        setIsOpen(false);
        localStorage.setItem("tic_id",idTicket);
    }
    const handleCloseCalen = () => {
        setIsOpen(false);
        localStorage.setItem("Calen",idTicket);
    }
      
    return(
        <>
        <Modal         
            size="xxl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Ticket {nombreTicket} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Link to={"CalendarizarTicket"} onClick={handleCloseCalen} className="dropdown-item">Calendarizar Ticket</Link>
                {/* <a href={"/calendarizacion_ticket.html?id_ticket="+ idTicket} onClick={handleClose} className="dropdown-item">Calendarizar Ticket</a> */}
                <Link to={"CalendarizacionMasiva"} onClick={handleClose} className="dropdown-item">Calendarizar Masivamente</Link>
                {/* <Button onClick={()=> {console.log(idTicket)}}></Button> */}
                <Link to={"ClonarTicket"} onClick={handleClose} className="dropdown-item">Clonar Ticket</Link>
                <a href={"/CalendarizacionesTicket"} onClick={handleClose} className="dropdown-item">Ver Calendarización</a>
                <Link to={"DetalleTicket"} onClick={handleClose}  className="dropdown-item">Ver Detalle</Link>
                <Link to={"ResultadoTicket"} onClick={handleClose} className="dropdown-item">Ver Resultados</Link>
            </Modal.Body>
        </Modal>
        </>
    )
}
export default ModalOpcionesTickets