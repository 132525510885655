import React, {useEffect} from "react";
import { actualizarInfoPagina } from "Utils/paginaInfo";
import { Button, Spinner } from 'react-bootstrap'
import TablaLocales from "./Tabla/TablaLocales";
import matrx from "Connection/transacciones/matrx";
import useDatosUsuarioWeb from 'Hooks/Datos/useDatosUsuarioWeb';

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="/Dashboard">Dashboard</a></li>
                    <li className="breadcrumb-item active">Locales</li>
                </ol>
            </div>
            <h4 className="page-title">Locales</h4>
        </div>
    )
}

function PaginaLocales(props){
    useEffect(() => { actualizarInfoPagina(props) }, [props]);
    const detalleUsuarioWeb = useDatosUsuarioWeb();
    
    useEffect(async() => {
        await detalleUsuarioWeb.refreshData();
        // const request = await matrx({
        //     trx: "locus",
        //     lid:0
        // })
        // ;
    }, []);

    if (detalleUsuarioWeb.isFetching) {
        return (
            <div className="container">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <Spinner animation='border'></Spinner>                
                </div>
            </div>
        )
    }
    if (detalleUsuarioWeb.datos === null) {
        return null
    }
    return (
        <div className="container">
            <TituloPagina /> 
            <TablaLocales cliente={detalleUsuarioWeb.datos.clie}/>
        </div>

    
    )
}

export default PaginaLocales;