import React, { useState, useEffect } from "react";
import { formatearFechaHora } from "Utils/funcionesFechaHora";
import { formatearHoraFecha} from "Utils/funcionesFechaHora";
import { backend_request } from "Connection/backend_reduce";
import FotoTareaTicket from "./FotoTareaTicket";
import Tabla from 'Components/Tabla/Tabla';
const thClassName= "ps-1"

const LlenarCajaTexto = ({numeroAccion, descripcion, textoIngresado}) => {
    return(
        <div className="row text-center">
            <hr style={{height:'1px',border:'1px solid #c8cacf',backgroundColor:'#c8cacf'}}/>
            <div className="col-2"> 
                <h4>{numeroAccion}</h4>
            </div>
            <div className="col-7">
                <h4>{descripcion}</h4>
            </div>
            <div className="col-3">
                <h4>{textoIngresado}</h4>
            </div>
        </div>
    )      
}

const SeleccionUnica = ({numeroAccion, descripcion, alternativa}) => {
    return(
        <div className="row text-center">
            <hr style={{height:'1px',border:'1px solid #c8cacf',backgroundColor:'#c8cacf'}}/>
            <div className="col-2">
                <h4>{numeroAccion}</h4>
            </div>
            <div className="col-7">
                <h4>{descripcion}</h4>
            </div>
            <div className="col-3">
                <h4>{alternativa}</h4>
            </div>
        </div>
    )
}

const SeleccionMultiple = ({alternativasSeleccionadas}) => {
    return(
        <>
             {
                alternativasSeleccionadas.split("~").map((item,index)=>(
                    <li key={"alt-"+index}>
                        
                            {item}
                    </li>
                ))
            }
        </>
    )
}

const ValorizacionEstrellas = ({numeroAccion, descripcion, numeroEstrellas}) => {
    return(
        <div className="row text-center">
            <hr style={{height:'1px',border:'1px solid #c8cacf',backgroundColor:'#c8cacf'}}/>
            <div className="col-2">
                <h4>{numeroAccion}</h4>
            </div>
            <div className="col-7">
                <h4>{descripcion}</h4>
            </div>
            <div className="col-3">
                <h4>{numeroEstrellas}</h4>
            </div>
        </div>
    )
}

function Foto ({idRequerimiento, idTarea }) {
    console.log('foto', idRequerimiento, idTarea)
    return(
                <FotoTareaTicket 
                    idTicket={idTarea}
                    idRequerimiento={idRequerimiento}
                />

    )
}

const FechaHora = ({fechaYHora}) => {
    return(formatearHoraFecha(fechaYHora)
        
    )
}

const ReponerProducto = ({numeroAccion, descripcion, textoMovil}) => {
    return(
        <div className="row text-center">
            <hr style={{height:'1px',border:'1px solid #c8cacf',backgroundColor:'#c8cacf'}}/>
            <div className="col-2">
                <h4>{numeroAccion}</h4>
            </div>
            <div className="col-7">
                <h4>{descripcion}</h4>
            </div>
            <div className="col-3">
                <h4>{textoMovil}</h4>
            </div>
        </div>
    )
}
function SwitchResponse({type,response, item, ticketId}) {
    switch (type) {
        case 1:
            return response
        case 2:
            return response
        case 3:
            return <SeleccionMultiple alternativasSeleccionadas={response}/>
        case 4:
            return response
        case 7:
            return "Reposición realizada"
        case 5:
            return <Foto 
                        idTarea={ticketId}
                        idRequerimiento={item.qid} 
                        // fotoBase64={item.ant} 
                    />
        case 6:
            return <FechaHora  fechaYHora={response} />
        default:
            return response
        
    }
}

function DetalleTareaRequerimientos ({tadet}) {
    const columnas = React.useMemo(() => [
        {
            Header: 'Requerimientos',
            accessor: 'qid',
            thClassName
        },{
            Header: 'Descripción',
            accessor: 'tiq',
            thClassName
        },{
            Header: 'Respuesta',
            accessor: 'aty',
            thClassName,
            Cell: props => {
                const {value,row}=props;
                return <SwitchResponse type={value} response={row.original.ant} item={row.original} ticketId={tadet.ans.tid}/>
            }
        }

    ] ,[tadet]) 
    // const lista_acciones = tadet.ans.tas;
    // console.log("tadet",tadet)
    return(
        
        <Tabla
                columns={columnas}
                globalFilter={true}
                data={tadet.ans.tas}
                titleSection={""}
                tableClass={"table table-centered table-hover  mb-0"}
                theadClassName={"table-light"}
                cellClassName={"p-1"}
            /> 
    )
    // return(
    //     <div>
    //         {lista_acciones &&
    //             lista_acciones.map((item, index) => {
    //                 switch (item.aty){
                        // case 1:
                        //     return <LlenarCajaTexto numeroAccion={item.qid} descripcion={item.tiq} textoIngresado={item.ant} key={index}/>
                        // case 2:
                        //     return <SeleccionUnica numeroAccion={item.qid} descripcion={item.tiq} alternativa={item.ant} key={index}/>
                        // case 3:
                        //     return <SeleccionMultiple numeroAccion={item.qid} descripcion={item.tiq} alternativasSeleccionadas={item.ant} key={index}/>
                        // case 4:
                        //     return <ValorizacionEstrellas numeroAccion={item.qid} descripcion={item.tiq} numeroEstrellas={item.ant} key={index}/>
                        // case 7:
                        // case 5:
                        //     return <Foto 
                        //                 key={index}
                        //                 idTarea={tadet.ans.tid}
                        //                 numeroAccion={item.qid} 
                        //                 descripcion={item.tiq} 
                        //                 // fotoBase64={item.ant} 
                        //             />
                        // case 6:
                        //     return <FechaHora numeroAccion={item.qid} descripcion={item.tiq} fechaYHora={item.ant} key={index}/>
                        // default:
                        //     return <ReponerProducto numeroAccion={item.qid} descripcion={item.tiq} textoMovil={item.ant} key={index}/>
    //                 }
    //             }) 
    //         }   
    //     </div> 
    // )
}

export default DetalleTareaRequerimientos;