import React from 'react'
import useRegion from "Hooks/Selects/useRegion";
import { MultiSelect } from 'react-multi-select-component';
import localization from "Assets/localization/multiSelect.json"

function MultiSelectRegion({
    isDisabled = false,
    dependencies = {}, 
    onChange = () => {},
    selected=[],
    name
}) {

    const regiones = useRegion();
    const fetched = React.useRef(false);
    React.useEffect(() => {
        fetched.current = false;
    }, [
        JSON.stringify(dependencies.hol),
        JSON.stringify(dependencies.ret),
        JSON.stringify(dependencies.zon),
        JSON.stringify(dependencies.nie),
    ])
    const onToggle = async(isOpen) => {
        if(isOpen && !fetched.current) {
            fetched.current = true;
            await regiones.refreshRegiones(dependencies);
        }
    }
    const handleOnChange = (values) => {
        const sorted = values.sort((a,b) => a.value - b.value)
        onChange(sorted, name)
    }
    return (
        <MultiSelect
            options={regiones.value}
            disabled={isDisabled}
            isLoading={regiones.isFetching}
            onChange={handleOnChange}
            onMenuToggle={onToggle}
            value={selected}
            overrideStrings={localization}
        />
    )
}

export default MultiSelectRegion