import React from 'react'
import { Form } from 'react-bootstrap';
import FormGroupLabel from './FormGroupLabel'
import useRegion from 'Hooks/Selects/useRegion';
import PropTypes from 'prop-types';


function SelectRegion(props){
    const {
        onChange, value, name, disabled,
        dependsOn, 
        size, 
        validation, validationFeedback // validaciones
    } = props;
    const opciones = useRegion()
    React.useEffect(()=> {
        opciones.refreshRegiones(dependsOn)
    },[dependsOn])
 
    return(
        <FormGroupLabel {...props}>
             <Form.Select 
                disabled={disabled}
                size={size}
                value={value}
                isInvalid={validation === false}
                isValid={validation === true}
                onChange={(e) => {
                    onChange(e.target.value, name)
                }}                
            >   
                <option value={0}>Seleccionar Región</option>
                {opciones.value.map((region, idx) => <option key={idx} value={region.value}>{region.label}</option>)}
            </Form.Select>
            {validationFeedback.isValid &&
                <Form.Control.Feedback tooltip>
                    {validationFeedback.isValid}
                </Form.Control.Feedback>
            }
            {validationFeedback.isInvalid && 
                <Form.Control.Feedback type="invalid" tooltip>
                    {validationFeedback.isInvalid}
                </Form.Control.Feedback>
            }
        </FormGroupLabel>
    )
}

SelectRegion.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    dependsOn: PropTypes.object,
}

SelectRegion.defaultProps = {
    "validation": undefined,
    "validationFeedback": {
        "isValid": undefined,
        "isInvalid": undefined,
    },
    dependsOn: {}
}

export default React.memo(SelectRegion)