import React, { useEffect, useRef, useCallback, useState } from 'react'
import { Button, CloseButton, Form } from 'react-bootstrap';
import Texto from './requerimientos/Texto';
import Seleccion from './requerimientos/Seleccion';
import Condicional from './requerimientos/Condicional';





function InputRequerimiento ({formulario, index}) {
    
    const handleOnChange = (e) => {
        const copyReq = [...formulario.values.listaRequerimientos]
        copyReq[index].tipo = parseInt(e.target.value, 10)
        formulario.setFieldValue("listaRequerimientos", copyReq)
    }
    return (
        <div className="row">
            <div className="col-7">
                {/* {[1, 4, 5, 6, 7].includes(
                    formulario.values.listaRequerimientos[index].tipo)? 
                        <Texto formulario={formulario} index={index} />: 
                        <Seleccion formulario={formulario} index={index}/>
                } */}
                {[1, 4, 5, 6, 7].includes(
                    formulario.values.listaRequerimientos[index].tipo)? 
                        <Texto formulario={formulario} index={index} />: 
                        [8].includes(formulario.values.listaRequerimientos[index].tipo)?
                         <Condicional formulario={formulario} index={index}/>:
                         <Seleccion formulario={formulario} index={index}/>
                }
            </div>
            {/* <div className="col-2">
                <Form.Select value={formulario.values.listaRequerimientos[index].next}>
                    <option value={null}>Seleccion</option>
                    {formulario.values.listaRequerimientos.map((val,idx) => {
                        return (
                            <option value={idx+1}>{idx+1}</option>
                        )
                    })}
                </Form.Select>

            </div> */}
            <div className="col-3">
                <Form.Select onChange={handleOnChange} value={formulario.values.listaRequerimientos[index].tipo}>
                    <option value="1">Ingreso de Texto</option>
                    <option value="2">Selección Única</option>
                    <option value="3">Selección Múltiple</option>
                    <option value="4">Valorización en estrellas</option>
                    <option value="5">Tomar Foto</option>
                    <option value="6">Fecha y Hora</option>
                    <option value="7">Reposición</option>
                    <option value="8">Requerimiento Condicional</option>
                </Form.Select>
            </div>
        </div>
    )

}


function Requerimientos({formulario}) {
    const [isDisabled, setIsDisabled] = useState(true);
    const currentId = useRef(999)  // numero arbitrario que no se deberia repetir
    
    const agregarRequerimiento = () => {
        const addReq = [...formulario.values.listaRequerimientos, {id: currentId.current , tipo: 1, titulo: '', next: ''}] 
        formulario.setFieldValue("listaRequerimientos",addReq)
        // setCurrentId(currentId + 1) 
        currentId.current++
    }  
    const eliminarRequerimiento = useCallback((id, formularioValues) => {
        const delReq = formularioValues.listaRequerimientos.filter(req => req.id !== id );
        formulario.setFieldValue("listaRequerimientos",delReq)
    },[]) // solo si se agrega 

    const isAnyInputEmpty = useCallback(
        (form) => {
            let isEmpty = false;
            for (let req of form.values.listaRequerimientos) {
                if(!req.titulo){
                    isEmpty = true;
                    break;
                }
            }
            return isEmpty;
        },[])
    useEffect(() => {

        setIsDisabled(isAnyInputEmpty(formulario));
    }, [formulario.values.listaRequerimientos]);
    return (
        <>
            <div className="card-header detalle-ticket-header">
                <h5 className="card-title mb-0" >
                    Requerimientos
                </h5>
            </div>
            <div className="card-body pb-1 pt-2 detalle-ticket-body">
                {formulario.values.listaRequerimientos.map((req, idx)=> (
                    <div className="row mb-2" key={`req-${idx}`}>
                        <div className="col pe-0" style={{maxWidth: "140px"}}>
                            <div className='d-flex align-items-center'>
                                <strong>
                                Requerimiento {idx + 1}
                                </strong>
                            </div>
                        </div>                   
                        <div className="col" style={{maxWidth: "50px"}}>
                            <div className='d-flex align-items-center p-0' >
                                
                                {idx !== 0 && <CloseButton className='ms-2' onClick={() => eliminarRequerimiento(req.id, formulario.values)}/> }

                            </div>
                        </div>
                        <div className="col">
                            <InputRequerimiento formulario={formulario} index={idx}/>
                        </div>

                    </div>
                ))}
                <Button 
                    disabled={isDisabled} 
                    onClick={agregarRequerimiento} 
                    variant='secondary' 
                    className='ps-1 pd-1 btn-rounded'
                >
                    <i className='mdi mdi-plus me-1'></i>
                    Agregar requerimiento
                </Button>
            </div>
        </>
    )
}

export default Requerimientos