import useComuna from 'Hooks/Selects/useComuna';
import React from 'react'
import { MultiSelect } from 'react-multi-select-component';
import localization from "Assets/localization/multiSelect.json"
function MultiSelectComuna({
    isDisabled,
    dependencies = {}, 
    onChange = () => {},
    selected=[],
    name

}) {
    const comunas = useComuna();
    
    // Variable utilizada para determinar si la lista de usaurios está actualizados
    const fetched = React.useRef(false) 

    React.useEffect(()=> {
        fetched.current = false; // si se actualiza una dependencia se debe menciar que esta desactualizado 
    },[ 
        dependencies.hol,
        dependencies.ret,
        dependencies.zon,
        dependencies.nie,
        dependencies.reg,
        dependencies.ciu,
    ])

    const onToggle = async(isOpen) => {
        if(isOpen && !fetched.current) {
            fetched.current = true; // Lista de comunas actualizados? SI
            await comunas.refreshComunas(dependencies);
        }
    }
    

    const handleOnChange = (values) => {
        const sorted = values.sort((a,b) => a.value - b.value)
        onChange(sorted, name)
    }
    return (
        <MultiSelect
            overrideStrings={localization}
            options={comunas.value}
            disabled={isDisabled}
            isLoading={comunas.isFetching}
            onMenuToggle={onToggle}
            value={selected}
            onChange={handleOnChange}
        />
    )
}

export default MultiSelectComuna;