import React, { useState, useEffect} from "react";
import FormularioLogin from "./FormularioLogin";
import FormularioRecuperarPassword from "./FormularioRecuperarPassword";
import FormularioNuevaPassword from "./FormularioNuevaPassword";
import toast from 'react-hot-toast';
import { sleep } from "Utils/time";

function LogoRepoflex(){
    return (
        <div className="auth-brand text-center text-lg-start">
            <a href="index.html" className="logo-dark">
                <span>
                    <img src="/assets/images/logo-white.png" alt="" height="55"/>
                </span>
            </a>
        </div>
    )
}
function ProductoZolbit(){
    
    return (
 
        <footer className="footer footer-alt">
            <span className="ng-scope">
                Un producto de 
                <img 
                    src="/assets/images/zolbit.png" 
                    height="20px" 
                    width="100px" 
                    alt="Zolbit"
                />
            </span>
        </footer>
    )
}



function LayoutLogin (props){
    
    useEffect(() => {
        document.title = props.title
    }, [props.title]);

    const [form, setForm] = useState("login");
    React.useEffect(() => {
        const run = async() => {
            const error = sessionStorage.getItem("error")
            // console.log('error', error)
            sessionStorage.removeItem('error')
            switch (error) {
                case 'st23':
                    // console.log('err')
                    toast.error(
                        <div>
                            Su sesión ha expirado, intente iniciar sesión nuevamente <br/>
                        </div>, {
                            style: {
                                border: '1px solid #000000',
                                padding: '16px',
                                color: '#BA6E00',
                                },
                                iconTheme: {
                                primary: '#BA6E00',
                                secondary: '#FFFAEE',
                                },
                            duration: 5000,
                            showHideTransition: 'slide',
                            allowToastClose: false,
                            loader: false,
                        })
                    break;
                default:
                    break;
        }
        }
        run()
    },[])
   
    return (
        <div className="authentication-bg pb-0">
            <div className="auth-fluid">
                <div className="auth-fluid-form-box">
                    <div className="align-items-center d-flex h-100">
                        <div className="card-body">
                            {/* Contenido de la pagina */}
                            <LogoRepoflex/>

                            {form === "login" && <FormularioLogin setForm={setForm}/>}
                            {form === "recuperar" && <FormularioRecuperarPassword setForm={setForm}/>}
                            {form === "nuevaPassword" && <FormularioNuevaPassword setForm={setForm}/>}
            
                            <ProductoZolbit /> 
                            {/* Fin del contenido */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default LayoutLogin;