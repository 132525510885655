import React from 'react'
import { Form } from 'react-bootstrap'
import FormGroupLabel from './FormGroupLabel';
import PropTypes from 'prop-types';

function InputTexto ({
    onChange, value, name, disabled,  // funcionalidades 
    placeholder, size, // diseño 
    validation, validationFeedback, // validaciones
    type = "text",
    className="",
    required=false,
    ...props
},) {

    return (
        <div className={className}>
            <FormGroupLabel {...props}>
                <Form.Control
                    type={type}
                    onChange={(e) => onChange(e.target.value, name)}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value}
                    size={size}
                    isInvalid={validation === false}
                    isValid={validation === true}
                    required={required}
                />
                {validationFeedback.isValid &&
                    <Form.Control.Feedback tooltip>
                        {validationFeedback.isValid}
                    </Form.Control.Feedback>
                }
                {validationFeedback.isInvalid && 
                    <Form.Control.Feedback type="invalid" tooltip>
                        {validationFeedback.isInvalid}
                    </Form.Control.Feedback>
                }
            </FormGroupLabel>
        </div>
    )
}

InputTexto.propTypes = {
    "value": PropTypes.string,
    "validation": PropTypes.bool,
    "validationFeedback": PropTypes.shape({
        "isValid": PropTypes.string,
        "isInvalid": PropTypes.string,
    }),
}

InputTexto.defaultProps = {
    "validation": undefined,
    "validationFeedback": {
        "isValid": undefined,
        "isInvalid": undefined,
    }
}
export default React.memo(InputTexto)