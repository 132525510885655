import { requestData } from "Connection/transacciones"

const catad = async(dat) => {
    // const catad = async({client_id, newCat, desCat, updCat}) => {
    const jsonBody = {
        "hdr": {
                "txi": 78,
                "req": "catad",
                "ott": true,
        },
        // "dat": {
        //     "cid": parseInt(client_id,10),
        //     "new": newCat,
        //     "des": parseInt(desCat,10),
        //     "upd": updCat
        // }
        "dat": dat
    }
    // console.log("json",jsonBody, "dat",dat)
    return await requestData(jsonBody)
}

export default catad;