import { getProfileOptions, logout } from "Authentication/Session";
import React from "react";
import Logo from "./Logo";
import MenuAdministracion from "./MenuAdministracion";
import MenuTickets from "./MenuTicket";
import Usuario from "./Usuario";



// -----Html exclusivo-----
// const { useState, useEffect,Fragment } = React;

// -----Html exclusivo-----






function HoraFecha(){
    return (
        <div className="navbar-text">
            <h6 className="m-0" id="hora-request">{""}</h6>
            <small id="ref-code" className="float-end"></small>
        </div>
    )
}


function Navbar({options}){
    const CREAR_TICKET =                React.useRef(Number(options[0]) === 1)
    const TICKETS =                     React.useRef(Number(options[1]) === 1)
    const CALENDARIZACIONES =           React.useRef(Number(options[2]) === 1)

    const GESTIONAR_SOLICITUDES =       React.useRef(Number(options[3]) === 1)
    const GESTIONAR_USUARIOS_WEB =      React.useRef(Number(options[4]) === 1)
    const GESTIONAR_USUARIOS_APP =      React.useRef(Number(options[5]) === 1)
    const LOCALES =                     React.useRef(Number(options[6]) === 1)
    const CLIENTES =                    React.useRef(Number(options[7]) === 1)
    const GESTIONAR_USUARIOS_COM =      React.useRef(Number(options[8]) === 1)
    const RTAREAS =                    React.useRef(Number(options[9]) === 1)
    const REPORTE =                    React.useRef(Number(options[10]) === 1)
    return (
        <div>
            <div className="navbar-custom topnav-navbar topnav-navbar-dark">
                <div className="container d-flex">
                    <Logo />
                    <Usuario/>
                </div>
            </div>
            <div className="topnav shadow-sm">
                <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                        <div className="container collapse navbar-collapse">
                            <ul className="navbar-nav  me-auto ">
                                {/* <PanelDeControl/> */}
                                <MenuTickets
                                    creacionTicket={CREAR_TICKET.current}
                                    tickets={TICKETS.current}
                                    calendarizaciones={CALENDARIZACIONES.current}
                                    reporte={REPORTE.current}
                                />
                                <MenuAdministracion
                                    gestionSolicitudes={GESTIONAR_SOLICITUDES.current}
                                    gestionUsuariosWeb={GESTIONAR_USUARIOS_WEB.current}
                                    gestionUsuariosApp={GESTIONAR_USUARIOS_APP.current}
                                    locales={LOCALES.current}
                                    clientes={CLIENTES.current}
                                    gestionUsuariosCom={GESTIONAR_USUARIOS_COM.current}
                                    rTareas={RTAREAS.current}

                                /> {/* me-auto */}

                                {/* <HoraFecha/> */}

                            </ul>
                            <HoraFecha/>
                            <div>
                                
                            </div>
                        </div>

                </nav>

            </div>
        </div>
    )
}
function NavbarWraper (){
    const options = React.useRef(getProfileOptions())
    if (options.current === undefined) {
        logout();
        return (null)
    }
    return <Navbar options={options.current} />
}
export default NavbarWraper

// -----Html exclusivo-----
// const navbarElement =  document.querySelector("#navbar_area");
// ReactDOM.render(<Navbar/>, navbarElement )
// -----Html exclusivo-----
