import { requestData } from "Connection/transacciones"

export const geret = async(dep = {}) => {

    const jsonBody = {
        hdr: {
            txi: 67,
            req: "geret",
        },
        dat: {
            ful: 1,
            hol: dep.hol
        }
    }
    return await requestData (jsonBody)
}

export default geret;