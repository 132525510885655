import BottonToggleFiltro from 'Components/Botones/BotonToggleFiltro'
import MultiSelectBanco from 'Components/Filtros/MultiSelectBanco'
import MultiSelectComuna from 'Components/Filtros/MultiSelectComuna'
import MultiSelectRegion from 'Components/Filtros/MultiSelectRegion'
import { Collapse, Button } from "react-bootstrap"
import { filtrosValores } from 'Utils/filtrosHandler';

import React from 'react'
import useFiltros from 'Hooks/Custom/useFiltros';
import { useLocation } from 'react-router-dom';



function FiltrosUsuariosApp({ totalUsuarios = 0, refreshUsers }) {
    // Vista de filtros
    const [filtrosVisibles, setFiltrosVisibles] = React.useState(true);
    const toggleFiltros = () => setFiltrosVisibles(!filtrosVisibles);
    
    const location = useLocation()
    const {
        // filtrosAplicados,
        isFiltrosUpdated,
        filtrosSelected, 
        handleOnChange, 
        aplicarFiltros,
        reiniciarFiltros
    } = useFiltros({
        cacheKey: "f-usuario-app",
        session: location.key
    })
    // React.useEffect
    
    return (
        <div>
            <div className='d-flex align-items-baseline mb-2'>
                <span className='me-2'>
                    Mostrando <strong>{totalUsuarios || 0}</strong> resultados
                </span>
                <span>
                    <BottonToggleFiltro onClick={toggleFiltros}>
                        Filtros {" "}
                    </BottonToggleFiltro>
                </span>
                <span className='text-waring ms-2'>
                    { !isFiltrosUpdated &&
                        <h4>
                            <span className="badge badge-danger-lighten">Filtros sin aplicar</span>
                        </h4>
                    }
                </span>
            </div>

            <Collapse in={filtrosVisibles}>
                <div>
                    <div className='row'>
                        <div className='col-4 mb-2'>
                            Regiones
                            <MultiSelectRegion 
                                selected={filtrosSelected.reg}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="reg"
                            />
                        </div>
                        <div className='col-4 mb-2'>
                            Comunas
                            <MultiSelectComuna 
                                selected={filtrosSelected.com}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="com"
                            />
                        </div>
                       
                        
                        

                    </div>
                    <div className='row'>
                        <div className='col-4 mb-2'>
                            Banco
                            <MultiSelectBanco
                                onChange={handleOnChange}
                                selected={filtrosSelected.ban}
                                name="ban"
                            />    
                        </div>
                    </div>
                    <div className='text-end mb-2' >
                        <Button type='button' onClick={reiniciarFiltros} className='btn btn-secondary me-2'>
                            Limpiar filtros
                        </Button>
                        <Button type='button' variant='info' onClick={() => aplicarFiltros(refreshUsers)} disabled={isFiltrosUpdated }>
                            Aplicar filtros
                        </Button>
                    </div>
                    
                </div>
                
            </Collapse>
            
        </div>
    )
}

export default FiltrosUsuariosApp