import { requestData } from "Connection/transacciones"

const gefor = async() => {
    const jsonBody = {
        hdr: {
            txi: 92,
            req: "gefor",
            ott: true
        },
        dat: {
        }
    }
    return await requestData (jsonBody);
}

export default gefor;