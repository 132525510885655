import { requestData } from "Connection/transacciones"

const aupen = async(status) => {
    const jsonBody = {
        hdr: {
            txi: 52,
            req: "aupen",
            ott: true,
        },
        dat: {
            ein: status
        }
    }
    return await requestData(jsonBody)

}

export default aupen;