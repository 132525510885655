import React from 'react'
import { Form } from 'react-bootstrap'
// import catad from 'Connection/transacciones/catad'
import matrx from 'Connection/transacciones/matrx'
import catad from 'Connection/transacciones/catad'
import useDatosUsuarioWeb from 'Hooks/Datos/useDatosUsuarioWeb'


function DatosGenerales({formulario, cliente}) {
    
    const [catalogoOpc, setcatalogoOpc] = React.useState([])
    React.useEffect (() => {
        const run = async() => {
            // const request = await matrx({   
            //     trx:"catad",
            //     cid:cliente
            // });
            const request = await catad({
                cid:cliente
            });
            // const request = await gecat(cliente);
            const options = request.ans.cat.map(opt => ({label: opt.nam, value: opt.cod})); 
            setcatalogoOpc(options)
        }
        run()
    }, [])
    return (
        <>
        
            <div className="card-header detalle-ticket-header">
                <h5 className="card-title mb-0" >
                    Datos generales
                </h5>
            </div>
            <div className="card-body pb-1 pt-2 detalle-ticket-body">
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="tipoTicket" className="col-form-label">
                            Tipo de Ticket
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Select 
                            id='tipoTicket' 
                            value={formulario.values.tipoTicket}
                            onChange={formulario.handleChange}
                            required 
                        >
                            <option value="2">Reposición</option>
                            <option value="1">Auditoría</option>
                            <option value="3">Implementación</option>
                        </Form.Select>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="nombreTicket" className="col-form-label">
                            Nombre Ticket
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Control 
                            value={formulario.values.nombreTicket}
                            onChange={formulario.handleChange}
                            id='nombreTicket' 
                            type='text'
                            required 
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="etiqueta" className="col-form-label">
                            Etiqueta
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Control 
                            value={formulario.values.etiqueta}
                            onChange={formulario.handleChange} 
                            id='etiqueta' 
                            type='text'
                            required 
                        />
                    
                    </div>
                </div>
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="descripcion" className="col-form-label">
                            Descripción
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Control 
                            as='textarea'
                            id='descripcion' 
                            rows={4}
                            value={formulario.values.descripcion}
                            onChange={formulario.handleChange} 
                            type='text'
                            maxLength={512} 
                            required 
                        >
                    
                        </Form.Control>
                    </div>
                </div>
                
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="categoriaProducto" className="col-form-label">
                            Categoria de producto
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Select 
                            id='categoriaProducto' 
                            value={formulario.values.Catprod}
                            onChange={formulario.handleChange}
                            defaultValue={""}
                            required 
                        >    
                            <option disabled value="">Seleccionar elemento</option>
                            {catalogoOpc.map((opt,i) => (
                                    <option value={(opt.value)} key={i}>{opt.label}</option>
                                    )
                                )}
                        </Form.Select>
                    </div>
                </div>
                
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="Catalogo" className="col-form-label">
                            Catalogo
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Control 
                            value={formulario.values.catalogo}
                            onChange={formulario.handleChange}
                            id='catalogo' 
                            type='text'
                            required 
                        />
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default DatosGenerales