import { requestData } from "Connection/transacciones"
import { clean } from "Utils/rut";

const newpw = async(user, newPassword, codigoVerificacion) => {
    const jsonBody = {
        hdr: {
            txi: 5,
            req: "newpw",
            ott: false
        },
        dat: {
            usr: clean(user).toUpperCase(),
            psw: newPassword,
            vcd: parseInt(codigoVerificacion, 10)
        }
    }

    return await requestData(jsonBody)

}

export default newpw;