import clall from "Connection/transacciones/clall";
import React, { useCallback } from "react";

function useListaClientes2() {
    const [values, setValues] = React.useState([]);
    const [isFetching, setIsFetching] = React.useState(false)
    const fetchClientes = useCallback(async() => {
        setIsFetching(true);
        const request =  await clall(1);
        if (request.ans.stx === "ok") {
            const nrequest = request.ans.wus.map(({ cid:cli,name:cln, ...rest }) => ({ cli, cln, ...rest }));
            setValues(nrequest? nrequest: [])
        }
        setIsFetching(false);
    },[])
    return {
        values,
        options: values,
        fetchClientes,
        isFetching
    }

}

export default useListaClientes2