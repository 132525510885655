import useComuna from 'Hooks/Selects/useComuna';
import useKas from 'Hooks/Selects/useKas';
import React from 'react'
import { MultiSelect } from 'react-multi-select-component';
import localization from "Assets/localization/multiSelect.json"
function MultiSelectKas({
    isDisabled,
    dependencies = {}, 
    onChange = () => {},
    selected=[],
    name

}) {
    const kas = useKas();
    
    // Variable utilizada para determinar si la lista de usaurios está actualizados
    const fetched = React.useRef(false) 

    React.useEffect(()=> {
        fetched.current = false; // si se actualiza una dependencia se debe menciar que esta desactualizado 
    },[ 
        dependencies.kam,
    ])

    const onToggle = async(isOpen) => {
        if(isOpen && !fetched.current) {
            fetched.current = true; // Lista de comunas actualizados? SI
            await kas.refreshKas(dependencies);
        }
    }
    

    const handleOnChange = (values) => {
        const sorted = values.sort((a,b) => a.value - b.value)
        onChange(sorted, name)
    }
    return (
        <MultiSelect
            overrideStrings={localization}
            options={kas.value}
            disabled={isDisabled}
            isLoading={kas.isFetching}
            onMenuToggle={onToggle}
            value={selected}
            onChange={handleOnChange}
        />
    )
}

export default MultiSelectKas;