import React from "react"
import usloa from "Connection/transacciones/usloa"
import uslox from "Connection/transacciones/uslox"
import { includes, isEqual } from "lodash";
import toast from "react-hot-toast";
import { Form } from "react-bootstrap";
import { inRange } from "lodash";

// const  test = {"lid": 3,
// "cod": "J501JU",
// "nam": "Supermercado La Plaza",
// "adr": "La Plaza s/n",
// "com": "Calle Larga",
// "reg": "Region de Valparaiso",
// "ret": "Plazas Unidas"
// }

function useLocalesAsignadosCliente ({cid}) {
    // valores
    const [localesIniciales, setLocalesIniciales] = React.useState([]) // locales iniciales
    const [localesDelCliente, setLocalesDelCliente] = React.useState([]) // locales para render
    const [localesAsignados, setLocalesAsignados] = React.useState([]); // locales para render
    const [localQuantity, setLocalQuantity] = React.useState(0)
    const isDirty = React.useMemo(() => { // determina si hay cambios
        let iniciales = []
        let asignados = []
        localesIniciales.map(local => {
            if (local.sta === 1){
                iniciales.push(local.clu)
            }
        })
        localesAsignados.map(local => {
            asignados.push(local.clu)
        })
        asignados.map(local => {
            if (!iniciales.includes(local)){
                return true
            }
        })
        return false
        // return false
        // return !isEqual(
        //     localesIniciales.map(item => item.clu), 
        //     localesAsignados.map(item => item.clu))
    }, [localesIniciales, localesAsignados]) 

    // metodos
    // const cancelarCambios = () => setLocalesAsignados(localesIniciales) // reinicia los valores
    // const cancelarCambios = () => setLocalesAsignados(
    //     localesIniciales.map(local => {
    //         if (local.sta === 1){
    //             return local
    //         }
    //     })
    // ) // reinicia los valores
    const cancelarCambios = () => { // reinicia los valores
        let localesArr = []
        const localesIniciales2 = JSON.parse(JSON.stringify(localesIniciales))
        localesIniciales2.map(
            value => {
                if (value.sta === 1){
                    localesArr.push(value)
                }
            }
        )
        
        setLocalesAsignados(localesArr)
        reboot()
        // setIsFetching(false)
        // console.log(localesIniciales)
    }
    const agregarLocal = (nuevoLocal) => {
        const localInfo = {
            lid: nuevoLocal.lid,
            cod: nuevoLocal.cod,
            nam: nuevoLocal.nam,
            adr: nuevoLocal.adr,
            com: nuevoLocal.com,
            reg: nuevoLocal.reg,
            ret: nuevoLocal.ret,
            clu: nuevoLocal.lid,
        }
        setLocalesAsignados(locales => {
            return [...locales, localInfo]
        })
    }
    const agregarLocalDeshabilitado = (nuevoLocal) => {
        const localInfo = {
            lid: nuevoLocal.lid,
            cod: nuevoLocal.cod,
            nam: nuevoLocal.nam,
            adr: nuevoLocal.adr,
            com: nuevoLocal.com,
            reg: nuevoLocal.reg,
            ret: nuevoLocal.ret,
            clu: nuevoLocal.clu,
        }
        setLocalesAsignados(locales => {
            return [...locales, localInfo]
        })
    }

    const eliminarLocal = (localId) => { // elimina a un local en base a su ID
        setLocalesAsignados(locales => locales.filter(local => local.lid !== localId))
        reboot()
    }



    const reboot = async() => {
        const a =await setIsFetching(true)
        
        const b = await setIsFetching(false)
    }
    // Cambios en el backend
    const [isUpdating, setIsUpdating] = React.useState(false)
    const confirmarCambiosBackend = async() => { 
        const localesAntes = []

        localesIniciales.map(local => {
            if (local.sta === 1){
                localesAntes.push(local.clu)
            }
        })


        const localesDespues = localesAsignados.map(local => local.clu)
        localesDespues.map(local => {
            if (local >= 10000000){

                localesIniciales.map(deshabilitados => {
                    if (deshabilitados.lid === local%1000000){
                        localesAntes.push(deshabilitados.clu)
                    }
                })
            }
        })
        setIsUpdating(true)

        const request = await uslox({
            cid: cid,
            antes: localesAntes,
            despues: localesDespues,
        })

        // console.log("antes",localesAntes)
        // console.log("despues",localesDespues)

        // console.log("antes",localesIniciales)
        // console.log("despues",localesAsignados)
        setIsUpdating(false)

        if (request.ans.stx === "ok"){
            fetch()
            toast.success(request.ans.msg)
        } else {
            toast.error(request.ans.msg)
        }

    }

    // control de request
    const [isFetching, setIsFetching] = React.useState(false);
    const fetched = React.useRef(false)
    const fetch = React.useCallback(async() => {
        setIsFetching(true)
        const request = await usloa({cid: [cid]})
        // console.log("request",request)
        fetched.current = true
        if (request.ans.stx === "ok"){
            await setLocalesIniciales(request.ans.loa || [])
            if (request.ans.loa){
                let localesArr = []
                JSON.parse(JSON.stringify(request.ans.loa)).map(
                    value => {
                        if (value.sta === 1){
                            localesArr.push(value)
                        }
                    }
                )
                await setLocalesAsignados(localesArr)

                await setLocalesDelCliente(JSON.parse(JSON.stringify(localesArr)))
            }
            // const localesArr = []
            // JSON.parse(JSON.stringify(localesIniciales)).map(local => {
            //     // console.log(local)
            //     if (local.sta === 1){
            //         localesArr.push(local)
            //     }
            // })
            // request.ans.loa.map(local => {
            //     if (local.sta === 1){
            //         localesArr.push(local)
            //     }
            // })
            // setLocalesAsignados(localesArr)
            // setLocalesAsignados(request.ans.loa || [])
            setLocalQuantity(request.ans.knt || 0)
        } 
        else if( request.asn.stx === "uk"){
            setLocalesAsignados([])
        }
        
        setIsFetching(false)
    },[])

    return {
        localesIniciales,
        localesAsignados,
        fetched: fetched.current,
        fetch,
        isFetching,
        hasChanged: isDirty,
        localQuantity,
        agregarLocal,
        eliminarLocal,
        agregarLocalDeshabilitado,
        confirmarCambios: confirmarCambiosBackend,
        isUpdating,
        cancelarCambios,
    }

}

export default useLocalesAsignadosCliente