import { requestData } from "Connection/transacciones"

const usall = async (dependencies = {}) => {
    const jsonBody = {
        hdr: {
            txi: 71,
            req: "usall",
            ott: true,
        },
        dat: dependencies
    }
    return await requestData(jsonBody)
}

export default usall;