import React, { useEffect, createRef } from 'react'
import toast from 'react-hot-toast';
import { Accordion, Button } from 'react-bootstrap'
import { actualizarInfoPagina } from 'Utils/paginaInfo';
// import FormularioCrearTicket from './formulario/FormularioCrearTicket';
import { Link, useNavigate } from 'react-router-dom'
import Excel from 'exceljs';
import calen from "Connection/transacciones/calen";
import prmas from 'Connection/transacciones/prmas';
// import "./aa.css";
import Dropzone from 'react-dropzone';
import { set } from 'lodash';
import locmc from 'Connection/transacciones/locmc';
import { saveAs } from "file-saver";
import { numToCol } from 'Utils/excelutils';
import { getCountry } from "Authentication/Session";
import { getClient } from "Authentication/Session";
import { getClientID } from 'Authentication/Session';
import prloc from 'Connection/transacciones/prloc';
import Tabla from 'Components/Tabla/Tabla';
import { formatearFechaHora, mesActual } from "Utils/funcionesFechaHora";
import Select from 'react-select';
import prcal from 'Connection/transacciones/prcal';
import prloa from 'Connection/transacciones/prloa';
import Calendas from './calendas';

const fechaDiaHoy = mesActual();

function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Presupuesto</li>
                </ol>
            </div>
            <h4 className="page-title">Presupuesto</h4>
        </div>
    )
}

function LayoutPresupuesto(props) {

    // cambiar accordeon
    // const [activeKey, setActiveKey] = React.useState("0");
    // const handleSelect = (eventKey) => {
    //     setActiveKey(eventKey);
    // };



    // const [selectedFile, setSelectedFile] = React.useState(null);
    // const dropzoneRef = createRef();
    // const openDialog = () => {
    //     // Note that the ref is set async,
    //     // so it might be null at some point 
    //     if (dropzoneRef.current) {
    //         dropzoneRef.current.open()
    //     }
    // };

    // const onDrop = (files) => {
    //     if (files.length > 0) {
    //         setSelectedFile(files[0]);
    //     }
    // };
    React.useEffect(() => { actualizarInfoPagina(props) }, []);  // eslint-disable-line

    return (
        <>
            <div className='container'>
                <TituloPagina />
                <div className="card">
                    <div className="card-body">
                    <Calendas/>
                    </div>
                </div>
            </div>
        </>)
}
export default LayoutPresupuesto
