import { login } from "Authentication/Session";
import useInputRut from "Hooks/Inputs/useInputRut";
import useMountedComponent from "Hooks/Custom/useMountedComponent";
import React, { useState } from "react";





function FormularioLogin({setForm}){
    // Elementos del formulario

    const {
        rut, 
        handleOnChange,
        formatInput,
    } = useInputRut()
    const [password, setPassword] = React.useState("")
    const [isDisabled, setIsDisabled] = useState(false) // activa o desactiva botones  

    const { mounted } = useMountedComponent(); // para evitar memory leaks
    const submitLogin = async(event) => {
        event.preventDefault();
        if(mounted === true) setIsDisabled(true);
        console.log()
        const isAuthenticated = await login(rut, password);
        const imagen = sessionStorage.getItem("imagen");
        sessionStorage.removeItem("imagen");
        if(isAuthenticated === true) {
            if(imagen){
                window.location.href = imagen;
            }
            else{
                window.location.href = "/Dashboard"
            }
        }

        // if(isAuthenticated === true) window.location.href = "/Dashboard"
        if(mounted === true) setIsDisabled(false);
    }
    const irRecuperarPassword = (event)=>{
        event.preventDefault();
        setForm("recuperar");
    }
    return (
        <>
            <h4 className="mt-0">
                Iniciar Sesión
            </h4>
            <br />
            <form onSubmit={submitLogin}>

                <div className="mb-2">
                    <label htmlFor="usuarioLogin">
                        Usuario
                    </label>
                    <input 
                        type="text"
                        className="form-control"
                        required
                        value={rut}
                        onChange={handleOnChange}
                        onBlur={formatInput}
                        placeholder="Usuario"
                        autoComplete="username"
                        disabled={isDisabled}

                    />
                </div>
                <div className="mb-3">
                
                    <small className="text-muted float-end">
                        ¿Olvidaste tu contraseña? {" "}
                        <a  tabIndex={-1}
                            href="/#"
                            onClick={irRecuperarPassword}
                        >
                        Recupérala
                        </a>
                    </small>    
                    <label htmlFor="passwordLogin">
                        Contraseña
                    </label>
                    <input 
                        type="password"
                        required
                        minLength={"6"}
                        maxLength={"16"}
                        className="form-control"
                        value={password}
                        onChange={e=> setPassword(e.target.value)}
                        placeholder="Contraseña"
                        disabled={isDisabled}
                    />
                </div>

                <div className="d-grid mb-0 text-center">
                    <button 
                        className="btn btn-primary"
                        type="submit"
                        disabled={isDisabled}
                    >
                    {!isDisabled &&
                        <> <i className="mdi mdi-login"></i> INICIAR SESIÓN </>
                    }
                    {isDisabled &&
                        <span className="spinner-border spinner-border-sm" role="status">

                        </span>
                    }
                        
                    </button>
                </div>
            </form>
        </>
    )
}


export default FormularioLogin;