import React from 'react'
import { gereg } from 'Connection/transacciones/gereg'



function useRegion() {
    const [value, setValue] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)
    
    const mounted = React.useRef(true)
    React.useEffect(() => {
        return () => {
            mounted.current = false
        };
    }, []);


    const refreshRegiones = async(dependencies = {}) => {
        if(mounted.current === true) setIsFetching(true);
        const requestRegiones = await gereg(dependencies)
        if(requestRegiones.ans.stx === "ok" && mounted.current === true){
            if(requestRegiones.ans.reg){
                const newValues = requestRegiones.ans.reg.map(item => ({
                    "value": item.cod,
                    "label": item.nam,   
                }))
                setValue(newValues)
            } else {
                setValue([])
            }
        }
        if(mounted.current === true) setIsFetching(false);
    }
    
    return {
        value,
        isFetching,
        refreshRegiones
    }
}

// useRegion.propTypes = {}

export default useRegion
