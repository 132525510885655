import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Toaster } from "react-hot-toast";
import IndexHandler from "Utils/IndexHandler";
import PaginaDetalleTicket from "Routes/detalleTicket/PaginaDetalleTicket";
import LayoutLogin from "Routes/login/LayoutLogin";
import LayoutResultadoTicket from "Routes/resultadoTicket/LayoutResultadoTicket";
import PaginaError404 from "Routes/errores/PaginaError404";
import PaginaSolicitudes from "Routes/gestionSolicitudes/PaginaSolicitudes";
import PaginaConfiguracion from "Routes/cuenta/LayoutConfiguracionUsuario";
import PaginaDashboard from "Routes/dashboard/LayoutDashboard";
import GestionUsuarios from "Routes/gestionUsuarios/usuariosWeb/LayoutGestionUsuariosWeb";
import ProtectedRoute from "Authentication/ProtectedRoute";
import NoAuthRoute from "Authentication/NoAuthRoute";
import PaginaLocales from "Routes/locales/PaginaLocales";
import PaginaCrearTicket from "Routes/creacionTickets/PaginaCrearTicket";
import PaginaListaTickets from "Routes/listaTickets";
import LayoutCliente from "Routes/gestionClientes/editarCliente/LayoutCliente"
import LayoutAgregarCliente from "Routes/gestionClientes/agregarCliente/LayoutAgregarCliente";
import GestionClientes from "Routes/gestionClientes/LayoutGestionClientes";
import LayoutWrapper from "Layouts/LayoutWrapper";
import LayoutGestionUsuariosApp from "Routes/gestionUsuarios/usuariosApp/LayoutGestionUsuariosApp";
import LayoutUser from "Routes/gestionUsuarios/usuariosApp/User/LayoutUser";
import LayoutUserWeb from "Routes/gestionUsuarios/usuariosWeb/User/LayoutUserWeb";
import LayoutUserComercial from "Routes/gestionUsuarios/usuariosComercial/User/LayoutUserCom";
import GestionUsuariosComercial from "Routes/gestionUsuarios/usuariosComercial/LayoutGestionUsuariosComercial";
import LayoutAgregarUsuario from "Routes/gestionUsuarios/usuariosWeb/AgregarUsuario/LayoutAgregarUsuario";
import LayoutAgregarUsuarioCom from "Routes/gestionUsuarios/usuariosComercial/AgregarUsuario/LayoutAgregarUsuarioCom.jsx";
import DangerModal from "Components/Modal/DangerModal";
import PaginaListaCalendarizacionesIdTicket from "Routes/listaCalendarizacion/LayoutListaCalendarizacionIdTicket";
import PaginaListaCalendarizaciones from "Routes/listaCalendarizacion/LayoutListaCalendarizacion";
import RevisionTask from "Routes/revisionTarea/LayoutListaTareas";
import Import from "Routes/calendarizacionMasiva/importLayout";
import Agrupar from "Routes/listaTickets/ResumenTickets/index";
import ImagenConsulta from "Routes/listaTickets/ImagenTicket/Imagen";
import PaginaReporte from "Routes/reporte/PaginaReporte";
import PaginaDetalleCalendarizacion from "Routes/listaCalendarizacion/detalleCalendarizacion/LayoutDetalleCalendarizacion";
import LayoutCalendarizarTicket from "Routes/listaTickets/Calendarizar/LayoutCalendarizar";
import LayoutAgregarLocal from "Routes/locales/AgregarLocal/LayoutAgregarLocal";
import LayoutGestionarLocal from "Routes/locales/Local/LayoutLocal";
import PaginaPrecios from "Routes/precios/layoutPrecios";
// import CargaPreciosMasiva from "Routes/precios/cargaMasivaPrecios/cargaMasivaPrecios";
// import Sample from "Routes/precios/updPrecios.jsx/sample";
import LayoutPresupuesto from "Routes/precios/Index/precioLayput";
import LayoutCargaPresupuesto from "Routes/precios/CargarPresupuesto/layoutCargas";

// import Sandbox from "Components/Sandbox";


function App() {
	
	return (
		<BrowserRouter>
			<Toaster position="top-center"/>
			<DangerModal />
			<Routes>
				<Route element={<NoAuthRoute />} >
					<Route path="/" element={<IndexHandler />} />
					<Route path="/Login" element={<LayoutLogin title={"Repoflex Web - Iniciar sesión"}/> }/>
				</Route>
				<Route element={<ProtectedRoute />} >
					<Route path="/" element={<LayoutWrapper/>}> 
						{/* <Route path="Sandbox" element={<Sandbox />}  /> */}
						<Route path="Dashboard" element={<PaginaDashboard title={"Repoflex Web - Dashboard"} refCode={"RF001"}/> }/>
						<Route path="Cuenta" element={<PaginaConfiguracion title={"Repoflex Web - Configuración de cuenta"} refCode={"RF004"}/> } />
						<Route path="Solicitudes" element={<PaginaSolicitudes title={"Repoflex Web - Solicitudes"}  refCode={"RF006"}/>}/>		
						<Route path="CrearTicket" element={<PaginaCrearTicket title={"Repoflex Web - Creación de Ticket"}  refCode={"RF009"} /> } />
						<Route path ="Calendarizaciones">
							<Route index element={<PaginaListaCalendarizaciones title={"Repoflex Web - Calendarizaciones"}  refCode={"RF102"} /> } />
							<Route path="DetalleCalendarizacion" element={<PaginaDetalleCalendarizacion title={"Repoflex Web - Detalle de Calendarización"}  refCode={"RF1099"} /> } />

						</Route>
						{/* <Route path="Calendarizaciones" element={<PaginaListaCalendarizaciones title={"Repoflex Web - Calendarizaciones"}  refCode={"RF102"} /> } /> */}
						{/* <Route path="CalendarizacionesTicket" element={<PaginaListaCalendarizacionesIdTicket title={"Repoflex Web - Calendarizaciones"}  refCode={"RF102"} /> } /> */}
						<Route path="CalendarizacionesTicket">
							<Route index element={<PaginaListaCalendarizacionesIdTicket title={"Repoflex Web - Calendarizaciones"}  refCode={"RF102"} /> } />
							<Route path="DetalleCalendarizacion" element={<PaginaDetalleCalendarizacion title={"Repoflex Web - Detalle de Calendarización"}  refCode={"RF1099"} /> } />
						</Route>
						<Route path="ListaTickets">
							<Route index element={<PaginaListaTickets title={"Repoflex Web - Lista de Tickets"} refCode={"RF101"}/>} />
							<Route path="ClonarTicket" element={<PaginaCrearTicket title={"Repoflex Web - Clonar Ticket"}  refCode={"RF010"} /> } />
							<Route path="DetalleTicket" element={<PaginaDetalleTicket title={"Repoflex Web - Detalle de Ticket"}  refCode={"RF002"} />}/>
							<Route path="ResultadoTicket" element={<LayoutResultadoTicket title={"Repoflex Web - Resultado de Ticket"}  refCode={"RF003"} />}/>
							<Route path="GroupDetail" element={<Agrupar title={"Repoflex Web - Resultado de Ticket"}  refCode={"RF023"} />}/> 
							<Route path="Imagen/:id" element={<ImagenConsulta title={"Repoflex Web - Imagen de Ticket"}  refCode={"RF0241"} />}/>
							<Route path="CalendarizacionMasiva" element={<Import title={"Repoflex Web - Calendarización Masiva"}  refCode={"RF105"} /> } />
							<Route path="CalendarizarTicket" element={<LayoutCalendarizarTicket title={"Repoflex Web - Calendarización de Ticket"}  refCode={"RF105"} /> } />
							
						</Route>
						<Route path="GestionClientes">
							<Route path="Clientes">
								<Route index element={<GestionClientes title={"Repoflex Web - Gestión de Clientes"} refCode={"RF015"}/>} />
								<Route path="EditarCliente" element={<LayoutCliente title={"Repoflex Web - Perfil de Cliente"} refCode={"RF016"} />} />
								<Route path="AgregarCliente" element={<LayoutAgregarCliente title={"Repoflex Web - Agregar Cliente"}  refCode={"RF017"} /> } 
							/>
						</Route>
						</Route>
						<Route path="GestionUsuarios">
							<Route path="Web" >
								<Route index element={<GestionUsuarios title={"Repoflex Web - Gestión de Usuarios"}  refCode={"RF007"}/>} />
								<Route path="UserWeb" element={<LayoutUserWeb title={"Repoflex Web - Perfil de usuario Web"} refCode={"RF013"} />} />
								<Route path="AgregarUsuario" element={<LayoutAgregarUsuario title={"Repoflex Web - Agregar Usuario"}  refCode={"RF014"} /> } />
							</Route>
							<Route path="App">
								<Route index element={<LayoutGestionUsuariosApp title={"Repoflex Web - Gestionar Usuarios App"}  refCode={"RF011"}/>}/>
								<Route path="User" element={<LayoutUser title={"Repoflex Web - Perfil de usuario App"}  refCode={"RF012"} />} />
							</Route>
							
							<Route path="Comercial">
								<Route index element={<GestionUsuariosComercial title={"Repoflex Web - Gestionar Usuarios Comerciales"}  refCode={"RF018"}/>}/>
								<Route path="UserCom" element={<LayoutUserComercial title={"Repoflex Web - Perfil de usuario Comerciales"}  refCode={"RF019"} />} />
								<Route path="AgregarUsuario" element={<LayoutAgregarUsuarioCom title={"Repoflex Web - Agregar Usuario Comercial"}  refCode={"RF020"} />} />
							</Route>
						</Route>
						<Route path="Locales">
							<Route index element={<PaginaLocales title={"Repoflex Web - Locales"}  refCode={"RF008"} /> } />
							<Route path="AgregarLocal" element={<LayoutAgregarLocal title={"Repoflex Web - Locales"}  refCode={"RF008"}/>}></Route>
							<Route path="GestionarLocal" element={<LayoutGestionarLocal title={"Repoflex Web - Locales"}  refCode={"RF008"}/>}></Route>
						</Route>
						{/* <Route path="Locales" element={<PaginaLocales title={"Repoflex Web - Locales"}  refCode={"RF008"} /> } /> */}
						{/* <Route path="Test" element={<Linkprueba title={"Repoflex Web - test"}  refCode={"RF020"} /> } /> */}
						<Route path="RevisionTareas" >
							<Route index element={<RevisionTask title={"Repoflex Web - Revision de Tareas"}  refCode={"RF021"} /> } />
						{/* <Route path="Test2" element={<Linkprueba2 title={"Repoflex Web - test2"}  refCode={"RF022"} /> } /> */}
						</Route><Route path="Presupuesto" >
							<Route index element={<LayoutPresupuesto title={"Repoflex Web - Presupuesto"}  refCode={"RF025"} /> } />
							<Route path="CargarPresupuesto" element={<LayoutCargaPresupuesto title={"Repoflex Web - Carga de Presupuesto"}  refCode={"RF026"} /> } />
							<Route path="VerPresupuesto" element={<PaginaPrecios title={"Repoflex Web - Ver Presupuesto"}  refCode={"RF027"} /> } />	
							{/* <Route path="CargarPresupuesto" element={<Sample title={"Repoflex Web - Carga de Presupuesto"}  refCode={"RF026"} /> } /> */}
							{/* <Route path="CargarPresupuesto" element={<LayoutCargaPrecios title={"Repoflex Web - Carga de Presupuesto"}  refCode={"RF026"} /> } /> */}

						{/* <Route path="Test2" element={<Linkprueba2 title={"Repoflex Web - test2"}  refCode={"RF022"} /> } /> */}
						</Route>
						<Route path="Reportes" element={<PaginaReporte title={"Repoflex Web - Reportes"}  refCode={"RF022"} /> } />
						{/* <Route path="Precios" element={<PaginaPrecios title={"Repoflex Web - Precios"}  refCode={"RF025"} /> } /> */}
						<Route path="*" element={<PaginaError404/>} />
					</Route>
				</Route>
			
			</Routes>
			
		</BrowserRouter>
	);
}

export default App;
