import React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isAuthenticated } from "./Session";

function ProtectedRoute () {
    const location = useLocation().pathname;
    const isAuth = isAuthenticated();
    if (!isAuth && location.includes("/ListaTickets/Imagen/")) {
        sessionStorage.setItem("imagen",location);
        // console.log("imagen")
    }
    return (isAuth? <Outlet />: <Navigate to={"/Login"} />)
}

export default ProtectedRoute;
