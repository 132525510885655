import React from "react";


const IndexHandler = () => {
    console.log(localStorage.ott)
    if (localStorage.ott) {
        window.location.href = "/Dashboard"
    } else {
        window.location.href = "/login"
    }
    return (
        <div>
            INDEX
        </div>
    )
}

export default IndexHandler;
