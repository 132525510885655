import React from "react";
import Select from "react-select"
import InputTexto from "Components/FormElements/InputTexto";
import gefor from "Connection/transacciones/gefor";
import geret from "Connection/transacciones/geret";
import gecom from "Connection/transacciones/gecom";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { gereg } from "Connection/transacciones/gereg";
import toast from "react-hot-toast";
import locad from "Connection/transacciones/locad";
import { coordinatesRegex } from "Utils/validations";

const propsStyles = {
    asRow: true,
    labelSize:3,
    size: "sm"
}

function AgregarLocal(){
    const Navigate = useNavigate()

    
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleShow = React.useCallback(() => setIsModalOpen(true),[]);
    const handleClose = React.useCallback(() => setIsModalOpen(false),[]);
    //Formularios
    const [codLocal, setCodLocal] = React.useState("")
    const [name, setName] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [latitude, setLatitude] = React.useState("")
    const [longitude, setLongitude] = React.useState("")

    //selectores
    const [codComuna, setCodComuna] = React.useState("")
    const [comunasOpc, setComunaOpc] = React.useState([])
    const [codFormat, setCodFormat] = React.useState("")
    const [formatOpc, setFormatOpc] = React.useState([])
    const [retailer, setRetailer] = React.useState("")
    const [retailerOpc, setRetailerOpc] = React.useState([])

    //selector filtro
    const [region, setRegion] = React.useState("")
    const [regionOpc, setRegionOpc] = React.useState([])

    const validName = React.useMemo(() => {
        if(name === "") return undefined
        else return true
    } ,[name])
    const validCodLoc = React.useMemo(() => {
        if(codLocal === "") return undefined
        else return true
    } ,[codLocal])
    const validAddress = React.useMemo(() => {
        if(address === "") return undefined
        else return true
    } ,[address])
    const validLatitude = React.useMemo(() => {
        if(latitude === "") return undefined
        else return true
    } ,[latitude])
    const validLongitude = React.useMemo(() => {
        if(longitude === "") return undefined
        else return true
    } ,[longitude])
    const validCodFormat = React.useMemo(() => {
        if(codFormat === "") return undefined
        else return true
    } ,[codFormat])
    const validRetailer = React.useMemo(() => {
        if(retailer === "") return undefined
        else return true
    } ,[retailer])
    const validCodRegion = React.useMemo(() => {
        if(region === "") return false
        else return true
    } ,[region])
    const validCodComuna = React.useMemo(() => {
        if(codComuna === "") return undefined
        else return true
    } ,[codComuna])

    React.useEffect(()=>{
        const run = async() =>{
            const request = await gefor()
            let opc1=[]
            if (request.ans.stx === "ok"){
                for (let i = 0; i < request.ans.cat.length; i++){
                    opc1.push({label: request.ans.cat[i].nam, value: request.ans.cat[i].cod})
                }
                setFormatOpc(opc1)
            }
            const request2 = await geret()
            let opc2=[]
            if(request2.ans.stx === "ok"){
                for (let i = 0; i < request2.ans.ret.length; i++){
                    opc2.push({label: request2.ans.ret[i].nam, value: request2.ans.ret[i].cod})
                }
                setRetailerOpc(opc2)
            }
            const request3 = await gereg()
            let opc3=[]
            if(request3.ans.stx === "ok"){
                // console.log(request3)
                for (let i = 0; i < request3.ans.reg.length; i++){
                    opc3.push({label: request3.ans.reg[i].nam, value: request3.ans.reg[i].cod})
                }
                setRegionOpc(opc3)
            }

        }
        run()
    },[])

    const onChangeCodLocal = (value) => setCodLocal(value);
    const onChangeName = (value) => setName(value);
    const onChangeAddress = (value) => setAddress(value);
    const onChangeLatitude = (value) => {
        if(coordinatesRegex.test(value)) setLatitude(value)
    }
    const onChangeLongitude = (value) => {
        if(coordinatesRegex.test(value)) setLongitude(value)
    }
    
    const onChangeFormat = (value) => setCodFormat(value)
    const onChangeComuna = (value) => setCodComuna(value)
    const onChangeRetailer = (value) => setRetailer(value)
    const onChangeRegion = async(value) => {
        setRegion(value)
        setCodComuna("")
        const request = await gecom({reg:[value.value]})
        let opc=[]
        if (request.ans.stx === "ok"){
            for (let i = 0; i < request.ans.com.length; i++){
                opc.push({label: request.ans.com[i].nam, value: request.ans.com[i].cod})
            }
            setComunaOpc(opc)
        }
    }

    const [isSubmiting, setIsSubmiting] = React.useState(false);
    const handlerSubmit = async()=>{
        setIsSubmiting(true);

        const request = await locad({
            loc: codLocal,
            nam: name,
            adr: address,
            lat: latitude,
            lon: longitude,
            com: codComuna.value,
            for: codFormat.value,
            ret: retailer.value
        })
        
        if(request.ans.stx === "ok") {
            toast.success(request.ans.msg)
            Navigate('..')
        } else if(request.ans.stx === "nk") {
            toast.error((t) => (<>
                {request.ans.msg}
            </>) )

        }
        setIsSubmiting(false);
    }

    return(
        <>
            <div className="card">
                <div className="card-body">
                    <div className="card-header detalle-ticket-header">
                        <h5 className="card-title mb-0">
                            Datos del local
                        </h5>
                    </div>
                    <div className="card-body pb-1 pt-2 detalle-ticket-body">
                        <div className="mb-1">
                            <InputTexto
                                {...propsStyles}
                                label="Codigo Local"
                                value={codLocal}
                                // validation={true}
                                onChange={onChangeCodLocal}
                            />
                        </div>
                        <div className="mb-1">
                            <InputTexto
                                {...propsStyles}
                                label="Nombre Local"
                                value={name}
                                // validation={true}
                                onChange={onChangeName}
                            />
                        </div>
                        <div className="mb-1">
                            <div className="row">
                                <div className="col-sm-3">
                                    <label> Retailer</label>
                                </div>
                                <div className="col-sm-9">
                                    <Select
                                        placeholder="Elegir retail"
                                        value={retailer}
                                        onChange={ opt => onChangeRetailer(opt)}
                                        options={retailerOpc}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <div className="row">
                                <div className="col-sm-3">
                                    <label> Formato</label>
                                </div>
                                <div className="col-sm-9">
                                    <Select
                                        placeholder="Elegir formato de local"
                                        value={codFormat}
                                        onChange={ opt => onChangeFormat(opt)}
                                        options={formatOpc}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <h5>
                                Ubicación
                            </h5>
                        </div>
                        <div className="mb-1">
                            <InputTexto
                                {...propsStyles}
                                label="Latitud"
                                value={latitude}
                                // validation={true}
                                onChange={onChangeLatitude}
                            />
                        </div>
                        <div className="mb-1">
                            <InputTexto
                                {...propsStyles}
                                label="Longitud"
                                value={longitude}
                                // validation={true}
                                onChange={onChangeLongitude}
                            />
                        </div>
                        <div className="mb-1">
                            <div className="row">
                                <div className="col-sm-3">
                                    <label> Region</label>
                                </div>
                                <div className="col-sm-9">
                                    <Select
                                        placeholder="Elegir Region"
                                        value={region}
                                        onChange={ opt => onChangeRegion(opt)}
                                        options={regionOpc}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <div className="row">
                                <div className="col-sm-3">
                                    <label> Comuna</label>
                                </div>
                                <div className="col-sm-9">
                                    <Select
                                        placeholder="Elegir Comuna"
                                        value={codComuna}
                                        onChange={ opt => onChangeComuna(opt)}
                                        options={comunasOpc}
                                        isDisabled={!validCodRegion}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <InputTexto
                                {...propsStyles}
                                label="Direccion local"
                                value={address}
                                // validation={true}
                                onChange={onChangeAddress}
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button 
                                variant="primary"
                                onClick={() => handlerSubmit()}
                                disabled={!(validName&&validAddress&&validCodComuna&&validCodFormat&&
                                            validCodLoc&&validLatitude&&validLongitude&&validRetailer)}
                            >
                                Guardar Local
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AgregarLocal