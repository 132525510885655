import { requestData } from "Connection/transacciones"

const wedes = async(userDat) => {
    const jsonBody = {
        "hdr": {
            "txi": 58,
            "req": "wedes",
            "ott": true,
        },
        "dat": 
        userDat
    }

    // console.log(JSON.stringify(jsonBody))
    return await requestData(jsonBody)

}

export default wedes;