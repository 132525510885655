import { requestData } from "Connection/transacciones"
import { clean } from "Utils/rut";

const wenew = async ({newRut, newName, newSnam, newPhon, newMail, newProfile, newClient, newUst}) => {
    const jsonBody = {
        "hdr": {
            "txi": 57,
            "req": "wenew",
            "ott": true,
        },
        "dat": {
            "rut": clean(newRut).toUpperCase(),
            "name": newName,
            "snam": newSnam,
            "phon": newPhon,
            "mail": newMail,
            "prof": parseInt(newProfile, 10),
            "clie": parseInt(newClient, 10),
            "ust": newUst
            }
        } 
    return await requestData(jsonBody)
}

export default wenew;