// import locgt from 'Connection/transacciones/locgt';
import locrp from 'Connection/transacciones/locrp';
import React from 'react';
// import taskm from 'Connection/transacciones/taskm';

function useListaReporte({
    dataOption = 0
}) {
    const [values, setValues] = React.useState([]);
    const [isFetching, setIsFetching] = React.useState(false)
    const fetchLocales = async(filtros = {}) => {
        setIsFetching(true)
        // console.log("aca",filtros,"aca2",dataOption)

        const request = await locrp(filtros, dataOption)
        // console.log("req",request)

        if (request.ans.stx === "ok") {
            // console.log("reqi",request.ans.tas)
            setValues(request.ans.loc? request.ans.loc: []) 
        }
        setIsFetching(false)
    }
    return {
        values,
        options: values,
        fetchLocales,
        isFetching
    }

}

export default useListaReporte