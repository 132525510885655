import React,{useEffect, useState} from "react";
import { backend_request } from "Connection/backend_reduce";
import { actualizarInfoPagina } from "Utils/paginaInfo";
import CabeceraTicket from "./CabeceraTicket";
import TareasTicket from "./_ResumenTareas/TareasTicket";
import {Link, useLocation} from 'react-router-dom'
import ticks from "Connection/transacciones/ticks";
import { Spinner } from "react-bootstrap";


function TituloPagina() {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                        <Link to={"/Dashboard"} >Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={"/ListaTickets"}>
                            Tickets
                        </Link>
                    </li>
                    <li className="breadcrumb-item active">Resultado Ticket</li>
                </ol>
            </div>
            <h4 className="page-title">Resultado Ticket</h4>
        </div>
    )
}
// console.log(props);
const LayoutResultadoTicket = (props) => {
    useEffect(() => { actualizarInfoPagina(props); }, [props]);
    // Request Ticks
    const [datosTicket, setDatosTicket] = React.useState(undefined);
    const [loading, setIsLoading] = React.useState(false);
    const location = useLocation();
    useEffect(() => {
        const run = async() => {
            setIsLoading(true)
            // console.log("aca",sessionStorage)
            let idTicket = sessionStorage.getItem(location.key) // obtiene los valores del sessionStorage
            if (!idTicket) { // si no hay valor en sessionStorage 
                idTicket = localStorage.getItem("tic_id")
                
                // console.log("aca",localStorage)
                sessionStorage.setItem(location.key, idTicket)
                localStorage.removeItem("tic_id")
            }
            // console.log("aca2",sessionStorage)
            if(idTicket) {
                const request = await ticks({
                    idTicket: idTicket,
                    cantidad: 1                    
                })
                if(request.ans.stx === "ok"){
                    // console.log(request.ans.tas[0])
                    setDatosTicket(request.ans.tas[0])
                }

            }       
            setIsLoading(false)

        }
        run()
    }, [])

    return (
        <div className="container">
            <TituloPagina/>
            {loading && (
                <div className="text-center">
                    <Spinner animation="border"/>
                </div>
            )}
            <CabeceraTicket datosTicket={datosTicket}/>
            <TareasTicket datosTicket={datosTicket}/> 
                        
        </div>
    )
    


}

export default LayoutResultadoTicket