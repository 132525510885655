import React from "react"
import usloa from "Connection/transacciones/usloa"
import uslox from "Connection/transacciones/uslox"
import { isEqual } from "lodash";
import toast from "react-hot-toast";

// const  test = {"lid": 3,
// "cod": "J501JU",
// "nam": "Supermercado La Plaza",
// "adr": "La Plaza s/n",
// "com": "Calle Larga",
// "reg": "Region de Valparaiso",
// "ret": "Plazas Unidas"
// }
function useLocalesAsignadosUsuario ({uid}) {
    // valores
    const [localesIniciales, setLocalesIniciales] = React.useState([]) // locales iniciales
    const [localesAsignados, setLocalesAsignados] = React.useState([]); // locales para render
    const [localQuantity, setLocalQuantity] = React.useState(0)
    const isDirty = React.useMemo(() => { // determina si hay cambios
        // console.log("cambio", localesIniciales)
        return !isEqual(
            localesIniciales.map(item => item.lid), 
            localesAsignados.map(item => item.lid))
    }, [localesIniciales, localesAsignados]) 

    // metodos
    const cancelarCambios = () => setLocalesAsignados(localesIniciales) // reinicia los valores
    const agregarLocal = (nuevoLocal) => {
        const localInfo = {
            lid: nuevoLocal.lid,
            cod: nuevoLocal.cod,
            nam: nuevoLocal.nam,
            adr: nuevoLocal.adr,
            com: nuevoLocal.com,
            reg: nuevoLocal.reg,
            ret: nuevoLocal.ret,
        }
        setLocalesAsignados(locales => {
            return [...locales, localInfo]
        })
    }
    const eliminarLocal = (localId) => { // elimina a un local en base a su ID
        setLocalesAsignados(locales => locales.filter(local => local.lid !== localId))
    }
    // Cambios en el backend
    const [isUpdating, setIsUpdating] = React.useState(false)
    const confirmarCambiosBackend = async() => { 
        const localesAntes = localesIniciales.map(local => local.lid)
        const localesDespues = localesAsignados.map(local => local.lid)
        setIsUpdating(true)
        // console.log("par",uid,"antes",localesAntes,"despues",localesDespues)
        const request = await uslox({
            uid: uid,
            antes: localesAntes,
            despues: localesDespues,
        })
        // console.log("request",request)
        setIsUpdating(false)
        if (request.ans.stx === "ok"){
            setLocalesIniciales(localesAsignados)
            toast.success(request.ans.msg)
        } else {
            toast.error(request.ans.msg)
        }
    }

    // control de request
    const [isFetching, setIsFetching] = React.useState(false);
    const fetched = React.useRef(false)
    const fetch = React.useCallback(async() => {
        setIsFetching(true)
        const request = await usloa({uid: uid})
        fetched.current = true
        if (request.ans.stx === "ok"){
            setLocalesIniciales(request.ans.loa || [])
            setLocalesAsignados(request.ans.loa || [])
            setLocalQuantity(request.ans.knt)
        } 
        else if( request.asn.stx === "uk"){
            setLocalesAsignados([])
        }
        
        setIsFetching(false)
    },[])

    return {
        localesAsignados,
        fetched: fetched.current,
        fetch,
        isFetching,
        hasChanged: isDirty,
        localQuantity,
        agregarLocal,
        eliminarLocal,
        confirmarCambios: confirmarCambiosBackend,
        isUpdating,
        cancelarCambios,
    }

}

export default useLocalesAsignadosUsuario