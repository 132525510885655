import { requestData } from "Connection/transacciones"

export const locad = async(dep = {}) => {
    const jsonBody = {
        hdr: {
            txi: 80,
            req: "locad",
        },
        dat: {
            ...dep
        }
    }
    // console.log(jsonBody.dat)
    return await requestData (jsonBody)
}
export default locad;