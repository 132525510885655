import React, { useEffect, createRef } from 'react'
import toast from 'react-hot-toast';
import { Accordion, Button } from 'react-bootstrap'
import { actualizarInfoPagina } from 'Utils/paginaInfo';
// import FormularioCrearTicket from './formulario/FormularioCrearTicket';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Excel from 'exceljs';
import calen from "Connection/transacciones/calen";
import prmas from 'Connection/transacciones/prmas';
// import "./aa.css";
import Dropzone from 'react-dropzone';
import { set } from 'lodash';
import locmc from 'Connection/transacciones/locmc';
import { saveAs } from "file-saver";
import { numToCol } from 'Utils/excelutils';
import { getCountry } from "Authentication/Session";
import { getClient } from "Authentication/Session";
import { getClientID } from 'Authentication/Session';
import prloc from 'Connection/transacciones/prloc';
import Tabla from 'Components/Tabla/Tabla';
import { fechaHoy2, formatearFechaHora, mesActual, mesProximo } from "Utils/funcionesFechaHora";
import Select from 'react-select';
import prcal from 'Connection/transacciones/prcal';
import prloa from 'Connection/transacciones/prloa';
import { get } from 'jquery';





function Cargas() {
    const navigate = useNavigate()

    const [selectedOption, setSelectedOption] = React.useState(null)
    const [calendaSelected, setCalendaSelected] = React.useState([])
    const [selectedBid, setSelectedBid] = React.useState(null)

    const nextItem = () => {
        navigate("/presupuesto")
    }

    const client = React.useRef(getClient());

    const handlerDownload = async (e) => {
        const request = await prloa(selectedOption.value, calendaSelected,e)
        if (request.ans.stx === "ok") {
            toast.success(((t) => (<>
                Planilla descargada!
            </>)))
            if (selectedBid === null || selectedBid === 0) {
                setSelectedBid(request.ans.bid)
            }

            const data = JSON.parse(JSON.stringify(request.ans.loc))
            const headersRow4 = [" ", "Código", "loc_id", "Presupuesto($)", "N° Tareas", "Nombre Local", "Direccion",
                "Comuna", "Region", "Retailer", "KAM", "KAS"]
            // console.log("data", data)

            const wb = new Excel.Workbook();
            const ws = wb.addWorksheet('Hoja 1');
            const header = ["this", "cod", "lid", "prv", "ntc", "nam", "adr", "com", "reg", "ret", "kam", "kas"]
            const headerCol = []

            for (let i = 0; i < header.length; i++) {
                if (i === 0) {
                    headerCol.push({ key: header[i], width: 6 })
                }
                else if (i === 1) {
                    headerCol.push({ key: header[i], width: 8 })
                }
                else if (i === 2) {
                    headerCol.push({ key: header[i], width: 5.2 })
                }
                else if (i === 3) {
                    headerCol.push({ key: header[i], width: 14 })
                }
                else if (i === 3 || i === 4) {
                    headerCol.push({ key: header[i], width: 10 })
                }
                else if (i === 5 || i === 6) {
                    headerCol.push({ key: header[i], width: 28 })
                }
                else if (i === 7) {
                    headerCol.push({ key: header[i], width: 12.9 })
                }
                else if (i === 8) {
                    headerCol.push({ key: header[i], width: 11.6 })
                }
                else {
                    headerCol.push({ key: header[i], width: 10.22 })
                }
            }
            ws.getRow(4).values = header
            ws.columns = headerCol
            data.map(item => {
                ws.addRow(item)
            })

            let row = ws.getRow(4)
            for (let i = 1; i <= row.cellCount; i++) {
                row.getCell(i).value = headersRow4[i - 1]
            }


            ws.eachRow(function (row, rowNumber) {
                row.eachCell((cell, colNumber) => {
                    if (rowNumber === 4 && !(colNumber >= 4 && colNumber <= 10)) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'ff0070c0' }
                        }
                        cell.font = {
                            color: { argb: 'FFFFFF' },
                        }
                    }
                    else if (rowNumber === 4 && (colNumber >= 4 && colNumber <= 10)) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            // fgColor: { argb: 'ffffcc66' }
                            fgColor: { argb: 'ff0070c0' }
                        }
                        cell.font = {
                            // color: { argb: 'ff000000' },
                            color: { argb: 'FFFFFF' },
                        }
                    }

                    if (colNumber !== 1) {
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                    }
                    cell.alignment = { wrapText: false, horizontal: 'left' };
                })
                row.commit();
            });

            const cellA1 = ws.getCell('A1');
            cellA1.value = 'Año';
            cellA1.font = {
                color: { argb: 'FFFFFF' },
            }
            cellA1.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff0070c0' }
            }
            cellA1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cellA1.alignment = { wrapText: false, horizontal: 'left' };
            const cellB1 = ws.getCell('B1');
            cellB1.value = 'Mes';
            cellB1.font = {
                color: { argb: 'FFFFFF' },
            }
            cellB1.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff0070c0' }
            }
            cellB1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cellB1.alignment = { wrapText: false, horizontal: 'left' };
            // const cellC1 = ws.getCell('C1');
            // cellC1.value = 'Hora';
            // cellC1.font = {
            //     color: { argb: 'FFFFFF' },
            // }
            // cellC1.fill = {
            //     type: 'pattern',
            //     pattern: 'solid',
            //     fgColor: { argb: 'ff0070c0' }
            // }
            // cellC1.border = {
            //     top: { style: 'thin' },
            //     left: { style: 'thin' },
            //     bottom: { style: 'thin' },
            //     right: { style: 'thin' }
            // };
            // cellC1.alignment = { wrapText: false, horizontal: 'left'};
            const valueCellA1 = ws.getCell('A2');
            // valueCellA1.value = Number(date.getFullYear())
            valueCellA1.value = Number(selectedOption.value.toString().substring(0, 4))
            valueCellA1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            valueCellA1.alignment = { wrapText: false, horizontal: 'left' };
            const valueCellB1 = ws.getCell('B2');
            // valueCellB1.value = Number(date.getMonth()+1)
            valueCellB1.value = Number(selectedOption.value.toString().substring(4, 6))
            valueCellB1.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
            valueCellB1.alignment = { wrapText: false, horizontal: 'left' };
            // const valueCellC1 = ws.getCell('C2');
            // valueCellC1.value = 800
            // valueCellC1.border = {
            //     top: { style: 'thin' },
            //     left: { style: 'thin' },
            //     bottom: { style: 'thin' },
            //     right: { style: 'thin' }
            // };
            // valueCellC1.alignment = { wrapText: false, horizontal: 'left'};

            // ws.mergeCells('D3:E3');
            // const cellD4 = ws.getCell('D3');
            // cellD4.value = "Presupuesto";
            // cellD4.font = {
            //     color: { argb: 'FFFFFF' },
            // }
            // cellD4.fill = {
            //     type: 'pattern',
            //     pattern: 'solid',
            //     fgColor: { argb: 'ff0070c0' }
            // }   
            // cellD4.border = {
            //     top: { style: 'thin' },
            //     left: { style: 'thin' },
            //     bottom: { style: 'thin' },
            //     right: { style: 'thin' }
            // };
            // cellD4.alignment = { wrapText: false, horizontal: 'center'};

            const emptyCell = ws.getCell('A4');
            emptyCell.fill = {
                type: 'pattern',
                // pattern: 'solid',
                fgColor: { argb: 'ffffffff' }
            }

            // for(let i = 5; i <= 5 + data.length; i++){
            //     for(let j = 11; j <= 31; j++){
            //         ws.getCell(numToCol(j)+i.toString()).border = {
            //             top: { style: 'thin' },
            //             left: { style: 'thin' },
            //             bottom: { style: 'thin' },
            //             right: { style: 'thin' }
            //         }
            //     }
            // }
            for (let i = 11; i < 12; i++) {
                for (let j = 5; j <= 4 + data.length; j++) {
                    ws.getCell(numToCol(i) + j.toString()).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    }
                }
            }
            // for (let i = 2; i <= 11+ maxReq; i++) {
            //     for (let j = 6; j <= 5 + exl.length; j++) {
            //       worksheet.getCell(numToCol(i)+j.toString()).border = {
            //         top: { style: 'thin' },
            //         left: { style: 'thin' },
            //         bottom: { style: 'thin' },
            //         right: { style: 'thin' }
            //       };
            //     }
            //    }

            const buffer = await wb.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            const blob = new Blob([buffer], { type: fileType });
            saveAs(blob, client.current + " carga de Presupuesto" + fileExtension);



        }
        else if (request.ans.stx === "nk") {
            toast.error(((t) => (<>
                {request.ans.msg}
            </>)))
        }
    }


    const [selectedFile, setSelectedFile] = React.useState(null);
    const [isUpload, setIsUpload] = React.useState(false);
    const dropzoneRef = createRef();
    const openDialog = () => {
        // Note that the ref is set async,
        // so it might be null at some point 
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    const onDrop = (files) => {
        if (files.length > 0) {
            setIsUpload(true)
            setSelectedFile(files[0]);
        }

    };

    const handlerUpload = async (e) => {

        const dataRequest = {};
        const data = [];
        const wb = new Excel.Workbook();
        const reader = new FileReader()

        reader.readAsArrayBuffer(selectedFile)
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer).then(workbook => {
                let maxDays = 29
                // console.log(workbook, 'workbook instance')
                workbook.eachSheet((sheet, id) => {
                    // if (id === 3) {
                    sheet.eachRow((row, rowIndex) => {
                        if (rowIndex === 2) {
                            dataRequest['cli'] = parseInt(getClientID())
                            dataRequest["ame"] = Number(String(row.values[1]) + String(row.values[2]).padStart(2, '0'))
                            // dataRequest["del"] = e
                            dataRequest["bid"]=selectedBid
                        }
                        if (rowIndex === 4) {
                            maxDays = row.values.length
                        }
                        if (rowIndex >= 5) {
                            let obj = {}
                            obj['lid'] = row.values[3]
                            obj['pre'] = row.values[4]
                            data.push(obj)
                        }
                    })
                    dataRequest['loc'] = data
                    // console.log(dataRequest)
                    const req = {}
                    req['mcf'] = dataRequest
                    // console.log(req)
                    // handler2(req)
                    handlerCarga(req)
                    // }
                })
            })
        }
    }

    const handlerCarga = async (req) => {
        const request = await prmas(req)
        if (request.ans.stx === "ok") {
            toast.success(((t) => (<>
                {request.ans.msg}
            </>)))
        }
        else if (request.ans.stx === "nk") {
            toast.error(((t) => (<>
                {request.ans.msg}
            </>)))
        }
    }

    const getData = useLocation().state
    React.useEffect(() => {
        const run = async () => {
            setSelectedOption(getData.fechaSelected)
            setCalendaSelected(getData.calendaSelected)
            setSelectedBid(getData.unicCalenda[0])


        }
        run()
        
    }
    ,[getData])

        
    if (getData === undefined) {
        navigate("/presupuesto")
    }
    else {
    return (
        <>
            {/* <div >
                <Button onClick={() => nextItem()} style={{ textAlign: "left" }} >
                    volver
                </Button>
            </div> */}
            <div >
                <div style={{ textAlign: "center" }}>
                    Fecha seleccionada: {selectedOption ? selectedOption.label : <></>}
                </div>
            </div>
            <div className="card-body" style={{ textAlign: "center" }}>
                <div className="col-md-6" style={{ textAlign: "center", margin: "0 auto" }}>
                    
                <div className="row">
                        <div className="col">
                        {/* <div className="col-md-6"> */}
                        <Button onClick={() => handlerDownload(0)} variant="outline-secondary" style={{ textAlign: "left" }} >
                            Descargar Planilla
                        </Button>
                        </div>
                        {/* <div className="col-md-6">
                            <Button onClick={() => { handlerDownload(1) }} variant="outline-secondary" >Reestablecer y Descargar Planilla</Button>
                        </div> */}
                    </div>
                    {/* <div>
                        <Button onClick={() => handlerDownload()} variant="outline-secondary" style={{ textAlign: "lext" }} >
                            Descargar Planilla
                        </Button>
                    </div> */}
                    <br />
                    <Dropzone ref={dropzoneRef} onDrop={onDrop} noClick noKeyboard>
                        {({ getRootProps, getInputProps, acceptedFiles }) => {
                            return (
                                <div className="container">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <p>Arrastre y suelte el archivo aquí</p>
                                        <p>o</p>
                                        <Button variant="outline-secondary" onClick={openDialog}>
                                            Explorar archivos
                                        </Button>
                                    </div>
                                    <aside>
                                        <h4>Archivo seleccionado:</h4>
                                        <ul>
                                            {acceptedFiles.map(file => (
                                                <li key={file.path}>
                                                    {file.path}
                                                </li>
                                            ))}
                                        </ul>
                                    </aside>
                                </div>
                            );
                        }}
                    </Dropzone>
                    <div className="row">
                        <div className="col">
                            <Button onClick={() => { handlerUpload(0) }} disabled={!isUpload}>Enviar</Button>
                        </div>
                        {/* <div className="col-md-6">
                            <Button onClick={() => { handlerUpload(1) }} disabled={!isUpload}>Reestablecer y Enviar</Button>
                        </div> */}
                        {/* <div className="col-md-6">
                            <Button onClick={() => { console.log(selectedBid) }}>look</Button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>

    )}
}
export default Cargas;