import gehol from 'Connection/transacciones/gehol'
import weall from 'Connection/transacciones/weall'
import React from 'react'

function useKam() {
    const [options, setOptions] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)

    const fetch = async(dependencies = {}) => {
        setIsFetching(true)
        const request = await weall({nType:"kam"});
        if(request.ans.stx === "ok") {
            if(request.ans.wus) {
                const newValues = request.ans.wus.map(item => ({
                    "value": item.uid,
                    "label": item.name +" "+item.snam,   
                }))
                setOptions(newValues)
            } else {
                setOptions([])
            }
        }
        setIsFetching(false)

    }

    return {
        options,
        isFetching,
        fetch
    }
    
}

export default useKam