import { requestData } from "Connection/transacciones"


const geloc = async(dat = {}) => {
    const jsonBody = {
        hdr: {
            txi: 64,
            req: "geloc",
        },
        dat: {
            ful: 1,
            hol: dat.hol,
            ret: dat.ret,
            zon: dat.zon,
            nie: dat.nie,
            reg: dat.reg,
            ciu: dat.ciu,
            com: dat.com,
        }
    }
    return await requestData (jsonBody)

}
export default geloc
