import { requestData } from "Connection/transacciones"

const usclx = async({uid, cli, cla}) => {
    const jsonBody = {
        hdr: {
            txi: 86,
            req: "usclx",
            ott: true,
        },
        dat: {
            uid:uid,
            cli:cli,
            cla:cla
        }
    }
    // console.log(jsonBody)
    return await requestData(jsonBody)

}

export default usclx;