import clall from "Connection/transacciones/clall";
import React, { useCallback } from "react";


function useListaClientes () {
    const [lista, setListaClientes] = React.useState([]);
    const [isFetching, setIsFetching] = React.useState();

    const fetch = useCallback(async() => {
        setIsFetching(true);
        const request =  await clall(1);
        if (request.ans.stx === "ok") {
            setListaClientes(request.ans.wus || []);
        }
        setIsFetching(false);
    },[])

    return  {
        lista,
        isFetching,
        fetch, 
    }
}

export default useListaClientes