import { requestData } from "Connection/transacciones"
// import { fechaDefault, limpiarFecha } from "Utils/funcionesFechaHora"

const prloa = async (date,cal,del) => {
    const jsonBody = {
        hdr: {
            txi: 96,
            req: "prloa",
            ott: true,
        },
        dat: {
            ame:date,
            cal:cal
        }
    }
    console.log(jsonBody)
    return await requestData(jsonBody)
}

export default prloa;