import { getTablaBanco, getTablaComunas, getTablaRegiones, getTablaTipoCuenta } from "Utils/tablasDecodificacion";
import React from 'react'
import usall from "Connection/transacciones/usall";
import { useLocation } from "react-router-dom";
// import StorageCacheApp from "Utils/StorageCacheApp";

const USUARIOS_APP_CACHE = "tablaUsuariosApp"
function useUsuariosApp() {
    const [values, setValues] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(false)

    const refreshUsers = async(dependencies = {}) => {
        setIsFetching(true)
        const request = await usall(dependencies);
        if (request.ans.stx === "ok"){
            const tablaComunas = await getTablaComunas();
            const tablaRegiones = await getTablaRegiones();
            const tablaBancos = await getTablaBanco();
            const tipoCuentas = await getTablaTipoCuenta();
            if (request.ans.wus) {
                const newValues = request.ans.wus.map(user => {
                    return ({
                        uid: user.uid,
                        rut: user.rut,
                        name: user.name,
                        snam: user.snam,
                        mail: user.mail,
                        acnu: user.acnu,
                        ndoc: user.ndoc,
                        phon: user.phon,
                        addr: user.addr, 
                        comuna: tablaComunas.find(comuna => comuna.cod === user.comu)?.nam || '',
                        region: tablaRegiones.find(region => region.cod === user.regi)?.name || '',
                        cuenta: tipoCuentas.find(tipoCuenta => tipoCuenta.cod === user.acty)?.name || '',
                        banco: tablaBancos.find(banco => banco.cod === user.bank)?.name || ''
                    })
                })
                // StorageCacheApp.addItem({
                //     key: USUARIOS_APP_CACHE,
                //     value: newValues,
                //     hash: location.key 
                // })
                setValues(newValues)
            } else {
                // StorageCacheApp.addItem({
                //     key: USUARIOS_APP_CACHE,
                //     value: [],
                //     hash: location.key 
                // })
                setValues([])
            }
        }
        setIsFetching(false)
    }
    
    return {
        values,
        refreshUsers,
        isFetching
    }
}

export default useUsuariosApp