import React from "react";
import { formatearFecha } from "Utils/funcionesFechaHora";


function CabeceraTicketContenido ({datosTicket}) {
    const iconStyle = {fontSize: "20px"}
    return (
        <div className="card ribbon-box pb">
            <div className="card-body">

                <div>
                    <h4 className="text-primary float-start mt-0">
                        <strong>
                        <i className="mdi mdi-ticket-outline me-1"></i>
                        Ticket: 
                        </strong>

                        {" " + datosTicket.tna}
                    </h4>
                </div>


                <h5 className="text-primary float-end mt-0">
                    {formatearFecha(datosTicket.crt)}
                </h5>
                <div className="ribbon-content">
                    <div className="row">
                        <div className="col-3">
                            <strong>
                                Tipo de ticket:
                            </strong>
                        </div>
                        <div className="col">
                            {datosTicket.ttd}
                        </div>
                    </div>



                    <div className="row mt-2">
                        <div className="col-3">
                            <strong>
                                Descripción:
                            </strong>
                        </div>
                        <div className="col">
                        {datosTicket.des.split("^").map((subText, index) => <p key={index} style={{marginBottom: 0}}>{subText}</p>)}
                        </div>
                    </div>
                    <hr />
                    <div className="row mt-2">
                        {datosTicket.tim !== 0 && 
                        <div className="col text-center">
                        {/* <i className="uil uil-clipboard-alt text-muted" style={iconStyle}></i> */}
                            <i className="uil uil-stopwatch text-muted" style={iconStyle}></i>

                            <h4>
                                <span>
                                    {datosTicket.tim}
                                </span>
                            </h4>
                            <p className="text-muted font-15 mb-0">Tiempo</p>
                        </div>
                                
                        }
                        
                        <div className="col text-center">
                            <i className="uil uil-images text-muted" style={iconStyle}></i>
                            <h4>
                                <span>
                                    {datosTicket.npi}
                                </span>
                            </h4>
                            <p className="text-muted font-15 mb-0">Fotos</p>
                        </div>
                        <div className="col text-center">
                            <i className="uil uil-comment-question text-muted" style={iconStyle}></i>
                            <h4>
                                <span>
                                    {datosTicket.nqu}
                                </span>
                            </h4>
                            <p className="text-muted font-15 mb-0">Requerimientos</p>
                        </div>
                        <div className="col text-center">
                            <i className="uil uil-archive text-muted" style={iconStyle}></i>
                            <h4>
                                <span>
                                    {datosTicket.nre}
                                </span>
                            </h4>
                            <p className="text-muted font-15 mb-0">Reposiciones</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
function CabeceraTicket ({datosTicket}){
    
    if(datosTicket === undefined) {
        return null
    }

    return <CabeceraTicketContenido datosTicket={datosTicket} />
    
}

export default CabeceraTicket;