import React from 'react'
import { MultiSelect } from 'react-multi-select-component'
import localization from "Assets/localization/multiSelect.json"
import useRetails from 'Hooks/Selects/useRetails'

function MultiSelectRetails({
    isDisabled,
    dependencies = {}, 
    onChange = () => {},
    selected=[],
    name
}) {
    const retails = useRetails()
    const fetched = React.useRef(false);

    React.useEffect(() => {
        fetched.current = false; // si se actualiza una dependencia se debe menciar que esta desactualizado 
    }, [
        JSON.stringify(dependencies.hol)
    ])

    const onToggle = async(isOpen) => {
        if(isOpen && !fetched.current) {
            fetched.current = true; // Lista de comunas actualizados? SI
            console.log(dependencies)
            await retails.fetch(dependencies);
        }
    }

    const handleOnChange = (values) => {
        const sorted = values.sort((a,b) => a.value - b.value)
        onChange(sorted, name)
    }

    
    return (
        <MultiSelect 
            value={selected}
            overrideStrings={localization}
            onChange={handleOnChange}
            options={retails.options}
            onMenuToggle={onToggle}
            disabled={isDisabled}
            isLoading={retails.isFetching}
        />
    )
}


export default MultiSelectRetails